import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import SuperAdminPlansPopupTable from "./SuperAdminPlansPopupTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function SuperAdminPlansPopup() {
  const [open, setOpen] = React.useState(false);
  return (
    <Box>
        <Box sx={style}>
          <Box>
            <Typography sx={{ color: "#25C027", fontSize: "32px" }}>
              Buy a Plan
            </Typography>
          </Box>
          <Box sx={{ mt: "40px" }}>
            <SuperAdminPlansPopupTable />
          </Box>
        </Box>
    </Box>
  );
}
