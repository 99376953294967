import { Box, Container, Typography,Modal,Stack } from "@mui/material";
import ContactInformation from "./ContactInformation";
import classes from "./EmployeeDashboardComponent.module.css";
import PostJob from "./PostJob";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { addJob } from "../../firebase/functions";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
const EmployeeDashboardPostNewJob = () => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    console.log(data);
    if (parseInt(data.maxSalary )<= parseInt(data.minSalary)) {
      console.log(data.minSalary, data.maxSalary);
      setError("minSalary", {
        type: "custom",
        message: "Minimum salary must be less than maximum salary",
        shouldFocus: true,
      });
      return;
    }
    //
    setLoading(true);

    try {
      const result = await addJob({
        ...data,
        skillsRequired: data.skillsRequired.split(/\r?\n/),
        keyResponsibilities: data.keyResponsibilities.split(/\r?\n/),
      });
      console.log(result.data);
      setLoading(false);
      toast.success("Job posted successfully");
      navigate("/employee-dashboard/manage-jobs");
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error(`${err.message}`);
      setLoading(false);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Container>
        <Box>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Post a New Job
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <PostJob
              setValue={setValue}
              getValues={getValues}
              skills={{ setSkills, skills }}
              register={register}
              setTags={setTags}
              errors={errors}
            />
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <ContactInformation
              loading={loading}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </Box>
        </Box>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Box>
  );
};

export default EmployeeDashboardPostNewJob;
