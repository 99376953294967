import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { getJobOverview } from "../../firebase/functions";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsSeeTracedIcon,
} from "../../utilities/Icons/Icons";
import {useNavigate} from 'react-router-dom';
function TableRowComponent({ id,time }) {
  const navigate=useNavigate();
  const [jobData, setJobData] = useState({});
  useEffect(() => {
    getJobOverview({ jobId: id }).then(({ data }) => {
      
      setJobData(data);
    });
  },[]);
  return (
    <TableRow
      key={id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        minHeight: "50px",
        padding: "50px",
      }}
    >
      <TableCell component="th" scope="row" sx={{ maxWidth: "25%" }}>
        <Typography>{jobData.jobTitle}</Typography>
        <Typography>{jobData?.city}</Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "25%" }}>
        <Typography>{fireBaseTime(time).toDateString()}</Typography>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "25%" }}>
        {jobData?.status}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          minWidth: "25%",
          minHeight: "100px",
        }}
      >
        <Box
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#F0FAF0",
          }}
        >
          <IconButton onClick={()=>{navigate(`/job-profile/${id}`)}}>
          <EmployeeDashboardManageJobsSeeTracedIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default TableRowComponent;
