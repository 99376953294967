import { Box, Container, Typography } from "@mui/material";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon
} from "../../../utilities/Icons/Icons";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#222222",
        borderRadius: "30px 30px 0px 0px",
        py: "20px",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography sx={{ color: "#fff", fontFamily: "Sofia Pro", fontSize: "14px" }}>
              &copy; 2022 MFGWorx. All Right Reserved.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ color: "#25C027", fontWeight: 600, fontSize: "32px" }}
            >
              MFGWorx
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "25px" }}>
            <FacebookIcon />
            <TwitterIcon />
            <InstagramIcon />
            <LinkedinIcon />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
