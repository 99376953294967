import { CoPresentOutlined } from "@mui/icons-material";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getJobOverview, getLogo } from "../../firebase/functions";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsSeeTracedIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
} from "../../utilities/Icons/Icons";

function ShortlistedTableRow({ data }) {
  const [jobData, setJobData] = useState({});
  const [logo, setLogo] = useState("");
  useEffect(() => {
    getJobOverview({ jobId: data.jobId }).then(({ data }) => {
      
      setJobData(data);
      getLogo({ orgId: data.organisationId }).then(({ data }) => {
        
        setLogo(data);
      });
    });
  }, []);
  return (
    <TableRow
      key={data.jobId}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        minHeight: "50px",
        padding: "50px",
      }}
    >
      <TableCell component="th" scope="row" sx={{ maxWidth: "25%" }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box
            sx={{
              backgroundColor: "#1967D2",
              width: "50px",
              height: "50px",
              display: "flex",
              overflow: "hidden",
              borderRadius: "8px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img width="50px" src={logo} />
          </Box>
          <Box>
            <Box>
              <Typography
                sx={{
                  color: "#0E0E0E",
                  fontFamily: "Sofia Pro",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                {jobData.jobTitle}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FeturedJobsFordIcon />
                <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                  {jobData.organisationName}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FeturedJobsLocationIcon />
                <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                  {`${jobData?.country} , ${jobData.city}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "25%" }}>
        {jobData.jobAddedAt
          ? fireBaseTime(jobData.jobAddedAt).toDateString()
          : null}
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "25%" }}>
        <Typography>{jobData.status}</Typography>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          minWidth: "25%",
          minHeight: "100px",
        }}
      >
        <Box
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#F0FAF0",
          }}
        >
          <EmployeeDashboardManageJobsSeeTracedIcon />
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default ShortlistedTableRow;
