import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,Modal
} from "@mui/material";
import { auth, updatePasswords } from "../../firebase/firebaseConfig";
import classes from "./CandidateDashboardChangePassword.module.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useState } from "react";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
const CandidateDashboardChangePassword = () => {
  const [loadingAbout , setLoadingAbout] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      if (data.newPassword !== data.confirmPassword) {
        setError("confirmPassword", {
          type: "custom",
          message: "Passwords do not match",
        });
        return 0;
      }
      // auth.
      console.log(data);
      setLoadingAbout(true);
      const result = await updatePasswords(data.oldPassword, data.newPassword);
      toast.success("Password updated successfully");
      console.log(result);
      setLoadingAbout(false);
    } catch (e) {
      console.log(JSON.stringify(e), "this is the error message");
        setLoadingAbout(false);
        toast.error(e.message);  
    }
  };
  return (
    <Container>
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Change password
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "60px",
          padding: "30px",
          background: "#FFFFFF",
          boxShadow: "0px 2px 20px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
        }}
      >
        <Typography
          sx={{
            color: "#202124",
            fontFamily: "Sofia Pro",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Change Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} sx={{ mt: "30px" }}>
            <Box className={classes.input}>
              <label>Old Passwords</label>
              <TextField
                {...register("oldPassword", {
                  required: "Old Password is required",
                })}
                error={Boolean(errors.oldPassword)}
                helperText={errors.oldPassword?.message}
                type="password"
                placeholder="******"
              />
            </Box>
            <Box className={classes.input}>
              <label>New Password</label>
              <TextField
                {...register("newPassword", {
                  required: "New Password is required",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message:
                      "Password must contain at least one uppercase, one lowercase and a number ",
                  },
                })}
                error={Boolean(errors?.newPassword)}
                helperText={errors?.newPassword?.message}
                type="password"
                placeholder="******"
              />
            </Box>
            <Box className={classes.input}>
              <label>Confirm Password</label>
              <TextField
                {...register("confirmPassword", {
                  required: "Please confirm your password",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message:
                      "Password must contain at least one uppercase, one lowercase and a number ",
                  },
                })}
                error={Boolean(errors?.confirmPassword)}
                helperText={errors?.confirmPassword?.message}
                type="password"
                placeholder="******"
              />
            </Box>
            <Box>
              <Button
                type="submit"
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
              >
                Update
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
      <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Container>
  );
};

export default CandidateDashboardChangePassword;
