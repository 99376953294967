import { Box, Button, Typography } from "@mui/material";
import { signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
import {
  FeturedJobsClockIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
  FeturedJobsMoneyIcon,
} from "../../../utilities/Icons/Icons";
import FordLogo from "../../../utilities/images/ford.png";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebase/firebaseConfig";
import { applyForJob, getLogo } from "../../../firebase/functions";
import ApplyButton from "../../../ApplyJob/ApplyButton";
import { fireBaseTime } from "../../../Helpers/TimeConvert";
import TimeAgo from "javascript-time-ago";
import ru from "javascript-time-ago/locale/ru.json";
function JobCard({ data, id }) {
  const [logo, setLogo] = useState("");
  TimeAgo.addDefaultLocale(en);
  TimeAgo.addLocale(ru);
  const navigate = useNavigate();
  useEffect(() => {
    getLogo({ orgId: data.organisationId }).then(({ data }) => {
      setLogo(data);
    });
  }, []);
  // const user = UserAuth();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "30px",
        background: "#fff",
        padding: "30px",
        borderRadius: "20px",
        mt: "30px",
        boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
        "&:hover": {
          background: "#25C027",
          color: "#fff",
          transition: "0.4s ease",
        },
      }}
      onClick={() => navigate(`/job-profile/${id}`)}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Box
          sx={{
            backgroundColor: "#262E50",
            width: "60px",
            height: "60px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ width: "52px", height: "20px" }} src={logo} alt="" />
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: "#0E0E0E",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              {data.jobTitle}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              mt: "5px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <FeturedJobsFordIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {data.organisationName}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <FeturedJobsLocationIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {data.city}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <FeturedJobsClockIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                <ReactTimeAgo
                  date={fireBaseTime(data.jobAddedAt).toUTCString()}
                  locale="en-US"
                />
                {/* {fireBaseTime(data.jobAddedAt).toUTCString()} */}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <FeturedJobsMoneyIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {`$${data.minSalary} - $${data.maxSalary}/${data.salaryType}`}
                {console.log(data)}
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{
                  color: "#1967D2",
                  background: "#DDE8F8",
                  width: "90px",
                  height: "25",
                  padding: "5px 15px",
                  fontFamily: "Sofia Pro",
                  fontSize: "13px",
                  borderRadius: "60px",
                }}
              >
                {data.jobType}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        <ApplyButton text={"Apply"} id={id} />
      </Box>
    </Box>
  );
}

export default JobCard;
