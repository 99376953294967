import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetType } from "../context/authContext";
import { initMessage, messageCheck } from "../firebase/functions";
import { CandidatedProfileMessageIcon } from "../utilities/Icons/Icons";
function MessageButton({ applicantId }) {
  const navigate = useNavigate();
  const userType = GetType();
  const [permission, setPermission] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    console.log("useeffectCheckingMessage");
    console.log("checking init message"+applicantId)
    messageCheck({ candidateId: applicantId })
      .then(({ data }) => {
        console.log("PERMSSSION", data);
        setPermission(data);
      })
      .catch((data) => {
        console.log("PERMSSSIONerroe", data);
        setPermission(data);
      });
  }, [applicantId]);
  const handleMessageInit = () => {
    setLoading(true);
    initMessage({ candidateId: applicantId }).then(({ data }) => {
      console.log("MEEEESAGWE", data);
      setLoading(false);
      navigate("/employee-dashboard/message");
      //   toast.success(data);
    });
  };
  return (
    <>
      {(userType.role == "Recruiter"  &&
      permission == true) ||( userType.role == "Agent") ? (
        <LoadingButton
          onClick={() => handleMessageInit()}
          loading={loading}
          // startIcon={<MessageIcon />}
          loadingIndicator={<CircularProgress />}
        >
          {/* {applicantId} */}
          <Box
            sx={{
              width: "50px",
              height: "50px",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: { xs: "#FFF", md: "#25C027" },
              fill: { md: "#FFF", xs: "#25C027" },
            }}
          >
            <CandidatedProfileMessageIcon />
          </Box>
        </LoadingButton>
      ) : null}
    </>
  );
}

export default MessageButton;
