import { async } from "@firebase/util";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  TextField,
  Stack,
  MenuItem,
  Typography,
  Autocomplete,
  Tooltip,
  IconButton,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import userEvent from "@testing-library/user-event";
import {
  collection,
  FieldPath,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import * as React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import {
  inviteEmployee,
  inviteEmployeeMfgworx,
  sendCandidate,
  teamAssignCompany,
  teamResumeUpdate,
  uploadResume,
} from "../../firebase/functions";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classess from "../EmployeeDashboardComponent/EmployeeDashboardComponent.module.css";
import { useEffect } from "react";
import convertBase64 from "../../Helpers/fileConvert";
import { EmployeeDashboardComponentSeeTracedIcon } from "../../utilities/Icons/Icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function TeamDashboardSendResume({ orgIdArray, userId, flag1, setflag1 }) {
  const agreementRef = React.useRef(null)
  const [open, setOpen] = React.useState(false);
  const [companyId, setCompanyId] = React.useState("");
  const [jobId, setJobId] = React.useState("");
  const [loadingAssign, setLoadingAssign] = React.useState(false);
  const [candidateId, setCandidateId] = React.useState("");

  const [condidateArray, setcondidateArray] = React.useState([]);
  const [jobArray, setJobArray] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [delStat, setDelete] = React.useState(false);
  const [btnText, setBtnText] = React.useState("Upload Pdf");
  const [resumeUrl , setResumeUrl] = React.useState("")
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getDashboardDetails = async (id) => {
    setloading(true);
    const fetchApplications = query(
      collection(db, "Jobs"),
      where("organisationId", "==", id)
    );
    await getDocs(fetchApplications)
      .then((docSnap) => {
        let temp = docSnap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setJobArray(temp);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
      });
  };
  useEffect(() => {
    getCandidateDetails();
  },[])
  const getCandidateDetails = async (id) => {
    setloading(true);
    const fetchApplications = query(
      collection(db, "Users"),
      where("type", "==", "user")
    );
    await getDocs(fetchApplications)
      .then((docSnap) => {
        let temp = docSnap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setcondidateArray(temp);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
      });
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    let temp = false;
    if (candidateId != "") {
      temp = true
    }
    setloading(true);
    sendCandidate({ ...data, resumeUrl: resumeUrl, inPortal: temp , candidateId:candidateId,jobId:jobId })
      .then(() => {
        toast.success("Invitation sent successfully");
        setflag1(flag1+1)
        handleClose();
        reset();
        setloading(false);
      })
      .catch((e) => {
        toast.error(e.message);
        setloading(false);
      });
  };
  const getResume = async (file) => {
    console.log("getResume");
    convertBase64(file).then((res) => {
      handleUploadResume(res);
    });
  };
  const handleUploadResume = async (res) => {
    console.log("handleUploadResume");
    setBtnText("Uploading");
    await teamResumeUpdate({
      resume: res,
    })
      .then(({ data }) => {
        setUpload(true);
        toast.success("Resume Uploaded Successfully");
        console.log(data);
        setResumeUrl(data);
        setDelete(false);
        setBtnText("Change File");
      })
      .catch((e) => {
        setloading(false);
      });
  };
  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    agreementRef.current?.click();
  };
  return (
    <Box>
      <Button
        onClick={handleOpen}
        sx={{
          color: "#FFF",
          backgroundColor: "#25C027",
          fontSize: "10px",
          fontWeight: 600,
          borderRadius: "10px",
          padding: "10px 25px",
          "&:hover": {
            backgroundColor: "#25C027",
          },
        }}
      >
        Send Resume
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classess.hidescrollbar}
          sx={{
            ...style,
            width: { xs: "95%", md: "50%" },
            overflow: "scroll",
            height: "90%",
          }}
          component={"form"}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing={4}>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "30px",
                  fontWeight: 500,
                }}
              >
                Send Resume
              </Typography>
            </Box>
            <TextField
              labelId="demo-multiple-chip-label"
              label="Company Name"
              name="teamSize"
              select
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value);
                getDashboardDetails(e.target.value);
                setValue("orgId", e.target.value);
              }}
              color="success"
              MenuProps={MenuProps}
            >
              {orgIdArray.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  {name.organisationName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              labelId="demo-multiple-chip-label"
              label="Role"
              name="role"
              select
              value={jobId}
              onChange={(e) => {
                setJobId(e.target.value);
                setValue("jobId", e.target.value);
                console.log(e.target.value);
              }}
              color="success"
              MenuProps={MenuProps}
            >
              {jobArray.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  {name.jobTitle}
                </MenuItem>
              ))}
            </TextField>
            <Autocomplete
              id="tags-outlined"
              options={condidateArray}
              getOptionLabel={(option) =>
                `${option.firstName} - ${option.email}`
              }
              onChange={(event, value) => {
                console.log(value);
                setCandidateId(value.id);
                setValue("candidateId", value.id);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Please enter Candidate name"
                  placeholder="Candidate"
                  variant="standard"
                  multiline
                />
              )}
            />
            <Box
              sx={{
                width: "35%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  width: "450px",
                  height: "180px",
                  border: "2px dashed #1E3758",
                  borderRadius: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <Button
                  component="label"
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    border: "2px dashed #1C1B1F",
                    display: "flex",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "#FFF",
                    },
                  }}
                  onClick={() => {
                    handleUploadClick();
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </Box>
                </Button>
                <Typography sx={{ color: "#1E3758" }}>{btnText}</Typography>
              </Box>

              <input
                ref={agreementRef}
                style={{
                  display: "none",
                }}
                type="file"
                accept="application/pdf, application/vnd.ms-excel"
                className="course-builder-inputImg"
                id="agreementpdf"
                onChange={(e) => {
                  return getResume(e.target.files[0]);
                }}
              />
            </Box>

            {upload ? <iframe src={resumeUrl} title="pdf"></iframe> : <></>}

            <TextField
              id="outlined-basic"
              label="Note"
              variant="outlined"
              color="success"
              multiline
              {...register("teamNote", { required: "Note is required" })}
              rows={5}
              sx={{
                width: "100%",
                mt: "10px",
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
              // error={Boolean(errors.aboutUs)}
              // helperText={errors.aboutUs && errors.aboutUs.message}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <LoadingButton
                loading={loadingAssign}
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
                type="submit"
              >
                Send
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
}

export default TeamDashboardSendResume;
