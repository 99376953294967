import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import { LoadingButton } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsPencilTracedIcon,
} from "../../utilities/Icons/Icons";
import AddIcon from "@mui/icons-material/Add";
import {
  addAward,
  deleteAward,
  editAward,
  getAward,
} from "../../firebase/functions";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
const Awards = () => {
  const [render, setRender] = React.useState(false);
  const [award, setAward] = React.useState([]);
  useEffect(() => {
    getAward().then(({ data }) => {
      
      setAward(data);
    });
  }, [render]);
  const [add, setAdd] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [loadButton, setLoadButton] = React.useState(false);
  const { handleSubmit, register, formState:{errors}, reset } = useForm();
  const onSubmit = (data) => {
    
    setLoadButton(true);
    if (edit) {
      data.id = edit;
      editAward({
        awardId: edit,
        awardData: {
          ...data,
        },
      }).then(() => {
        toast.success("award Updated Successfully");
        setRender(!render);
        setLoadButton(false);
        setAdd(false);
        setEdit(null);
        reset();
      });
    } else {
      addAward({ award: { ...data } }).then(({ data }) => {
        setAdd(false);
        setRender(!render);
        setLoadButton(false);
        toast.success("Award Added Successfully");
      });
    }
  };
  const handleDelete = (id) => {
    
    deleteAward({ awardId: id }).then(() => {
      setRender(!render);
      toast.success("award Deleted Successfully");
    });
  };
  return (
    <Box>
     
      <Stack direction alignItems={"center"}>
        <Typography
          sx={{
            color: "#202124",
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Sofia Pro",
          }}
        >
          Awards
        </Typography>
        <LoadingButton
          loading={loadButton}
          size="small"
          onClick={() => {
            setEdit(null);
            reset({});
            return setAdd(!add);
          }}
          startIcon={<AddIcon />}
        >
          Add
        </LoadingButton>
      </Stack>
      <Timeline className={"awards-timeline"}>
        {/* //!edit timeline item */}
        {add ? (
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <TimelineItem>
              <TimelineSeparator className={"awards-separator_padding"}>
                <TimelineDot className={"awards-timeline_dot"}>
                  <Typography
                    sx={{
                      color: "#202124",
                      fontSize: "18px",
                      fontWeight: 500,
                      fontFamily: "Sofia Pro",
                    }}
                  >
                    E
                  </Typography>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Stack>
                    <TextField
                      {...register("awardTitle", {
                        required: {
                          value: true,
                          message: "mandatory field",
                        },
                      })}
                      variant="standard"
                      placeholder="AwardTitle"
                      error={!!errors?.AwardTitle ? true : false}
                      helperText={errors?.AwardTitle?.message}
                      sx={{
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    ></TextField>
                    <TextField
                      variant="standard"
                      placeholder="Award Provider"
                      {...register("awardProvider", {
                        required: "Mandatory field",
                      })}
                      sx={{
                        color: "#1967D2",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    ></TextField>
                  </Stack>
                  <Box>
                    <Typography
                      sx={{
                        display: "inline-block",
                        background: "#FFF7E6",
                        color: "#F9AB00",
                        padding: "5px 20px",
                        borderRadius: "20px",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      <TextField
                        {...register("timePeriod", {
                          required: "Mandatory field",
                          pattern:{
                            value: /^[0-9]{4}$/,
                            message: "Invalid Time Period"
                          }
                        })}
                        error={Boolean(errors?.timePeriod)}
                        helperText={errors?.timePeriod?.message}
                        placeholder="2001"
                        sx={{ width: "90px" }}
                        variant="standard"
                      ></TextField>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ py: "20px" }}>
                  <Typography>
                    <TextField
                      {...register("description", {
                        required: "Mandatory field",
                      })}
                      placeholder="Description"
                      multiline
                      rows={4}
                      fullWidth
                      variant="standard"
                    ></TextField>
                  </Typography>
                  <Button type="submit">Submit</Button>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Box>
        ) : null}
        {/*//! endItem */}
        {award?.map((item, index) => {
          
          return (
            <>
              {edit === item.awardId ? (
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                  <TimelineItem>
                    <TimelineSeparator className={"awards-separator_padding"}>
                      <TimelineDot className={"awards-timeline_dot"}>
                        <Typography
                          sx={{
                            fontFamily: "Sofia Pro",
                            fontWight: 700,
                            fontSize: "11px",
                            color: "#1967D2",
                          }}
                        >
                          E
                        </Typography>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box sx={{ display: "flex", gap: "20px" }}>
                        <Stack>
                          <TextField
                            {...register("awardTitle", {
                              required: {
                                value: true,
                                message: "Mandatory field",
                              },
                            })}
                            variant="standard"
                            placeholder="AwardTitle"
                            error={!!errors?.awardTitle ? true : false}
                            helperText={errors?.awardTitle?.message}
                            sx={{
                              color: "#202124",
                              fontFamily: "Sofia Pro",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          ></TextField>
                          <TextField
                            variant="standard"
                            placeholder="Award Provider"
                            {...register("awardProvider", {
                              required: "Mandatory field",
                            })}
                            sx={{
                              color: "#202124",
                              fontFamily: "Sofia Pro",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          ></TextField>
                        </Stack>
                        <Box>
                          <Typography
                            sx={{
                              display: "inline-block",
                              background: "#FFF7E6",
                              color: "#F9AB00",
                              padding: "5px 20px",
                              borderRadius: "20px",
                              fontFamily: "Sofia Pro",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            <TextField
                              {...register("timePeriod", {
                                required: "Mandatory field",
                              })}
                              placeholder="2000-2022"
                              sx={{ width: "90px" }}
                              variant="standard"
                            ></TextField>
                          </Typography>
                        </Box>
                        {/* <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#F0FAF0",
                            }}
                          >
                            <EmployeeDashboardManageJobsPencilTracedIcon />
                          </Box>
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#F0FAF0",
                            }}
                          >
                            <EmployeeDashboardManageJobsDeleteTracedIcon />
                          </Box>
                        </Box> */}
                      </Box>
                      <Box sx={{ py: "20px" }}>
                        <Typography>
                          <TextField
                            {...register("description", {
                              required: "Mandatory field",
                            })}
                            placeholder="Description"
                            multiline
                            rows={4}
                            fullWidth
                            variant="standard"
                          ></TextField>
                        </Typography>
                        <Stack direction>
                          <Button type="submit">Submit</Button>
                          <Button
                            onClick={() => setEdit(null)}
                            color="error"
                            type="submit"
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                </Box>
              ) : (
                <TimelineItem key={item.id}>
                  <TimelineSeparator className={"awards-separator_padding"}>
                    <TimelineDot className={"awards-timeline_dot"}>
                      <Typography
                        sx={{
                          fontFamily: "Sofia Pro",
                          fontWight: 700,
                          fontSize: "11px",
                          color: "#1967D2",
                        }}
                      >
                        {item?.data?.awardTitle?.substring(0, 1)}
                      </Typography>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box>
                        <Typography
                          sx={{
                            color: "#202124",
                            fontFamily: "Sofia Pro",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {item.data.awardTitle}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#202124",
                            fontFamily: "Sofia Pro",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {item.data.awardProvider}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            display: "inline-block",
                            background: "#FFF7E6",
                            color: "#F9AB00",
                            padding: "5px 20px",
                            borderRadius: "20px",
                            fontFamily: "Sofia Pro",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {item.data.timePeriod}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#F0FAF0",
                          }}
                        >
                          <Tooltip placement="top" arrow title="Edit">
                            <IconButton
                              onClick={() => {
                                reset({
                                  awardTitle: item.data.awardTitle,
                                  awardProvider: item.data.awardProvider,
                                  timePeriod: item.data.timePeriod,
                                  description: item.data.description,
                                });
                                return edit
                                  ? setEdit(null)
                                  : setEdit(item.awardId);
                              }}
                            >
                              <EmployeeDashboardManageJobsPencilTracedIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Tooltip placement="top" arrow title="Delete">
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#F0FAF0",
                            }}
                          >
                            <IconButton
                              onClick={() => handleDelete(item.awardId)}
                            >
                              <EmployeeDashboardManageJobsDeleteTracedIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box sx={{ py: "50px" }}>
                      <Typography>{item.data.description}</Typography>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              )}
            </>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default Awards;
