import { Box, Container, Stack, Typography,Modal } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collectionGroup, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import { getAllJobsAppliedId } from "../../firebase/functions";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsDownArrowIcon,
  EmployeeDashboardManageJobsSeeTracedIcon,
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import ApplicationCardCandidate from "./ApplicationCardCandidate";
import classes from "./CandidateDashboardAppliedJobs.module.css";
import TableRowComponent from "./TableRowComponent";

const CandidateDashboardAppliedJobs = () => {
  const fakeArr = [1, 2, 3, 4, 5];
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setloading] = useState(false);
  const userType = GetType();
  useEffect(() => {
    setloading(true);
    // getAllJobsAppliedId().then(({ data }) => {
    //   setloading(false);
    //   setAppliedJobs(data);
    //   console.log(data);
    // }).catch((e)=>{
    //   setloading(false);
    //   toast.error(e.message);
    // })
    if (userType.uid != undefined) {
      fetchData();
    }
  }, [userType]);
  const fetchData = async () => {
    const usercollectionRef = query(
      collectionGroup(db, "Applications"),
      where("appliedBy", "==", userType.uid),
      orderBy("appliedAt", "desc")
    );
    await getDocs(usercollectionRef)
      .then((dat) => {
        console.log(dat);
        setAppliedJobs(dat.docs.map((doc) => doc.data()));
        setloading(false);
         
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
      });
  }
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Applied Jobs
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "30px",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              My Applied Jobs
            </Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#F0FAF0",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography>
            <EmployeeDashboardManageJobsDownArrowIcon />
          </Box> */}
        </Box>

        <Box sx={{ my: "0px" }}>
          <TableContainer>
            {/* <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                <TableRow
                  sx={{ "&:first-child td, &:first-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Date Applied
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ width: "100%" }}>
                {appliedJobs.map((item) => (
                  <TableRowComponent
                    id={item.jobId}
                    time={item.applicationTime}
                  />
                ))}
              </TableBody>


            </Table> */}
          </TableContainer>
              <Stack
              className={classes.hidescrollbar}
              spacing={2}
              sx={{ height: "60vh", overflowY: "scroll", mt: "25px" }}
            >
              {appliedJobs?appliedJobs.map((item, index) => {
                
              return  <ApplicationCardCandidate method={item.method} status={item.status} offerStatus={item.offerStatus} data={item.jobId} />
              }):<></>}
            </Stack>
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Container>
  );
};

export default CandidateDashboardAppliedJobs;
