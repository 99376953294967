import { Button, CircularProgress, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { auth } from "../firebase/firebaseConfig";
import {
  acceptInvite,
  applyCheck,
  applyForJob,
  inviteCheck,
} from "../firebase/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import { GetType } from "../context/authContext";

function ApplyButton({ style, text, id }) {
  const user = auth.currentUser;
  const userType = GetType();
  const [loading, setLoading] = React.useState(false);
  const [invite, setInvite] = React.useState(false);
  const [applied, setApplied] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setApplied(false);
    setInvite(false);
    inviteCheck({ jobId: id }).then(({ data }) => {
      if (data == true) {
        setInvite(true);
      }
    });
    applyCheck({ jobId: id }).then(({ data }) => {
      if (data == true) {
        setApplied(true);
      }
    });
  }, [id]);
  const handleApply = () => {
    setLoading(true);
    if (auth.currentUser) {
      applyForJob({ jobId: id })
        .then(() => {
          toast.success("Job Applied Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.message.includes("undefined")) {
            toast.error("Please add your skills", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate("/candidate-dashboard/my-resume");
          } else {
            toast.warning(err.message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Swal.fire({
        title: "Please Login",
        text: "You need to login to apply for a job",
        icon: "warning",
        confirmButtonText: "Login",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        cancelButtonColor: "#d33",
        confirmButtonColor: "#24BD2C",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: true,
      }).then((result) => {
        if (result.value) {
          navigate("/register/login");
        } else {
          setLoading(false);
        }
      });
    }
  };
  return (
    <>
      {!user ? (
        <>
          {" "}
          <Button
            onClick={() => navigate("/registration/login")}
            sx={{
              textTransform: "none",
              fontSize: "18px",
              fontFamily: "Sofia Pro",
              fontWeight: 600,
              backgroundColor: "#24BD2C",
              color: "#fff",
              padding: "14px 24px",
              borderRadius: "8px",
              mt: "20px",
              "&:hover": {
                backgroundColor: "#24BD2C",
              },
            }}
          >
            {text}
          </Button>
        </>
      ) : (
        <>
          {userType.role == "user" ? (
            <>
              {!invite ? (
                <>
                  <LoadingButton
                    disabled={applied}
                    onClick={(e) => {
                      e.stopPropagation(); // to prevent the click event from bubbling up to the parent
                      handleApply(id);
                    }}
                    loadingIndicator={
                      <CircularProgress sx={{ color: "white" }} />
                    }
                    loading={loading}
                    sx={{
                      ...style,
                      textTransform: "none",
                      fontSize: "18px",
                      fontFamily: "",
                      backgroundColor: "#24BD2C",
                      color: "#fff",
                      padding: "14px 24px",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#24BD2C",
                      },
                    }}
                  >
                    {applied ? "Applied" : text}
                  </LoadingButton>
                </>
              ) : (
                <LoadingButton
                  onClick={(e) => {
                    e.stopPropagation(); // to prevent the click event from bubbling up to the parent
                    setLoading(true);
                    acceptInvite({ jobId: id }).then(() => {
                      toast.success("Invite Accepted");
                      setLoading(false);
                    });
                  }}
                  loadingIndicator={
                    <CircularProgress sx={{ color: "white" }} />
                  }
                  loading={loading}
                  sx={{
                    ...style,
                    textTransform: "none",
                    fontSize: "12px",
                    fontFamily: "",
                    backgroundColor: "#24BD2C",
                    color: "#fff",
                    padding: "8px 17px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#24BD2C",
                    },
                  }}
                >
                  Accept Invite
                </LoadingButton>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
}

export default ApplyButton;
