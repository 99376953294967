import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import NoPlans from "../../utilities/images/svg images/no-plans.svg";

const SuperAdminDashboardNoPlans = () => {
  return (
    <Box sx={{ ...flexCenter, width: "100%", height: "100vh" }}>
      <Stack spacing={3} sx={{ ...flexCenter, width: "80%", height: "80vh" }}>
        <Box>
          <img src={NoPlans} alt="" />
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: "32px", fontFamily: "Poppins", color: "#25C027" }}
          >
            No plans Available!!! Please buy a Plan
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              textTransform: "none",
              width: "300px",
              color: "#FFF",
              backgroundColor: "#25C027",
              borderRadius:"8px",
              "&:hover": {
                backgroundColor: "#25C027",
              },
            }}
          >
            Get a Plan
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default SuperAdminDashboardNoPlans;
