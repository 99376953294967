import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsInputCompositeIcon from "@mui/icons-material/SettingsInputComposite";
import Select from "react-select";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Autocomplete,
  TextField,
  Typography,
  Checkbox
} from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import { useEffect, useState } from "react";
import {
  Configure,
  connectMenu,
  Hits,
  InstantSearch,
  connectRefinementList,
  RefinementList,
  SearchBox,
} from "react-instantsearch-dom";
import { useSearchParams } from "react-router-dom";
import InstantFeatureMessageMobile from "../../components/Home/InstantMessageFeture/InstantFeatureMessageMobile";
import InstantMessageFeture from "../../components/Home/InstantMessageFeture/InstantMessageFeture";
import JobOverView from "../../components/JobSearch/JobOverView/JobOverView";
import JobSearchPageFeaturedJob from "../../components/JobSearch/JobSearchPageFeaturedJob/JobSearchPageFeaturedJob";
import Recruiting from "../../components/SharedComponents/EmployersFindYou/Recruiting";
import MobileMenu from "../../components/SharedComponents/MobileMenu/MobileMenu";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import { getALlobsUsers } from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  flexBetweenWithItemsCenter,
  responsiveFlexDirection,
} from "../../utilities/commonStyles/commonStyles";
import { SearchIcon } from "../../utilities/Icons/Icons";
import findLeft from "../../utilities/images/findLeft.png";
import findRight from "../../utilities/images/findRight.png";
import "./filters.css";
import classes from "./JobSearchPage.module.css";

const searchClient = algoliasearch(
  "0K7ORGONBJ",
  "c491fef7ef4aa942b0e00a8d110abdcf"
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const searchClient = algoliasearch(
//   "0K7ORGONBJ",
//   "c491fef7ef4aa942b0e00a8d110abdcf"
// );
const StyledFilter = styled("select")({
  height: "40px",
  fontSize: "14px",
  color: "#7C7C7C",
  backgroundColor: "transparent",
  padding: "0px 20px 0px 20px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "1px solid #25C027",
  width: "200px",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});
const StyledStack = styled(Stack)({
  display: "flex",
  // alignItems: "center",
  borderRadius: "8px",
  gap: "10px",
});
const FilterTypo = styled(Typography)({
  overflowY: "scroll",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "space-between",
  // alignItems: "center",
  "&::-webkit-scrollbar": {
    width: "0.5em",
  },
});
const MenuSelect = ({ items, currentRefinement, refine }) => {
  const [value, setValue] = useState(null)
  return <Autocomplete
    id="combo-box-demo"
    options={items}
    value={value}
    onChange={(event, value) => {
      setValue(value)
      refine(value?.value)

    }
    }
    renderInput={(params) => <TextField {...params} />}
  />
}
const options = [
  { label: "abc", value: "abc" }
  , { label: "abcd", value: "abcd" }
  , { label: "abcde", value: "abcde" }
  , { label: "abcdef", value: "abcdef" }
]
const MenuSelectMulti = ({ items, currentRefinement, refine }) => {
  const [value, setValue] = useState([])
  return <Autocomplete
    multiple
    options={items}
    value={value}
    onChange={(event, value) => {
      // console.log(value)
      // console.log(val)
      // value = [...new Set(value.map(item => item.value))]
      // console.log(value)
      let mymap = new Map()
      value = value.filter(el => {
        const val = mymap.get(el.label);
        if (val) {
          if (el.label < val) {
            mymap.delete(el.label);
            mymap.set(el.label, el.value);
            return true;
          } else {
            return false;
          }
        }
        mymap.set(el.label, el.value);
        return true;
      });
      setValue(value)
      if (value.length < 1) {
        refine([])
        return
      }
      value.forEach(e => {
        refine(e.value)
      })

    }
      // refine(event.currentTarget.value)
    }
    renderInput={(params) => <TextField {...params} />}
  />
};


const CustomMenuSelect = connectMenu(MenuSelect);
const CustomMenuSelectMulti = connectRefinementList(MenuSelectMulti);


const JobSearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [overview, setOverview] = useState([]);
  useEffect(() => {
    getALlobsUsers().then((res) => {
      setFeaturedJobs(res.data);
      setOverview(res.data[0].id);
    });
  }, []);
  const getOverview = (id) => { };
  //filters for the jobs
  const [options, setOptions] = useState({});
  const Hit = ({ hit }) => {
    console.log(hit);
    // setSearchJobs(hit);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName="index_jobs">
      <Configure filters={"expired:false"} />
      <Box>
        <Box sx={displayOnDesktop}>
          <NavBar color={"green"} />
        </Box>
        <Box sx={displayOnMobile}>
          <MobileMenu color={"green"} />
        </Box>

        <Box
          sx={{ ...displayOnDesktop, backgroundColor: "#25C027", pt: "20px" }}
        >
          <Container>
            <Box sx={{ ...flexBetweenWithItemsCenter }}>
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  alignItems: "end",
                  mt: "5%",
                }}
              >
                <img style={{ width: "100%" }} src={findLeft} alt="" />
              </Box>
              <Box sx={{ width: "40%", textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "20px", md: "30px" },
                    color: "#FFF",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                  }}
                >
                  Find Jobs
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "10px", md: "15px" },
                    color: "#FFF",
                    fontFamily: "Poppins",
                  }}
                >
                  Home / Jobs
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  alignItems: "end",
                  mt: "5%",
                }}
              >
                <img style={{ width: "100%" }} src={findRight} alt="" />
              </Box>
            </Box>
          </Container>
        </Box>

        <Container sx={displayOnDesktop}>
          <Box
            sx={{
              backgroundColor: "#fff",
              // padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
              position: "relative",
              top: "-50px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                className={classes.input}
                sx={{
                  width: "100%",
                  borderRight: "1px solid #ECEDF2",
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <SearchIcon />
                <SearchBox
                  defaultRefinement={query}
                  translations={{
                    placeholder: "Search by Job Title or Skills",
                  }}
                  submit={
                    <Box>
                      <Button
                        sx={{
                          textTransform: "none",
                          width: "150px",
                          fontSize: "18px",
                          fontFamily: "",
                          backgroundColor: "#24BD2C",
                          color: "#fff",
                          padding: "14px 24px",
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: "#24BD2C",
                          },
                        }}
                      >
                        Find Jobs
                      </Button>
                    </Box>
                  }
                  searchAsYouType={false}
                />
                {/* <input
                  type="text"
                  placeholder="Job title, keywords, or company"
                /> */}
              </Box>
            </Box>
          </Box>
        </Container>

        <Container>
          {/* <Box sx={{ ...itemsCenter, gap: "30px" }}>
            <StyledFilter
              onChange={(e) => {
                setOptions({ ...options, jobTitles: e.target.value });
              }}
              style={{ backgroundColor: "#25C027", color: "#FFF" }}
            >
              <option>City</option>
            </StyledFilter>
            <StyledFilter
              onChange={(e) => {
                setOptions({ ...options, jobTitles: e.target.value });
              }}
              style={{ backgroundColor: "#25C027", color: "#FFF" }}
            >
              <option>Experience</option>
            </StyledFilter>
            <StyledFilter
              onChange={(e) => {
                setOptions({ ...options, jobTitles: e.target.value });
              }}
              style={{ backgroundColor: "#25C027", color: "#FFF" }}
            >
              <option>Job type</option>
            </StyledFilter>
            <StyledFilter
              onChange={(e) => {
                setOptions({ ...options, jobTitles: e.target.value });
              }}
              style={{ backgroundColor: "#25C027", color: "#FFF" }}
            >
              <option>Minimum qualification</option>
            </StyledFilter>
          </Box> */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack direction gap={1} alignItems={"center"}>
                <Typography>Filters </Typography>
                <SettingsInputCompositeIcon
                  sx={{
                    color: "#25C027",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Stack>
            </AccordionSummary>
            {/* <CustomMenuSelect attribute="city" /> */}
            <Box
              className={classes.filterBox}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                padding: "30px",
              }}
            >
              <StyledStack className={classes.selectBox}>
                {/* <Divider textAlign="center"> */}
                <Typography fontSize={"11px"}>Organisation Name</Typography>
                {/* </Divider> */}
                <FilterTypo fontSize={"10px"}>
                  <CustomMenuSelect attribute="organisationName" />
                  {/* <RefinementList attribute="organisationName" /> */}
                </FilterTypo>
              </StyledStack>
              <StyledStack className={classes.selectBox}>
                {/* <Divider textAlign="center"> */}
                <Typography fontSize={"11px"}>Job Type </Typography>
                {/* </Divider> */}
                <FilterTypo fontSize={"10px"}>
                  <CustomMenuSelect attribute="jobType" />
                  {/* <RefinementList attribute="jobType" /> */}
                </FilterTypo>
              </StyledStack>

              <StyledStack className={classes.selectBox}>
                {/* <Divider textAlign="center"> */}
                <Typography fontSize={"11px"}>Experience </Typography>
                {/* </Divider> */}
                <FilterTypo fontSize={"10px"}>
                  <CustomMenuSelect attribute="experience" />
                  {/* <RefinementList attribute="experience" /> */}
                  {/* <MenuSelect attribute="experience" /> */}
                </FilterTypo>
              </StyledStack>


              <StyledStack className={classes.selectBox}>
                {/* <Divider textAlign="center"> */}
                <Typography fontSize={"11px"}>
                  Minimum Qualification{" "}
                </Typography>
                {/* </Divider> */}
                <FilterTypo fontSize={"10px"}>
                  <CustomMenuSelect attribute="minQualification" />
                  {/* <RefinementList attribute="minQualification" /> */}
                </FilterTypo>
              </StyledStack>
              <StyledStack className={classes.selectBox}>
                {/* <Divider textAlign="center"> */}
                <Typography fontSize={"11px"}>City </Typography>
                {/* </Divider> */}
                <FilterTypo fontSize={"10px"}>
                  <CustomMenuSelectMulti attribute="city" />
                  {/* <RefinementList attribute="city" /> */}
                </FilterTypo>
              </StyledStack>
            </Box>
          </Accordion>
        </Container>
        <Container>
          <Box
            sx={{
              ...responsiveFlexDirection,
              display: "flex",
              justifyContent: "space-between",
              gap: "40px",
              height: "100vh",
              my: "70px",
            }}
          >
            <Stack
              className="hidescrollbar"
              sx={{
                height: "100vh",
                overflowY: "scroll",
              }}
              spacing={2}
            >
              <Hits
                hitComponent={({ hit }) => (
                  <>
                    {/* {JSON.stringify(hit)} */}
                    {hit.active && !hit.expired ? (
                      <JobSearchPageFeaturedJob
                        job={hit}
                        id={hit.objectID}
                        setOverview={setOverview}
                        overview={overview}
                      />
                    ) : null}
                  </>
                )}
              />
            </Stack>
            <JobOverView id={overview} />
          </Box>
        </Container>
        <Box sx={displayOnDesktop}>
          <InstantMessageFeture />
        </Box>
        <Box sx={displayOnMobile}>
          <InstantFeatureMessageMobile />
        </Box>
        <Recruiting />
      </Box>
    </InstantSearch>
  );
};

export default JobSearchPage;

// const StyledFilter = styled("select")({
//   // width: "150px",
//   height: "40px",
//   fontSize: "14px",
//   color: "white",
//   backgroundColor: "#24BD2C",
//   margin: "20px",
//   padding: "0px 20px 0px 20px",
//   minHeight: "45px",
//   borderRadius: "8px",
//   border: "none",

//   option: {
//     color: "black",
//     backgroundColor: "white",
//     border: "none",
//     minHeight: "50px",
//     borderRadius: "0px",
//     padding: "10px",
//   },
// });

// function Hit({hits}) {
//   useEffect(()=>{
//     // console.log(JSON.stringify("PROPS12",props.hit))
//   },[])
//   return (
//     <>
//     <JobCard />
//     <article>
//       {/* <p>{JSON.stringify(hits)}</p>
//       <h1>
//         <Highlight attribute="jobTitle" hit={props.hit} />
//       </h1>
//       <p>
//         <Highlight attribute="jobType" hit={props.hit} />
//       </p>
//       <p>
//         <Highlight attribute="minQualification" hit={props.hit} />
//       </p>
//       <p>
//         <Highlight attribute="jobSpecialization" hit={props.hit} />
//       </p>
//       <p>
//         <Highlight attribute="city" hit={props.hit} />
//       </p>
//       <p>
//         <Highlight attribute="organisationName" hit={props.hit} />
//       </p> */}
//     </article>
//     </>
//   );
// }

// Hit.propTypes = {
//   hit: PropTypes.object.isRequired,
// };

{
  /* <Box
      className={classes.input}
      sx={{
        width: "100%",
        borderRight: "1px solid #ECEDF2",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <LocationIcon />
      <input type="text" placeholder="City or postcode" />
    </Box>
    <Box sx={{ width: "100%" }}>
      <Box
        className={classes.input}
        sx={{
          width: "100%",
          padding: "20px",
        }}
      >
      <Box
      className="styled-select"
      sx={{ display: "flex", alignItems: "center", gap: "15px" }}
      >
      <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      >
      <path
      d="M16.2 3.36842H13.5V1.68421C13.5 0.755368 12.6927 0 11.7 0H6.3C5.3073 0 4.5 0.755368 4.5 1.68421V3.36842H1.8C0.8073 3.36842 0 4.12379 0 5.05263V8.42105H4.5V6.73684H6.3V8.42105H11.7V6.73684H13.5V8.42105H18V5.05263C18 4.12379 17.1927 3.36842 16.2 3.36842ZM6.3 1.68421H11.7V3.36842H6.3V1.68421ZM13.5 10.9474H11.7V9.26316H6.3V10.9474H4.5V9.26316H0V14.3158C0 15.2446 0.8073 16 1.8 16H16.2C17.1927 16 18 15.2446 18 14.3158V9.26316H13.5V10.9474Z"
              fill="#25C027"
              />
              </svg>
              
          <select>
            <option selected disabled value="job-location">
              categories
            </option>
            <option value="">Bangladesh</option>
            <option value="">India</option>
            <option value="">Pakistan</option>
            <option value="">Nepal</option>
            <option value="">China</option>
          </select>
        </Box>
        </Box>
      </Box> */
}
