import {
    Container, Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import {
    PlansModalCrossIcon,
    PlansModalTickIcon
} from "../../utilities/Icons/Icons";

function createData(planName, plan1, plan2, plan3, plan4, plan5, plan6) {
  return { planName, plan1, plan2, plan3, plan4, plan5, plan6 };
}

const rows = [
  createData("Seats", 1, 3, 6, 5, 6, 12),
  createData("Slots", 3, 6, 12, 10, 12, 24),
  createData("Resume database", true, true, true, true, true, true),
  createData("Live Chat function", true, true, true, true, true, true),
  createData("Key word Matching", false, false, false, true, true, true),
];

export default function SuperAdminDashboardPricing() {
  return (
    <Container>
      <TableContainer
        sx={{ boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                textTransform: "uppercase",
                backgroundColor: "#25C027",
              }}
            >
              <TableCell></TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  Starter
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  1 Month
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  Starter
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  3 Months
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  Starter
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  12 Months
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  Premium
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  1 Month
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  Premium
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  3 Months
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  Premium
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  12 Months
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">
                  {row.planName}
                </TableCell>
                <TableCell align="center">
                  {row.plan1 === true ? (
                    <PlansModalTickIcon />
                  ) : row.plan1 === false ? (
                    <PlansModalCrossIcon />
                  ) : (
                    row.plan1
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.plan2 === true ? (
                    <PlansModalTickIcon />
                  ) : row.plan2 === false ? (
                    <PlansModalCrossIcon />
                  ) : (
                    row.plan2
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.plan3 === true ? (
                    <PlansModalTickIcon />
                  ) : row.plan3 === false ? (
                    <PlansModalCrossIcon />
                  ) : (
                    row.plan3
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.plan4 === true ? (
                    <PlansModalTickIcon />
                  ) : row.plan4 === false ? (
                    <PlansModalCrossIcon />
                  ) : (
                    row.plan4
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.plan5 === true ? (
                    <PlansModalTickIcon />
                  ) : row.plan5 === false ? (
                    <PlansModalCrossIcon />
                  ) : (
                    row.plan5
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.plan6 === true ? (
                    <PlansModalTickIcon />
                  ) : row.plan6 === false ? (
                    <PlansModalCrossIcon />
                  ) : (
                    row.plan6
                  )}
                </TableCell>
              </TableRow>
            ))}

            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                color: "#FFF",
                backgroundColor: "#25C027",
              }}
            >
              <TableCell
                component="th"
                scope="row"
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                Price
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
