import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Chip,
  Container,
  Pagination,
  Skeleton,
  Stack,
  Typography,Modal
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { getFormField, getJobSeeker } from "../../firebase/functions";
//import { fireBaseTime } from "../../Helpers/TimeConvert";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useCountries } from "use-react-countries";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import { itemsCenter } from "../../utilities/commonStyles/commonStyles";
import { SuperAdminOrganizationSearchIcon } from "../../utilities/Icons/Icons";
import classes from "./SuperAdminDashboardCompanyList.module.css";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { toast } from "react-toastify";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
const StyledFilter = styled("select")({
  // width: "150px",
  height: "40px",
  fontSize: "14px",
  color: "#B7B7B7",
  backgroundColor: "#FFF",
  margin: "0px",
  padding: "0px 20px 0px 20px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "1px solid #25C027",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const SuperAdminDashboardJobSeeker = () => {
  const { countries } = useCountries();
  const names = countries.map((country) => country.name);
  const [location, setLocation] = useState({});
  const [allApplicants, setAllApplicants] = useState([]);
  const [noofAcceptReject, setNoofAcceptReject] = useState([]);
  const [status, setStatus] = useState({});
  const [titles, setTitles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const { jobTitles, setJobTitles } = useState([]);
  const [toggle, setToggle] = useState(true);
  const [experienceArray, setExperienceArray] = useState([]);
  const [educationArray, setEducationArray] = useState([]);
  const [skillArray, setSkillArray] = useState([]);
  const [sort, setSort] = useState({ order: "desc" });
  const [pageNumber, setPageNumber] = useState(1);
  const [jobSeeker, setJobSeeker] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setloading(true);
    // getJobSeeker({}).then(({ data }) => {
    //   console.log(data);
    //   setloading(false);
    //   if (Array.isArray(data)) {
    //     setJobSeeker(data);
    //     setAllApplicants(data);
    //     setOriginalData(data);
    //   }
    //   //setAdminData(data);
    // }).catch((e)=>{
    //   setloading(false);
    //   toast.error(e.message);
    // })
    getJobSeeker();
  }, []);
  const getJobSeeker = async() => {
       const employerPostedJob = query(collection(db, `Users`),where("type", "==", "user"));
       await getDocs(employerPostedJob)
         .then((docSnap) => {
           setJobSeeker(
             docSnap.docs.map((doc) => {
               return { ...doc.data(), id: doc.id };
             })
           );
           setAllApplicants(
             docSnap.docs.map((doc) => {
               return { ...doc.data(), id: doc.id };
             })
           );
           setOriginalData(
             docSnap.docs.map((doc) => {
               return { ...doc.data(), id: doc.id };
             })
           );
           setloading(false);
         })
         .catch((e) => {
           console.log(e);
           setloading(false);
           toast.error(e.message);
         });
  }
  // useEffect(() => {
  //   console.log("USEEFFECT50");
  //   getJobTitles().then(({ data }) => {
  //     setTitles(data);

  //   });
  // }, []);

  useEffect(() => {
    getFormField({ form: "experience" }).then(({ data }) => {
      console.log("Experience", data);
      setExperienceArray(data);
    }).catch((e)=>{
      console.log(e);
    })
    getFormField({ form: "education" }).then(({ data }) => {
      console.log("Education", data);
      setEducationArray(data);
    }).catch((e)=>{
      console.log(e);
    })
    getFormField({ form: "skills" }).then(({ data }) => {
      console.log("Skills", data);
      setSkillArray(
        data.map((skill) => {
          return {
            value: skill,
            label: skill,
          };
        })
      );
    }).catch((e)=>{
     console.log(e);
    })
    setStatus({
      ...status,
      // jobTitles: data[0].id,
      // status: "All",
      experience: "Any",
      educationLevel: "Any",
      skills: [],
      country: "",
      state: "",
      city: "",
    });
  }, []);
  useEffect(() => {
    console.log("USEEFFECT100", status);
    const filterData = originalData
      .filter((item) => {
        return status.status == "All"
          ? 1
          : item.status === status.status ||
              (status.status == "hired" && item.status == "offerRevised");
      })
      ?.filter((item) => {
        return status.experience == "Any"
          ? 1
          : item.experience === status.experience;
      })
      ?.filter((item) => {
        return status.educationLevel == "Any"
          ? 1
          : item.educationLevel === status.educationLevel;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.skills.length == 0
          ? 1
          : status?.skills?.every((skill) => item?.skills?.includes(skill));
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.country == "" ? 1 : item.country === status.country;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.state == "" ? 1 : item.state === status.state;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.firstName == "" ? 1 : item.firstName === status.firstName;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.city == "" ? 1 : item.city === status.city;
      });

    let sortedData = filterData;
    if (sort.method == "firstName") {
      sortedData = filterData.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
    } else if (sort.method == "appliedAt") {
      sortedData = filterData.sort(
        (a, b) => fireBaseTime(a.createdAt) - fireBaseTime(b.createdAt)
      );
      console.log("SORTEDDATATIME");
    }
    if (sort.order == "asc") {
      sortedData = sortedData.reverse();
    }
    console.log("SORTEDDATA", sortedData);
    setAllApplicants(sortedData);
    // if(sorted)
    console.log("useEffectfilter", filterData);
    // setAllApplicants(filterData);
  }, [status, toggle, sort]);
  return (
    <Box
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Container>
        <Box sx={{ mt: "60px" }}>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Poppins",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Job seeker
            </Typography>
          </Box>
          {/* {`${JSON.stringify(allApplicants)}`} */}
          <Stack sx={{ mt: "20px" }}>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "10px", md: "20px" },
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Select
                id="tags-standard"
                isMulti
                isSearchable
                onChange={(e, value) => {
                  // console.log(e)
                  let arr = [];
                  // arr = (Array.isArray(e) ? e.map(x => x.value) : []);
                  // console.log(arr)
                  for (let i = 0; i < e.length; i++) {
                    arr.push(e[i].value);
                  }
                  setStatus({ ...status, skills: arr });
                }}
                options={skillArray}
                // getOptionLabel={(option) => option}

                style={customStyles}
              />

              <StyledFilter
                onChange={(e) => {
                  setStatus({ ...status, experience: e.target.value });
                }}
                value={status.experience}
                sx={{ maxWidth: { xs: "75px", md: "auto" } }}
              >
                <option selected value="Any">
                  Any
                </option>
                {experienceArray.map((exp, index) => {
                  return <option value={exp}>{exp}</option>;
                })}
              </StyledFilter>
              <StyledFilter
                onChange={(e) => {
                  setStatus({ ...status, educationLevel: e.target.value });
                }}
                value={status.educationLevel}
              >
                <option selected value="Any">
                  Any
                </option>
                {educationArray.map((exp, index) => {
                  return (
                    <option selected={index == 0} value={exp}>
                      {exp}
                    </option>
                  );
                })}
              </StyledFilter>
              {status.status != "resumeDatabase" ? (
                <>
                  {" "}
                  <StyledFilter
                    onChange={(e) => {
                      setSort({ ...sort, method: e.target.value });
                    }}
                    value={sort.method}
                  >
                    <option value="firstName">First Name</option>;
                    <option value="appliedAt">Date Created</option>;
                  </StyledFilter>
                  <StyledFilter
                    onChange={(e) => {
                      setSort({ ...sort, order: e.target.value });
                    }}
                    value={sort.order}
                  >
                    <option value="desc">Descending</option>;
                    <option value="asc">Ascending</option>;
                  </StyledFilter>
                </>
              ) : null}
            </Box>
          </Stack>

          <Box
            className={classes.input}
            sx={{
              width: { xs: "100%", md: "40%" },
              display: "flex",
              alignItems: "center",
              gap: "20px",
              border: "1px solid #25C027",
              mt: "20px",
            }}
          >
            <SuperAdminOrganizationSearchIcon />
            <input
              style={{ width: "100%" }}
              type="text"
              placeholder="Search for name"
              onChange={(e) => {
                setStatus({ ...status, firstName: e.target.value });
              }}
            />
          </Box>

          {/* <Stack sx={{ display: "block" }} direction>
            <Box sx={{ display: "flex", gap: "25px" }}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Country
                </Typography>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  <CountrySelector
                    onChange={(e) => {
                      //  setValue("country", e.name);
                      console.log(e);
                      setLocation({ ...location, country: e });
                      if (e == null) {
                        setStatus({
                          ...status,
                          country: "",
                          city: "",
                          state: "",
                        });
                      } else {
                        setStatus({
                          ...status,
                          country: e.name,
                          city: "",
                          state: "",
                        });
                      }
                    }}
                    name="country"
                    placeholder="Select a country"
                    value={location.country}
                  />
                </FormControl>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  State*
                </Typography>
                <StateSelector
                  country={location.country}
                  name="state"
                  value={location.state}
                  countryPlaceholder="Select a country first"
                  onChange={(e) => {
                    // setValue("state", e.name);

                    setLocation({ ...location, state: e });
                    if (e == null) {
                      setStatus({ ...status, state: "", city: "" });
                    } else {
                      setStatus({ ...status, state: e.name, city: "" });
                    }
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  City*
                </Typography>
                <CitySelector
                  country={location.country}
                  state={location.state}
                  name="city"
                  value={location.city}
                  statePlaceholder="Select a state first"
                  onChange={(e) => {
                    // setValue("city", e.name);
                    setLocation({ ...location, city: e });
                    if (e == null) {
                      setStatus({ ...status, city: "" });
                    } else {
                      setStatus({ ...status, city: e.name });
                    }
                  }}
                />
              </Box>
            </Box>
          </Stack> */}
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "0px",
              boxShadow: 2,
              borderRadius: "20px",
              mt: "40px",
            }}
          >
            <Box
              className={classes.hidescrollbar}
              sx={{
                overflowY: "scroll",
                overflowX: "hidden",
                padding: "40px 20px",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: 500, color: "#202124" }}
                >
                  Job Seeker
                </Typography>
              </Box>
              <TableContainer sx={{ overflowX: "hidden", mt: "20px" }}>
                <Table sx={{ width: "100%" }}>
                  <TableBody sx={{ width: "100%" }}>
                    {allApplicants
                      .map((arr, index) => (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: { md: "center" },
                            border: "1px solid #e0e0e0",
                            // height: "250px",
                            borderRadius: "10px",
                            padding: { xs: "10px", md: "20px" },
                            gap: { xs: "10px", md: "20px" },
                            mb: "20px",
                          }}
                        >
                          {/* avatar */}
                          <Box
                            sx={{
                              height: "100%",
                              display: "flex",
                              // justifyContent: "center",
                            }}
                          >
                            <Avatar
                              sx={{
                                width: { xs: "45px", md: "80px" },
                                height: { xs: "45px", md: "80px" },
                              }}
                              src={arr.displayImage}
                              alt=""
                            />
                          </Box>
                          {/* candidate details */}
                          <Stack
                            spacing={2}
                            sx={{
                              width: { md: "50%", xs: "100%" },
                              height: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: "18px",
                                lineHeight: "18px",
                              }}
                            >
                              {arr.firstName} {arr.lastName}
                            </Typography>

                            <Box
                              sx={{
                                ...itemsCenter,
                                gap: { xs: "5px", md: "20px" },
                              }}
                            >
                              <Typography
                                sx={{
                                  ...itemsCenter,
                                  gap: { md: "10px", xs: "5px" },
                                }}
                              >
                                <svg
                                  width="13"
                                  height="17"
                                  viewBox="0 0 13 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.41911 0.053489C3.29808 0.340426 1.50484 1.64739 0.586439 3.57576C0.0599809 4.68111 -0.13394 6.06529 0.0945643 7.08652C0.548814 9.11672 2.28134 12.1668 5.12607 15.9444C5.47375 16.4061 5.80568 16.8238 5.86375 16.8727C6.09271 17.0657 6.40456 17.0369 6.6296 16.802C6.82787 16.5951 8.03164 14.9834 8.62973 14.1241C11.0559 10.6384 12.3344 8.03418 12.4258 6.39165C12.5001 5.05571 11.9811 3.48069 11.1009 2.37148C10.8728 2.08401 10.3398 1.55346 10.0514 1.3268C8.74491 0.300012 7.04693 -0.166753 5.41911 0.053489ZM6.68759 3.45147C7.71151 3.66874 8.47662 4.61566 8.47662 5.66554C8.47662 6.71783 7.70525 7.66727 6.67444 7.88375C6.34193 7.9536 5.86375 7.93072 5.54288 7.8296C4.43964 7.48202 3.7647 6.32488 4.00435 5.1919C4.26029 3.98174 5.47467 3.19407 6.68759 3.45147Z"
                                    fill="#25C027"
                                  />
                                </svg>
                                {/* {"  "} {arr.address} {arr.city} */}
                                {arr.state} {arr.country}
                              </Typography>
                              <Typography
                                sx={{
                                  ...itemsCenter,
                                  gap: { md: "10px", xs: "5px" },
                                }}
                              >
                                {arr.currentSalary != "" ? (
                                  <>
                                    {" "}
                                    <svg
                                      width="20"
                                      height="17"
                                      viewBox="0 0 20 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 2.125C0 0.952 0.8 0 1.78571 0H15.3571C16.3429 0 17.1429 0.952 17.1429 2.125V11.475C17.1429 12.648 16.3429 13.6 15.3571 13.6H1.78571C0.8 13.6 0 12.648 0 11.475V2.125ZM4.28571 2.55V1.7H2.85714V2.55C2.85714 2.77543 2.78189 2.99163 2.64793 3.15104C2.51398 3.31045 2.3323 3.4 2.14286 3.4H1.42857V5.1H2.14286C2.71118 5.1 3.25622 4.83134 3.65809 4.35312C4.05995 3.8749 4.28571 3.2263 4.28571 2.55ZM11.4286 6.8C11.4286 5.89826 11.1276 5.03346 10.5917 4.39584C10.0559 3.75821 9.32919 3.4 8.57143 3.4C7.81367 3.4 7.08694 3.75821 6.55112 4.39584C6.01531 5.03346 5.71429 5.89826 5.71429 6.8C5.71429 7.70174 6.01531 8.56654 6.55112 9.20416C7.08694 9.84179 7.81367 10.2 8.57143 10.2C9.32919 10.2 10.0559 9.84179 10.5917 9.20416C11.1276 8.56654 11.4286 7.70174 11.4286 6.8ZM14.2857 1.7H12.8571V2.55C12.8571 3.2263 13.0829 3.8749 13.4848 4.35312C13.8866 4.83134 14.4317 5.1 15 5.1H15.7143V3.4H15C14.8106 3.4 14.6289 3.31045 14.4949 3.15104C14.361 2.99163 14.2857 2.77543 14.2857 2.55V1.7ZM4.28571 11.05C4.28571 10.3737 4.05995 9.7251 3.65809 9.24688C3.25622 8.76866 2.71118 8.5 2.14286 8.5H1.42857V10.2H2.14286C2.3323 10.2 2.51398 10.2896 2.64793 10.449C2.78189 10.6084 2.85714 10.8246 2.85714 11.05V11.9H4.28571V11.05ZM14.2857 11.9V11.05C14.2857 10.8246 14.361 10.6084 14.4949 10.449C14.6289 10.2896 14.8106 10.2 15 10.2H15.7143V8.5H15C14.4317 8.5 13.8866 8.76866 13.4848 9.24688C13.0829 9.7251 12.8571 10.3737 12.8571 11.05V11.9H14.2857ZM5 17C4.54746 17.0002 4.10647 16.8299 3.74026 16.5135C3.37405 16.1971 3.10142 15.7509 2.96143 15.2388C3.16 15.2796 3.36286 15.3 3.57143 15.3H15.3571C16.2096 15.3 17.0272 14.897 17.63 14.1797C18.2328 13.4624 18.5714 12.4895 18.5714 11.475V3.5445C18.9894 3.72035 19.3512 4.04614 19.6071 4.47697C19.863 4.90779 20.0002 5.42245 20 5.95V11.475C20 12.2006 19.8799 12.919 19.6466 13.5893C19.4133 14.2597 19.0713 14.8687 18.6401 15.3818C18.209 15.8948 17.6972 16.3018 17.1339 16.5794C16.5706 16.8571 15.9669 17 15.3571 17H5Z"
                                        fill="#25C027"
                                      />
                                    </svg>
                                    {"  "} ${arr.currentSalary}{" "}
                                  </>
                                ) : null}
                              </Typography>
                            </Box>
                            {/* <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        gap: "10px",
                                        marginLeft: "5vw",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "30px",
                                          height: "30px",
                                          borderRadius: "8px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          background: "#EAF9EA",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/super-admin-dashboard/job-seeker/${arr.id}`
                                          )
                                        }
                                      >
                                        <EmployeeDashboardComponentTickIcon />
                                      </Box>
                                    </Box> */}

                            {/* <Typography
                           sx={{
                             marginTop: "9px",
                             marginBottom: "9px",
                             fontWeight: "400",
                             fontSize: "14px",
                             lineHeight: "14px",
                           }}
                         >
                           Current:{" "}
                           <span
                             style={{
                               color: "#696969",
                               fontWeight: "400",
                               fontSize: "14px",
                               lineHeight: "14px",
                             }}
                           >
                             {" "}
                            {arr.}
                           </span>
                         </Typography>
                         <Typography
                           sx={{
                             marginTop: "9px",
                             marginBottom: "9px",
                             fontWeight: "400",
                             fontSize: "14px",
                             lineHeight: "14px",
                           }}
                         >
                           Previous:{" "}
                           <span
                             style={{
                               color: "#696969",
                               fontWeight: "400",
                               fontSize: "14px",
                               lineHeight: "14px",
                             }}
                           >
                             {" "}
                             Krishworks
                           </span>
                         </Typography> */}
                            <Typography
                              sx={{
                                marginTop: "9px",
                                marginBottom: "9px",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "14px",
                              }}
                            >
                              Phone Number:{" "}
                              <span
                                style={{
                                  color: "#696969",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "14px",
                                }}
                              >
                                {arr.phoneNumber}
                              </span>{" "}
                            </Typography>
                            <Typography
                              sx={{
                                marginTop: "9px",
                                marginBottom: "9px",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "14px",
                              }}
                            >
                              E-Mail:{" "}
                              <span
                                style={{
                                  color: "#696969",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "14px",
                                }}
                              >
                                {arr.email}
                              </span>{" "}
                            </Typography>
                            <Stack direction gap={1}>
                              {arr?.skills ? (
                                <Stack
                                  sx={{ display: "flex", flexWrap: "wrap" }}
                                  direction
                                  gap={1}
                                >
                                  {arr.skills.map((skill) => {
                                    return (
                                      <Chip
                                        sx={{
                                          color: "gray",
                                          backgroundColor: "#f0faf0",
                                        }}
                                        label={skill}
                                      />
                                    );
                                  })}
                                </Stack>
                              ) : (
                                <></>
                              )}
                            </Stack>
                          </Stack>

                          {/* Actions */}
                        </Box>
                      ))
                      .slice((pageNumber - 1) * 5, (pageNumber - 1) * 5 + 5)}
                  </TableBody>
                </Table>
              </TableContainer>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  onChange={(e, pageNumber) => {
                    setPageNumber(pageNumber);
                  }}
                  sx={{
                    backgroundColor: "#fff",
                    color: "#25C027",
                  }}
                  count={
                    Math.ceil(allApplicants.length / 5)
                      ? Math.ceil(allApplicants.length / 5)
                      : 1
                  }
                />
              </Container>
            </Box>
          </Box>
        </Box>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default SuperAdminDashboardJobSeeker;
