import {
  Box,
  Button,
  Container,
  Modal,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import InstantMessageFeture from "../../components/Home/InstantMessageFeture/InstantMessageFeture";
import Recruiting from "../../components/SharedComponents/EmployersFindYou/Recruiting";
import Footer from "../../components/SharedComponents/Footer/Footer";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import { getAboutUs } from "../../firebase/functions";
//import CandidateCard from "./CandidateCard";
//import OverView from "./OverView";
import styled from "@emotion/styled";
import InstantFeatureMessageMobile from "../../components/Home/InstantMessageFeture/InstantFeatureMessageMobile";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import MobileMenu from "../../components/SharedComponents/MobileMenu/MobileMenu";
import {
  displayOnDesktop,
  displayOnMobile,
  flexBetweenWithItemsCenter,
  flexCenter
} from "../../utilities/commonStyles/commonStyles";
import about1 from "../../utilities/images/about-1.png";
import about2 from "../../utilities/images/about-2.png";
import aboutLeft from "../../utilities/images/aboutLeft.png";
import aboutRight from "../../utilities/images/aboutRight.png";
import ErrorPage from "../JobSearchPage/ErrorPage";
import Testimonials from "./Testimonials";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { auth, db } from "../../firebase/firebaseConfig";
import { GetType } from "../../context/authContext";
import { useNavigate } from "react-router-dom";

const StyledFilter = styled("select")({
  // width: "150px",
  height: "40px",
  fontSize: "14px",
  color: "white",
  backgroundColor: "#24BD2C",
  margin: "20px",
  padding: "0px 20px 0px 20px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "none",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});

const About = () => {
  const [loadingError, setloadingError] = useState(false);
   const user = auth.currentUser;
   const navigate = useNavigate();
   const userType = GetType();
  const [loadingAbout, setLoadingAbout] = useState(false);
  const [aboutUsData, setAboutUsData] = useState("");
  useEffect(() => {
    setLoadingAbout(true);
    getTagsFromFirebase();
  }, []);
  const getTagsFromFirebase = async() => {
    const usercollectionRef = doc(db,`superAdmin/aboutUs`);
   const docSnap = await getDoc(usercollectionRef)
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setAboutUsData(docSnap.data().about);
            setLoadingAbout(false);
      } else {
        //! doc.data() will be undefined in this case
        console.log("No such document!");
            setloadingError(true);
        setLoadingAbout(false);
      }
    // getAboutUs({ docName: "aboutUs" })
    //   .then(({ data }) => {
    //     console.log(data);
    //     // reset({...data});
    //     setAboutUsData(data.about);
    //   }).catch((e) => {
    //     console.log(e);
    //     setLoadingAbout(false);
    //   });
  };
  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <NavBar color={"green"} />
      </Box>
      <Box sx={displayOnMobile}>
        <MobileMenu color={"green"} />
      </Box>

      <Box sx={{ backgroundColor: "#25C027", pt: "20px" }}>
        <Container>
          <Box sx={{ ...flexBetweenWithItemsCenter }}>
            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={aboutLeft}
                alt=""
              />
            </Box>
            <Box sx={{ width: "40%", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "30px" },
                  color: "#FFF",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                }}
              >
                About Us
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", md: "15px" },
                  color: "#FFF",
                  fontFamily: "Poppins",
                }}
              >
                Home / About
              </Typography>
            </Box>
            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img style={{ width: "100%" }} src={aboutRight} alt="" />
            </Box>
          </Box>
        </Container>
      </Box>

      <Container sx={{ ...flexCenter, mt: "100px" }}>
        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          {/* <Box sx={{ ...flexBetweenWithItemsCenter }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "50px",
                  fontWeight: 500,
                  mt: "15px",
                }}
              >
                4M
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 300,
                  mt: "15px",
                }}
              >
                4M daily active users
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "50px",
                  fontWeight: 500,
                  mt: "15px",
                }}
              >
                12k
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 300,
                  mt: "15px",
                }}
              >
                Over 12k open job positions
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "50px",
                  fontWeight: 500,
                  mt: "15px",
                }}
              >
                20M
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 300,
                  mt: "15px",
                }}
              >
                Over 20 million stories shared
              </Typography>
            </Box>
          </Box> */}

          <Box>
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: 500,
                mt: "60px",
              }}
            >
              ABOUT MFGWorx
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
                mt: "20px",
              }}
            >
              {aboutUsData}
            </Typography>
          </Box>
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "#25C027", py: "50px", my: "50px" }}>
        <Container>
          <Box sx={{ ...flexBetweenWithItemsCenter }}>
            <Box
              sx={{
                width: "30%",
                display: "flex",
                alignItems: "end",
                minHeight: "100%",
              }}
            >
              <img style={{ width: "100%" }} src={about2} alt="" />
            </Box>
            <Box sx={{ width: "40%", textAlign: "center" }}>
              <Box sx={{ textAlign: "center" }}>
                <Stack>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "14px", md: "30px" },
                      fontWeight: 500,
                    }}
                  >
                    Your Dream Jobs Are Waiting
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "10px", md: "16px" },
                      fontWeight: 500,
                      mt: 3,
                    }}
                  >
                    Over 1 million interactions, 50,000 success stories Make
                    yours now.
                  </Typography>
                  {!user ? (
                    <Box
                      sx={{
                        ...flexCenter,
                        gap: { md: "20px", xs: "15px" },
                        flexWrap: "wrap",
                        mt: "30px",
                      }}
                    >
                      <Button
                        onClick={() => navigate("/registration/login")}
                        sx={{
                          backgroundColor: "#fff",
                          color: "#24BD2C",
                          fontSize: { xs: "10px", md: "15px" },
                          fontWeight: 400,
                          "&:hover": {
                            color: "#24BD2C",
                            backgroundColor: "#fafafa",
                          },
                        }}
                      >
                        Search Job
                      </Button>
                      <Button
                        onClick={() => navigate("/registration/login")}
                        sx={{
                          backgroundColor: "#1967D2",
                          color: "#fff",
                          fontSize: { xs: "10px", md: "15px" },
                          fontWeight: 400,
                          "&:hover": {
                            color: "#fff",
                            backgroundColor: "#1052A0",
                          },
                        }}
                      >
                        Apply Job Now
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        ...flexCenter,
                        gap: { md: "20px", xs: "15px" },
                        flexWrap: "wrap",
                        mt: "30px",
                      }}
                    >
                      <Button
                        onClick={() => navigate("/job-search")}
                        sx={{
                          backgroundColor: "#fff",
                          color: "#24BD2C",
                          fontSize: { xs: "10px", md: "15px" },
                          fontWeight: 400,
                          "&:hover": {
                            color: "#24BD2C",
                            backgroundColor: "#fafafa",
                          },
                        }}
                      >
                        Search Job
                      </Button>
                      <Button
                        onClick={() => navigate("/job-search")}
                        sx={{
                          backgroundColor: "#1967D2",
                          color: "#fff",
                          fontSize: { xs: "10px", md: "15px" },
                          fontWeight: 400,
                          "&:hover": {
                            color: "#fff",
                            backgroundColor: "#1052A0",
                          },
                        }}
                      >
                        Apply Job Now
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Box>
            </Box>

            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img style={{ width: "100%" }} src={about1} alt="" />
            </Box>
          </Box>
        </Container>
      </Box>

      <Testimonials />
      <Box sx={displayOnDesktop}>
        <InstantMessageFeture />
      </Box>
      <Box sx={displayOnMobile}>
        <InstantFeatureMessageMobile />
      </Box>
      <Recruiting />
      <Box sx={{ ...displayOnDesktop, mt: "10vw" }}>
        <Footer></Footer>
      </Box>
      <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
      <Modal
        open={loadingError}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
            background: "white",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <ErrorPage></ErrorPage>
        </Stack>
      </Modal>
    </Box>
  );
};

export default About;
