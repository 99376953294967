import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import CandidateDashboardMobileMenu from "../../components/CandidateDashboard/CandidateDashboardMobileMenu";
import CandidateDashboardSideNavbar from "../../components/CandidateDashboard/CandidateDashboardSidenavbar";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveFlexDirection
} from "../../utilities/commonStyles/commonStyles";

const CandidateDashboard = () => {
  return (
    <Box>
      {/* <DashboardNavbar /> */}
      <Box sx={{ ...displayOnDesktop, height: "10vh" }}>
        <NavBar color={"green"} />
      </Box>

      <Box
        sx={{
          ...responsiveFlexDirection,
          height: { md: "90vh" },
          display: "flex",
          gap: "25px",
        }}
      >
        <Box sx={{ ...displayOnDesktop, width: "20%" }}>
          <CandidateDashboardSideNavbar />
        </Box>
        <Box sx={{ ...displayOnMobile }}>
          <CandidateDashboardMobileMenu />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default CandidateDashboard;
