import { Box, Container, Stack, Typography ,Modal,} from "@mui/material";
import { collection, collectionGroup, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";

import {
  fetchApplicationsCandidate,
  fetchMessagesCandidate,
  fetchPostedJobsCandidate,
  fetchShortlistedCandidate,
  getAllappliedJobs
} from "../../firebase/functions";
import { responsiveFlexDirection } from "../../utilities/commonStyles/commonStyles";
import {
  EmployeeDashboardComponentApplicationIcon,
  EmployeeDashboardComponentMessagesIcon,
  EmployeeDashboardComponentPostedJobIcon,
  EmployeeDashboardComponentShortlistIcon
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import ApplicationCardCandidate from "./ApplicationCardCandidate";
import classes from "./CandidateDashboardComponent.module.css";
const CandidateDashboardComponent = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [postedJobs, setPostedJobs] = useState(0);
  const [applications, setApplications] = useState(0);
  const [messages, setMessages] = useState(0);
  const [loading, setloading] = useState(false);
  const [shortlisted, setShortlisted] = useState(0);
  const user = GetType();
  useEffect(() => {
    setloading(true);
    if (user.uid != undefined) {
   //   alert("hello")
      fetchData();
    }
  }, [user]);
   const fetchData = async () => {
     const usercollectionRef = query(
       collection(db, `Users/${user.uid}/applications`),
       orderBy("applicationTime", "desc"),
       limit(10)
     );
     await getDocs(usercollectionRef)
       .then((dat) => {
         console.log(dat);
         setAppliedJobs(dat.docs.map((doc) => doc.id));
         setloading(false);
         
       })
       .catch((e) => {
         console.log(e);
         setloading(false);
       });
    // alert("hello")
     const employerPostedJob = collection(db, `Jobs`);
      await getDocs(employerPostedJob).then((docSnap) => {
     //  console.log("Document data:", docSnap.data());
       setPostedJobs(docSnap.size);
       setloading(false);
       }).catch((e) => {
         console.log(e);
         setloading(false);
       });
     const fetchApplications = query(
       collectionGroup(db, "Applications"),
       where("appliedBy", "==", user.uid)
     );
     await getDocs(fetchApplications)
       .then((querySnapshot) => {
         setApplications(querySnapshot.size);
         setloading(false);
       })
       .catch((e) => {
         setloading(false);
         console.log(e);
       });
     const userMessageCollection = query(collectionGroup(
       db,
       `Messages`
     ), where("applicantId", "==", user.uid));
     await getDocs(userMessageCollection)
       .then((dat) => {
         setMessages(dat.size);
        
         setloading(false);
       })
       .catch((e) => {
         console.log(e);
         setloading(false);
       });
     const fetchShortlisted = query(
       collectionGroup(db, "Applications"),
       where("appliedBy", "==", user.uid),
       where("status", "==", "accepted")
     );
     await getDocs(fetchShortlisted)
       .then((querySnapshot) => {
         setShortlisted(querySnapshot.size);
         setloading(false);
       })
       .catch((e) => {
         setloading(false);
         console.log(e);
       });

     //!Api Function
      // getAllappliedJobs()
      //   .then(({ data }) => {
      //     setAppliedJobs(data[0] ? data : []);
      //     setloading(false);
      //   })
      //   .catch((e) => {
      //     setloading(false);
      //   });
      // fetchPostedJobsCandidate({})
      //   .then((response) => {
      //     setPostedJobs(response.data);
      //     setloading(false);
      //   })
      //   .catch((e) => {
      //     setloading(false);
      //   });
      // fetchApplicationsCandidate({})
      //   .then((response) => {
      //     setApplications(response.data);
      //     setloading(false);
      //   })
      //   .catch((e) => {
      //     setloading(false);
      //   });
      // fetchMessagesCandidate({})
      //   .then((response) => {
      //     setMessages(response.data);
      //     setloading(false);
      //   })
      //   .catch((e) => {
      //     setloading(false);
      //   });
      // fetchShortlistedCandidate({})
      //   .then((response) => {
      //     setShortlisted(response.data);
      //     setloading(false);
      //   })
      //   .catch((e) => {
      //     setloading(false);
      //   });
   };
  return (
    <Container>
      <Box
        className={classes.hidescrollbar}
        sx={{ height: "90vh", overflowY: "scroll" }}
      >
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            Howdy, {user.displayName}
          </Typography>
        </Box>
        {/* {`${JSON.stringify(user)}`} */}
        <Stack spacing={3} sx={{ mt: "50px" }}>
          <Box
            sx={{
              ...responsiveFlexDirection,
              display: "flex",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E8F0FB",
                }}
              >
                <EmployeeDashboardComponentPostedJobIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#1967D2",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {postedJobs}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Posted Jobs
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FCEBEA",
                }}
              >
                <EmployeeDashboardComponentApplicationIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#D93025",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {applications}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Application
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              ...responsiveFlexDirection,
              display: "flex",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FEF3D9",
                }}
              >
                <EmployeeDashboardComponentMessagesIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#F9AB00",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {messages}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Messages
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E1F2E5",
                }}
              >
                <EmployeeDashboardComponentShortlistIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#34A853",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {shortlisted}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Shortlisted
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "8px",
              padding: "30px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                Jobs Applied Recently
              </Typography>
            </Box>

            <Stack
              className={classes.hidescrollbar}
              spacing={2}
              sx={{ height: "60vh", overflowY: "scroll", mt: "25px" }}
            >
              {appliedJobs.map ? (
                appliedJobs.map((item, index) => (
                  <ApplicationCardCandidate data={item} />
                ))
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Stack>
        <Modal
          open={loading}
          // onClose={}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack
            border={5}
            sx={{
              width: "100%",
              height: "100vh",
            }}
            justifyContent={"center"}
            alignItems="center"
          >
            <LoadingSearchComponents />
          </Stack>
        </Modal>
      </Box>
    </Container>
  );
};

export default CandidateDashboardComponent;
