import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getOrgCard } from "../../firebase/functions";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon
} from "../../utilities/Icons/Icons";
function OrgCard({ orgId }) {
  const [orgInfo, setOrgInfo] = React.useState({});
  const navigate = useNavigate();
  useEffect(() => {
    console.log("haha", orgId);
    getOrgCard({ orgId: orgId })
      .then(({ data }) => {
        console.log("haha", data);
        setOrgInfo(data);
      })
      .catch((err) => {
        console.log("haha", err);
      });
  }, []);

  return (
    <Box sx={{ width: { xs: "100%", md: "30%" } }}>
      <Stack spacing={2} sx={{ padding: "30px", backgroundColor: "#F0FBF0" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              // backgroundColor: "#FF3365",
              width: "60px",
              height: "60px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={orgInfo?.organisationLogo}
              alt=""
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                fontFamily: "Sofia Pro",
                color: "#202124",
              }}
            >
              {orgInfo?.organisationName}
            </Typography>
            <Typography
              onClick={() => {
                navigate(`/company-profile/${orgId}`);
              }}
              sx={{
                fontSize: "14px",
                fontFamily: "Sofia Pro",
                color: "#25C027",
                cursor: "pointer",
              }}
            >
              View company profile
            </Typography>
          </Box>
        </Box>

        <Box sx={{ py: "10px" }}>
          <Stack spacing={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                }}
              >
                Primary Industry
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                }}
              >
                {orgInfo?.categories?.slice(0, 1).toString(",")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                }}
              >
                Company Size
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                }}
              >
                {orgInfo?.teamSize}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                }}
              >
                Founded in:
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                }}
              >
                {orgInfo?.estSince}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                }}
              >
                Phone:
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                }}
              >
                {orgInfo?.organisationPhone}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                }}
              >
                Email
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                }}
              >
                {orgInfo?.organisationEmail}
              </Typography>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                }}
              >
                Location:
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                }}
              >
                {orgInfo?.city}, {orgInfo?.state}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                }}
              >
                Social media:
              </Typography>

              <IconButton
                sx={{
                  backgroundColor: "#00acee",
                  "&:hover": {
                    backgroundColor: "#00acee",
                  },
                }}
                onClick={() => {
                  window.open(orgInfo?.twitter, "_blank");
                }}
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "#00acee",
                  "&:hover": {
                    backgroundColor: "#00acee",
                  },
                }}
                onClick={() => {
                  window.open(orgInfo?.linkedin, "_blank");
                }}
              >
                <LinkedinIcon />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "#3b5998",
                  "&:hover": {
                    backgroundColor: "#3b5998",
                  },
                }}
                onClick={() => {
                  window.open(orgInfo?.facebook, "_blank");
                }}
              >
                <FacebookIcon />
              </IconButton>

              {/* </Typography> */}
            </Box>
            
          </Stack>
        </Box>

        <Box>
          <Typography
            // to={`/${orgInfo?.organisationWebsite}`}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              window.open(orgInfo?.organisationWebsite, "_blank");
            }}
          >
            <Button
              // onClick={() => {
              //   window.open(
              //     "#" + orgInfo?.organisationWebsite,
              //     "_blank",
              //     "noopener,noreferrer"
              //   );
              // }}
              sx={{
                width: "100%",
                textTransform: "none",
                fontSize: "18px",
                fontFamily: "",
                backgroundColor: "#D2F3D2",
                color: "#25C027",
                padding: "14px 24px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#D2F3D2",
                },
              }}
            >
              {" "}
              {orgInfo?.organisationWebsite}{" "}
            </Button>
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export default OrgCard;
