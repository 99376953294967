import { Box, Container, Stack, Typography,Modal } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { auth, db } from "../../firebase/firebaseConfig";
import { getDashboardDetails } from "../../firebase/functions";
import { responsiveFlexDirection } from "../../utilities/commonStyles/commonStyles";

import {
  EmployeeDashboardComponentApplicationIcon,
  EmployeeDashboardComponentMessagesIcon,
  EmployeeDashboardComponentPostedJobIcon,
  EmployeeDashboardComponentShortlistIcon
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./SuperAdminDashboardComponent.module.css";
// import classes from "./EmployeeDashboardComponent.module.css";
// import JobApplicantCard from "./JobApplicantCard";
const SuperAdminDashboardComponent = () => {
  const [userData, setUserData] = useState(auth.currentUser);
  const [loading , setloading] = useState(false);
  const [adminData, setAdminData] = useState("");
  useEffect(() => {
    setloading(true);
    // getDashboardDetails({}).then(({ data }) => {
    //   console.log(data);
    //   setAdminData(data);
    //   setloading(false);
    // }).catch((e)=>{
    //   setloading(false);
    //   toast.error(e.message);
    // })
    getJobDataById();
  }, []);
   const getJobDataById = async () => {
     const usercollectionRef = doc(db, `superAdmin/dashBoard`);
     const docSnap = await getDoc(usercollectionRef);
     if (docSnap.exists()) {
       setAdminData(docSnap.data());
         setloading(false);
     } else {
       //! doc.data() will be undefined in this case
       console.log("No such document!");
      setloading(false);
       toast.error("Something went worng");
     }
   };
  function convertToInternationalCurrencySystem(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(0) + "B+"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + "M+"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + "K+"
      : Math.abs(Number(labelValue));
  }

  return (
    <Container>
      <Box
        className={classes.hidescrollbar}
        sx={{ height: "80vh", overflowY: "scroll" }}
      >
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            Howdy, {userData?.displayName ? userData.displayName : "User"}
          </Typography>
        </Box>

        <Stack spacing={3} sx={{ mt: "50px" }}>
          <Box
            sx={{
              ...responsiveFlexDirection,
              display: "flex",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E8F0FB",
                }}
              >
                <EmployeeDashboardComponentPostedJobIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#1967D2",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {adminData.activeOrganization
                    ? convertToInternationalCurrencySystem(
                        adminData.activeOrganization
                      )
                    : 0}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Active Organizations
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FCEBEA",
                }}
              >
                <EmployeeDashboardComponentApplicationIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#D93025",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {adminData.activeJobs
                    ? convertToInternationalCurrencySystem(adminData.activeJobs)
                    : 0}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Active Jobs
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ...responsiveFlexDirection,
              display: "flex",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FEF3D9",
                }}
              >
                <EmployeeDashboardComponentMessagesIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#F9AB00",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {adminData.totalNoOfCandidates
                    ? convertToInternationalCurrencySystem(
                        adminData.totalNoOfCandidates
                      )
                    : 0}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Total No Of Candidate
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E1F2E5",
                }}
              >
                <EmployeeDashboardComponentShortlistIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#34A853",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {adminData.totalNoOfHires
                    ? convertToInternationalCurrencySystem(
                        adminData.totalNoOfHires
                      )
                    : 0}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Total No Of Hires
                </Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Container>
  );
};

export default SuperAdminDashboardComponent;
