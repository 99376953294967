import { Box, Grid, Typography } from "@mui/material";
import { RecentJobsLocationIcon } from "../../../utilities/Icons/Icons";
import FordLogo from "../../../utilities/images/ford.png";

const RecentJobs = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography
          sx={{
            color: "#202124",
            fontFamily: "Sofia Pro",
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Recent Jobs
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1, mt: "30px" }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
                  borderRadius: "20px",
                  padding: "10px",
                  mt: "30px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography
                    sx={{
                      color: "#1967D2",
                      background: "#eee",
                      width: "90px",
                      height: "25",
                      padding: "5px 15px",
                      fontSize: "13px",
                      borderRadius: "60px",
                      fontFamily: "Sofia Pro",
                    }}
                  >
                    Full Time
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#262E50",
                      width: "90px",
                      height: "90px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={FordLogo} alt="" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "15px",
                    mt: "10px",
                    mb: "25px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#34A853",
                      fontFamily: "Sofia Pro",
                      fontSize: "18px",
                    }}
                  >
                    Ford
                  </Typography>
                  <Typography
                    sx={{
                      color: "#0E0E0E",
                      fontFamily: "Sofia Pro",
                      fontSize: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Equipment Engineer
                  </Typography>
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <RecentJobsLocationIcon />
                    <Typography
                      sx={{
                        fontFamily: "Sofia Pro",
                        fontSize: "14px",
                        color: "#696969",
                      }}
                    >
                      New York
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default RecentJobs;
