import { Avatar, Box, Button, Grid, Typography, Skeleton } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetType } from "../../../context/authContext";
import { auth, db } from "../../../firebase/firebaseConfig";
import { getJobOverview, getLogo } from "../../../firebase/functions";
import { FeturedJobsFordIcon, PopularJobsCategoriesIcon, RecentJobsLocationIcon } from "../../../utilities/Icons/Icons";

function PopularJobCard({ item ,data}) {
    const navigate = useNavigate();
    const [logo, setLogo] = useState("");
    const user = auth.currentUser;
  const userType = GetType();
  const [loading, setLoading] = useState(true);
    const [jobData, setJobData] = useState({});
    useEffect(() => {
        // getJobOverview({ jobId: data }).then(({ data }) => {
        //   setJobData(data);
        //   getLogo({ orgId: data.organisationId }).then(({ data }) => {
        //     setLogo(data);
        //   });
        // });
      getJobDataById();
    }, []);
   const getJobDataById = async () => {
     const usercollectionRef = doc(db, `Jobs/${data}`);
     const docSnap = await getDoc(usercollectionRef);
     if (docSnap.exists()) {
       const extraRef = doc(db, `Jobs/${data}/extraInfo/infoDoc`);
       const docSnap1 = await getDoc(extraRef);
       if (docSnap1.exists()) {
         console.log(docSnap.data());
         console.log(docSnap1.data());
         setJobData({ ...docSnap.data(), ...docSnap1.data() });
         setLoading(false);
       } else {
         //! doc.data() will be undefined in this case
         console.log("No such document!");
       }
       const orgRef = doc(db, `Organisations/${docSnap.data().organisationId}`);
       const docSnap2 = await getDoc(orgRef);
       if (docSnap2.exists()) {
         setLogo(docSnap2.data().organisationLogo);
       } else {
         //! doc.data() will be undefined in this case
         console.log("No such document!");
       }
     } else {
       //! doc.data() will be undefined in this case
       console.log("No such document!");
     }
   };
  return (
    <Grid item xs={12} md={3}>
      {loading ? <Skeleton variant="rect" height={100} width={100} /> :
        <Box
          sx={{
            boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
            borderRadius: "20px",
            padding: "15px",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Box>
              <Avatar
                sx={{
                  width: "75px",
                  height: "75px",
                }}
                src={logo}
                alt=""
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontSize: "18px",
                  fontWeight: 500,
                  fontFamily: "Sofia Pro",
                }}
              >
                {jobData.jobTitle}
              </Typography>
              <Typography
                sx={{
                  color: "#25C027",
                  fontSize: "14px",
                  fontFamily: "Sofia Pro",
                }}
              >
                {data?.jobPreference}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  mt: "10px",
                }}
              >
                <FeturedJobsFordIcon />
                <Typography
                  sx={{
                    color: "#696969",
                    fontSize: { xs: "10px", md: "14px" },
                  }}
                >
                  {jobData.organisationName}
                </Typography>
              </Box>
            </Box>
          </Box>
          {!user ? (
            <Box sx={{ mt: "20px" }}>
              <Button
                onClick={() => navigate(`/job-profile/${data}`)}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  color: "#fff",
                  backgroundColor: "#24BD2C",
                  borderRadius: "8px",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#24BD2C",
                  },
                }}
              >
                View Jobs Details
              </Button>
            </Box>
          ) : userType.role == "user" ? (
            <Box sx={{ mt: "20px" }}>
              <Button
                onClick={() => navigate(`/job-profile/${data}`)}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  color: "#fff",
                  backgroundColor: "#24BD2C",
                  borderRadius: "8px",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#24BD2C",
                  },
                }}
              >
                View Jobs Details
              </Button>
            </Box>
          ) : (
            <></>
          )}
          {/* <Box sx={{ mt: "20px" }}>
        <Button
          onClick={() =>
            navigate(`/candidate-profile/${data.id}/jobId`)
          }
          sx={{
            width: "100%",
            textTransform: "none",
            fontSize: "15px",
            fontFamily: "",
            color: "#fff",
            backgroundColor: "#24BD2C",
            borderRadius: "8px",
            "&:hover": {
              color: "#fff",
              backgroundColor: "#24BD2C",
            },
          }}
        >
          View Jobs Details
        </Button>
      </Box> */}
        </Box>}
    </Grid>
  );
}

export default PopularJobCard;
