import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { Field } from "formik";
import * as React from "react";
import { getFormField } from "../../firebase/functions";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PostJob = ({
  register,
  setTags,
  skills,
  setValue,
  getValues,
  errors,
}) => {
  const theme = useTheme();
  const [email, setEmail] = React.useState([]);
  const [title, setTitle] = React.useState([]);
  const [deadline, setDeadline] = React.useState([]);
  const [jobType, setJobType] = React.useState([]);
  const [specialisms, setSpecialisms] = React.useState([]);
  const [careerLevel, setCareerLevel] = React.useState([]);
  const [minSalary, setMinSalary] = React.useState([]);
  const [maxSalary, setMaxSalary] = React.useState([]);
  const [experience, setExperience] = React.useState([]);
  const [gender, setGender] = React.useState([]);
  const [industry, setIndustry] = React.useState([]);
  const [qualification, setQualification] = React.useState([]);
  const [salaryType, setSalaryType] = React.useState("");
  const [skillArray, setSkillArray] = React.useState([]);
  const [shiftArray, setShiftArray] = React.useState([]);
  const [jobTypeArray, setJobTypeArray] = React.useState([]);
  const [careerLevelArray, setCareerLevelArray] = React.useState([]);
  const [experienceArray, setExperienceArray] = React.useState([]);
  const [industryArray, setIndustryArray] = React.useState([]);
  const [educationArray, setEducationArray] = React.useState([]);

  React.useEffect(() => {
    getFormField({form:"skills"})
    .then(({ data }) => {
      console.log("Skills",data);
      setSkillArray(data)
    });
  }, []);

  React.useEffect(() => {
    getFormField({form:"jobTypes"})
    .then(({ data }) => {
      console.log("Job Types",data);
      setJobTypeArray(data)
    });
  }, []);
  React.useEffect(() => {
    getFormField({form:"shift"})
    .then(({ data }) => {
      console.log("shift",data);
      setShiftArray(data)
    });
  }, []);
  React.useEffect(() => {
    getFormField({form:"career"})
    .then(({ data }) => {
      console.log("Career Level",data);
      setCareerLevelArray(data)
    });
  }, []);

  React.useEffect(() => {
    getFormField({form:"experience"})
    .then(({ data }) => {
      console.log("Experience",data);
      setExperienceArray(data)
    });
  }, []);

  React.useEffect(() => {
    getFormField({form:"primaryIndustry"})
    .then(({ data }) => {
      console.log("Industry",data);
      setIndustryArray(data)
    });
  }, []);

  React.useEffect(() => {
    getFormField({form:"education"})
    .then(({ data }) => {
      console.log("Education",data);
      setEducationArray(data)
    });
  }, []);

  const handleDeadlineChange = (event) => {
    const {
      target: { value },
    } = event;
    setDeadline(typeof value === "string" ? value.split(",") : value);
  };

  const handleJobTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setJobType(typeof value === "string" ? value.split(",") : value);
  };

  const handleSpecialismsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSpecialisms(typeof value === "string" ? value.split(",") : value);
    setTags(specialisms);
  };
  const skillChange = (event) => {
    const {
      target: { value },
    } = event;
    skills.setSkills(typeof value === "string" ? value.split(",") : value);
  };

  const handleCareerLevelChange = (event) => {
    const {
      target: { value },
    } = event;
    setCareerLevel(typeof value === "string" ? value.split(",") : value);
  };

  const handleMinSalaryChange = (event) => {
    const {
      target: { value },
    } = event;
    setMinSalary(typeof value === "string" ? value.split(",") : value);
  };

  const handleMaxSalaryChange = (event) => {
    const {
      target: { value },
    } = event;
    setMaxSalary(typeof value === "string" ? value.split(",") : value);
  };

  const handleExperienceChange = (event) => {
    const {
      target: { value },
    } = event;
    setExperience(typeof value === "string" ? value.split(",") : value);
  };

  const handleGenderChange = (event) => {
    const {
      target: { value },
    } = event;
    setGender(typeof value === "string" ? value.split(",") : value);
  };

  const handleQualificationChange = (event) => {
    const {
      target: { value },
    } = event;
    setQualification(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        sx={{
          color: "#202124",
          fontFamily: "Sofia Pro",
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        Post Job
      </Typography>
      <Stack spacing={4} sx={{ mt: "60px" }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          >
            Job Title
          </Typography>
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            color="success"
            InputLabelProps={{ shrink: true }}
            sx={{
              width: "100%",
              mt: "10px",
              color: "#696969",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
            error={Boolean(errors.title)}
            helperText={errors.title?.message}
            {...register("jobTitle", {
              required: "Job Title is required",
              // minLength: {
              //   value: 5,
              //   message: "Job Title must be at least 5 characters",
              // },
              // maxLength: {
              //   value: 50,
              //   message: "Job Title must be at most 50 characters",
              // },
            })}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{ color: "#202124", fontFamily: "Sofia Pro", fontSize: "15px" }}
          >
            Job Description
          </Typography>
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            color="success"
            error={Boolean(errors.jobDescription)}
            helperText={errors.jobDescription?.message}
            multiline
            rows={10}
            sx={{
              width: "100%",
              mt: "10px",
              color: "#696969",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
            {...register("jobDescription", {
              required: true,
              // minLength: {
              //   value: 10,
              //   message: "Job Description must be at least 10 characters long",
              // },
              // maxLength: {
              //   value: 500,
              //   message: "Job Description must be at least 500 characters long",
              // },
            })}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{ color: "#202124", fontFamily: "Sofia Pro", fontSize: "15px" }}
          >
            Job Role
          </Typography>
          <TextField
            id="outlined-basic"
            label="Please describe the skills you are looking for this post"
            placeholder="Please describe the skills you are looking for this post"
            variant="outlined"
            color="success"
            error={Boolean(errors.jobRole)}
            helperText={errors.jobRole?.message}
            InputLabelProps={{ shrink: true }}
            multiline
            rows={6}
            sx={{
              width: "100%",
              mt: "10px",
              color: "#696969",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
            {...register("skillsRequired", {
              required: true,
              // minLength: {
              //   value: 10,
              //   message: "Skills Required must be at least 10 characters long",
              // },
              // maxLength: {
              //   value: 500,
              //   message: "Skills Required must be at least 500 characters long",
              // },
            })}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{ color: "#202124", fontFamily: "Sofia Pro", fontSize: "15px" }}
          >
            Key Responsibilities
          </Typography>
          <TextField
            id="outlined-basic"
            label="Please describe the key responsibilities you are looking for this job"
            placeholder="Please describe the key responsibilities you are looking for this job "
            variant="outlined"
            color="success"
            error={Boolean(errors.keyResponsibilities)}
            helperText={errors.keyResponsibilities?.message}
            InputLabelProps={{ shrink: true }}
            multiline
            rows={6}
            sx={{
              width: "100%",
              mt: "10px",
              color: "#696969",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
            {...register("keyResponsibilities", {
              required: true,
              // minLength: {
              //   value: 30,
              //   message:
              //     "Key Responsibilities must be at least 30 characters long",
              // },
              // maxLength: {
              //   value: 500,
              //   message: "Job Description must be max 500 characters long",
              // },
            })}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Job Type</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <InputLabel id="demo-multiple-chip-label" color="success">
                      Select
                    </InputLabel>
                    <Select
                      {...register("jobType", {
                        required: true,
                      })}
                      labelId="demo-multiple-chip-label"
                      label="Select"
                      // value={jobType}
                      // onChange={handleJobTypeChange}
                      color="success"
                      MenuProps={MenuProps}
                    >
                      {jobTypeArray.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Skills required</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={skillArray}
                      getOptionLabel={(option) => option}
                      defaultValue={[]}
                      onChange={(event, value) => {
                        setValue("skills", value);
                      }}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type here to search"
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Career Level</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <InputLabel id="demo-multiple-chip-label" color="success">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      label="Select"
                      {...register("careerLevel", {
                        required: true,
                      })}
                      color="success"
                      MenuProps={MenuProps}
                    >
                      {careerLevelArray.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} sx={{ display: "flex", gap: "15px" }}>
                <Stack>
                  <FormControl
                    error={Boolean(errors.salaryType)}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setValue("salaryType", e.target.value);
                      // reset({
                      //   maxSalary: "",
                      //   minSalary: "",
                      // });
                      return setSalaryType(e.target.value);
                    }}
                    // helperText={errors.salaryType && errors.salaryType.message}
                    value={salaryType}
                  >
                    <FormLabel id="demo-radio-buttons-group-label">
                      Salary Type : per {salaryType}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      // {...register("salaryType", {
                      //   required: "Salary Type is required",
                      // })}
                      value={salaryType}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormControlLabel
                        value="hour"
                        control={<Radio />}
                        label="Hourly"
                      />
                      <FormControlLabel
                        value="year"
                        control={<Radio />}
                        label="Yearly"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {errors.salaryType && errors.salaryType.message}
                    </FormHelperText>
                  </FormControl>

                  <Stack>
                    <Stack direction gap={2}>
                      <Box sx={{ width: "50%" }}>
                        <Typography>Min($/{salaryType})</Typography>
                        <FormControl sx={{ width: "100%", mt: "10px" }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            {...register("minSalary", {
                              // required: "Current Salary is required",
                              type: "number",
                            })}
                            id="outlined-basic"
                            error={Boolean(errors.minSalary)}
                            helperText={
                              errors.minSalary && errors.minSalary.message
                            }
                            label="Min Salary"
                            variant="outlined"
                            sx={{ width: "100%" }}
                          />
                        </FormControl>
                      </Box>

                      <Box sx={{ width: "50%" }}>
                        <Typography>Max($/{salaryType})</Typography>
                        <FormControl sx={{ width: "100%", mt: "10px" }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            {...register("maxSalary", {
                              type: "number",
                            })}
                            id="outlined-basic"
                            error={Boolean(errors.maxSalary)}
                            helperText={
                              errors.maxSalary && errors.maxSalary.message
                            }
                            label="Max Salary"
                            variant="outlined"
                            sx={{ width: "100%" }}
                          />
                        </FormControl>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>

              {/* <Grid item xs={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Min Salary in $</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <TextField
                      id="outlined-basic"
                      {...register("minSalary", {
                        pattern: {
                          //only accept positive numbers
                          value: /^[0-9]*$/,
                          message: "Min Salary must be a positive number",
                        },
                      })}
                      error={Boolean(errors.minSalary)}
                      helperText={errors.minSalary?.message}
                    />
                  </FormControl>
                </Box>
              </Grid> */}

              <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Specialization</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    {/* <InputLabel id="demo-multiple-chip-label" color="success">
                      Specialization
                    </InputLabel> */}
                    <TextField
                      labelId="demo-multiple-chip-label"
                      // label="Specialization"
                      {...register("jobSpecialization", {
                        required: {
                          value: true,
                          message: "Specialization is required",
                        },
                      })}
                      color="success"
                      MenuProps={MenuProps}
                    ></TextField>
                  </FormControl>
                </Box>
              </Grid>
              {/* <Grid item xs={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Max Salary in $</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <TextField
                      {...register("maxSalary", {
                        //accept only numbers
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only numbers are allowed",
                        },
                      })}
                      error={Boolean(errors.maxSalary)}
                      helperText={errors.maxSalary?.message}
                    />
                  </FormControl>
                </Box>
              </Grid> */}

              <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Experience</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <InputLabel id="demo-multiple-chip-label" color="success">
                      Select
                    </InputLabel>
                    <Select
                      {...register("experience", {
                        required: "This field is required",
                      })}
                      labelId="demo-multiple-chip-label"
                      label="Select"
                      // value={experience}
                      // onChange={handleExperienceChange}
                      color="success"
                      MenuProps={MenuProps}
                    >
                      {experienceArray.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Shift</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={shiftArray}
                      getOptionLabel={(option) => option}
                      defaultValue={[]}
                      onChange={(event, value) => {
                        setValue("shift", value);
                      }}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type here to search"
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Industry</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <InputLabel id="demo-multiple-chip-label" color="success">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      label="Select"
                      color="success"
                      MenuProps={MenuProps}
                      {...register("jobCategory", {
                        required: true,
                      })}
                    >
                      {industryArray.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Qualification</Typography>
                  <FormControl sx={{ width: "100%", mt: "10px" }}>
                    <InputLabel id="demo-multiple-chip-label" color="success">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      label="Select"
                      color="success"
                      MenuProps={MenuProps}
                      {...register("minQualification", {
                        required: true,
                      })}
                    >
                      {educationArray.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
// const skillArray = [
//   "Painting",
//   "Sculpting",
//   "Manufacturing",
//   "Welding",
//   "Carpentry",
//   "Plumbing",
//   "Electrical",
//   "Mechanical",
//   "Cleaning",
//   "Cooking",
// ];

export default PostJob;
