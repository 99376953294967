import { Stack ,Modal} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { GetType } from "../../../context/authContext";
import { db } from "../../../firebase/firebaseConfig";
import { getTotalSeatSlots } from "../../../firebase/functions";
import LoadingSearchComponents from "../../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import Usertable from "./Usertable";
function ManageUsersPage() {
  const [totalSeatSlots, setTotalSeatSlots] = React.useState({});
  const [loading, setLoading] = useState(false);
   const userType = GetType();
  useEffect(() => {
    setLoading(true);
    if (userType.organisation!=undefined) {
      soln();
    }
    // getTotalSeatSlots().then(({ data }) => {
    //   setTotalSeatSlots(data);
    //   setLoading(false);
    // }).catch((e)=>{
    //   setLoading(false);
    //   toast.error(e.message);
    // })
  }, []);
  const soln = async() => {
    
    const usercollectionRef = doc(db,`Organisations/${userType.organisation}`);
     const docSnap = await getDoc(usercollectionRef)
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
         // setAboutUsData(docSnap.data().about);
          const orgData = docSnap.data();
          setTotalSeatSlots({
            totalSeats: orgData.totalSeats,
            totalSlots: orgData.totalJobSlots,
            featured: orgData?.isFeatured,
            active: orgData?.active,
          });
          setLoading(false);
        } else {
          //! doc.data() will be undefined in this case
          console.log("No such document!");
          setLoading(false);
        }
  }
  return (
    <>
      {/* {JSON.stringify(totalSeatSlots)} */}
      <Stack>
        <Usertable seat={totalSeatSlots} />
      </Stack>
  
    </>
  );
}

export default ManageUsersPage;
