import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MethodTags from "../../components/EmployeeDashboardComponent/ReusableComponents/MethodTags";
import { OfferStatus } from "../../components/EmployeeDashboardComponent/ReusableComponents/OfferStatus";
import { StatusDrop } from "../../components/EmployeeDashboardComponent/ReusableComponents/StatusDrop";
import { StatusLogo } from "../../components/EmployeeDashboardComponent/ReusableComponents/StatusLogo";
import { getApplicantInfo } from "../../firebase/functions";
import MessageButton from "../../MessageButton/MessageButton";

function OverView({ overView, jobId, filter }) {
  const [info, setInfo] = useState({});
  const [age, setAge] = useState(0);
  const navigate = useNavigate();

  const [status, setStatus] = useState("");

  const [invite, setInvite] = useState(null);
  useEffect(() => {
    setStatus(overView.status);
    getApplicantInfo({
      applicantId: overView.appliedBy ? overView.appliedBy : overView,
    }).then(({ data }) => {
      setInfo(data);
      console.log(data);
      const ag = new Date().getFullYear() - data.dob.split("-")[0];
      setAge(ag);
    });
  }, [overView]);

  return (
    <Stack
      spacing={3}
      sx={{
        // maxHeight: "80vh",
        width: "100%",
        padding: "40px",
        boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ color: "#202124", fontSize: "18px", fontWeight: 500 }}
        >
          Candidate Overview
        </Typography>

        <Button
          onClick={() => {
            navigate(
              `/candidate-profile/${overView.appliedBy}/${
                overView.jobId ? overView.jobId : jobId
              }?filter=${filter}`
            );
          }}
        >
          <Typography
            sx={{ color: "#25C027", fontSize: "18px", fontWeight: 500 }}
          >
            View Details
          </Typography>
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <svg
                width="17"
                height="22"
                viewBox="0 0 17 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 0C6.24648 0.00270323 4.08602 0.914156 2.49253 2.53442C0.899053 4.15468 0.00266759 6.35146 9.04313e-06 8.64286C-0.00269001 10.5154 0.598857 12.3371 1.71237 13.8286C1.71237 13.8286 1.94419 14.1389 1.98205 14.1837L8.5 22L15.021 14.1798C15.055 14.1381 15.2876 13.8286 15.2876 13.8286L15.2884 13.8262C16.4014 12.3354 17.0026 10.5145 17 8.64286C16.9973 6.35146 16.1009 4.15468 14.5075 2.53442C12.914 0.914156 10.7535 0.00270323 8.5 0ZM8.5 11.7857C7.88868 11.7857 7.29108 11.6014 6.78278 11.256C6.27449 10.9107 5.87832 10.4199 5.64438 9.84558C5.41043 9.27129 5.34922 8.63937 5.46849 8.02972C5.58775 7.42006 5.88213 6.86006 6.3144 6.42052C6.74667 5.98098 7.29742 5.68166 7.89699 5.56039C8.49657 5.43912 9.11805 5.50136 9.68284 5.73924C10.2476 5.97711 10.7304 6.37994 11.07 6.89678C11.4096 7.41362 11.5909 8.02126 11.5909 8.64286C11.5899 9.47608 11.2639 10.2749 10.6845 10.864C10.105 11.4532 9.31945 11.7847 8.5 11.7857Z"
                  fill="#25C027"
                />
              </svg>
            </Box>
            <Box>
              <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                Location:
              </Typography>
              <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                {info.city},{info.state}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.09091 17.2381L16.5455 9.42857C17 8.95238 17.5455 8.66667 18.1818 8.66667C18.8182 8.66667 19.3636 8.95238 19.8182 9.42857L20 9.61905V1.90476C20 0.857143 19.1818 0 18.1818 0H1.81818C0.818182 0 0 0.857143 0 1.90476V17.1429C0 18.1905 0.818182 19.0476 1.81818 19.0476H9.09091V17.2381ZM1.81818 1.90476H18.1818V4.7619H1.81818V1.90476ZM18.1818 10.5714C18.0909 10.5714 17.9091 10.6667 17.8182 10.7619L16.9091 11.7143L18.8182 13.7143L19.7273 12.7619C19.9091 12.5714 19.9091 12.1905 19.7273 12L18.5455 10.7619C18.4545 10.6667 18.3636 10.5714 18.1818 10.5714ZM16.4545 12.2857L10.9091 18V20H12.8182L18.3636 14.1905L16.4545 12.2857Z"
                  fill="#25C027"
                />
              </svg>
            </Box>
            <Box>
              <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                Languages:
              </Typography>
              <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                {info?.languages?.toString()}
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Stack spacing={2}>
          {/* <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10.5 10.2L7.8 15L6.5 14.2L9 9.8V5H10.5V10.2Z"
                  fill="#25C027"
                />
              </svg>
            </Box>
            <Box>
              <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                Age:
              </Typography>
              <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                {age}
              </Typography>
            </Box>
          </Box> */}

          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
                  fill="#25C027"
                />
              </svg>
            </Box>
            <Box>
              <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                Job Preference
              </Typography>
              <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                {info.jobPreference}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.2 3.36842H13.5V1.68421C13.5 0.755368 12.6927 0 11.7 0H6.3C5.3073 0 4.5 0.755368 4.5 1.68421V3.36842H1.8C0.8073 3.36842 0 4.12379 0 5.05263V8.42105H4.5V6.73684H6.3V8.42105H11.7V6.73684H13.5V8.42105H18V5.05263C18 4.12379 17.1927 3.36842 16.2 3.36842ZM6.3 1.68421H11.7V3.36842H6.3V1.68421ZM13.5 10.9474H11.7V9.26316H6.3V10.9474H4.5V9.26316H0V14.3158C0 15.2446 0.8073 16 1.8 16H16.2C17.1927 16 18 15.2446 18 14.3158V9.26316H13.5V10.9474Z"
                  fill="#25C027"
                />
              </svg>
            </Box>
            <Box>
              <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                Experience:
              </Typography>
              <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                {info?.experience?.toString()}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Box>

      <Stack spacing={2}>
        <Box>
          <Typography
            sx={{ color: "#202124", fontSize: "18px", fontWeight: 500 }}
          >
            Candidate Description
          </Typography>
          <Box sx={{ boxSizing: "border-box", overflow: "hidden" }}>
            <Typography sx={{ mt: "10px", color: "#696969", fontSize: "15px" }}>
              {info.aboutMe}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{ color: "#202124", fontSize: "18px", fontWeight: 500 }}
          >
            Skills
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              mt: "10px",
            }}
          >
            {info?.skills?.map((skill, index) => {
              return <Chip key={index} label={skill} />;
            })}
          </Box>
        </Box>
      </Stack>

      <Box>
        {/* status {status}
        offer status {overView?.offerStatus}
       method  {overView?.method} */}

        <StatusDrop
          offerStatus={overView.offerStatus}
          status={status}
          jobId={jobId}
          setStatus={setStatus}
          overView={overView}
          info={info}
          // info={info}
        />
        <MessageButton
          applicantId={overView.appliedBy ? overView.appliedBy : overView}
        />
        <Stack direction alignItems={"center"} gap={2} sx={{mt:"20px"}}>
          <StatusLogo status={status} offerStatus={overView.offerStatus} />
          <MethodTags status={status} method={overView.method} />
          <OfferStatus status={status} offerStatus={overView.offerStatus} />
        </Stack>
      </Box>
      <Box>{/* <StatusLogo status={status} /> */}</Box>
    </Stack>
  );
}

export default OverView;
