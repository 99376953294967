import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  DashboardNavbarDownArrowIcon,
  DashboardNavbarHeartIcon,
  DashboardNavbarNotificationIcon,
} from "../../../utilities/Icons/Icons";
import DashboardNavbarLogo from "../../../utilities/images/svg images/dashboard-navbar-logo.svg";
import UpdatedDashboardNavbarLogo from "../../../utilities/images/Asset-10.svg";
import DashboardNavbarProfileImg from "../../../utilities/images/svg images/dashboard-navbar-profile-img.svg";
import LoginComponent from "../NavBar/LoginComponent";
import NavBarLoggedInComponent from "../NavBar/NavBarLoggedInComponent";
import { auth } from "../../../firebase/firebaseConfig";
import { useState } from "react";
import TeamNavBarLoggedInComponent from "../NavBar/TeamNavBarLoggedInComponent";
import { useEffect } from "react";
import { getUserType } from "../../../firebase/functions";
const DashboardNavbar = () => {
  const [userData, setUserData] = useState('');
   const data = async () => {
     const type = await getUserType();
    setUserData(type.data.role)
   };
   useEffect(() => {
     data();
   }, []);
  return (
    <Box
      sx={{
        position: "static",
        top: 0,
        left: 0,
        right: 0,
        boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: "20px",
          }}
        >
          <Box>
            <img
              style={{ width: "200px" }}
              src={UpdatedDashboardNavbarLogo}
              alt=""
            />
          </Box>
          {userData == "Admin" ? <NavBarLoggedInComponent /> : <></>}
          {userData == "Agent" ? <TeamNavBarLoggedInComponent /> : <></>}
        </Box>
      </Container>
    </Box>
  );
};

export default DashboardNavbar;
