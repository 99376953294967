import { Box, Container, Typography } from "@mui/material";
import InstantMessageFetureImage from "../../../utilities/images/instant-message-feature.png";

const InstantMessageFeture = () => {
  return (
    <Box sx={{ my: "100px" }}>
      <Container>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "50%" }}>
            <Typography
              sx={{ color: "#24BD2C", fontWeight: 700, fontSize: "35px", textAlign:"center" }}
            >
              Connect with candidates and hiring managers instantly with our
              MFGWorx Text Feature
            </Typography>
            <Typography
              sx={{
                color: "#696969",
                fontSize: "18px",
                fontFamily: "Sofia Pro",
                mt: "10px",
              }}
            >
              MFGWorx helps make your job search and candidate search as
              convenient as possible. Employers, text candidates directly to
              their phone through our platform and connect instantly! As a job
              seeker, you will have the capability to respond to employer
              messages directly from your phone via text!
            </Typography>
            {/* <Button
              sx={{
                textTransform: "none",
                fontSize: "18px",
                fontFamily: "Sofia Pro",
                fontWeight: 600,
                backgroundColor: "#24BD2C",
                color: "#fff",
                padding: "14px 24px",
                borderRadius: "8px",
                mt: "20px",
                "&:hover": {
                  backgroundColor: "#24BD2C",
                },
              }}
            >
              Get Started
            </Button> */}
          </Box>
          <Box sx={{ width: "50%", display: "flex", justifyContent: "center" }}>
            <img
              style={{ width: "100%" }}
              src={InstantMessageFetureImage}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default InstantMessageFeture;
