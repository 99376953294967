import { CircularProgress } from "@material-ui/core";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LoadingButton } from "@mui/lab";
import { Box, Container, IconButton, Stack, Typography,Modal } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import { getOrgInfoAdmin, updateOrgInfo } from "../../firebase/functions";
import CompanyProfileUpdatingContactInformation from "../../pages/ProfileUpadatingPages/CompanyProfileUpdatingContactInformation";
import CompanyProfileUpdatingComponent from "../../pages/ProfileUpadatingPages/CompanyProfileUpdatingCoponent";
import CompanyProfileUpdatingSocialNetwork from "../../pages/ProfileUpadatingPages/CompanyProfileUpdatingSocialNetwork";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";

const EmployeeDashboardCompanyProfile = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [teamSize, setTeamSize] = useState('');
  const userType = GetType();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  useEffect(() => {
    setLoading(true);
    if (userType.organisation != undefined) {
      
      getData();
    }
    // getOrgInfoAdmin().then(({ data }) => {
    //   console.log(data);
    //   reset({
    //     ...data,
    //   });
    //   setLoading(false);
    // });
  }, [userType]);
  const getData = async() => {
     const usercollectionRef = doc(db,`Organisations/${userType.organisation}`);
     const docSnap = await getDoc(usercollectionRef)
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setTeamSize(docSnap.data().teamSize);
         // (docSnap.data().about);
          reset({
         ...docSnap.data(),
       });
              setLoading(false)
        } else {
          //! doc.data() will be undefined in this case
          console.log("No such document!");
          setLoading(false);
        }
  }
  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    updateOrgInfo({ ...data }).then(({ data }) => {
      setLoading(false);
      // navigate("/employee-dashboard");
    }).catch((e)=>{
      setLoading(false);
      toast.error(e.message);
    })
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className="hidescrollbar"
      sx={{ height: "90vh", overflowY: "scroll", pb: "30px" }}
    >
      {/* {`${JSON.stringify(register)}`} */}
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Stack justifyContent={"center"}>
          {/* <Box sx={{ width: "80%" }}> */}
          <Stack direction justifyContent={"space-between"}>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Company Profile
            </Typography>
            <IconButton disabled={!activeStep}>
              <ArrowForwardIcon onClick={() => navigate("/")} color="success" />
            </IconButton>
          </Stack>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              my: "30px",
            }}
          >
            <CompanyProfileUpdatingComponent
              setValue={setValue}
              register={register}
              errors={errors}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              teamSize={teamSize}
              setTeamSize= {setTeamSize}
              getValues={getValues}
            />
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <CompanyProfileUpdatingSocialNetwork
              setValue={setValue}
              register={register}
              errors={errors}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <CompanyProfileUpdatingContactInformation
              setValue={setValue}
              getValues={getValues}
              register={register}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </Box>
          {/* </Box> */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={loading}
              loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
              type="submit"
              fullWidth
              sx={{
                width: "150px",
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "Sofia Pro",
                backgroundColor: "#25C027",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#25C027",
                },
              }}
            >
              Save
            </LoadingButton>
          </Box>
        </Stack>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Box>
  );
};

export default EmployeeDashboardCompanyProfile;
