import { Box, Container, Modal, Stack, Typography } from "@mui/material";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getAllInvitedUsers, getJobTitles } from "../../firebase/functions";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./EmployeeDashboardAllApplicants.module.css";
import { ApplicantDetails } from "./ReusableComponents/ApplicantDetails";
import JobNotFound from "../../pages/JobSearchPage/JobNotFound";
import { UndoRounded } from "@mui/icons-material";
import { GetType } from "../../context/authContext";
import {
  collection,
  collectionGroup,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import OfferCandidate from "../TeamDashboard/OfferCandidate";
const StyledFilter = styled("select")({
  height: "40px",
  fontSize: "14px",
  color: "black",
  padding: "0px 20px 0px 20px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "1px solid #25C027",
  outline: "none",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});

const Recruiter = () => {
  const [allApplicants, setAllApplicants] = useState([]);
  const [noofAcceptReject, setNoofAcceptReject] = useState([]);
  const [status, setStatus] = useState({});
  const [loadingAbout, setLoadingAbout] = useState(false);
  const [titles, setTitles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [flag , setflag] = useState(0)
  const userType = GetType();
  useEffect(() => {
    // getCandidateIds().then(({ data }) => {
    //   //
    //   setCandidates(data);
    //   setOriginalData(data);
    //   setOverView(data[0]);
    // });
    // setLoadingAbout(true);
    // getJobTitles().then(({ data }) => {
    //   setTitles(data);
    //   setStatus({
    //     ...status,
    //     jobTitles: data[0].id,
    //     status: "all",
    //     experience: "Any",
    //   });
    // }).catch((e)=>{
    //   setLoadingAbout(false);
    //   toast.error(e.message);
    // })
    if (userType.organisation != undefined) {
      soln();
    }
  }, [userType ]);
  const soln = async () => {
    const fetchApplications = query(
      collection(db, "Jobs"),
      where("organisationId", "==", userType.organisation)
    );
    await getDocs(fetchApplications)
      .then((data) => {
        if (data.size != 0) {
          setTitles(
            data.docs.map((job) => {
              console.log(job.data());
              return { title: job.data().jobTitle, id: job.id };
            })
          );
          setStatus({
            ...status,
            jobTitles: data.docs[0].id,
            status: "All",
            experience: "Any",
          });
        } else {
          setLoadingAbout(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (status.jobTitles != undefined) {
       setLoadingAbout(true);
      console.log(status.jobTitles);
      getJobData();
    }
  }, [status.jobTitles , flag]);
  const getJobData = async () => {
    console.log("testing gello");
    const fetchApplications = collection(db, `Jobs/${status.jobTitles}/teamApplications`);
    getDocs(fetchApplications)
      .then((data) => {
        console.log("testing gello");
        console.log(data.docs);
        // setAllApplicants(data.docs.map((doc) => doc.data()));
        setAllApplicants(data.docs.map((job) => {
            console.log(job.data());
            return { ...job.data(), id: job.id };
          })
        );
        // setAllApplicants(data.docs.map((doc) => doc.data()));
        setOriginalData(
          data.docs.map((job) => {
            console.log(job.data());
            return { ...job.data(), id: job.id };
          })
        );
         setLoadingAbout(false);
        console.log(allApplicants);
      })
      .catch((e) => {
        console.log("testing gello");
        setLoadingAbout(false);
        toast.error(e.message);
        console.log(e.message);
      });
  };
  // useEffect(() => {
  //   const filterData = originalData.filter((item) => {
  //     return status.status == "All" ? 1 : item.status === status.status;
  //   });

  //   setAllApplicants(filterData);
  // }, [status]);

  return (
    <Container>
      <Box
        className={classes.hidescrollbar}
        sx={{ height: "90vh", overflowY: "scroll" }}
      >
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            Recruiter
          </Typography>
        </Box>
        {/* {`${JSON.stringify(status)}`} */}
        <Stack spacing={3} sx={{ mt: "50px" }}>
          <Box
            sx={{
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "8px",
            }}
          >
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "80%",
                  rowGap: "10px",
                  columnGap: "1.1%",
                }}
              >
                <Box>
                  <StyledFilter
                    onChange={(e) => {
                      setStatus({ ...status, jobTitles: e.target.value });
                      // return handleJobChange();
                    }}
                  >
                    {titles?.map((title) => {
                      return <option value={title.id}>{title.title}</option>;
                    })}
                  </StyledFilter>
                </Box>
                <Box>
                </Box>
              </Box>
            </Box>
            <Stack
              className={classes.hidescrollbar}
              spacing={2}
              sx={{ overflowY: "scroll" }}
            >
              {allApplicants.length == 0 && !loadingAbout ? (
                <JobNotFound />
              ) : (
                <>
                  {allApplicants.map((applicant, index) => {
                    return (
                      <OfferCandidate
                        key={index}
                        data={applicant}
                        flag={flag}
                        setflag={setflag}
                      />
                    );
                  })}
                </>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default Recruiter;
