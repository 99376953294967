import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { getFormField } from "../../firebase/functions";
import convertBase64 from "../../Helpers/fileConvert";
import { responsiveWidth } from "../../utilities/commonStyles/commonStyles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CandidateProfileUpdatingComponent = ({
  user,
  register,
  errors,
  setValue,
  reset,
}) => {
  const theme = useTheme();
  const [currentSalary, setCurrentSalary] = React.useState([]);
  const [expectedSalary, setExpectedSalary] = React.useState([]);
  const [experience, setExperience] = React.useState([]);
  const [educationLevels, setEducationLevels] = React.useState("");
  const [languages, setLanguages] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [dob, setDob] = React.useState(user?.dob ? user.dob : null);
  const [salaryType, setSalaryType] = React.useState("");
  const [skillArray, setSkillArray] = React.useState([]);
  const [jobTypeArray, setJobTypeArray] = React.useState([]);
  const [careerLevelArray, setCareerLevelArray] = React.useState([]);
  const [experienceArray, setExperienceArray] = React.useState([]);
  const [industryArray, setIndustryArray] = React.useState([]);
  const [educationArray, setEducationArray] = React.useState([]);
  React.useEffect(() => {
    setExperience(user?.experience ? user.experience : []);
    setSalaryType(user?.salaryType ? user.salaryType : "hour");
  }, []);

  React.useEffect(() => {
    getFormField({ form: "categories" }).then(({ data }) => {
      console.log("Categories", data);
      setSkillArray(data);
    });
  }, []);

  React.useEffect(() => {
    getFormField({ form: "jobTypes" }).then(({ data }) => {
      console.log("Job Types", data);
      setJobTypeArray(data);
    });
  }, []);

  React.useEffect(() => {
    getFormField({ form: "career" }).then(({ data }) => {
      console.log("Career Level", data);
      setCareerLevelArray(data);
    });
  }, []);

  React.useEffect(() => {
    getFormField({ form: "experience" }).then(({ data }) => {
      console.log("Experience", data);
      setExperienceArray(data);
    });
  }, []);

  React.useEffect(() => {
    getFormField({ form: "primaryIndustry" }).then(({ data }) => {
      console.log("Industry", data);
      setIndustryArray(data);
    });
  }, []);

  React.useEffect(() => {
    getFormField({ form: "education" }).then(({ data }) => {
      console.log("Education", data);
      setEducationArray(data);
    });
  }, []);
  const handleCurrentSalaryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentSalary(typeof value === "string" ? value.split(",") : value);
  };
  const handleExpectedSalaryChange = (event) => {
    const {
      target: { value },
    } = event;
    setExpectedSalary(typeof value === "string" ? value.split(",") : value);
  };
  const handleExperienceChange = (event) => {
    const {
      target: { value },
    } = event;
    setExperience(typeof value === "string" ? value.split(",") : value);
  };

  const handleEducationLevelsChange = (event) => {
    const {
      target: { value },
    } = event;

    setEducationLevels(typeof value === "string" ? value.split(",") : value);
    setValue("educationLevel", event.target.value);
  };

  const handleLanguagesChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguages(typeof value === "string" ? value.split(",") : value);
  };

  const handleCategoriesChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategories(typeof value === "string" ? value.split(",") : value);
    setValue("categories", value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        sx={{
          color: "#25C027",
          fontFamily: "Sofia Pro",
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        My Profile
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center", md: "end" },
          gap: { xs: "15px", md: "30px" },
          mt: "20px",
        }}
      >
        <Box
          sx={{
            width: { xs: "120px", md: "150px" },
            height: { xs: "80px", md: "150px" },
          }}
        >
          <Avatar
            src={image ? URL.createObjectURL(image) : user?.displayImage}
            sx={{ width: "100%", height: "100%", borderRadius: "50%" }}
          ></Avatar>
          {/* <img  src={image?URL.createObjectURL(image):null} alt="" /> */}
        </Box>
        <Box>
          <label htmlFor="contained-button-file">
            <Input
              onChange={async (e) => {
                await setValue(
                  "displayImage",
                  await convertBase64(e.target.files[0])
                );
                return setImage(e.target.files[0]);
              }}
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              sx={{ display: "none" }}
            />
            <Button
              variant="contained"
              component="span"
              sx={{
                textTransform: "none",
                fontSize: { xs: "8px", md: "14px" },
                fontFamily: "Sofia Pro",
                backgroundColor: "#25C027",
                color: "#fff",
                padding: { xs: "10px 20px", md: "15px 30px" },
                borderRadius: "8px",
                mt: "30px",
                "&:hover": {
                  backgroundColor: "#25C027",
                },
              }}
            >
              Upload Photo
            </Button>
          </label>

          <Typography
            sx={{
              color: "#696969",
              fontFamily: "Sofia Pro",
              fontSize: "14px",
              mt: "30px",
            }}
          >
            Max file size is 1MB, Minimum dimension: 330x300 And Suitable files
            are .jpg & .png
          </Typography>
        </Box>
      </Box>

      <Stack spacing={4} sx={{ mt: "50px" }}>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  First Name*
                </Typography>
                <TextField
                  {...register("firstName", {
                    required: "First Name is required",
                  })}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%", mt: "10px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Last Name*
                </Typography>
                <TextField
                  {...register("lastName", {
                    required: "Last Name is required",
                  })}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%", mt: "10px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Job Title*
                </Typography>
                <TextField
                  {...register("jobPreference", {
                    required: "Job Preference is required",
                  })}
                  error={Boolean(errors.jobPreference)}
                  helperText={
                    errors.jobPreference && errors.jobPreference.message
                  }
                  id="outlined-basic"
                  label="Job Preference"
                  variant="outlined"
                  sx={{ width: "100%", mt: "10px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Phone
                </Typography>
                <TextField
                  {...register("phoneNumber", {
                    required: "Phone Number is required",
                  })}
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber && errors.phoneNumber.message}
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%", mt: "10px" }}
                />
              </Box>
            </Grid>
            {/* //!SALARY */}
            <Grid item xs={12} md={6} sx={{ display: "flex", gap: "15px" }}>
              <Stack>
                <FormControl
                  error={Boolean(errors.salaryType)}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setValue("salaryType", e.target.value);
                    // reset({
                    //   maxSalary: "",
                    //   minSalary: "",
                    // });
                    return setSalaryType(e.target.value);
                  }}
                  // helperText={errors.salaryType && errors.salaryType.message}
                  value={salaryType}
                >
                  <FormLabel id="demo-radio-buttons-group-label">
                    Salary Types : per {salaryType}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    // {...register("salaryType", {
                    //   required: "Salary Type is required",
                    // })}
                    value={salaryType}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value="hour"
                      control={<Radio />}
                      label="Hourly"
                    />
                    <FormControlLabel
                      value="year"
                      control={<Radio />}
                      label="Yearly"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {errors.salaryType && errors.salaryType.message}
                  </FormHelperText>
                </FormControl>

                <Stack>
                  <Stack direction gap={2}>
                    <Box sx={{ ...responsiveWidth }}>
                      <Typography>Current($/{salaryType})</Typography>
                      <FormControl sx={{ width: "100%", mt: "10px" }}>
                        <TextField
                          {...register("currentSalary", {
                            // required: "Current Salary is required",
                            type: "number",
                          })}
                          id="outlined-basic"
                          label="Current Salary"
                          variant="outlined"
                          sx={{ width: "100%" }}
                        />
                      </FormControl>
                    </Box>

                    <Box sx={{ ...responsiveWidth }}>
                      <Typography>Expected($/{salaryType})</Typography>
                      <FormControl sx={{ width: "100%", mt: "10px" }}>
                        <TextField
                          {...register("expectedSalary", {
                            type: "number",
                          })}
                          id="outlined-basic"
                          label="Expected Salary"
                          variant="outlined"
                          sx={{ width: "100%" }}
                        />
                      </FormControl>
                    </Box>
                  </Stack>
                  <Typography
                    sx={{ color: "#202124", fontFamily: "Sofia Pro" }}
                  >
                    *Note (Salary information will be hidden)
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography>Experience*</Typography>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  <Autocomplete
                    {...register("experience", {
                      required: "This field is required",
                    })}
                    disablePortal
                    id="combo-box-demo"
                    options={experienceArray}
                    getOptionLabel={(option) => option}
                    defaultValue={user?.experience}
                    error={Boolean(errors.experience)}
                    helperText={errors.experience && errors.experience.message}
                    label="Select"
                    color="success"
                    MenuProps={MenuProps}
                    onChange={(event, value) => {
                      return setValue("experience", value);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Experience" />
                    )}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Date of birth
                </Typography>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Date of birth"
                      onChange={(data) => {
                        setValue("dob", data);
                        return setDob(data);
                      }}
                      value={dob ? dob : new Date()}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  {/* <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentUtils}>
                    <DesktopDatePicker
                      // {...register("dob", {
                      //   required: "Age is required",
                      // })}
                      disableFuture
                      onChange={(data) => {
                        setValue("dob", data);
                        return setDob(data);
                      }}
                      value={dob ? dob : new Date()}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <TextField></TextField> */}
                  {/* <DesktopDatePicker
                    label="Date desktop"
                    inputFormat="MM/dd/yyyy"
                    // value={value}
                    // onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                  /> */}
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography>Education Levels</Typography>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={educationArray}
                    getOptionLabel={(option) => option}
                    defaultValue={user?.educationLevel}
                    onChange={(event, value) => {
                      return setValue("educationLevel", value);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Education level" />
                    )}
                  />
                </FormControl>
              </Box>
            </Grid>
            {/* {`${JSON.stringify(educationArray)}`} */}
            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography>Status</Typography>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={[
                      "Actively looking",
                      "Open to New Opportunities",
                      "Not Looking For Work At This Time",
                    ]}
                    getOptionLabel={(option) => option}
                    defaultValue={user?.statusCand}
                    onChange={(event, value) => {
                      return setValue("statusCand", value);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography>Languages</Typography>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  {user?.firstName ? (
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={languageArray}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setValue("languages", value);
                      }}
                      defaultValue={user?.languages?.map((data) => data)}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type to search"
                          variant="standard"
                          multiline
                        />
                      )}
                    />
                  ) : null}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ width: "100%" }}>
                <Typography>Categories</Typography>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  {user?.firstName ? (
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={skillArray}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setValue("categories", value);
                      }}
                      defaultValue={user.categories?.map((data) => data)}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Please enter your skills"
                          placeholder="Skills"
                          variant="standard"
                          multiline
                        />
                      )}
                    />
                  ) : null}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Description*
                </Typography>
                <TextField
                  {...register("aboutMe", {
                    required: "About me is required",
                  })}
                  id="outlined-basic"
                  label="Write something on about yourself"
                  variant="outlined"
                  error={Boolean(errors.aboutMe)}
                  helperText={errors.aboutMe && errors.aboutMe.message}
                  color="success"
                  multiline
                  rows={10}
                  sx={{
                    width: "100%",
                    mt: "10px",
                    color: "#696969",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
const categoryArray = [
  "Plumbing",
  "Household",
  "Manufacturing",
  "Logistics",
  "Electronics",
  "Automotive",
];
const languageArray = [
  // international languages
  "English",
  "French",
  "German",
  "Spanish",
  "Chinese",
  "Japanese",
  "Korean",
  "Hindi",
  "Arabic",
  "Portuguese",
  "Russian",
  "Turkish",
  "Urdu",
  "Persian",
  "Telugu",
  "Marathi",
  "Tamil",
  "Bengali",
  "Punjabi",
  "Gujarati",
  "Oriya",
  "irish",
];
export default CandidateProfileUpdatingComponent;
