import { Box, Button } from "@mui/material";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  displayOnDesktop,
  displayOnMobile,
  flexCenter
} from "../../utilities/commonStyles/commonStyles";
import mobileRegistrationLogo from "../../utilities/images/mobileRegistrationLogo.png";
import NavbarLogo from "../../utilities/images/Asset-9.svg";
import RegisterBg from "../../utilities/images/svg images/register-bg.svg";
import RegisterImg from "../../utilities/images/svg images/register-img.svg";
import RegisterLogo from "../../utilities/images/svg images/register-logo.svg";
import UpdatedRegisterLogo from "../../utilities/images/Asset-9.svg";
import classes from "./Register.module.css";

const Register = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: { md: "flex" } }}>
      <Box sx={displayOnMobile}>
        <Box sx={{ ...flexCenter, backgroundColor: "#25C027", py: "20px" }}>
          <img style={{width:"200px"}} src={NavbarLogo} alt="" />
        </Box>
        <Button
          onClick={() => navigate("/")}
          sx={{
            textTransform: "none",
            fontSize: "12px",
            fontFamily: "Sofia Pro",
            fontWeight: 600,
            backgroundColor: "#24BD2C",
            color: "#fff",
            padding: "8px 16px",
            borderRadius: "0px 8px 8px 0px",
            mt: "10px",
            "&:hover": {
              backgroundColor: "#24BD2C",
            },
          }}
        >
          Return to home page
        </Button>
      </Box>
      <Box
        style={{
          backgroundImage: `url(${RegisterBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
        sx={{
          ...displayOnDesktop,
          width: "50%",
          height: "100vh",
          background: "#24BD2C",
          paddingLeft: "50px",
          position: "relative",
        }}
      >
        <Box sx={{ mt: "30px" }} onClick={() => navigate("/")}>
          <img style={{ width: "200px" }} src={UpdatedRegisterLogo} alt="" />
        </Box>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: "0px",
            paddingRight: "10px",
          }}
        >
          <img style={{ width: "90%" }} src={RegisterImg} alt="" />
        </Box>
      </Box>
      <Box
        className="hidescrollbar"
        sx={{
          width: { md: "50%", xs: "100%" },
          height: "100vh",
          background: "#fff",
          paddingBottom: "50px",
          overflowY: "scroll",
        }}
      >
        <Box sx={{ ...displayOnDesktop }}>
          <Box
            sx={{
              mt: "60px",
              display: "flex",
              justifyContent: "end",
              paddingRight: "50px",
            }}
          >
            <Box sx={{ display: "flex", gap: "30px" }}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
                to="register"
              >
                Register
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
                to="login"
              >
                Login
              </NavLink>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
