// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  signInWithEmailAndPassword,
  getAuth,
  signOut,
  signInWithCustomToken,
  sendPasswordResetEmail,
  confirmPasswordReset,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { HttpsCallable } from "firebase/functions";
import { getCustomToken, saveFCM , deleteFCM } from "./functions";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";

const prod = true;

const firebaseConfig = prod
  ? {
      apiKey: "AIzaSyDUSUPtRRQIiPJNnh8dUjUuDdcAIKlJJ7w",
      authDomain: "mfgworx.firebaseapp.com",
      projectId: "mfgworx",
      storageBucket: "mfgworx.appspot.com",
      messagingSenderId: "558338218475",
      appId: "1:558338218475:web:6a39e3906678f9286161ae",
      measurementId: "G-J1W0971VG3",
    }
  : {
      apiKey: "AIzaSyBBwrxGjDwmbk0BRmuMZuR8ZEGbOwBvioo",
      authDomain: "dev-jobboard-gcp.firebaseapp.com",
      projectId: "dev-jobboard-gcp",
      storageBucket: "dev-jobboard-gcp.appspot.com",
      messagingSenderId: "54923166217",
      appId: "1:54923166217:web:7af1bb622b7aeafff26ed8",
      measurementId: "G-9WMTBVK9EJ",
    };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const signIn = async (email, password = "password") => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    const token = await user.user.getIdToken();
    try {
      const messaging = getMessaging();
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      })
      .then(() => {
        getToken(messaging, {
          vapidKey: prod
            ? "BBIdctTCLQ5aERD5Cj8Vvk-4bxGUDtBoRLqfA2crUhbtTc2MD3UblZKTYmiDEKyqXMd06y3z07MHPg3_zyNVPqU"
            : "BGkjfGlPxFqynS2_f62mCYZq8Kdz2G16WrJsDuaCpxdJHis4GyBo5kqJFo4KL7PsmfrWxYrZs6Mjtf5QmX6nJ6Y",
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log(
                "Token: " + currentToken + "\nUID: " + auth.currentUser.uid
              );
              saveFCM({ fcm: currentToken });
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      });
    }
    catch(err) {
     console.log(err);
    }
    return "success";
  } catch (error) {
    return new Error(error.message);
  }
};

export const sendPasswordResetLink = async (email) => {
  try {
    if (!email) {
      throw new Error("Email is required");
    }
    const result = await sendPasswordResetEmail(auth, email);
    // console.log(result, "email send");
    return result;
  } catch (e) {
    // console.log(JSON.stringify(e));
    throw new Error(e);
  }
};
export const updatePasswords = async (password, newPassword) => {
  try {
    const credentials = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );
    console.log(password, newPassword);
    const user = await auth.currentUser;
    const userCred = await reauthenticateWithCredential(
      auth.currentUser,
      credentials
    );
    const result = await updatePassword(auth.currentUser, newPassword);
    // await
    return result;
  } catch (error) {
    console.log("error");
    return new Error(error.message);
  }
};

export const logOut = async () => {
  try {
    await deleteFCM({});
    await signOut(auth);
    console.log("logged out");
  } catch (error) {
    console.log(error);
  }
};
export const functions = getFunctions(app);

//connectFunctionsEmulator(functions, "localhost", 5001);
