import {
  Box,
  Container,
  Stack,
  Typography,
  Modal,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classess from "../EmployeeDashboardComponent/EmployeeDashboardComponent.module.css";
import { GetType } from "../../context/authContext";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { acceptInviteCandidateTeam, initMessage, rejectInviteCandidateTeam } from "../../firebase/functions";
import { CandidatedProfileMessageIcon } from "../../utilities/Icons/Icons";
import { useForm } from "react-hook-form";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const EmployeeDashboardCandidate = () => {
    const user = GetType();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
      const [status, setStatus] = useState("");
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);
     const [inviteLoad, setInviteLoad] = useState(false);
  const [loading, setloading] = useState(false);
    const [userData, setUserData] = useState({});
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingAssign, setLoadingAssign] = useState(false);
    const [flag, setflag] = useState(false);
    const { id, jobId } = useParams();
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
      reset,
    } = useForm();
     const onSubmit = (tempData) => {
       setLoadingAssign(true);
       if (status == "accept") {
         acceptInviteCandidateTeam({
           ...tempData,
           jobId: userData.jobId,
           candidateId: userData.candidateId,
         })
           .then((data) => {
             console.log(data.data);
             if (data.data == "insufficient-data") {
               toast.error("Incomplete Profile");
               setflag(flag + 1);
               setLoadingAssign(false);
             } else {
               toast.success("Candidate Accepted");
               setLoadingAssign(false);
               setOpen(false);
               setflag(flag + 1);
             }
           })
           .catch((e) => {
             console.log(e.message);
           });
       } else {
         rejectInviteCandidateTeam({
           ...tempData,
           jobId: userData.jobId,
           candidateId: userData.candidateId,
         }).then(() => {
           toast.success("Candidate Rejected");
           setLoadingAssign(false);
           setOpen(false);
           setflag(flag + 1);
         });
       }
     };
  useEffect(() => {
    console.log("inside userEffect");
    setloading(true);
    if (id != undefined) {
      getCandidateDetails();
    }
  }, [id,flag]);
  const getCandidateDetails = async () => {
    setloading(true);
    const employerPostedJob = doc(db, `Jobs/${jobId}/teamApplications/${id}`);
    const docSnap = await getDoc(employerPostedJob);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setUserData(docSnap.data());
      setloading(false);
    } else {
      //! doc.data() will be undefined in this case
      console.log("No such document!");
      setloading(false);
    }
  };

  return (
    <Container>
      <Box
        className={classess.hidescrollbar}
        sx={{ height: "80vh", overflowY: "scroll" }}
      >
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            Recruiter
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "90%" },
            height: { xs: "100%", md: "180px" },
            paddingTop: "10px",
            borderRadius: "8px",
            border: "1px solid #ECEDF2",
            boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  color: "#25C027",

                  fontSize: "20px",
                  ml: "2vw",
                  fontWeight: 500,
                }}
              >
                {userData?.firstName} {userData?.lastName}
              </Typography>
            </Box>
            {userData?.status != "Pending" ? (
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    mr: "0.5vw",
                    fontWeight: 300,
                    color:
                      userData?.status == "Pending"
                        ? "#BCAA09"
                        : userData?.status == "Approved"
                        ? "#25C027"
                        : "#9A1209",
                  }}
                >
                  {userData?.status}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "25%",
                  height: "30px",
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <LoadingButton
                  loadingIndicator={<CircularProgress size={20} />}
                  loading={inviteLoad}
                  onClick={() => {
                    setStatus("accept");
                    setOpen(true);
                  }}
                  sx={{
                    width: "125px",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    backgroundColor: "#25c027",
                    borderRadius: "3px",
                    "&:hover": {
                      backgroundColor: "success.dark",
                    },
                  }}
                >
                  Accept
                </LoadingButton>
                <LoadingButton
                  loadingIndicator={<CircularProgress size={20} />}
                  loading={inviteLoad}
                  // color="error.main"
                  onClick={() => {
                    setStatus("reject");
                    setOpen(true);
                  }}
                  sx={{
                    width: "125px",
                    height: "100%",
                    display: "flex",
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "red",
                    borderRadius: "3px",
                    marginLeft: "5px",
                    "&:hover": {
                      backgroundColor: "error.dark",
                    },
                  }}
                >
                  Reject
                </LoadingButton>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "100%" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontSize: "17px",
                  ml: "2vw",
                  fontWeight: 300,
                }}
              >
                {userData?.organisationName}
              </Typography>
            </Box>
            <LoadingButton
              onClick={() => {
                setLoadingButton(true);
                initMessage({ candidateId: userData.candidateId }).then(
                  ({ data }) => {
                    console.log("MEEEESAGWE", data);
                    setLoadingButton(false);
                    navigate("/employee-dashboard/message");
                  }
                );
              }}
              sx={{
                color: "#25C027",
              }}
              loading={loadingButton}
              // startIcon={<MessageIcon />}
              loadingIndicator={<CircularProgress />}
            >
              {/* {applicantId} */}
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FFF",
                  fill: "#25C027",
                  padding: "0px !important",
                }}
              >
                <CandidatedProfileMessageIcon />
              </Box>{" "}
              Message
            </LoadingButton>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "350px" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontSize: "17px",
                  ml: "2vw",
                  fontWeight: 300,
                }}
              >
                {userData.jobTitle}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "350px" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              href={userData?.resumeUrl}
              target="_blank"
              component={"a"}
              sx={{ textAlign: "right", cursor: "pointer" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  ml: "2vw",
                  fontWeight: 300,
                  color: "#25C027",
                }}
              >
                View Resume
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "90%" },
            height: "auto",
            mt: "20px",
            padding: "10px",
            borderRadius: "8px",
            border: "1px solid #ECEDF2",
            boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {userData?.teamNote ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      // color: "#1967D2",

                      fontSize: "20px",
                      ml: "2vw",
                      fontWeight: 500,
                    }}
                  >
                    Note
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      mr: "0.5vw",
                      fontWeight: 300,
                    }}
                  >
                    Received by Recruiter
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "100%" },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "17px",
                      //   ml: "2vw",
                      fontWeight: 300,
                    }}
                  >
                    {userData?.teamNote}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
          {userData?.orgNote ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      // color: "#1967D2",

                      fontSize: "20px",
                      ml: "2vw",
                      fontWeight: 500,
                    }}
                  >
                    Note
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      mr: "0.5vw",
                      fontWeight: 300,
                    }}
                  >
                    Send to Recruiter
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "100%" },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "17px",
                      //   ml: "2vw",
                      fontWeight: 300,
                    }}
                  >
                    {userData?.orgNote}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classess.hidescrollbar}
          sx={{
            ...style,
            width: { xs: "95%", md: "50%" },
            overflow: "scroll",
            height: "50%",
          }}
          component={"form"}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing={4}>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "30px",
                  fontWeight: 500,
                }}
              >
                Send Response
              </Typography>
            </Box>
            <TextField
              id="outlined-basic"
              label="Note"
              variant="outlined"
              color="success"
              multiline
              {...register("orgNote", { required: "Note is required" })}
              rows={5}
              sx={{
                width: "100%",
                mt: "10px",
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
              error={Boolean(errors.orgNote)}
              helperText={errors.orgNote && errors.orgNote.message}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <LoadingButton
                loading={loadingAssign}
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
                type="submit"
              >
                Send
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
};

export default EmployeeDashboardCandidate;
