import { Box, Container, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import classes from "./SuperAdminDashboardComponent.module.css";

const SuperAdminDashboardPaymentInvoices = () => {
  const fakeArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Subscription
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "30px",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              My Subscription
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: "#25C027",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Buy Subscription
            </Typography>
          </Box>
        </Box>

        <Box
          className={classes.hidescrollbar}
          sx={{ height: "40vh", overflowY: "scroll", my: "40px" }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                <TableRow
                  sx={{ "&:first-child td, &:first-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      miWidth: "150px",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "150px",
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Transaction id
                  </TableCell>
                  <TableCell
                    sx={{
                      miWidth: "150px",
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Package
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "150px",
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Expiry
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "150px",
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Total Jobs/CVs
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "150px",
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Used
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "150px",
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Remaining
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "150px",
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "150px",
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Company Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ width: "100%" }}>
                {fakeArr.map((arr, index) => (
                  <TableRow
                    key={arr}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      padding: "50px",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ minWidth: "150px" }}
                    >
                      <Typography>{index + 1}</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: "100px" }}>
                      #593677663
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ miWidth: "150px", textAlign: "center" }}
                    >
                      <Typography>Quartarly</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ minWidth: "110px", textAlign: "center" }}
                    >
                      Jan 11, 2023
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ minWidth: "100px", textAlign: "center" }}
                    >
                      8
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ minWidth: "100px", textAlign: "center" }}
                    >
                      5
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ minWidth: "100px", textAlign: "center" }}
                    >
                      42
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        minHeight: "100px",
                      }}
                    >
                      <Typography>Active</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        minHeight: "100px",
                      }}
                    >
                      <Typography>Ford</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Container>
  );
};

export default SuperAdminDashboardPaymentInvoices;
