import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import {
    flexBetweenWithItemsCenter,
    flexCenter,
    responsiveFlexDirection
} from "../../utilities/commonStyles/commonStyles";
import {
    HomeFeaturesApplyJobIcon,
    HomeFeaturesFindJobIcon,
    HomeFeaturesLoginIcon
} from "../../utilities/Icons/Icons";

const HomeFeatureComponent = () => {
  return (
    <Container sx={{ my: "150px" }}>
      <Box sx={{ ...responsiveFlexDirection, display: "flex", gap: "30px" }}>
        <Box
          sx={{
            ...flexBetweenWithItemsCenter,
            backgroundColor: "#FFF",
            boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
            borderRadius: "8px",
            padding: "20px",
            width: { xs: "100%", md: "33%" },
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                ...flexCenter,
                width: "75px",
                height: "75px",
                borderRadius: "50px",
                backgroundColor: "#25C027",
              }}
            >
              <HomeFeaturesLoginIcon />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontWeight: 700,
                  fontSize: "22px",
                  fontFamily: "Poppins",
                }}
              >
                Register/Log In
              </Typography>
              <Typography
                sx={{
                  color: "#202124",
                  fontWeight: 700,
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
              >
                Create your free account or simply log in
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontWeight: 700,
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
              >
                Register account
              </Typography>
            </Box>
          </Stack>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontWeight: 700,
                fontSize: "125px",
                fontFamily: "Poppins",
              }}
            >
              1
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            ...flexBetweenWithItemsCenter,
            backgroundColor: "#FFF",
            boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
            borderRadius: "8px",
            padding: "20px",
            width: { xs: "100%", md: "33%" },
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                ...flexCenter,
                width: "75px",
                height: "75px",
                borderRadius: "50px",
                backgroundColor: "#25C027",
              }}
            >
              <HomeFeaturesFindJobIcon />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontWeight: 700,
                  fontSize: "22px",
                  fontFamily: "Poppins",
                }}
              >
                Search Open Jobs
              </Typography>
              <Typography
                sx={{
                  color: "#202124",
                  fontWeight: 700,
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
              >
              Search through amazing opportunities with top industrial companies
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontWeight: 700,
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
              >
                Find Job
              </Typography>
            </Box>
          </Stack>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontWeight: 700,
                fontSize: "125px",
                fontFamily: "Poppins",
              }}
            >
              2
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            ...flexBetweenWithItemsCenter,
            backgroundColor: "#FFF",
            boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
            borderRadius: "8px",
            padding: "20px",
            width: { xs: "100%", md: "33%" },
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                ...flexCenter,
                width: "75px",
                height: "75px",
                borderRadius: "50px",
                backgroundColor: "#25C027",
              }}
            >
              <HomeFeaturesApplyJobIcon />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontWeight: 700,
                  fontSize: "22px",
                  fontFamily: "Poppins",
                }}
              >
                Apply
              </Typography>
              <Typography
                sx={{
                  color: "#202124",
                  fontWeight: 700,
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
              >
              Apply to various roles and let hiring managers contact you instantly
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontWeight: 700,
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
              >
                Apply job
              </Typography>
            </Box>
          </Stack>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontWeight: 700,
                fontSize: "125px",
                fontFamily: "Poppins",
              }}
            >
              3
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default HomeFeatureComponent;
