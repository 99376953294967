import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  Modal,
  Pagination,
  Stack,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import { fetchOrgNotifs, SetAllNotificationAsRead, SetNotificationAsReadById } from "../../firebase/functions";
import { sdkfireBaseTime } from "../../Helpers/SdkTimeConvert";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import {
  displayOnDesktop,
  displayOnMobile,
  itemsCenter
} from "../../utilities/commonStyles/commonStyles";
import {
  EmployeeDashboardComponentCrossedIcon,
  EmployeeDashboardComponentTickIcon
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./EmployeeDashboardComponent.module.css";

const EmployeeDashboardResumeAlerts = () => {
  const fakeArr = [1, 2, 3, 4, 5];
  const [companyData, setcompanyData] = useState([]);
  const [ind, setIndex] = useState(-1);
  const [loading, setloading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [allReadLoadingButton, setAllReadLoadingButton] = useState(false);
  const [loadButtton, setLoadButton] = useState(false);
  const userType = GetType();
  const [flag, setflag] = useState(0);
  const fake = [1, 2, 3, 4, 5];
  useEffect(() => {
    setloading(true);
    fetchOrgNotifs({})
      .then((response) => {
        console.log(Array.isArray(response));
        if (Array.isArray(response.data)) {
          setcompanyData(response.data);
        }
        setloading(false);
        console.log(response);
        setLoadButton(false);
      })
      .catch((e) => {
          setloading(false)
        toast.error(e.message);
        setLoadButton(false);
      });
     if (userType.organisation != undefined) {
       soln();
     }
  }, []);
  const soln = async () => {
    const fetchApplications = query(
      collection(db, `Organisations/${userType.organisation}/Notifications`),
      orderBy("timeStamp", "desc")
    );
    await getDocs(fetchApplications)
      .then((data) => {
        setcompanyData(
          data.docs.map((doc) => {
            console.log(doc.data());
            return { ...doc.data(), id: doc.id };
          })
        );
      
               setloading(false);
          //     console.log(response);
               setLoadButton(false);
      })
      .catch((e) => {
        console.log(e);
         setloading(false);
         toast.error(e.message);
         setLoadButton(false);
      });
  };
  const updateAsRead = (id) => {
    setLoadButton(true);
    SetNotificationAsReadById({notificationId:id}).then((response) =>{
      setflag(flag + 1);
      console.log(response);
       fetchOrgNotifs({})
         .then((response) => {
           console.log(Array.isArray(response));
           if (Array.isArray(response.data)) {
             setcompanyData(response.data);
           }
           setLoadButton(false);
           setIndex(-1);
         })
         .catch((e) => {
           setLoadButton(false);
           setIndex(-1);
         });
    }).catch((error) =>{
      console.log(error);
      setLoadButton(false);
   })
 }
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "2vw",
        }}
      >
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Notification List
        </Typography>
        <LoadingButton
          loading={allReadLoadingButton}
          onClick={() => {
            setAllReadLoadingButton(true);
            SetAllNotificationAsRead({})
              .then((response) => {
                 fetchOrgNotifs({})
                   .then((response) => {
                     console.log(Array.isArray(response));
                     if (Array.isArray(response.data)) {
                       setcompanyData(response.data);
                     }
               setAllReadLoadingButton(false);
      
                   })
                   .catch((e) => {
                     setAllReadLoadingButton(false);
      
                   });
               
              })
              .catch((e) => {
                setAllReadLoadingButton(false);
              });
          }}
          sx={{
            textTransform: "none",
            fontSize: "12px",
            fontFamily: "",
            backgroundColor: "#24BD2C",

            color: "#fff",
            padding: "8px 17px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#24BD2C",
            },
          }}
        >
          Mark as All Read
        </LoadingButton>
      </Box>

      <Box
        sx={{
          ...displayOnDesktop,
          backgroundColor: "#fff",
          padding: "30px",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        {/* <Typography
          sx={{
            color: "#202124",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Resume Alerts
        </Typography> */}

        <Box>
          {/* <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            My Alerts
          </Typography> */}
          {/* <LoadingButton
            loading={allReadLoadingButton}
            onClick={() => {
              setAllReadLoadingButton(true);
              SetAllNotificationAsRead({})
                .then((response) => {
                  setAllReadLoadingButton(false);
                })
                .catch((e) => {
                  setAllReadLoadingButton(false);
                });
            }}
            sx={{
              textTransform: "none",
              fontSize: "12px",
              fontFamily: "",
              backgroundColor: "#24BD2C",
              color: "#fff",
              padding: "8px 17px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#24BD2C",
              },
            }}
          >
            Mark as All Read
          </LoadingButton> */}
          <TableContainer sx={{ mt: "20px" }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#24BD2C" }}>
                <TableRow
                  sx={{ "&:first-child td, &:first-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Message
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Time
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Mark As Read
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Actions
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {companyData
                  .map((arr, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        minHeight: "50px",
                        padding: "50px",
                        background: arr.read ? "white" : "#B9FDA1",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography>{arr.title}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>{arr.message}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>
                          {" "}
                          {arr.timeStamp
                            ? sdkfireBaseTime(arr.timeStamp).toDateString()
                            : //new Date(arr.timeStamp).toDateString()
                              ""}
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="left">Weekly</TableCell> */}
                      <TableCell
                        align="left"
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "130px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#F0FAF0",
                            cursor: "pointer",
                          }}
                        >
                          {/* {arr.read == true ? (
                            <EmployeeDashboardComponentTickIcon />
                          ) : (
                            <EmployeeDashboardComponentCrossedIcon />
                          )} */}
                          <LoadingButton
                            loading={loadButtton && ind == index}
                            onClick={() => {
                              setIndex(index);
                              updateAsRead(arr.id);
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <EmployeeDashboardComponentTickIcon />
                          </LoadingButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                  .slice((pageNumber - 1) * 10, (pageNumber - 1) * 10 + 10)}
              </TableBody>
            </Table>
          </TableContainer>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              onChange={(e, pageNumber) => {
                setPageNumber(pageNumber);
              }}
              sx={{
                backgroundColor: "#fff",
                color: "#25C027",
              }}
              count={
                Math.ceil(companyData.length / 10)
                  ? Math.ceil(companyData.length / 10)
                  : 1
              }
            />
          </Container>
        </Box>
      </Box>

      <Box sx={displayOnMobile}>
        <Box sx={{ mt: "20px" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "20px",
            }}
          >
            <Stack spacing={3} sx={{ mt: "20px" }}>
              {companyData.map((arr, index) => (
                <Stack
                  key={index}
                  spacing={1}
                  sx={{
                    width: "100%",
                    boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
                    padding: "15px",
                    borderRadius: "8px",
                    background: arr.read ? "white" : "#B9FDA1",
                  }}
                >
                  <Box sx={{ ...itemsCenter, gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "30%",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#25C027",
                        fontWeight: 600,
                      }}
                    >
                      Title
                    </Typography>
                    <Typography
                      sx={{
                        width: "65%",
                        fontSize: "12px",
                        color: "#425466",
                        fontWeight: 600,
                      }}
                    >
                      {arr.title}
                    </Typography>
                  </Box>
                  <Box sx={{ ...itemsCenter, gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "30%",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#25C027",
                        fontWeight: 600,
                      }}
                    >
                      Message
                    </Typography>
                    <Typography
                      sx={{
                        width: "70%",
                        fontSize: "12px",
                        color: "#425466",
                        fontWeight: 600,
                      }}
                    >
                      {arr.message}
                    </Typography>
                  </Box>
                  <Box sx={{ ...itemsCenter, gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "30%",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#25C027",
                        fontWeight: 600,
                      }}
                    >
                      Time
                    </Typography>
                    <Typography
                      sx={{
                        width: "70%",
                        fontSize: "12px",
                        color: "#425466",
                        fontWeight: 600,
                      }}
                    >
                      {arr.timeStamp
                        ? sdkfireBaseTime(arr.timeStamp).toDateString()
                        : ""}
                    </Typography>
                  </Box>
                  <Box sx={{ ...itemsCenter, gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "30%",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#25C027",
                        fontWeight: 600,
                      }}
                    >
                      Mark as Read
                    </Typography>
                    {/* <Typography
                      sx={{
                        width: "70%",
                        fontSize: "12px",
                        color: "#425466",
                        fontWeight: 600,
                      }}
                    >
                      {arr.read == true ? (
                        <EmployeeDashboardComponentTickIcon />
                      ) : (
                        <EmployeeDashboardComponentCrossedIcon />
                      )}
                    </Typography> */}
                    <LoadingButton
                      loading={loadButtton && ind == index}
                      onClick={() => {
                        setIndex(index);
                        updateAsRead(arr.id);
                      }}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <EmployeeDashboardComponentTickIcon />
                    </LoadingButton>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default EmployeeDashboardResumeAlerts;
