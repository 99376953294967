import { Box, Modal, Paper, Stack } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import EmployeeDashboardMobileMenu from "../../components/EmployeeDashboardComponent/EmployeeDashboardMobileMenu";
import EmployeeDashboardPaymentInvoices from "../../components/EmployeeDashboardComponent/EmployeeDashboardPaymentInvoices";
import SideNavbar from "../../components/EmployeeDashboardComponent/EmployeeDashboardSideNavbar";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import { auth } from "../../firebase/firebaseConfig";
import { checkActive, getUserType } from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveFlexDirection,
} from "../../utilities/commonStyles/commonStyles";

const EmployeeDashboard = () => {
  const [userType, setUser] = React.useState({});
  const [isActive, setIsActive] = React.useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      const type = await getUserType();
      setUser(type.data);

      if (type.data.role == "Recruiter") {
        checkActive().then(({ data }) => {
          if (data == true) {
            setIsActive(true);
          } else {
            setIsActive(false);
          }
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Box>
      <Modal
        open={isActive == false}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <Paper
            sx={{
              p: "20px",
              maxWidth: "900px",
              maxHeight: "90vh",
              overflowY: "scroll",
            }}
          >
            <EmployeeDashboardPaymentInvoices />
          </Paper>
        </Stack>
      </Modal>
      {/* <DashboardNavbar /> */}

      <Box sx={{ ...displayOnDesktop, height: "10vh" }}>
        <NavBar color={"green"} />
      </Box>

      <Box
        sx={{
          ...responsiveFlexDirection,
          height: { md: "90vh" },
          display: "flex",
          gap: "25px",
        }}
      >
        <Box sx={{ ...displayOnDesktop, width: "20%" }}>
          <SideNavbar />
        </Box>
        <Box sx={{ ...displayOnMobile }}>
          <EmployeeDashboardMobileMenu />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeDashboard;
