import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Skeleton,
  Typography
} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import {
  acceptInvite,
  offerActions,
  rejectInvite
} from "../../firebase/functions";
import { sdkfireBaseTime } from "../../Helpers/SdkTimeConvert";
import { flexBetweenWithItemsCenter } from "../../utilities/commonStyles/commonStyles";
import {
  FeturedJobsClockIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
  FeturedJobsMoneyIcon
} from "../../utilities/Icons/Icons";
import MethodTags from "../EmployeeDashboardComponent/ReusableComponents/MethodTags";
import { OfferStatus } from "../EmployeeDashboardComponent/ReusableComponents/OfferStatus";
import { StatusLogo } from "../EmployeeDashboardComponent/ReusableComponents/StatusLogo";

function ApplicationCardCandidate({
  data,
  invite,
  item,
  inviteStatus,
  method,
  status,
  avoidTag,
}) {
  const navigate = useNavigate();
  TimeAgo.addDefaultLocale(en);
  TimeAgo.addLocale(ru);
  const [jobData, setJobData] = useState({});
  const [offerStatus, setOfferStatus] = useState(false);
  const [invitestatus, setInvitestatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState("");
  const [inviteLoad, setInviteLoad] = useState(false);
  const [flag, setflag] = useState(0);
  useEffect(() => {
    // setOfferStatus(item?.status);
    setOfferStatus(item?.offerStatus);
    setInvitestatus(item?.status);

    // getJobOverview({ jobId: data }).then(({ data }) => {
    //  console.log(data)
    //   setJobData(data);
    //   setLoading(false);
    //   getLogo({ orgId: data.organisationId }).then(({ data }) => {
    //     setLogo(data);
    //   });
    // });
    console.log(data);
    getJobDataById();
  }, [flag]);
  const handleOffer = (action) => {
    offerActions({
      jobId: data,
      action: action == true ? "offerAccepted" : "offerRejected",
    }).then(() => {
      toast.success(`Offer ${action == true ? "accepted" : "declined"}`);
      setOfferStatus(action == true ? "offerAccepted" : "offerRejected");
    });
  };
  const handleInvite = (action) => {
    setInviteLoad(true);
    if (action == "accept") {
      acceptInvite({ jobId: data })
        .then((data) => {
          console.log(data.data);
          if (data.data == "insufficient-data") {
            toast.error("Incomplete Profile");
            setflag(flag + 1);
            setInviteLoad(false);
          } else {
            toast.success("Invitation accepted");
            setInvitestatus("invitationAccepted");
            setInviteLoad(false);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else {
      rejectInvite({ jobId: data }).then(() => {
        toast.success("Invitation rejected");
        setInvitestatus("invitationRejected");
        setInviteLoad(false);
      });
    }
  };
  const getJobDataById = async() => {
      const usercollectionRef = doc(db, `Jobs/${data}`);
      const docSnap = await getDoc(usercollectionRef);
      if (docSnap.exists()) {
        const extraRef = doc(db, `Jobs/${data}/extraInfo/infoDoc`);
        const docSnap1 = await getDoc(extraRef);
        if (docSnap1.exists()) {
          console.log(docSnap.data());
          console.log(docSnap1.data());
          setJobData({ ...docSnap.data(), ...docSnap1.data() })
           setLoading(false);
        } else {
          //! doc.data() will be undefined in this case
          console.log("No such document!");
          setLoading(false);
          toast.error("job not found");
        }
        const orgRef = doc(db,`Organisations/${docSnap.data().organisationId}`);
        const docSnap2 = await getDoc(orgRef);
        if (docSnap2.exists()) {
            setLogo(docSnap2.data().organisationLogo);
        } else {
          //! doc.data() will be undefined in this case
          console.log("No such document!");
          toast.error("Something went wrong");
        }
      } else {
        //! doc.data() will be undefined in this case
        console.log("No such document!");
        setLoading(false);
        toast.error("Job not found");
      }
  }
  return (
    <Box
    sx={{
      background: "#fff",
      padding: {xs:"10px", md:"20px"},
      borderRadius: "8px",
      border: "1px solid #ECEDF2",
      width: "100%",
    }}
    >
      {loading ? (
        <Skeleton variant="rect" height={100} width={100} />
        ) : (
          <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
          >
          {/* {`${JSON.stringify(jobData)}`} */}
          <Box
            sx={{
              backgroundColor: "#1967D2",
              width: "50px",
              height: "50px",
              display: "flex",
              overflow: "hidden",
              borderRadius: "8px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ width: "100%" }} src={logo} />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  ...flexBetweenWithItemsCenter,
                  width: "100%",
                  gap: { xs: "5px", md: "10px" },
                }}
              >
                <Typography
                  sx={{
                    color: "#0E0E0E",
                    fontFamily: "Sofia Pro",
                    fontSize: { xs: "14px", md: "18px" },
                    fontWeight: 500,
                  }}
                >
                  {jobData.jobTitle}
                </Typography>

                <Box
                  sx={{
                    width: "130px",
                    height: "30px",
                    backgroundColor: "#e9f9e9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                  }}
                >
                  {/* <Tooltip title="View Job Details" arrow placement="top">
                    <IconButton
                      onClick={() => navigate(`/job-profile/${data}`)}
                    >
                      <svg
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.32391 0.00468018C5.4182 0.0947923 4.77942 0.244783 4.04736 0.539263C2.37278 1.21284 0.97197 2.48152 0.166786 4.05385C-0.0555952 4.4881 -0.0555952 4.50366 0.166786 4.93791C1.10977 6.7793 2.87322 8.19156 4.91915 8.74382C7.38187 9.40862 10.0042 8.76136 11.9296 7.0135C12.5674 6.43451 13.1384 5.69202 13.5221 4.94274C13.7445 4.50849 13.745 4.48609 13.5408 4.08731C12.7171 2.47865 11.3116 1.2006 9.62845 0.529794C8.74623 0.178171 7.95571 0.020401 6.99285 0.00374134C6.6838 -0.00159725 6.38278 -0.00116813 6.32391 0.00468018ZM7.37526 1.82884C8.38803 2.03745 9.1755 2.78276 9.47369 3.8149C9.52696 3.99929 9.5348 4.08661 9.5348 4.49588C9.5348 4.90516 9.52696 4.99248 9.47369 5.17686C9.18979 6.15954 8.50502 6.8461 7.52489 7.13073C7.34099 7.18414 7.25389 7.192 6.84568 7.192C6.43747 7.192 6.35037 7.18414 6.16647 7.13073C5.49438 6.93554 4.94151 6.53718 4.58789 5.99324C4.28435 5.52636 4.16884 5.15894 4.15 4.60035C4.13478 4.14906 4.17181 3.90724 4.31464 3.52557C4.64226 2.65015 5.48268 1.96249 6.41756 1.80494C6.59726 1.77465 7.1832 1.78927 7.37526 1.82884ZM6.39658 2.75263C5.28484 3.04464 4.72259 4.3108 5.2542 5.32511C5.71491 6.20412 6.80295 6.54976 7.6705 6.09268C8.81652 5.48891 8.97603 3.92642 7.97621 3.09808C7.5562 2.75008 6.92865 2.61286 6.39658 2.75263Z"
                          fill="#25C027"
                        />
                      </svg>
                    </IconButton>
                  </Tooltip> */}
                  <Button variant="text" onClick={() => navigate(`/job-profile/${data}`)}>
          <Typography
            sx={{ color: "#25C027", fontSize: "12px", fontWeight: 500 }}
          >
            View Job Details
          </Typography>
        </Button>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", mt:"10px" }}>
                {jobData.skills.map((skills, index) => {
                  return <Chip label={skills} />;
                })}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: { xs: "10px", md: "20px" },
                alignItems: "center",
                mt: "10px",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FeturedJobsFordIcon />
                <Typography
                  sx={{
                    color: "#696969",
                    fontSize: { xs: "10px", md: "14px" },
                  }}
                >
                  {jobData.organisationName}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FeturedJobsLocationIcon />
                <Typography
                  sx={{
                    color: "#696969",
                    fontSize: { xs: "10px", md: "14px" },
                  }}
                >
                  {jobData?.city}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FeturedJobsClockIcon />
                <Typography
                  sx={{
                    color: "#696969",
                    fontSize: { xs: "10px", md: "14px" },
                  }}
                >
                  {/* <ReactTimeAgo
                  date={"2022-07-08T08:47:27.501Z"}
                  locale="en-US"
                /> */}
                  {jobData.jobAddedAt ? (
                    <ReactTimeAgo
                      date={sdkfireBaseTime(jobData?.jobAddedAt).toISOString()}
                      locale="en-US"
                    />
                  ) : (
                    ""
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FeturedJobsMoneyIcon />
                <Typography
                  sx={{
                    color: "#696969",
                    fontSize: { xs: "10px", md: "14px" },
                  }}
                >
                  {`$${jobData?.minSalary} - $${jobData?.maxSalary} /${jobData?.salaryType}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  mt: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#1967D2",
                    background: "#DDE8F8",
                    height: "25",
                    padding: "5px 20px",
                    fontSize: "13px",
                    borderRadius: "60px",
                    fontFamily: "Sofia Pro",
                    textAlign: "center",
                  }}
                >
                  {jobData?.jobType}
                </Typography>
                <Typography
                  sx={{
                    color: "#1967D2",
                    background: "transparent",
                    height: "25",
                    padding: "5px 20px",
                    width: "120px",
                  }}
                ></Typography>
              </Box>
            </Box>
            {/* <MethodTags status={inviteStatus} /> */}
            {/* if invite is invite then show two buttons accept and reject offer  */}
            {invite == "invite" &&
              (!offerStatus ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  {/* {offerStatus} */}
                  <Button
                    onClick={() => handleOffer(true)}
                    sx={{
                      width: "125px",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      backgroundColor: "#25c027",
                      borderRadius: "3px",
                      "&:hover": {
                        backgroundColor: "success.dark",
                      },
                    }}
                  >
                    Accept Offer
                  </Button>
                  <Button
                    onClick={() => handleOffer(false)}
                    // color="error.main"
                    sx={{
                      width: "125px",
                      height: "100%",
                      display: "flex",
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      borderRadius: "3px",
                      marginLeft: "5px",
                      "&:hover": {
                        backgroundColor: "error.dark",
                      },
                    }}
                  >
                    Reject Offer
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "30px",
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  {/* <StatusLogo status={offerStatus} /> */}
                </Box>
              ))}

            {/* if inviteStatus is invite then two buttons accepted and rejected Invite */}

            {/* {inviteStatus && (
              <Box
                sx={{
                  width: "100%",
                  height: "30px",
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <OfferStatus offerStatus={invitestatus?inviteStatus:offerStatus} />
              </Box>
            )} */}
            {invitestatus == "invited" && (
              <Box
                sx={{
                  width: "100%",
                  height: "30px",
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <LoadingButton
                  loadingIndicator={<CircularProgress size={20} />}
                  loading={inviteLoad}
                  onClick={() => handleInvite("accept")}
                  sx={{
                    width: "125px",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    backgroundColor: "#25c027",
                    borderRadius: "3px",
                    "&:hover": {
                      backgroundColor: "success.dark",
                    },
                  }}
                >
                  Accept Invite
                </LoadingButton>
                <LoadingButton
                  loadingIndicator={<CircularProgress size={20} />}
                  loading={inviteLoad}
                  // color="error.main"
                  onClick={() => handleInvite("reject")}
                  sx={{
                    width: "125px",
                    height: "100%",
                    display: "flex",
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "red",
                    borderRadius: "3px",
                    marginLeft: "5px",
                    "&:hover": {
                      backgroundColor: "error.dark",
                    },
                  }}
                >
                  Reject Invite
                </LoadingButton>
              </Box>
            )}

            {item?.offerLetter && (
              <Typography
                target="_blank"
                component={"a"}
                href={item?.offerLetter}
              >
                Download Offer
              </Typography>
            )}

            {/* job applied Status after action  */}
            {!avoidTag ? (
              <>
                {" "}
                <StatusLogo status={status} offerStatus={offerStatus} />
                <MethodTags status={status} method={method} />
                <OfferStatus status={status} offerStatus={offerStatus} />
              </>
            ) : null}
            {/* <StatusLogo method={method} status={status} /> */}
            {/* <OfferStatus offerStatus={offerStatus} /> */}
            {/* <StatusLogo status={status}  /> */}
            {/* <OfferStatus offerStatus={offerStatus} /> */}
            {/* <MethodTags status={status} method={method} /> */}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ApplicationCardCandidate;
