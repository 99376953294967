import { Box, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logOut } from "../../firebase/firebaseConfig";
import {
  CandidateDashboardNotificationIcons, EmployeeDashboardIcon,
  EmployeeDashboardLogoutIcon,
  EmployeeDashboardManageJobsIcon,
  SuperAdminDashboardAboutUsIcons,
  SuperAdminDashboardContactDetailsIcons,
  SuperAdminDashboardFaqIcons,
  SuperAdminDashboardFormFieldIcons,
  SuperAdminDashboardJobSeekerIcons,
  SuperAdminDashboardOrganizationListIcons,
  SuperAdminDashboardPolicyIcons,
  SuperAdminDashboardTermsIcons
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./SuperAdminDashboardSideNavbar.module.css";

const SuperAdminDashboardSideNavbar = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const handleLogout = () => {
    setloading(true);
    logOut().then(() => {
      setloading(false);
      navigate("/");
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
  };
  return (
    <Box
      sx={{
        height: "80vh",
        position: "sticky",
        top: "0px",
        left: "0px",
        right: "0px",
      }}
    >
      <Box className={classes.sidebar}>
        <Box>
          <NavLink
            to="dashboard"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Dashboard
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="company-list"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardOrganizationListIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Organization List
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to="form-field"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardFormFieldIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Form field
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="job-seeker"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardJobSeekerIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Job Seeker
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="member-list"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardManageJobsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
               Invite Member
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="about-us"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardAboutUsIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                About Us
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="terms"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardTermsIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Terms
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="policy"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardPolicyIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Policy
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="faq"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardFaqIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Faq
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to="price-update"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardFaqIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Product
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="contact-us-details"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <SuperAdminDashboardContactDetailsIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Contact Us Details
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to="notification"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <CandidateDashboardNotificationIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Notification
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to="#"
            className={({ isActive }) =>
              false ? classes.active : classes.inactive
            }
            onClick={handleLogout}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardLogoutIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Logout
              </Typography>
            </Box>
          </NavLink>
          {/* <NavLink
        
            to=""
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardChangePasswordIcon />
              <LoadingButton loading={loading} sx={{ fontFamily: "Poppins",
              fontSize: "15px",}} >Logout</LoadingButton>
            </Box>
          </NavLink> */}

          {/* <NavLink
            to="refund-request"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardChangePasswordIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Refund Request
              </Typography>
            </Box>
          </NavLink> */}

          {/* <NavLink
            to="payment-invoices"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardChangePasswordIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Payment Invoices
              </Typography>
            </Box>
          </NavLink> */}
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default SuperAdminDashboardSideNavbar;
