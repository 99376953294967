import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Chip, CircularProgress, Modal, Skeleton, Stack, Typography } from "@mui/material";
import { Container, height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApplicantInfo } from "../../firebase/functions";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import ReplayIcon from '@mui/icons-material/Replay';
import {
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon
} from "../../utilities/Icons/Icons";
function CandidateCard({ id, setOverView, overView, jobId, filter }) {
  const navigate = useNavigate();
  const [userData, setApplicantInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingError , setLoadingError]= useState(false);
  const [flag , setflag] = useState(0);
  useEffect(() => {
    setLoadingError(false);
    setLoading(true);
    getApplicantInfo({ applicantId: id.appliedBy }).then(({ data }) => {
      setLoading(false);
      setApplicantInfo(data);
      setLoadingError(false);
    }).catch((error)=>{
      console.log(error.message);
      //alert("hello")
      setLoading(false);
      setLoadingError(true);
    })
  }, [id,flag]);
  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" height={"200px"} width="100%" />
      ) :loadingError?  <Container sx={{ my: "30px" }}>
      <Box sx={{...flexCenter, flexDirection:"column",  gap:"20px", textAlign: "center" }}>
      <Box>
          <Typography
            sx={{
              color: "#B7B7B7",
              fontSize: "25px",
              fontFamily: "Poppins",
            }}
          >
           Sorry ! something went wrong.
          </Typography>
        </Box>
        <ReplayIcon onClick={()=>{
                setflag(flag+1);
              }} sx={{width:"5vw", height:"5vw",color:"#B7B7B7" , cursor:"pointer"}}  />
       
      </Box>
    </Container>:(
        <Box
          // onClick={() => {
          //   setOverView(id);
          // }}
          onClick={() => {
            setOverView(id);
            navigate(
              `/candidate-profile/${id.appliedBy}/${
                overView.jobId ? overView.jobId : jobId
              }?filter=${filter}`
            );
          }}
          sx={{
            width: "100%",
            display: "flex",
            gap: { xs: "10px", md: "20px" },
            background: overView === id ? "#25C027" : "#FFFFFF",
            color: overView === id ? "#FFFFFF" : "#000",
            fill: overView === id ? "#FFFFFF" : "#000",
            padding: { xs: "15px", md: "30px" },
            borderRadius: "20px",
            boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
            "&:hover": {
              backgroundColor: "#25C027",
              color: "#FFF",
              fill: "#FFF",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                width: { xs: "50px", md: "100px" },
                height: { xs: "50px", md: "100px" },
              }}
              src={userData?.displayImage}
              alt=""
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "18px" },
                  fontWeight: 500,
                }}
              >
                {userData?.firstName} {userData?.lastName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "10px", md: "20px" },
                flexWrap:"wrap",
                alignItems: "center",
                mt: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: "5px", md: "10px" },
                }}
              >
                <FeturedJobsFordIcon />
                <Typography
                  sx={{
                    fontSize: { xs: "10px", md: "14px" },
                  }}
                >
                  {userData?.jobPreference}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: "5px", md: "10px" },
                }}
              >
                <FeturedJobsLocationIcon />
                <Typography sx={{ fontSize: { xs: "10px", md: "18px" } }}>
                  {userData?.city},{userData?.state}
                </Typography>
              </Box>

              {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsMoneyIcon />
              <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                {userData?.expectedSalary ? userData?.expectedSalary : 0}$
              </Typography>
            </Box> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: { xs: "7px", md: "15px" },
                mt: "15px",
              }}
            >
              {userData?.skills
                ?.map((skills) => {
                  return (
                    <Box>
                      <Chip
                        sx={{
                          color: "#1967D2",
                          background: "#eee",
                        }}
                        label={skills}
                      />
                    </Box>
                  );
                })
                .slice(0, 4)}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default CandidateCard;
