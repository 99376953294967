import { Box, Button, Container, Input, Typography } from "@mui/material";
import {
    EmployeeDashboardManageJobsDeleteTracedIcon,
    EmployeeDashboardManageJobsPencilTracedIcon
} from "../../utilities/Icons/Icons";
import classes from "./CandidateDashboardCVManager.module.css";

const CandidateDashboardCVManager = () => {
  return (
    <Container>
      <Box
        className={classes.hidescrollbar}
        sx={{ height: "80vh", overflowY: "scroll" }}
      >
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            Cv Manager
          </Typography>
        </Box>

        <Box
          sx={{
            mt: "60px",
            padding: "30px",
            background: "#FFFFFF",
            border: "1px solid #ECEDF2",
            boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
            borderRadius: "8px",
          }}
        >
          <Typography>Cv Manager</Typography>

          <Box
            sx={{
              width: "100%",
              minHeight: "350px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#FFFFFF",
              border: "2px dashed #ECEDF2",
              borderRadius: "8px",
              mt: "40px",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography>Drop files here to upload</Typography>
              <Typography sx={{ mt: "15px" }}>
                To upload file size is (Max 5Mb) and allowed file types are
                (.doc, .docx, .pdf)
              </Typography>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  sx={{ display: "none" }}
                />
                <Button
                  component="span"
                  sx={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Sofia Pro",
                    backgroundColor: "#24BD2C",
                    color: "#fff",
                    padding: "15px 40px",
                    borderRadius: "8px",
                    mt: "25px",
                    "&:hover": {
                      backgroundColor: "#24BD2C",
                    },
                  }}
                >
                  Upload Resume
                </Button>
              </label>
            </Box>
          </Box>

          <Box
            sx={{
              mt: "25px",
              display: "flex",
              alignItems: "center",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                background: "#F0FAF0",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              <Box>
                <Typography>Sample CV</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    mt: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E2F5E2",
                    }}
                  >
                    <EmployeeDashboardManageJobsPencilTracedIcon />
                  </Box>
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E2F5E2",
                    }}
                  >
                    <EmployeeDashboardManageJobsDeleteTracedIcon />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                background: "#F0FAF0",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              <Box>
                <Typography>UI CV</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    mt: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E2F5E2",
                    }}
                  >
                    <EmployeeDashboardManageJobsPencilTracedIcon />
                  </Box>
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E2F5E2",
                    }}
                  >
                    <EmployeeDashboardManageJobsDeleteTracedIcon />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                background: "#F0FAF0",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              <Box>
                <Typography>UX CV</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    mt: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E2F5E2",
                    }}
                  >
                    <EmployeeDashboardManageJobsPencilTracedIcon />
                  </Box>
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E2F5E2",
                    }}
                  >
                    <EmployeeDashboardManageJobsDeleteTracedIcon />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default CandidateDashboardCVManager;
