import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Container,
  Modal,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getJobInfoForApplicantCard,
  userInfoForApplicantCard
} from "../../../firebase/functions";
import MessageButton from "../../../MessageButton/MessageButton";
import {
  flexCenter,
  responsiveFlexDirection
} from "../../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import ApplicantCardActions from "../ApplicantCardActions";
import MethodTags from "./MethodTags";
import { OfferStatus } from "./OfferStatus";
import { StatusDrop } from "./StatusDrop";
import ReplayIcon from '@mui/icons-material/Replay';
import { StatusLogo } from "./StatusLogo";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";

export const ApplicantDetails = ({
  info,
  status,
  selections,
  method,
  offerStatus,
  showAction,
  noofAcceptReject,
  setNoofAcceptReject,
  key,
  skills,
}) => {
  const [jobInfo, setJobInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  
  const [avatar, setAvatar] = useState("");
  const [loadingError , setLoadingError]= useState(false);
  const [flag , setflag] = useState(0);
  const [tag, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setLoadingError(false);
    setStatus(status);
    console.log("useeffectInFO", info);
    
    // getJobInfoForApplicantCard({ jobId: info.data.jobId }).then((data) => {
    //   setJobInfo(data.data);
    // });

    soln();
    // userInfoForApplicantCard({
    //   applicantId: info.data.applicationId
    //     ? info.data.applicationId
    //     : info.data.appliedBy
    //     ? info.data.appliedBy
    //     : info.data.applicantId,
    // }).then((data) => {
    //   setUserInfo(data.data);
    //   console.log(data.data);
    //   setAvatar(data.data.applicantData.displayImage);
    //   setLoading(false);
    //   setLoadingError(false);
    // }).catch((error)=>{
    //   console.log(error);
    //   setLoading(false);
    //   setLoadingError(true);
    // })
  }, [
    info.data.applicationId
      ? info.data.applicationId
      : info.data.appliedBy
      ? info.data.appliedBy
      : info.data.applicantId,
      flag
  ]);
  const soln = async() =>{
      const usercollectionRef = doc(db,`Users/${info.data.applicationId
        ? info.data.applicationId
        : info.data.appliedBy
        ? info.data.appliedBy
        : info.data.applicantId}`);
   const docSnap = await getDoc(usercollectionRef)
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        // setAboutUsData(docSnap.data().about);
        setUserInfo({ applicantData: docSnap.data() });
    console.log(docSnap.data());
      setAvatar(docSnap.data().displayImage);
      setLoading(false);
      setLoadingError(false);
      } else {
        //! doc.data() will be undefined in this case
            // console.log(error);
      setLoading(false);
      setLoadingError(true);
      }
  }
  return (
    <>
      {/* {JSON.stringify(userInfo)} */}
      {/* {JSON.stringify(info)} */}
      {loading ? (
        <Skeleton variant="rect" width="100%" height={170} />
      ) : loadingError?  <Container sx={{ my: "30px" }}>
      <Box sx={{...flexCenter, flexDirection:"column",  gap:"20px", textAlign: "center" }}>
      <Box>
          <Typography
            sx={{
              color: "#B7B7B7",
              fontSize: "25px",
              fontFamily: "Poppins",
            }}
          >
           Sorry ! something went wrong.
          </Typography>
        </Box>
        <ReplayIcon onClick={()=>{
                setflag(flag+1);
              }} sx={{width:"5vw", height:"5vw",color:"#B7B7B7" , cursor:"pointer"}}  />
       
      </Box>
    </Container>:(
        <Box
        sx={{
            ...responsiveFlexDirection,
            width: "100%",
            display: "flex",
            border: "1px solid #e0e0e0",
            maxHeight: "100%",
            marginTop: "20px",
            borderRadius: "20px",
            overflow: "hidden",
            backgroundColor: { xs: "#24BD2C", md: "transparent" },
          }}
        >
          {/* avatar */}
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "60%" },
              padding: { xs: "10px", md: "20px" },
              gap: "10px",
              backgroundColor: { md: "#24BD2C" },
              color: "#FFF",
            }}
          >
            <Box
              sx={{
                width: { xs: "45px", md: "90px" },
                height: { xs: "45px", md: "90px" },
              }}
            >
              <Avatar
                sx={{ width: "100%", height: "100%" }}
                src={avatar}
                alt=""
                />
            </Box>
            {/* candidate details */}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                gap: "10px",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "18px" }}
                >
                {userInfo?.applicantData?.firstName}{" "}
                {userInfo?.applicantData?.lastName}
              </Typography>
              <Typography
                className="text_field-span"
                sx={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Stack
                  direction
                  alignItems={"center"}
                  gap={1}
                  sx={{
                    color: "#FFF",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                  }}
                >
                  <span>
                    <svg
                      width="13"
                      height="16"
                      viewBox="0 0 13 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.5 0C4.77672 0.00196598 3.1246 0.66484 1.90606 1.84321C0.687511 3.02159 0.00203992 4.61924 6.91533e-06 6.28571C-0.00205706 7.64756 0.457949 8.97245 1.30946 10.0571C1.30946 10.0571 1.48673 10.2829 1.51569 10.3154L6.5 16L11.4867 10.3126C11.5127 10.2823 11.6905 10.0571 11.6905 10.0571L11.6911 10.0554C12.5422 8.97121 13.002 7.64693 13 6.28571C12.998 4.61924 12.3125 3.02159 11.0939 1.84321C9.8754 0.66484 8.22328 0.00196598 6.5 0ZM6.5 8.57143C6.03252 8.57143 5.57553 8.43737 5.18684 8.18622C4.79814 7.93506 4.49518 7.57808 4.31629 7.16042C4.13739 6.74276 4.09058 6.28318 4.18178 5.83979C4.27298 5.39641 4.4981 4.98913 4.82866 4.66947C5.15922 4.34981 5.58038 4.13211 6.03888 4.04392C6.49738 3.95572 6.97263 4.00099 7.40452 4.17399C7.83642 4.34699 8.20557 4.63996 8.46529 5.01584C8.72501 5.39172 8.86363 5.83364 8.86363 6.28571C8.86285 6.89169 8.61358 7.47263 8.17048 7.90112C7.72738 8.32961 7.12663 8.57067 6.5 8.57143Z"
                        fill="white"
                        />
                    </svg>
                  </span>
                  {"  "} {userInfo?.applicantData?.state},
                  {userInfo?.applicantData?.city}{" "}
                  {userInfo?.applicantData?.noticePeriod ? (
                    <>
                      {" "}
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.32201 0.0169382C6.98808 0.144689 5.99595 0.426818 4.8727 0.997793C3.54212 1.67411 2.34813 2.74361 1.47753 4.03888C1.10891 4.58731 0.658457 5.51315 0.442172 6.16689C0.267594 6.69457 0.128918 7.29509 0.0441635 7.8904C-0.0147212 8.30408 -0.0147212 9.69267 0.0441635 10.1063C0.191639 11.1424 0.465648 12.0499 0.893749 12.9202C1.3781 13.9049 1.85252 14.5664 2.63576 15.3492C3.41044 16.1233 4.08271 16.6067 5.03109 17.0717C5.69283 17.3961 6.12009 17.554 6.79094 17.7221C8.55129 18.1634 10.3168 18.0788 12.0367 17.4707C12.558 17.2865 13.4681 16.8307 13.9359 16.5195C14.9878 15.8201 15.9534 14.8309 16.6425 13.7468C16.896 13.348 17.3034 12.5065 17.4696 12.0385C18.2965 9.71012 18.1525 7.21106 17.0659 5.02751C16.6273 4.1463 16.1391 3.45566 15.4536 2.74681C14.6934 1.96067 13.9478 1.41829 12.9504 0.925792C11.9079 0.411025 10.9364 0.136247 9.78269 0.0297062C9.4241 -0.00339226 8.6089 -0.0105325 8.32201 0.0169382ZM9.34076 3.8367C9.42322 3.88028 9.54053 3.98471 9.60149 4.06878L9.7123 4.22164L9.7218 6.63635L9.73134 9.05107L11.5726 10.8977C13.151 12.4807 13.4198 12.7651 13.455 12.8892C13.5178 13.1111 13.5046 13.2584 13.4037 13.4609C13.2459 13.7773 12.9147 13.931 12.5649 13.85C12.3927 13.8102 12.3267 13.7486 10.3872 11.817C9.26688 10.7011 8.3571 9.76699 8.31878 9.6932C8.25246 9.56555 8.25063 9.47741 8.25978 6.89108L8.26922 4.22076L8.37481 4.07096C8.58803 3.76846 9.0155 3.66481 9.34076 3.8367Z"
                          fill="#25C027"
                        />
                      </svg>
                      {"  "} 1 month notice period{" "}
                    </>
                  ) : null}
                </Stack>
              </Typography>
              {/* <Typography
          sx={{
            marginTop: "9px",
            marginBottom: "9px",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "14px",
          }}
        >
          Current:{" "}
          <span
            style={{
              color: "#696969",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "14px",
            }}
          >
            {" "}
            krishworks
          </span>
        </Typography>
        <Typography
          sx={{
            marginTop: "9px",
            marginBottom: "9px",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "14px",
          }}
        >
          Previous:{" "}
          <span
            style={{
              color: "#696969",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "14px",
            }}
          >
            {" "}
            Krishworks
          </span>
        </Typography> */}
              <Typography
                sx={{
                  marginTop: "9px",
                  marginBottom: "9px",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "14px",
                }}
              >
                Experience:{" "}
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                  }}
                  >
                  {userInfo?.applicantData?.experience} Years
                </span>{" "}
              </Typography>
              <Typography
                sx={{
                  marginTop: "9px",
                  marginBottom: "9px",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "14px",
                }}
                >
                Job Preference:{" "}
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                  }}
                >
                  {userInfo?.applicantData?.jobPreference}
                </span>{" "}
              </Typography>
              <Stack direction gap={1}>
                <Stack direction gap={1}>
                  {skills
                    ? skills.map((skill, index) => {
                        return (
                          <Chip
                            sx={{ color: "gray", backgroundColor: "#f0faf0" }}
                            label={skill}
                          />
                        );
                      })
                    : userInfo?.applicantData?.skills?.map((skill) => {
                        return (
                          <Chip
                            sx={{ color: "gray", backgroundColor: "#f0faf0" }}
                            label={skill}
                          />
                        );
                      })}
                </Stack>
              </Stack>
              {/* <Typography
          sx={{
            marginTop: "14px",
            marginBottom: "14px",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "14px",
          }}
        >
          Applied On:
        </Typography> */}

              {/* candidate status  */}
            </Box>
          </Box>

          {/* Actions */}
          <Box
            sx={{
              ...flexCenter,
              width: { xs: "100%", md: "40%" },
              minHeight: "100%",
              border: { md: "1px solid #25C027" },
              borderRadius: { md: "0px 20px 20px 0px" },
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              flexWrap: { xs: "wrap", md: "nowrap" },
              mt: { xs: "10px", md: "0px" },
            }}
            >
            <Stack
              direction
              alignItems={"center"}
              gap={1}
              sx={{ ...flexCenter }}
            >
              {/* applicationcardaction component show only when all applicant */}
              <MessageButton
                applicantId={
                  info.data.applicationId
                    ? info.data.applicationId
                    : info.data.appliedBy
                    ? info.data.appliedBy
                    : info.data.applicantId
                  }
              />
              {showAction ? (
                <Stack direction="row-reverse" gap={2}>
                  <StatusDrop
                    status={tag}
                    setStatus={setStatus}
                    offerStatus={offerStatus}
                    overView={{
                      appliedBy: info.data.applicationId
                        ? info.data.applicationId
                        : info.data.appliedBy
                        ? info.data.appliedBy
                        : info.data.applicantId,
                      status: status,
                    }}
                    noofAcceptReject={noofAcceptReject}
                    setNoofAcceptReject={setNoofAcceptReject}
                    jobId={info.data.jobId}
                  />
                  {/* <ApplicantCardActions
                    setStatus={setStatus}
                    selection={selections}
                    status={status}
                    ids={info.data}
                  /> */}
                </Stack>
              ) : null}
              <ApplicantCardActions
                setStatus={setStatus}
                selection={selections}
                status={status}
                ids={info.data}
              />
            </Stack>

            <Stack
              direction
              justifyContent={"center"}
              gap={2}
              sx={{ mt: "10px" }}
            >
              <StatusLogo status={tag} offerStatus={offerStatus} />
              <MethodTags status={tag} method={method} />
              <OfferStatus status={tag} offerStatus={offerStatus} />
            </Stack>
          </Box>
        </Box>
      )}
      <Modal
        open={false}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
          >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </>
  );
};

  {/* <LoadingButton  loadingIndicator={<CircularProgress sx={{  color: "white" }} />}
          loading={loading}
          onClick={()=>{
            setflag(flag+1);
          }}
          sx={{
            width:"10vw",
            height:"5vw",
            textTransform: "none",
            fontSize: "30px",
            fontFamily: "",
            backgroundColor: "#24BD2C",
            color: "#fff",
            padding: "8px 17px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#24BD2C",
            },
          }} variant="outlined">
    <ReplayIcon  />
  </LoadingButton > */}