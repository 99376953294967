import { Box, Container, Typography } from "@mui/material";
import {
  responsiveFlexDirection,
  responsiveWidth,
} from "../../../utilities/commonStyles/commonStyles";
import LetTheCompanyFindYouImage from "../../../utilities/images/let-the-company-find-you.png";

const LetTheCompanyFindYou = () => {
  return (
    <Box sx={{ my: "100px" }}>
      <Container>
        <Box
          sx={{
            ...responsiveFlexDirection,
            display: "flex",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <Box sx={{ ...responsiveWidth }}>
            <img
              style={{ width: "100%" }}
              src={LetTheCompanyFindYouImage}
              alt=""
            />
          </Box>
          <Box sx={{ ...responsiveWidth }}>
            <Typography
              sx={{ color: "#202124", fontWeight: 700, fontSize: "58px" }}
            >
              Make your <span style={{ color: "#24BD2C" }}> Job </span>search{" "}
              <span style={{ color: "#24BD2C" }}> stress-free</span>
            </Typography>
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                mt: "10px",
              }}
            >
              MFGWorx is a mobile-friendly platform built for job seekers. Not
              only can you apply to top jobs in the industry, MFGWorx has
              features to make your job search experience seamless. As a job
              seeker, you can create a profile, upload your resume (don’t have
              one? No problem! Use our simple resume builder), apply to amazing
              opportunities, accept job offers, and more!
            </Typography>
            {/* <Button
              sx={{
                textTransform: "none",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontFamily: "",
                backgroundColor: "#24BD2C",
                color: "#fff",
                padding: "14px 24px",
                borderRadius: "8px",
                mt: "20px",
                "&:hover": {
                  backgroundColor: "#24BD2C",
                },
              }}
            >
              Get Started
            </Button> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LetTheCompanyFindYou;
