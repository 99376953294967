import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsPencilTracedIcon,
} from "../../utilities/Icons/Icons";
import AddIcon from "@mui/icons-material/Add";
import {
  addEducation,
  deleteEducation,
  editEducation,
  getEducation,
} from "../../firebase/functions";
import { toast, ToastContainer } from "react-toastify";
import { YearPicker } from "@mui/x-date-pickers/YearPicker";
const EducationTimeline = () => {
  const [education, setEducation] = React.useState([]);
  const [toggle, setToggle] = React.useState(false);
  useEffect(() => {
    getEducation().then(({ data }) => {
      console.log(data.sort((a, b) => b.startYear - a.startYear));
      setEducation(data.sort((a, b) => a.endYear - b.endYear));
    }).catch((e)=>{
     console.log(e);
    })
  }, [toggle]);
  const [add, setAdd] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [loadButton, setLoadButton] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    setLoadButton(true);
    if (edit) {
      data.id = edit;
      editEducation({
        educationId: edit,
        educationData: {
          ...data,
        },
      }).then(() => {
        toast.success("Education Updated Successfully");
        setLoadButton(false);
        setAdd(false);
        setEdit(null);
        setToggle(!toggle);
        reset();
      });
    } else {
      console.log(data);
      addEducation({ education: { ...data } }).then(({}) => {
        setAdd(false);
        setLoadButton(false);
        setToggle(!toggle);
        toast.success("Education Added Successfully");
      });
    }
  };
  const handleDelete = (id) => {
    deleteEducation({ educationId: id }).then(() => {
      toast.success("Education Deleted Successfully");
      setToggle(!toggle);
    });
  };
  return (
    <Box>
      <Stack direction alignItems={"center"}>
        <Typography
          sx={{
            color: "#202124",
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Sofia Pro",
          }}
        >
          Education
        </Typography>
        <LoadingButton
          loading={loadButton}
          size="small"
          onClick={() => {
            setEdit(null);
            reset({});
            return setAdd(!add);
          }}
          startIcon={<AddIcon />}
        >
          Add
        </LoadingButton>
      </Stack>
      <Timeline className={"education-timeline"}>
        {/* //!edit timeline item */}
        {add ? (
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <TimelineItem>
              <TimelineSeparator className={"education-separator_padding"}>
                <TimelineDot className={"education-timeline_dot"}>
                  <Typography
                    sx={{
                      fontFamily: "Sofia Pro",
                      fontWight: 700,
                      fontSize: "11px",
                      color: "#D93025",
                    }}
                  >
                    E
                  </Typography>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Stack>
                    <TextField
                      {...register("degree", {
                        required: {
                          value: true,
                          message: "mandatory field",
                        },
                      })}
                      variant="standard"
                      placeholder="Degree"
                      error={!!errors?.degree ? true : false}
                      helperText={errors?.degree?.message}
                      sx={{
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    ></TextField>
                    <TextField
                      variant="standard"
                      placeholder="Institution"
                      {...register("institution", {
                        required: "Mandatory field",
                      })}
                      sx={{
                        color: "#D93025",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    ></TextField>
                  </Stack>
                  <Box>
                    <Typography
                      sx={{
                        display: "inline-block",
                        background: "#f9e0df",
                        color: "#D93025",
                        padding: "5px 20px",
                        borderRadius: "20px",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {/* <YearPicker />
                      <YearPicker /> */}
                      <Stack direction>
                        <TextField
                          {...register("startYear", {
                            required: "Mandatory field",
                            maxLength: {
                              value: 4,
                              message: "maximum 4 digits",
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "only numbers allowed",
                            },
                          })}
                          placeholder="2000"
                          sx={{ width: "45px" }}
                          variant="standard"
                        ></TextField>
                        <Typography>-</Typography>
                        <TextField
                          {...register("endYear", {
                            required: "Mandatory field",
                            maxLength: {
                              value: 4,
                              message: "maximum 4 digits",
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "only numbers allowed",
                            },
                          })}
                          placeholder="2000"
                          sx={{ width: "45px" }}
                          variant="standard"
                        ></TextField>
                      </Stack>
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#F0FAF0",
                      }}
                    >
                      <EmployeeDashboardManageJobsPencilTracedIcon />
                    </Box>
                    <Box
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#F0FAF0",
                      }}
                    >
                      <EmployeeDashboardManageJobsDeleteTracedIcon />
                    </Box>
                  </Box> */}
                </Box>
                <Box sx={{ py: "20px" }}>
                  <Typography>
                    <TextField
                      {...register("description")}
                      placeholder="Description"
                      multiline
                      rows={4}
                      fullWidth
                      variant="standard"
                    ></TextField>
                  </Typography>
                  <LoadingButton type="submit">Submit</LoadingButton>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Box>
        ) : null}
        {/*//! endItem */}
        {education
          ?.sort((a, b) => {
            return a.data.endYear - b.data.endYear;
          })
          .map((item, index) => {
            return (
              <>
                {edit === item.educationId ? (
                  <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <TimelineItem>
                      <TimelineSeparator
                        className={"education-separator_padding"}
                      >
                        <TimelineDot className={"education-timeline_dot"}>
                          <Typography
                            sx={{
                              fontFamily: "Sofia Pro",
                              fontWight: 700,
                              fontSize: "11px",
                              color: "#D93025",
                            }}
                          >
                            E
                          </Typography>
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ display: "flex", gap: "20px" }}>
                          <Stack>
                            <TextField
                              {...register("degree", {
                                required: {
                                  value: true,
                                  message: "mandatory field",
                                },
                              })}
                              variant="standard"
                              placeholder="Degree"
                              error={!!errors?.degree ? true : false}
                              helperText={errors?.degree?.message}
                              sx={{
                                color: "#202124",
                                fontFamily: "Sofia Pro",
                                fontWeight: 500,
                                fontSize: "15px",
                              }}
                            ></TextField>
                            <TextField
                              variant="standard"
                              placeholder="Institution"
                              {...register("institution", {
                                required: "Mandatory field",
                              })}
                              sx={{
                                color: "#D93025",
                                fontFamily: "Sofia Pro",
                                fontWeight: 500,
                                fontSize: "15px",
                              }}
                            ></TextField>
                          </Stack>
                          <Box>
                            <Typography
                              sx={{
                                display: "inline-block",
                                background: "#f9e0df",
                                color: "#D93025",
                                padding: "5px 20px",
                                borderRadius: "20px",
                                fontFamily: "Sofia Pro",
                                fontWeight: 500,
                                fontSize: "15px",
                              }}
                            >
                              <Box></Box>
                              <TextField
                                {...register("startYear", {
                                  required: "Mandatory field",
                                })}
                                placeholder="2000-2022"
                                sx={{ width: "40px" }}
                                variant="standard"
                              ></TextField>
                              -
                              <TextField
                                {...register("endYear", {
                                  required: "Mandatory field",
                                })}
                                placeholder="2000-2022"
                                sx={{ width: "40px" }}
                                variant="standard"
                              ></TextField>
                            </Typography>
                          </Box>
                          {/* <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#F0FAF0",
                            }}
                          >
                            <EmployeeDashboardManageJobsPencilTracedIcon />
                          </Box>
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#F0FAF0",
                            }}
                          >
                            <EmployeeDashboardManageJobsDeleteTracedIcon />
                          </Box>
                        </Box> */}
                        </Box>
                        <Box sx={{ py: "20px" }}>
                          <Typography>
                            <TextField
                              {...register("description")}
                              placeholder="Description"
                              multiline
                              rows={4}
                              fullWidth
                              variant="standard"
                            ></TextField>
                          </Typography>
                          <Stack direction>
                            <Button type="submit">Submit</Button>
                            <Button
                              onClick={() => setEdit(null)}
                              color="error"
                              type="submit"
                            >
                              Cancel
                            </Button>
                          </Stack>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  </Box>
                ) : (
                  <TimelineItem key={item.id}>
                    <TimelineSeparator
                      className={"education-separator_padding"}
                    >
                      <TimelineDot className={"education-timeline_dot"}>
                        <Typography
                          sx={{
                            fontFamily: "Sofia Pro",
                            fontWight: 700,
                            fontSize: "11px",
                            color: "#D93025",
                          }}
                        >
                          {item?.data?.degree?.substring(0, 1)}
                        </Typography>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box sx={{ display: "flex", gap: "20px" }}>
                        <Box>
                          <Typography
                            sx={{
                              color: "#202124",
                              fontFamily: "Sofia Pro",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            {item.data.degree}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#D93025",
                              fontFamily: "Sofia Pro",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            {item.data.institution}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              display: "inline-block",
                              background: "#f9e0df",
                              color: "#D93025",
                              padding: "5px 20px",
                              borderRadius: "20px",
                              fontFamily: "Sofia Pro",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            {item.data.startYear}
                          </Typography>
                          -
                          <Typography
                            sx={{
                              display: "inline-block",
                              background: "#f9e0df",
                              color: "#D93025",
                              padding: "5px 20px",
                              borderRadius: "20px",
                              fontFamily: "Sofia Pro",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            {item.data.endYear}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#F0FAF0",
                            }}
                          >
                            <Tooltip placement="top" arrow title="Edit">
                              <IconButton
                                onClick={() => {
                                  reset({
                                    degree: item.data.degree,
                                    institution: item.data.institution,
                                    timePeriod: item.data.timePeriod,
                                    description: item.data.description,
                                  });
                                  return edit
                                    ? setEdit(null)
                                    : setEdit(item.educationId);
                                }}
                              >
                                <EmployeeDashboardManageJobsPencilTracedIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Tooltip placement="top" arrow title="Delete">
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#F0FAF0",
                              }}
                            >
                              {" "}
                              <IconButton
                                onClick={() => handleDelete(item.educationId)}
                              >
                                <EmployeeDashboardManageJobsDeleteTracedIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box sx={{ py: "50px" }}>
                        <Typography>{item.data.description}</Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                )}
              </>
            );
          })}
      </Timeline>
    </Box>
  );
};

export default EducationTimeline;
