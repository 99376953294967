import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  addFormField,
  deleteFormField,
  getFormField
} from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  itemsCenter
} from "../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./SuperAdminDashboardCompanyList.module.css";
const SuperAdminDashboardFormField = () => {
  const [userFieldDataFromFirebase, setUserFormFieldFromFirebase] = useState(
    []
  );
  const [userValue, setUserValue] = useState("");
  const [userField, setUserField] = useState("Skills");
  const [userFieldValue, setUserFieldValue] = useState("skills");
  const [userFormField, setUserFormField] = useState("skills");
  const [loading, setloading] = useState(false);
  const [Loadingbtn, setloadingbtn] = useState(false);
  const [flag, setflag] = useState(0);
  const getFormFieldData = (label, value) => {
    setUserField(label);
    setUserFieldValue(value);
    getFormFieldFromFirebase(value);
  };
  useEffect(() => {
    getFormFieldFromFirebase(userFieldValue);
  }, [flag]);

  const getFormFieldFromFirebase = (value) => {
    setloading(true);
    getFormField({ form: value })
      .then((response) => {
        console.log(response);
        setUserFormFieldFromFirebase(response.data);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const addFormFieldFromFirebase = () => {
    if (userValue == "") {
      return;
    }
    setloadingbtn(true);
    addFormField({ form: userFieldValue, field: userValue })
      .then((response) => {
        console.log(response);
        toast.success("Field Added");
        setUserValue("");
        setloadingbtn(false);
        setflag(flag + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const deleteFormFieldFromFirebase = (i) => {
    deleteFormField({
      form: userFieldValue,
      field: userFieldDataFromFirebase[i],
    })
      .then((response) => {
        console.log(response);
        toast.success("Field deleted");
        setflag(flag + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  var [formField, setformField] = useState([
    {
      value: "skills",
      label: "Skills",
    },
    {
      value: "salaryRange",
      label: "Salary Range",
    },
    {
      value: "experience",
      label: "Experience",
    },
    {
      value: "primaryIndustry",
      label: "Primary Industries",
    },
    {
      value: "categories",
      label: "Categories",
    },
    {
      value: "headCount",
      label: "Head Count",
    },
    {
      value: "jobTypes",
      label: "Job Types",
    },
    {
      value: "career",
      label: "Career",
    },
    {
      value: "education",
      label: "Education",
    },
    {
      value: "shift",
      label: "Shift",
    },
  ]);
  return (
    <Box
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Container sx={{ ...displayOnDesktop }}>
        <Box sx={{ mt: "60px" }}>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Form Field
            </Typography>
          </Box>
          <Box>
            <Box
              className={classes.input}
              sx={{ width: "100%", borderRight: "1px solid #ECEDF2" }}
            >
              <Select
                value={formField.filter(function (option) {
                  return option.value === userFormField;
                })}
                options={formField}
                onChange={(event) => {
                  setUserFormField(event.value);
                  getFormFieldData(event.label, event.value);
                }}
              ></Select>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "30px",
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "20px",
              mt: "60px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                {userField}
              </Typography>
            </Box>

            <Box
              className={classes.hidescrollbar}
              sx={{
                height: "40vh",
                overflowY: "scroll",
                overflowX: "hidden",
                my: "40px",
              }}
            >
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                    <TableRow
                      sx={{
                        "&:first-child td, &:first-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                          miWidth: "150px",
                        }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        {userField}
                      </TableCell>

                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ width: "100%" }}>
                    {userFieldDataFromFirebase &&
                      userFieldDataFromFirebase.map((arr, index) => (
                        <TableRow
                          key={arr}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "50px",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ minWidth: "150px" }}
                          >
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ maxWidth: "100px" }}>
                            {arr}
                          </TableCell>

                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {" "}
                              <Box
                                sx={{
                                  width: "20px",
                                  height: "30px",
                                  borderRadius: "8px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // background: "#EAF9EA",
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "8px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "8px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              ></Box>
                              <Box>
                                <Button
                                  sx={{
                                    textTransform: "none",
                                    fontSize: "15px",
                                    fontFamily: "",
                                    backgroundColor: "#25C027",
                                    color: "#fff",
                                    padding: "0px",
                                    borderRadius: "8px",
                                    "&:hover": {
                                      backgroundColor: "#25C027",
                                    },
                                  }}
                                  onClick={() =>
                                    deleteFormFieldFromFirebase(index)
                                  }
                                >
                                  Delete
                                </Button>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}

                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        padding: "50px",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ minWidth: "150px" }}
                      >
                        <Typography>
                          {userFieldDataFromFirebase.length + 1}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" sx={{ maxWidth: "100px" }}>
                        <TextField
                          id="outlined-basic"
                          label="Enter new skills"
                          value={userValue}
                          //   variant="outlined"
                          color="success"
                          sx={{
                            width: "100%",
                            mt: "10px",
                            color: "#696969",
                            fontFamily: "Sofia Pro",
                            fontSize: "15px",
                            border: "none",
                          }}
                          type="text"
                          onChange={(e) => {
                            setUserValue(e.target.value);
                          }}
                        />
                      </TableCell>

                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {" "}
                          <Box
                            sx={{
                              width: "20px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#EAF9EA",
                            }}
                          >
                            <LoadingButton
                              sx={{
                                textTransform: "none",
                                fontSize: "15px",
                                fontFamily: "",
                                backgroundColor: "#25C027",
                                color: "#fff",
                                padding: "0px",
                                borderRadius: "8px",
                                "&:hover": {
                                  backgroundColor: "#25C027",
                                },
                              }}
                              onClick={() => {
                                addFormFieldFromFirebase();
                              }}
                              type="submit"
                              loading={Loadingbtn}
                            >
                              Add
                            </LoadingButton>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Container>

      <Container sx={displayOnMobile}>
        <Box sx={{ mt: "20px" }}>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Form Field
            </Typography>
          </Box>
          <Box>
            <Box
              className={classes.input}
              sx={{ width: "100%", borderRight: "1px solid #ECEDF2" }}
            >
              <Select
                value={formField.filter(function (option) {
                  return option.value === userFormField;
                })}
                options={formField}
                onChange={(event) => {
                  setUserFormField(event.value);
                  getFormFieldData(event.label, event.value);
                }}
              ></Select>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "30px",
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "20px",
              mt: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                {userField}
              </Typography>
            </Box>

            <Stack spacing={3} sx={{ mt: "20px" }}>
              {userFieldDataFromFirebase &&
                userFieldDataFromFirebase.map((arr, index) => (
                  <Stack
                    key={index}
                    spacing={1}
                    sx={{
                      width: "100%",
                      boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
                      padding: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    <Box sx={{ ...itemsCenter, gap: "10px" }}>
                      <Typography
                        sx={{
                          width: "30%",
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#25C027",
                          fontWeight: 600,
                        }}
                      >
                        #No
                      </Typography>
                      <Typography
                        sx={{
                          width: "65%",
                          fontSize: "12px",
                          color: "#425466",
                          fontWeight: 600,
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </Box>
                    <Box sx={{ ...itemsCenter, gap: "10px" }}>
                      <Typography
                        sx={{
                          width: "30%",
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#25C027",
                          fontWeight: 600,
                        }}
                      >
                        Skills
                      </Typography>
                      <Typography
                        sx={{
                          width: "70%",
                          fontSize: "12px",
                          color: "#425466",
                          fontWeight: 600,
                        }}
                      >
                        {arr}
                      </Typography>
                    </Box>
                    <Box sx={{ ...itemsCenter, gap: "10px" }}>
                      <Typography
                        sx={{
                          width: "30%",
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#25C027",
                          fontWeight: 600,
                        }}
                      >
                        Actions
                      </Typography>

                      <Box>
                        <Button
                          sx={{
                            textTransform: "none",
                            fontSize: "15px",
                            fontFamily: "",
                            backgroundColor: "#25C027",
                            color: "#fff",
                            padding: "0px",
                            borderRadius: "8px",
                            "&:hover": {
                              backgroundColor: "#25C027",
                            },
                          }}
                          onClick={() => deleteFormFieldFromFirebase(index)}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  </Stack>
                ))}
            </Stack>
          </Box>
        </Box>
      </Container>

      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default SuperAdminDashboardFormField;
{
  /* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                mt: "30px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Occupation
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Date Added
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Flagged
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Featured
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
            </Box> */
}
