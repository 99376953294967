import { Avatar, Box, Button, Container, Typography,Modal,Stack } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetType } from "../../../context/authContext";
import { auth, logOut } from "../../../firebase/firebaseConfig";
import { itemsCenter } from "../../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../LoadingSearchComponents/LoadingSearchComponents";
import LoginComponent from "../NavBar/LoginComponent";
import classes from "./MobileMenuSidebar.module.css";

const MobileMenuSidebar = ({ color }) => {
  const userType = GetType();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [loading , setloading] = useState(false);
  const [user, setUser] = useState(true);
  const [company, setCompany] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUserData(auth.currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = () => {
    setloading(true);
    logOut().then(() => {
      navigate("/");
      setloading(false);
    });
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "sticky",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
      }}
    >
      <Container
        sx={{ ...itemsCenter, backgroundColor: "#25C027", py: "20px" }}
      >
        <Box>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
              fill="white"
            />
          </svg>
        </Box>
        <Typography
          sx={{
            width: "90%",
            textAlign: "center",
            color: "#FFF",
            fontSize: "20px",
          }}
        >
          Menu
        </Typography>
      </Container>
      <Box
        className={classes.sidebar}
        sx={{ boxShadow: 1, minHeight: "100%", padding: "20px" }}
      >
        <Box className={classes.link}>
          <Link to="/">Home</Link>
          {userData?.email ? (
            <Link
              to={
                userType.role == "user"
                  ? "/candidate-dashboard/dashboard"
                  : "/employee-dashboard/dashboard"
              }
            >
              Dashboard
            </Link>
          ) : null}
          {userType.role == "Recruiter" && !company && (
            <Link to="/candidate-list">Candidate List</Link>
          )}
          {!user && company && <Link to="/company-list">Company List</Link>}
          {userType.role == "Recruiter" ? null : (
            <Link to="/job-search">Find Job</Link>
          )}
          {<Link to="/about-us">About Us</Link>}
          <Link to="/policy">Policy</Link>
          <Link to="/terms">Terms</Link>
          <Link to="/faq">Faq</Link>
          <Link to="/contact-us">Contact Us</Link>
          {auth.currentUser ? (
            <Button
              sx={{ color: "#25c027", border: "1px solid #25C027", mb: "10px" }}
              onClick={() => handleLogout()}
            >
              Logout
            </Button>
          ) : null}
        </Box>
        {!userData?.email ? <LoginComponent /> : null}

        {userData?.email ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#C4C4C4",
                border: "2px solid #FFFCFC",
              }}
            >
              <Avatar src={userType.displayImage} />
            </Box>
            <Box sx={{ color: "#25c027" }}>
              <Typography>{userType.displayName}</Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default MobileMenuSidebar;
