import { Box, Container } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../firebase/firebaseConfig";
import { ColorLogo } from "../../../utilities/images/svg images/SvgImages";
import LoginComponent from "./LoginComponent";
import classes from "./NavBar.module.css";
import NavBarLoggedInComponent from "./NavBarLoggedInComponent";

const ColorNavBar = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      
      setUserData(auth.currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Container>
      <Box
        sx={{
          py: "30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <ColorLogo />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "50px" }}>
          <Box
            className={classes.colorNavLink}
            sx={{ display: "flex", alignItems: "center", gap: "25px" }}
          >
            <Link to="/">Home</Link>
            <Link to="/">Find Job</Link>
            <Link to="/">Browse All Job</Link>
            <Link to="/">About Us</Link>
            <Link to="/">Contact</Link>
          </Box>
          {userData?.email ? <NavBarLoggedInComponent /> : <LoginComponent />}
        </Box>
      </Box>
    </Container>
  );
};

export default ColorNavBar;
