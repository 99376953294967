import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  getApplicantAwards,
  getApplicantInfo,
  getAwardsApplicant,
} from "../../firebase/functions";

import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsPencilTracedIcon,
} from "../../utilities/Icons/Icons";

const CandidateProfileAwards = ({ applicantId }) => {
  const [awards, setAwards] = React.useState([]);
  useEffect(() => {
    getAwardsApplicant({ applicantId }).then(({ data }) => {
      setAwards(data);
    });
  }, [applicantId]);
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            color: "#202124",
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Sofia Pro",
          }}
        >
          Awards
        </Typography>
      </Box>
      <Timeline className={"awards-timeline"}>
        {awards.map((award, index) => {
          return (
            <TimelineItem>
              <TimelineSeparator className={"awards-separator_padding"}>
                <TimelineDot className={"awards-timeline_dot"}></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {award.awardTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        display: "inline-block",
                        background: "#FFF7E6",
                        color: "#F9AB00",
                        padding: "5px 20px",
                        borderRadius: "20px",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {award.timePeriod}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ py: "50px" }}>
                  <Typography>{award.description}</Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default CandidateProfileAwards;
