import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFeaturedJobs, getLogo } from "../../../firebase/functions";
import {
  FeturedJobsClockIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
  FeturedJobsMoneyIcon
} from "../../../utilities/Icons/Icons";
import FordLogo from "../../../utilities/images/ford.png";
import JobCard from "./JobCard";

const FeaturedJobs = () => {
  const navigate = useNavigate()
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [logo, setLogo] = useState("");
  useEffect(() => {
    getFeaturedJobs().then((res) => {
      
      setFeaturedJobs(res.data);
    });
  }, []);
  return (
    <Box sx={{ width: "75%" }}>
      <Box>
        <Typography
          sx={{
            color: "#202124",
            fontFamily: "Sofia Pro",
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Featured Jobs
        </Typography>
      </Box>
      <Stack spacing={2}>
        {featuredJobs.map((job) => <JobCard data={job.data} id={job.id} />)}
      </Stack>
    </Box>
  );
};
export default FeaturedJobs;
