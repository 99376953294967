import { Box, Button, Stack, Typography,Modal } from "@mui/material";
import { LoadingButton, loadingButtonClasses } from "@mui/lab";
import React, { useEffect ,useState } from "react";
import { toast } from "react-toastify";
import {
  checkBasePlan,
  createPortalLink,
  getProductDetails,
  getTotalSeatSlots
} from "../../firebase/functions";
import {
  flexBetweenWithItemsCenter,
  flexCenter
} from "../../utilities/commonStyles/commonStyles";
import ListAllplans from "./ListAllplans";
import ShowPlans from "./ShowPlans";
import SuperAdminPlansPopup from "../SuperAdminDashboardSideNavbar/SuperAdminPlansPopup";
import SuperAdminPlansPopupTable from "../SuperAdminDashboardSideNavbar/SuperAdminPlansPopupTable";
import SuperAdminDashboardNoPlans from "../SuperAdminDashboardSideNavbar/SuperAdminDashboardNoPlans";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
function EmployeeDashboardPaymentInvoices() {
  const [currentPlan, setCurrentPlan] = React.useState({});
  const [totalSeats, setTotalSeats] = React.useState({});
  const [loadingAbout , setLoadingAbout] = useState(false);
  const [plan, setPlan] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoadingAbout(true);
    checkBasePlan()
      .then(({ data }) => {
        setLoadingAbout(false);
        console.log("PLLLLLLLLLAS", data);
        if (data.product) {
          setCurrentPlan(data);
          getProductDetails({ productId: data.product.id }).then(({ data }) => {
            setPlan(data);
          });
          setLoadingAbout(false);
        } else {
          setCurrentPlan({});
          setLoadingAbout(false);
        }
        setLoadingAbout(false);
      })
      .catch((e) => {
        console.log("PLLLLLLLLLAS", e);
        setLoading(false);
         // toast.error(e.message);
      });
    getTotalSeatSlots().then(({ data }) => {
      console.log("USERDATA", data);
      setTotalSeats(data);
    }).catch((e)=>{
      console.log(e);
    })
  }, []);
  const handlePortalLink = () => {
    setLoading(true);
    createPortalLink()
      .then(({ data }) => {
        setLoading(false);
        console.log(data);
        if(data=="user is not an organisation admin"){
          toast.error("You are not organisation admin");
        }else{
          window.open(data, "_blank");
        }
      })
      .catch((e) => {
        toast.error(e.message);
        setLoadingAbout(false);
      });
  };
  return (
    <Box sx={{ padding: { xs: "40px 20px", md: "40px" } }}>
      {/* <Box border={1}>{JSON.stringify(currentPlan)}</Box> */}
      {/* <Box border={1}>{JSON.stringify(totalSeats)}</Box> */}
      <Box>
        <p style={{ fontWeight: "700", fontFamily: "Poppins" }}>
          Current plan
        </p>

        <Box sx={{ mt: "20px" }}>
          {currentPlan?.product ? (
            <Stack
              sx={{
                width: "350px",
                background: "#FFFFFF",
                boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
                borderRadius: "20px",
                padding: "20px",
              }}
            >
              <Box sx={{ ...flexCenter, mb: "20px" }}>
                <Box
                  sx={{
                    ...flexCenter,
                    width: "64px",
                    height: "64px",
                    background: "rgba(37, 192, 39, 0.75)",
                    borderRadius: "14px",
                    fontSize: "40px",
                    color: "#FFF",
                  }}
                >
                  {currentPlan?.product?.displayName[0]}
                </Box>
              </Box>
              <p
                style={{
                  color: "#1A202C",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  fontFamily:"Poppins"
                }}
              >
                {currentPlan?.product?.displayName}
              </p>
              <Typography
                sx={{
                  color: "#425466",
                  fontSize: "14px",
                  textAlign: "center",
                  mt: "10px",
                }}
              >
                {new Date(
                  currentPlan?.subscription?.current_period_start * 1000
                ).toLocaleDateString()}{" "}
                to{" "}
                {new Date(
                  currentPlan?.subscription?.current_period_end * 1000
                ).toLocaleDateString()}
              </Typography>

              <Box sx={{ ...flexCenter, mt: "20px" }}>
                <hr style={{ width: "50%", color: "#E4ECF7" }} />
              </Box>

              <Box
                sx={{
                  ...flexCenter,
                  gap: "20px",
                  mt: "20px",
                  textAlign: "center",
                }}
              >
                {/* <Box>
                  <Typography>Total Seats</Typography>
                  <Typography>{totalSeats?.totalSeats}</Typography>
                </Box> */}
                <Box>
                  <Typography>Total Job slots</Typography>
                  <Typography>{totalSeats?.totalSlots}</Typography>
                </Box>
              </Box>
              <Stack spacing={3} sx={{ mt: "30px" }}>
                <Box sx={{ ...flexBetweenWithItemsCenter }}>
                  <Typography>Auto Renewal</Typography>
                  <Box>
                    {!currentPlan?.subscription?.current_period_end ? (
                      "false"
                    ) : (
                      <svg
                        width="24"
                        height="19"
                        viewBox="0 0 24 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.6489 5.27557L9.42051 10.5511L6.78497 7.8858L4.14942 5.22053L2.07471 7.29524L0 9.36995L4.71086 14.126L9.42171 18.8821L14.6121 13.6461C17.4668 10.7663 20.747 7.4593 21.9013 6.29712L24 4.18411L21.9385 2.09205L19.8772 0L14.6489 5.27557Z"
                          fill="#25C027"
                        />
                      </svg>
                    )}
                  </Box>
                </Box>
                <Box sx={{ ...flexBetweenWithItemsCenter }}>
                  <Typography>Featured Employer</Typography>
                  <Box>
                    {totalSeats?.featured ? (
                      <svg
                        width="24"
                        height="19"
                        viewBox="0 0 24 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.6489 5.27557L9.42051 10.5511L6.78497 7.8858L4.14942 5.22053L2.07471 7.29524L0 9.36995L4.71086 14.126L9.42171 18.8821L14.6121 13.6461C17.4668 10.7663 20.747 7.4593 21.9013 6.29712L24 4.18411L21.9385 2.09205L19.8772 0L14.6489 5.27557Z"
                          fill="#25C027"
                        />
                      </svg>
                    ) : (
                      "false"
                    )}
                  </Box>
                </Box>
              </Stack>
              {/* <Typography>
                Active:
                {totalSeats?.active ? "true" : "false"}
              </Typography> */}

              <Box sx={{ ...flexCenter, mt: "20px" }}>
                {/* <Button
                
                >
                  Upgrade
                </Button> */}
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#25C027",
                    color: "#FFF",
                    padding: "10px 20px",
                    borderRadius: "6px",
                    fontSize: "12px",
                    fontWeight: 600,
                    '&:hover': {
                      background: "#25C027",
                   },
                  }}
            loading={loading}
            onClick={() => {
              return handlePortalLink();
            }}
          >
            GO TO CUSTOMER PORTAL
          </LoadingButton>
              </Box>
            </Stack>
          ) : (
            <Typography variant="h3">No Plan please buy a plan</Typography>
            // <SuperAdminDashboardNoPlans></SuperAdminDashboardNoPlans>
          )}
        </Box>
      </Box>
      {/* <Stack border={1}>{JSON.stringify(plan)}</Stack> */}
      {/* {JSON.stringify(currentPlan)} */}
      {currentPlan?.product ? null : (
        <>
          <SuperAdminPlansPopup/>
        </>
      )}
      {/* <div>check if the user has a base plan</div>
      <div>if yes show current plan details</div>
      <div>if no show the plan details</div>
      <div>show button to user portal</div>
      <div>show usage under current plan</div> */}

{/* <LoadingButton
  loading={loading}
  onClick={() => {
    return handlePortalLink();
  }}
>
  GO TO CUSTOMER PORTAL
</LoadingButton> */}
      {/* {currentPlan.product ? (
        <div>
          <ShowPlans />
        </div>
      ) : null} */}
          <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Box>
  );
}

export default EmployeeDashboardPaymentInvoices;
