import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { editUserProfile, myProfile } from "../../firebase/functions";
import CandidateProfileUpdatingComponent from "./CandidateProfileUpdatingComponent";
import CandidateProfileUpdatingContactInformation from "./CandidateProfileUpdatingContactInformation";
import CandidateProfileUpdatingSocialNetwork from "./CandidateProfileUpdatingSocialNetwork";

const CandidateProfileUpadtingPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  useEffect(() => {
    myProfile().then(({ data }) => {
      reset({
        ...data,
      });
      setUser(data);
    });
  }, []);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    editUserProfile({
      ...data,
    }).then(() => {
      setLoading(false);
     // navigate("/candidate-dashboard/my-resume");
      navigate("/candidate-resume-updating");
    });
  };

  return (
    <Box component={"form"} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "40px 0px 100px 0px",
        }}
      >
        <Box sx={{ width: "80%" }}>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              My Profile
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              my: "30px",
            }}
          >
            <CandidateProfileUpdatingComponent
              user={user}
              setValue={setValue}
              register={register}
              errors={errors}
              reset={reset}
            />
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <CandidateProfileUpdatingContactInformation
              setValue={setValue}
              getValues={getValues}
              register={register}
              errors={errors}
            />
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <CandidateProfileUpdatingSocialNetwork
              register={register}
              errors={errors}
            />
          </Box>
          <LoadingButton
            loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
            loading={loading}
            type="submit"
            fullWidth
            sx={{
              // width: "150px",
              textTransform: "none",
              fontSize: "18px",
              fontFamily: "Sofia Pro",
              backgroundColor: "#25C027",
              color: "#fff",
              padding: "10px 40px",
              borderRadius: "8px",
              mt: "30px",
              "&:hover": {
                backgroundColor: "#25C027",
              },
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Container>
    </Box>
  );
};

export default CandidateProfileUpadtingPage;
