import { async } from "@firebase/util";
import {
  Box,
  Container,
  MenuItem,
  Select,
  Stack,
  Typography,Modal
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import {
  featuredJob,
  getAllJobsAppliedId,
  getJobIds,
  suggestedJobs,
} from "../../firebase/functions";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsDownArrowIcon,
  EmployeeDashboardManageJobsSeeTracedIcon,
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import ApplicationCardCandidate from "./ApplicationCardCandidate";
import classes from "./CandidateDashboardAppliedJobs.module.css";

const CandidateDashboardAppliedJobs = () => {
  const fakeArr = [1, 2, 3, 4, 5];
const [loading , setloading] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [filter, setFilter] = useState(10);
  useEffect(() => {
    // getAllJobsAppliedId().then(({ data }) => {
    //
    //   setAppliedJobs(data);
    // });
setloading(true);
    if (filter == 10) {
     allJobs();
      // getJobIds().then(({ data }) => {
      //   setAppliedJobs(data);
      //   setloading(false);
      // }).catch((e)=>{
      //   setloading(false);
      //   toast.error(e.message);
      // })
    } else if (filter == 20) {
      suggestedJobs().then(({ data }) => {
        console.log(data);
        setAppliedJobs(data);
        setloading(false);
      }).catch((e)=>{
        setloading(false);
        toast.error(e.message);
      })
    } else if (filter == 30) {
      featuredJob()
        .then(({ data }) => {
          console.log("FEAAATURED", data);
          setAppliedJobs(data);
          setloading(false);
        })
        .catch((e) => {
            setloading(false);
            toast.error(e.message);
        });
    }
  }, [filter]);
  const allJobs = async () => {
    const employerPostedJob =  query(collection(db, `Jobs`),orderBy("jobAddedAt", "desc"));
    await getDocs(employerPostedJob)
      .then((docSnap) => {
        setAppliedJobs(docSnap.docs.map((doc) => doc.id));
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
        toast.error(e.message);
      });
  };
  // const suggestedJob = async () => {
  //   const employerPostedJob = query(collection(db, `Organisations`),where("isFeatured", "==", true)
  //     ,limit(10));
  //   await getDocs(employerPostedJob)
  //     .then(async(docSnap) => {
  //       const data = docSnap.docs.map((doc) => doc.id);
  //   const suggested = query(collection(db, `Jobs`),where("organisationId", "in", data)
  //     ,limit(20));
  //   await getDocs(suggested)
  //     .then((docSnap1) => {
  //       setAppliedJobs(docSnap1.docs.map((doc) => doc.id)); 
  //       setloading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setloading(false);
  //       toast.error(e.message);
  //     })
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setloading(false);
  //       toast.error(e.message);
  //     });
  // };
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Stack>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Job Alerts
        </Typography>
      </Stack>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "30px",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              My Job Alerts
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter}
              label="Show"
              onChange={(e) => setFilter(e.target.value)}
            >
              <MenuItem value={10}>All Jobs </MenuItem>
              <MenuItem value={20}>Suggested Jobs</MenuItem>
              <MenuItem value={30}>Featured Jobs</MenuItem>
            </Select>
          </Stack>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#F0FAF0",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography>
            <EmployeeDashboardManageJobsDownArrowIcon />
          </Box> */}
        </Box>

        <Box sx={{ my: "0px" }}>
          {/* <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                <TableRow
                  sx={{ "&:first-child td, &:first-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Criteria
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Created
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ width: "100%" }}>
                {fakeArr.map((arr) => (
                  <TableRow
                    key={arr}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      minHeight: "50px",
                      padding: "50px",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ maxWidth: "25%" }}
                    >
                      <Typography>
                        Senior Mechanical Engineer, Machinary
                      </Typography>
                      <Typography>Newyork</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: "25%" }}>
                      Human Resources, Junior
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: "25%" }}>
                      <Typography>Nov 12, 20212</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: "25%",
                        minHeight: "100px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#F0FAF0",
                        }}
                      >
                        <EmployeeDashboardManageJobsSeeTracedIcon />
                      </Box>
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#F0FAF0",
                        }}
                      >
                        <EmployeeDashboardManageJobsDeleteTracedIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
          <Stack
            className={classes.hidescrollbar}
            spacing={2}
            sx={{ height: "60vh", overflowY: "scroll", mt: "25px" }}
          >
            {appliedJobs?.map((item, index) => (
              <ApplicationCardCandidate data={item} avoidTag={true} />
            ))}
          </Stack>
          <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
        </Box>
      </Box>
    </Container>
  );
};

export default CandidateDashboardAppliedJobs;
