import { Box, Container, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Footer from "../../components/SharedComponents/Footer/Footer";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import { getAboutUs } from "../../firebase/functions";
//import CandidateCard from "./CandidateCard";
//import OverView from "./OverView";
import styled from "@emotion/styled";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import MobileMenu from "../../components/SharedComponents/MobileMenu/MobileMenu";
import {
  displayOnDesktop,
  displayOnMobile,
  flexBetweenWithItemsCenter,
  flexCenter
} from "../../utilities/commonStyles/commonStyles";
import findLeft from "../../utilities/images/findLeft.png";
import findRight from "../../utilities/images/findRight.png";
import ErrorPage from "../JobSearchPage/ErrorPage";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";

const StyledFilter = styled("select")({
  // width: "150px",
  height: "40px",
  fontSize: "14px",
  color: "white",
  backgroundColor: "#24BD2C",
  margin: "20px",
  padding: "0px 20px 0px 20px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "none",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});

const PolicyComponent = () => {
  const [candidates, setCandidates] = useState([]);
  const [overView, setOverView] = useState({});
  const [jobTitles, setJobTitles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [options, setOptions] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingAbout, setLoadingAbout] = useState(false);
  const [loadingError , setloadingError] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  useEffect(() => {
    setLoadingAbout(true);
    getTagsFromFirebase();
  }, []);
  const getTagsFromFirebase = async() => {
    const usercollectionRef = collection(db,`superAdmin/policy/infoDoc`);
    await getDocs(usercollectionRef).then((data)=>{
      setPolicyData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoadingAbout(false);
    }).catch((e) => {
      console.log(e);
      setloadingError(true);
      setLoadingAbout(false);
    });
    // getAboutUs({ docName: "policy" })
    //   .then(({ data }) => {
    //     console.log(data);
    //     // reset({...data});
    //     setPolicyData(data.value);
    //     setLoadingAbout(false);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     setLoadingAbout(false);
    //     setloadingError(true);
    //   });
  };
  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <NavBar color={"green"} />
      </Box>
      <Box sx={displayOnMobile}>
        <MobileMenu color={"green"} />
      </Box>
      <Box sx={{ backgroundColor: "#25C027", pt: "20px" }}>
        <Container>
          <Box sx={{ ...flexBetweenWithItemsCenter }}>
            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={findLeft}
                alt=""
              />
            </Box>
            <Box sx={{ width: "40%", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "30px" },
                  color: "#FFF",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                }}
              >
                Policy
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", md: "15px" },
                  color: "#FFF",
                  fontFamily: "Poppins",
                }}
              >
                Home / Policy
              </Typography>
            </Box>
            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img style={{ width: "100%" }} src={findRight} alt="" />
            </Box>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box sx={{ ...flexCenter, mb: { xs: "40px", md: "0" }, }}>
          <Stack sx={{ width: { xs: "90%", md: "70%" } }}>
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: 500,
                mt: "100px",
                textAlign: "center",
              }}
            >
              Policy
            </Typography>
            <Typography sx={{ textAlign: "center" }}>Home / Policy</Typography>
            {policyData.map((item, index) => {
              return (
                <>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      mt: "100px",
                      textAlign: "left",
                    }}
                  >
                    {index + 1}. {item.heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 300,
                      mt: "20px",
                    }}
                  >
                    {item.description}
                  </Typography>
                </>
              );
            })}
          </Stack>
        </Box>
      </Container>
      <Box sx={{ ...displayOnDesktop, mt: "10vw" }}>
        {" "}
        <Footer></Footer>
      </Box>
      <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
      <Modal
        open={loadingError}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
             background:"white"
          }}
          justifyContent={"center"}
          alignItems="center"
        >
         <ErrorPage></ErrorPage>
        </Stack>
      </Modal>
    </Box>
  );
};

export default PolicyComponent;
