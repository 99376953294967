import { Box, Modal, Stack } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeaturedCandidates from "../../components/Home/FeaturedCandidates/FeaturedCandidates";
import Header from "../../components/Home/Header/Header";
import InstantFeatureMessageMobile from "../../components/Home/InstantMessageFeture/InstantFeatureMessageMobile";
import InstantMessageFeture from "../../components/Home/InstantMessageFeture/InstantMessageFeture";
import LetTheCompanyFindYou from "../../components/Home/LetTheCompanyFindYou/LetTheCompanyFindYou";
import LetTheCompanyFindYouMobile from "../../components/Home/LetTheCompanyFindYou/LetTheCompanyFindYouMobile";
import PopularJobCategories from "../../components/Home/PopularJobCategories/PopularJobCategories";
import SendOfferLetters from "../../components/Home/SendOfferLetters/SendOfferLetters";
import SendOfferLettersMobile from "../../components/Home/SendOfferLetters/SendOfferLettersMobile";
import Recruiting from "../../components/SharedComponents/EmployersFindYou/Recruiting";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { GetType } from "../../context/authContext";
import { auth } from "../../firebase/firebaseConfig";
import { getUserType, saveFCM } from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile
} from "../../utilities/commonStyles/commonStyles";
import HomeFeatureComponent from "./HomeFeatureComponent";
const HomePage = () => {
  const [loading, setloading] = useState(false);
  const data = GetType();
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      const type = await getUserType();
      const prod = true;
    
      setloading(true);
      try {
          const messaging = getMessaging();
        Notification.requestPermission()
          .then((permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
            }
          })
          .then(() => {
            getToken(messaging, {
              vapidKey: prod
                ? "BGkjfGlPxFqynS2_f62mCYZq8Kdz2G16WrJsDuaCpxdJHis4GyBo5kqJFo4KL7PsmfrWxYrZs6Mjtf5QmX6nJ6Y"
                : "BGkjfGlPxFqynS2_f62mCYZq8Kdz2G16WrJsDuaCpxdJHis4GyBo5kqJFo4KL7PsmfrWxYrZs6Mjtf5QmX6nJ6Y",
            })
              .then((currentToken) => {
                if (currentToken) {
                  console.log(
                    "Token: " + currentToken + "\nUID: " + auth.currentUser.uid
                  );
                  saveFCM({ fcm: currentToken });
                } else {
                  console.log(
                    "No registration token available. Request permission to generate one."
                  );
                }
              })
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
              });
          });
      } catch (err) {
        console.log(err);
      }
      if (type.data.role == "Recruiter" && !type.data.organisation) {
        navigate("company-profile-updating");
        setloading(false);
      }
      if (!type.data.profileComplete && type.data.role == "user") {
        navigate("/candidate-profile-updating");
        setloading(false);
      }
      if (!type.data.profileComplete && type.data.role == "Admin") {
        navigate("/super-admin-dashboard/dashboard");
        setloading(false);
      }
      if (!type.data.profileComplete && type.data.role == "Agent") {
        navigate("/team/dashboard");
        setloading(false);
      }
      setloading(false);
    });
    setloading(false);
    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    const auth = getAuth();
    console.log(auth);
  }, []);
  return (
    <Box>
      <Header />
      {data.role != "Recruiter" ? <PopularJobCategories /> : null}
      <Box sx={displayOnDesktop}>
        <InstantMessageFeture />
      </Box>
      <Box sx={displayOnMobile}>
        <InstantFeatureMessageMobile />
      </Box>
      <Box sx={displayOnDesktop}>
        <LetTheCompanyFindYou />
      </Box>
      <Box sx={displayOnMobile}>
        <LetTheCompanyFindYouMobile />
      </Box>
      {/* {data.role == "user" ? (
        <Container>
          <RecentJobs />
        </Container>
      ) : (
        <></>
      )} */}
      {/* {data.role == "Recruiter" ? <FeaturedCandidates /> : <></>} */}
      <HomeFeatureComponent />
      <Box sx={displayOnDesktop}>
        <SendOfferLetters />
      </Box>
      <Box sx={displayOnMobile}>
        <SendOfferLettersMobile />
      </Box>
      <Recruiting />
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default HomePage;
