import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import {
  PlansModalCrossIcon,
  PlansModalTickIcon
} from "../../utilities/Icons/Icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createCheckoutLink, listAllCustomPlan, listBasePlans } from "../../firebase/functions";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
function createData(planName, plan1, plan2, plan3, plan4, plan5, plan6) {
  return { planName, plan1, plan2, plan3, plan4, plan5, plan6 };
}

const rows = [
  createData("Seats", 1, 3, 6, 5, 6, 12),
  createData("Slots", 3, 6, 12, 10, 12, 24),
  createData("Resume database", true, true, true, true, true, true),
  createData("Live Chat function", true, true, true, true, true, true),
  createData("Key word Matching", false, false, false, true, true, true),
];

export default function SuperAdminPlansPopupTable() {
  const [plans, setPlans] = React.useState([]);
  const [loading , setLoading] = useState(false);
  const [index , setIndex]  = useState(-1);
  const navigate = useNavigate();
  useEffect(() => {
    listBasePlans().then(({ data }) => {
      console.log("plaaaans", data);
        setPlans((plan) => plan.concat(data));
      // setPlans(data);
    }).catch((e) => {;
      toast.error(e.message);
  });
    listAllCustomPlan()
      .then(({ data }) => {
        console.log("plaaaans", data);
        if (Array.isArray(data)) {
          setPlans((plan)=> plan.concat(data));
        }
      })
      .catch((e) => {
        // toast.error(e.message);
      });
  }, []);
  const handleBuy = (id) => {
    console.log(id);
    setLoading(true);
    createCheckoutLink({ productId: id }).then(({ data }) => {
      console.log(data);
      window.open(data, "_self");
      setLoading(false);
    }).catch((e) => {;
      toast.error(e.message);
      setLoading(false);
  });
  };
  return (
    <Box>
      <TableContainer sx={{ boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",height:400 ,overflow: "auto" }}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                textTransform: "uppercase",
                backgroundColor: "#25C027",
              }}
            >
              {/* <TableCell></TableCell> */}
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Name
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              {/* <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  Seats
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell> */}
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                 Slots
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Interval
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Resume database
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                 Live Chat function
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
         
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Key word Matching
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                 
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Featured Employer
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                 
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Price
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
             
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Action
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
             
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {`${JSON.stringify(plans)}`} */}
            {plans.map((plan ,ind) => (
              <>              <TableRow
              key={plan?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">
                {plan?.displayName}
                </TableCell>
                {/* <TableCell align="center">
                {plan.seats?plan.seats:""}
                </TableCell> */}
                <TableCell align="center">
                 {plan.jobSlots?plan.jobSlots:""}
                </TableCell>
                <TableCell align="center">
                {plan?.plan?.recurring?.interval_count}{" "}
                {plan?.plan?.recurring?.interval}
                </TableCell>
                <TableCell align="center">
                    <PlansModalTickIcon />
                 
                </TableCell>
                <TableCell align="center">
                    <PlansModalTickIcon />
               
                </TableCell>
                <TableCell align="center">
                    <PlansModalTickIcon />
          
                </TableCell>
                <TableCell align="center">
             
                    <PlansModalTickIcon />
                
                </TableCell>
                {/* <TableCell align="center">
                {plan.name =="STARTER"|| plan.name =="ENTERPRISE" ||plan.name =="PREMIUM" ? (
                    <PlansModalTickIcon />
                  ) :(
                    <PlansModalCrossIcon />
                  )  }
                </TableCell>
                <TableCell align="center">
                  {plan.name =="STARTER"|| plan.name =="ENTERPRISE" ||plan.name =="PREMIUM" ? (
                    <PlansModalTickIcon />
                  ) :(
                    <PlansModalCrossIcon />
                  )  }
                </TableCell>
                <TableCell align="center">
                {plan.name =="ENTERPRISE" ||plan.name =="PREMIUM" ? (
                    <PlansModalTickIcon />
                  ) :(
                    <PlansModalCrossIcon />
                  )  }
                </TableCell>
                <TableCell align="center">
                {plan.name =="ENTERPRISE"  ? (
                    <PlansModalTickIcon />
                  ) :(
                    <PlansModalCrossIcon />
                  )  }
                </TableCell> */}
                <TableCell align="center">
                {plan?.plan?.unit_amount/100}$
                </TableCell>
                <TableCell align="center">
                <Box sx={{ ...flexCenter, width: "100%", mt: "20px" }}>
        <LoadingButton onClick={() =>{ setIndex(ind);handleBuy(plan.id)}}
        loading={loading && index==ind}
          sx={{
            textTransform: "none",
            color: "#FFF",
            backgroundColor: "#25C027",
            "&:hover": { backgroundColor: "#25C027" },
          }}
        >
          Buy
        </LoadingButton>
      </Box>
                </TableCell>
              </TableRow>
              </>
 ))}

            {/* <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                color: "#FFF",
                backgroundColor: "#25C027",
              }}
            >
              <TableCell
                component="th"
                scope="row"
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                Price
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
              >
                $350
              </TableCell>
            </TableRow> */}
            {/* <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center"></TableCell>
              <TableCell>
                <FormControlLabel
                  sx={{ ...flexCenter, ml: "3%" }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#25C02780",
                        "&.Mui-checked": {
                          color: "#25C02780",
                        },
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  sx={{ ...flexCenter, ml: "3%" }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#25C02780",
                        "&.Mui-checked": {
                          color: "#25C02780",
                        },
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  sx={{ ...flexCenter, ml: "3%" }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#25C02780",
                        "&.Mui-checked": {
                          color: "#25C02780",
                        },
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  sx={{ ...flexCenter, ml: "3%" }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#25C02780",
                        "&.Mui-checked": {
                          color: "#25C02780",
                        },
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  sx={{ ...flexCenter, ml: "3%" }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#25C02780",
                        "&.Mui-checked": {
                          color: "#25C02780",
                        },
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  sx={{ ...flexCenter, ml: "3%" }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#25C02780",
                        "&.Mui-checked": {
                          color: "#25C02780",
                        },
                      }}
                    />
                  }
                />
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>

      
    </Box>
  );
}
