import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetType } from "../../../context/authContext";
import { auth } from "../../../firebase/firebaseConfig";
import SendOfferLettersImg from "../../../utilities/images/svg images/SendOfferLetters.svg";

const SendOfferLetters = () => {
  const user = auth.currentUser;
  const navigate = useNavigate();
  const userType = GetType();
  return (
    <Box sx={{ my: "100px" }}>
      <Container>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "50%" }}>
            <Typography
              sx={{
                color: "#202124",
                fontWeight: 700,
                fontSize: "35px",
              }}
            >
              Send {" "}<span style={{ color: "#24BD2C" }}> offer letters in seconds</span>{" "} through our MFGWorx hiring platform
          
            </Typography>
            <Typography
              sx={{
                color: "#696969",
                fontSize: "18px",
                fontFamily: "Sofia Pro",
                mt: "10px",
              }}
            >
              A platform that truly supports hiring managers! Submit offer letters directly to candidates via MFGWorx. Candidates can view, download, accept or kindly reject offer letters all from their dashboard.
            </Typography>
            {!user ? (
                        <>  <Button
                        onClick={() => navigate("/registration/login")}
              sx={{
                textTransform: "none",
                fontSize: "18px",
                fontFamily: "Sofia Pro",
                fontWeight: 600,
                backgroundColor: "#24BD2C",
                color: "#fff",
                padding: "14px 24px",
                borderRadius: "8px",
                mt: "20px",
                "&:hover": {
                  backgroundColor: "#24BD2C",
                },
              }}
            >
              Get Started
            </Button></> ) : userType.role == "user" ? (
                        <></>) : (
                         <Button
                         onClick={() => navigate("/employee-dashboard/dashboard")}
              sx={{
                textTransform: "none",
                fontSize: "18px",
                fontFamily: "Sofia Pro",
                fontWeight: 600,
                backgroundColor: "#24BD2C",
                color: "#fff",
                padding: "14px 24px",
                borderRadius: "8px",
                mt: "20px",
                "&:hover": {
                  backgroundColor: "#24BD2C",
                },
              }}
            >
              Get Started
            </Button>)}
          </Box>
          <Box sx={{ width: "50%", display: "flex", justifyContent: "center" }}>
            <img style={{ width: "100%" }} src={SendOfferLettersImg} alt="" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SendOfferLetters;
