import { Button, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./NavBar.module.css";
function LoginComponent() {
  const navigate = useNavigate();
  return (
    <Stack direction>
      <Stack direction gap={2}>
        <Button
          className={classes.navLink}
          onClick={() => navigate("/registration/login")}
          sx={{
            textTransform: "none",
            fontSize: "18px",
            fontFamily: "Sofia Pro",
            backgroundColor: "#7BDF80",
            color: "#fff",
            padding: "14px 24px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#7BDF80",
            },
          }}
        >
          Login 
          {/* <span style={{ display: "inline-block", padding: "0px 5px" }}>/</span>
        <Link to="/register">Register</Link> */}
        </Button>
        <Button
          className={classes.navLink}
          onClick={() => navigate("/registration/register")}
          sx={{
            textTransform: "none",
            fontSize: "18px",
            fontFamily: "Sofia Pro",
            backgroundColor: "#7BDF80",
            color: "#fff",
            padding: "14px 24px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#7BDF80",
            },
          }}
        >
          {/* <Link to="/register/login">Login</Link>{" "}
        <span style={{ display: "inline-block", padding: "0px 5px" }}>/</span> */}
          Register
        </Button>
      </Stack>
      <Button
      onClick={() => navigate("/registration/login")}
        sx={{
          textTransform: "none",
          fontSize: "18px",
          fontFamily: "Sofia Pro",
          fontWeight: 600,
          backgroundColor: "#fff",
          color: "#24BD2C",
          padding: "14px 24px",
          borderRadius: "8px",
          marginLeft: "20px",
          "&:hover": {
            backgroundColor: "#fff",
          },
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.58959 0.0376026C6.92442 0.208138 6.42893 0.746004 6.31917 1.41662C6.298 1.54577 6.2814 2.6623 6.28128 3.96202L6.28105 6.27738L3.96668 6.27761C2.66751 6.27773 1.55146 6.29434 1.42236 6.31552C0.297836 6.49973 -0.323234 7.71645 0.172372 8.76444C0.387793 9.22001 0.896681 9.59195 1.42236 9.67807C1.55146 9.69925 2.66751 9.71586 3.96668 9.71598L6.28105 9.71621V12.0556C6.28105 13.4576 6.29679 14.4792 6.32031 14.6054C6.4135 15.1048 6.78111 15.6013 7.22051 15.8211C7.33757 15.8797 7.5594 15.949 7.71342 15.9751C8.64026 16.1322 9.50312 15.5307 9.67854 14.6052C9.70287 14.4768 9.71842 13.4833 9.71842 12.0556V9.71621H12.0568C13.4839 9.71621 14.477 9.70066 14.6053 9.67632C15.5229 9.50222 16.1222 8.65017 15.9788 7.72356C15.8668 6.99937 15.2825 6.41985 14.5591 6.31524C14.421 6.29527 13.2763 6.27859 12.0151 6.27816L9.72221 6.27738L9.71053 3.80571L9.69885 1.33404L9.60835 1.10978C9.33488 0.432249 8.78967 0.0376809 8.07786 0.00212015C7.91525 -0.00596894 7.69799 0.00977926 7.58959 0.0376026Z"
            fill="#24BD2C"
          />
        </svg>
        <span style={{ marginLeft: "5px" }}>Job Post</span>
      </Button>
    </Stack>
  );
}

export default LoginComponent;
