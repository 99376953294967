import { Box, Container, Typography, Modal, Stack, TextField, MenuItem } from "@mui/material";
import ContactInformation from "../EmployeeDashboardComponent/ContactInformation";
import classes from "../EmployeeDashboardComponent/EmployeeDashboardComponent.module.css"
import PostJob from "../EmployeeDashboardComponent/PostJob";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { addJob, teamAddJob, teamOrgDetail } from "../../firebase/functions";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { GetType } from "../../context/authContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TeamDashboardPostJob = () => {
  const user = GetType();
   const [orgArray, setOrgArray] = useState([]);
   const [orgIdArray, setOrgIdArray] = useState([]);
 useEffect(() => {
   console.log("inside userEffect");
  setLoading(true);
   if (user?.uid != undefined) {
     getJobDataById(user.uid);
   }
 }, [user]);
 const getJobDataById = async (id) => {
   teamOrgDetail({ id: id }).then(({ data }) => {
     console.log(data);
     setOrgIdArray(data.orgId);
     if (Array.isArray(data.orgArray)) {
       setOrgArray(data.orgArray);
     }
   setLoading(false);
   });
 };
 useEffect(() => {
   if (orgIdArray.length > 0) {
     companyFunction();
   }
 }, [orgIdArray]);
 const companyFunction = async () => {
   const orgCollectionRef = query(
     collection(db, "Organisations"),
     where("__name__", "in", orgIdArray)
   );
   await getDocs(orgCollectionRef)
     .then((docSnap) => {
       let temp = docSnap.docs.map((doc) => {
         return { ...doc.data(), id: doc.id };
       });
       console.log(temp);
       setCompanyArray(temp);
     })
     .catch((e) => {
       console.log(e);
       toast.error(e.message);
     });
 };
  const [companyId, setCompanyId] = useState('');
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [companyArray , setCompanyArray] = useState([])
  const [skills, setSkills] = useState([]);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    console.log(data);
    if (parseInt(data.maxSalary) <= parseInt(data.minSalary)) {
      console.log(data.minSalary, data.maxSalary);
      setError("minSalary", {
        type: "custom",
        message: "Minimum salary must be less than maximum salary",
        shouldFocus: true,
      });
      return;
    }
    //
    setLoading(true);

    try {
      const result = await teamAddJob({
        ...data,
        skillsRequired: data.skillsRequired.split(/\r?\n/),
        keyResponsibilities: data.keyResponsibilities.split(/\r?\n/),
        organisationId:companyId,
      });
      console.log(result.data);
      setLoading(false);
      toast.success("Job posted successfully");
      navigate("/team/manage-jobs");
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error(`${err.message}`);
      setLoading(false);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Container>
        <Box>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Post a New Job
            </Typography>
          </Box>
          <TextField
            labelId="demo-multiple-chip-label"
            label="Company Name"
            name="teamSize"
            select
            // value={companyId}
            sx={{ width: "35vw" }}
            onChange={(e) => {
              setCompanyId(e.target.value);
              console.log(e.target.value);
            }}
            color="success"
            MenuProps={MenuProps}
          >
            {companyArray.map((name) => (
              <MenuItem key={name.id} value={name.id}>
                {name.organisationName}
              </MenuItem>
            ))}
          </TextField>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <PostJob
              setValue={setValue}
              getValues={getValues}
              skills={{ setSkills, skills }}
              register={register}
              setTags={setTags}
              errors={errors}
            />
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <ContactInformation
              loading={loading}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </Box>
        </Box>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default TeamDashboardPostJob;
