import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  addBookmarks,
  checkBookMark, getLogo
} from "../../../firebase/functions";
import {
  BookMarkIcon,
  FeturedJobsClockIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
  FeturedJobsMoneyIcon
} from "../../../utilities/Icons/Icons";

const JobSearchPageFeaturedJob = ({ job, id, setOverview, overview }) => {
  const [logo, setLogo] = useState();
  const [bookmark, setBookmark] = useState(false);
  // const [job,setJob]=useState({});
  useEffect(() => {
    getLogo({ orgId: job.organisationId }).then((res) => {
      setLogo(res.data);
    });
    checkBookMark({ jobId: job.objectID }).then(({ data }) => {
      setBookmark(data);
    });
  }, [job]);
  const addToBookMark = (jobId) => {
    addBookmarks({ jobId }).then(({ data }) => {
      if (!bookmark) toast.success("Bookmark added");
      if (bookmark) toast.error("Bookmark removed");
      console.log(data);
      setBookmark(!bookmark);
    });
  };
  return (
    <Box sx={{ width: "100%", mb:"30px" }}>
      <Stack spacing={2}>
        <Box
          onClick={() => setOverview(id)}
          display={job?.active ? "flex" : "hidden"}
          sx={{
            // display: "flex",
            width: "100%",
            gap: "20px",
            background: overview == id ? "#25C027" : "#fff",
            padding: "30px",
            borderRadius: "20px",
            boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
            "&:hover": {
              backgroundColor: "#25C027",
            },
            mt: "10px",
            p: "10px",
          }}
        >
          {/* {JSON.stringify(bookmark)}haha */}
          {/* {JSON.stringify({ active: job.active, expired: job.expired })} */}
          <Box
            sx={{
              backgroundColor: "#262E50",
              width: "50px",
              height: "50px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "100%", borderRadius: "10px", marginTop: "16px" }}
              src={logo}
              alt=""
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ color: "#0E0E0E", fontSize: "18px", fontWeight: 500 }}
              >
                {job.jobTitle}
              </Typography>

              <IconButton
                onClick={() => {
                  return addToBookMark(job.objectID);
                }}
                sx={{
                  backgroundColor: bookmark ? "green" : "",
                }}
              >
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#ECEDF2",
                  }}
                >
                  <BookMarkIcon />
                </Box>
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: {xs:"10px", md:"20px"},
                alignItems: "center",
                flexWrap:"wrap",
                mt: "5px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <FeturedJobsFordIcon />
                <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                  {job.organisationName}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <FeturedJobsLocationIcon />
                <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                  {job.city},{job.state}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <FeturedJobsClockIcon />
                <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                  {new Date(job.jobAddedAt).toDateString()}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <FeturedJobsMoneyIcon />
                <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                  {`$${job?.minSalary} - $${job?.maxSalary} /${job?.salaryType}`}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                mt: "15px",
              }}
            >
              {job?.skills
                ?.map((skill, index) => {
                  return (
                    <Box>
                      <Chip
                        label={skill}
                        sx={{
                          color: "#1967D2",
                          background: "#eee",

                          height: "25",
                          // padding: "5px 15px",
                          fontSize: "12px",
                        }}
                      />
                    </Box>
                  );
                })
                .slice(0, 5)}
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default JobSearchPageFeaturedJob;
