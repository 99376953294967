import {
  Avatar,
  Box,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,Modal
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOrgData } from "../../firebase/functions";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
//import JobcardCompanyProfile from "./JobcardCompanyProfile";
const SuperAdminDashboardCompanyView = () => {
  const [loading , setloading] = useState(false);
  const [orgData, setOrgData] = useState({});
  const [jobs, setJobs] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    setloading(true);
    getOrgData({ organisationId: id })
      .then(({ data }) => {
        console.log("haha", data);
        setOrgData(data);
        setJobs(data.jobs);
        setloading(false);
      })
      .catch((e)=>{
        setloading(false);
        toast.error(e.message);
      })
  }, [id]);

  console.log(orgData);
  return (
    <Box
      className="hidescrollbar"
      sx={{
        height: "80vh",
        overflowY: "scroll",
        background: "#FFFFFF",
        boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
        borderRadius: "8px",
        py:"20px"
      }}
    >
      {/* {JSON.stringify(getValues())}sdfsdf */}
      <Container>
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Profile
          </Typography>
        </Box>

        <Box style={{ width: "150px", height: "150px", mt: "20px" }}>
          <Avatar src={orgData.organisationLogo?orgData.organisationLogo:""} style={{ width: "100%", height: "150px" }}></Avatar>
        </Box>

        <Box sx={{ width: "100%", mt: "30px" }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              >
                Company name
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                  mt: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#696969",
                  }}
                >
                  {orgData.organisationName?orgData.organisationName:""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              >
                Email address
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                  mt: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#696969",
                  }}
                >
                  {orgData.organisationEmail?orgData.organisationEmail:""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              >
                Phone
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                  mt: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#696969",
                  }}
                >
                  {orgData.organisationPhone?orgData.organisationPhone:""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              >
                Website
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                  mt: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#696969",
                  }}
                >
                {orgData.organisationWebsite?orgData.organisationWebsite:""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              >
                Est. Since
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                  mt: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#696969",
                  }}
                >
                  {orgData.estSince?orgData.estSince:""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              >
                Team Size
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                  mt: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#696969",
                  }}
                >
                  {orgData.teamSize?orgData.teamSize:""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              >
                Categories
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                  mt: "10px",
                }}
              >
                <Stack direction="row" spacing={1}>
                  {orgData.categories ? orgData.categories.map((item) => (
                    <Chip
                      label={item}
                      sx={{ backgroundColor: "#25C027", color: "#FFF" }}
                    />
                  )):<></>}
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              >
                Allow In Search & Listing
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                  mt: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#696969",
                  }}
                >
                  Yes
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "#202124",
                }}
              ></Typography>
              <Box
                sx={{
                  backgroundColor: "#F0FAF0",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#696969",
                  }}
                >
               {orgData.organisationDescription?orgData.organisationDescription:""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Box>
  );
};

export default SuperAdminDashboardCompanyView;
