import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Input,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { toast } from "react-toastify";
import { createOrg1, getFormField } from "../../firebase/functions";
import convert64 from "../../Helpers/fileConvert";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CompanyProfileUpdatingComponent = ({
  data,
  setValue,
  activeStep,
  setActiveStep,
  errors,
  register,
  getValues,
  teamSize,
  setTeamSize
}) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [age, setAge] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [image, setImage] = React.useState("");
  const [categoryArray, setCategoryArray] = React.useState([]);
  const [teamSizeArray, setTeamSizeArray] = React.useState([]);

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  React.useEffect(() => {
    getFormField({ form: "categories" }).then((result) => {
      console.log(result.data);
      setCategoryArray(result.data);
    });
  }, []);

  React.useEffect(() => {
    getFormField({ form: "headCount" }).then((result) => {
      console.log(result.data);
      setTeamSizeArray(result.data);
    });
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleFileChange = async (event) => {
    try {
      setImage(event);
      const base64 = await convert64(event);
      setLogo(base64);
      setValue("organisationLogo", base64);
    } catch (e) {}
  };
  const onSubmit = async (event) => {
    const { data } = await createOrg1({
      ...event,
      organisationLogo: logo,
      categories: personName,
    });
    if (data == "success") {
      setActiveStep({
        first: true,
      });
    }
    toast.success("Organisation Created Successfully");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        sx={{
          color: "#25C027",
          fontFamily: "Sofia Pro",
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        Profile
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center", md: "end" },
          gap: { xs: "15px", md: "30px" },
          mt: "20px",
        }}
      >
        <Box>
          {/* {getValues("organisationLogo")} */}
          <img
            src={
              image
                ? URL.createObjectURL(image)
                : getValues("organisationLogo")
                ? getValues("organisationLogo")
                : "https://via.placeholder.com/150"
            }
            alt="logo"
            width="150"
            height="150"
          />
        </Box>
        <Box>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              onChange={(e) => {
                handleFileChange(e.target.files[0]);
              }}
              id="contained-button-file"
              multiple
              type="file"
              sx={{ display: "none" }}
            />
            <Button
              variant="contained"
              component="span"
              sx={{
                textTransform: "none",
                fontSize: { xs: "8px", md: "14px" },
                fontFamily: "Sofia Pro",
                backgroundColor: "#25C027",
                color: "#fff",
                padding: { xs: "10px 20px", md: "15px 30px" },
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#25C027",
                },
              }}
            >
              Browse Logo
            </Button>
          </label>

          <Typography
            sx={{
              color: "#696969",
              fontFamily: "Sofia Pro",
              fontSize: { xs: "8px", md: "14px" },
              mt: { xs: "10px", md: "30px" },
            }}
          >
            Max file size is 1MB, Minimum dimension: 330x300 And Suitable files
            are .jpg & .png
          </Typography>
        </Box>
      </Box>

      <Stack spacing={4} sx={{ mt: "50px" }}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              >
                Company name*
              </Typography>
              <TextField
                {...register("organisationName", {
                  required: true,
                  minLength: 3,
                  maxLength: 50,
                })}
                error={!!errors?.organisationName}
                helperText={
                  errors?.organisationName
                    ? errors.organisationName.message
                    : null
                }
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%", mt: "10px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              >
                Email Address*
              </Typography>
              <TextField
                {...register("organisationEmail", {
                  required: true,
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Invalid Email",
                  },
                })}
                error={!!errors?.organisationEmail}
                helperText={
                  errors?.organisationEmail
                    ? errors.organisationEmail.message
                    : null
                }
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%", mt: "10px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              >
                Phone*
              </Typography>
              <TextField
                {...register("organisationPhone", {
                  required: true,
                  minLength: 10,
                  maxLength: 22,
                  pattern: {
                    value: "^(+d{1,2}s)?(?d{3})?[s.-]d{3}[s.-]d{4}$",
                    message: "Invalid Phone Number",
                  },
                })}
                error={!!errors?.organisationPhone}
                helperText={
                  errors?.organisationPhone
                    ? errors.organisationPhone.message
                    : null
                }
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%", mt: "10px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              >
                Website
              </Typography>
              <TextField
                // {...register("organisationWebsite", {
                //   minLength: 6,
                //   maxLength: 50,
                // })}
                // error={!!errors?.organisationWebsite}
                // helperText={
                //   errors?.organisationWebsite
                //     ? errors.organisationWebsite.message
                //     : null
                // }
                {...register("organisationWebsite", {
                  required: {
                    value: false,
                    message: "Url is required",
                  },
                  pattern: {
                    value:
                      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/,
                    message: "Please enter a valid url",
                  },
                })}
                error={Boolean(errors.organisationWebsite)}
                helperText={
                  errors.organisationWebsite &&
                  errors.organisationWebsite.message
                }
                placeholder="https://www.MFGWorx.com/"
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%", mt: "10px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              >
                Est. Since*
              </Typography>
              <TextField
                {...register("estSince", {
                  pattern: {
                    value: /[0-9]{4}/,
                    message: "Invalid Year",
                  },
                  required: true,
                  minLength: 4,
                  maxLength: 5,
                  type: "number",
                })}
                error={!!errors?.estSince}
                helperText={errors?.estSince ? errors.estSince.message : null}
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%", mt: "10px" }}
              />
            </Box>
          </Grid>
          {/* {`${JSON.stringify(register.teamSize)}`} */}
          <Grid item xs={6}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              >
                Team Size
              </Typography>
              <FormControl sx={{ width: "100%", mt: "10px" }}>
                {/* <InputLabel id="demo-multiple-chip-label" color="success">
                  Count
                </InputLabel> */}
                <TextField
                  labelId="demo-multiple-chip-label"
                  label="Count"
                  name="teamSize"
                  select
                  //  inputRef={register("teamSize",{ required: true })}
                  value={teamSize}
                  {...register("teamSize", {
                    required: true,
                    // minLength: 1,
                    // maxLength: 50,
                    // type: "number",
                  })}
                  onChange={(e) => {
                    // setValue("teamSize", e.target.value)
                    setTeamSize(e.target.value);
                    console.log(e.target.value);
                  }}
                  error={!!errors?.teamSize}
                  helperText={errors?.teamSize ? errors.teamSize.message : null}
                  color="success"
                  MenuProps={MenuProps}
                >
                  {teamSizeArray.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              {/* <TextField
                {...register("teamSize", {
                  required: true,
                  minLength: 1,
                  maxLength: 50,
                  type: "number",
                })}
                error={!!errors?.teamSize}
                helperText={errors?.teamSize ? errors.teamSize.message : null}
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%", mt: "10px" }}
              /> */}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              >
                Categories*
                {/* {`${JSON.stringify(categoryArray)}`} */}
              </Typography>
              <Box sx={{ minWidth: 120, mt: "10px" }}>
                {getValues("categories") ? (
                  <FormControl sx={{ width: "100%" }}>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={categoryArray}
                      defaultValue={getValues("categories")?.map(
                        (data) => data
                      )}
                      freeSolo
                      onChange={(event, value) => {
                        setValue("categories", value);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Add Categories"
                        />
                      )}
                    />
                  </FormControl>
                ) : (
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={categoryArray}
                    freeSolo
                    onChange={(event, value) => {
                      setValue("categories", value);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Add Categories"
                      />
                    )}
                  />
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              >
                About Company
              </Typography>
              <TextField
                {...register("organisationDescription", {
                  required: "This field is required",
                  minLength: {
                    value: 10,
                    message: "Minimum 10 characters",
                  },
                  maxLength: {
                    value: 500,
                    message: "Maximum 500 characters",
                  },
                })}
                error={!!errors?.organisationDescription}
                helperText={
                  errors?.organisationDescription
                    ? errors.organisationDescription.message
                    : null
                }
                id="outlined-basic"
                variant="outlined"
                color="success"
                multiline
                rows={10}
                sx={{
                  width: "100%",
                  mt: "10px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              {getValues(`secondChanceEmployer`) ? (
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  <FormControlLabel
                    onChange={(e) => {
                      console.log(true);
                      console.log(e.target.checked);
                      setValue(`secondChanceEmployer`, e.target.checked);
                    }}
                    control={
                      <Checkbox defaultChecked={getValues(`secondChanceEmployer`)} />
                    }
                    label="  Are you a second chance employer?"
                  />
                </FormControl>
              ) : (
                <FormControlLabel
                  onChange={(e) => {
                    console.log(true);
                    console.log(e.target.checked);
                    setValue(`secondChanceEmployer`, e.target.checked);
                  }}
                  control={<Checkbox />}
                  label="  Are you a second chance employer?"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
//industrial category job
// const categoryArray = [
//   "Accounting",
//   "Administrative",
//   "Advertising",
//   "Agriculture",
//   "Architecture",
//   "Arts",
//   "Automotive",
//   "Banking",
//   "Beauty",
//   "Biotechnology",
//   "Business",
//   "Chemical",
//   "Civil",
//   "Computer",
//   "Construction",
//   "Consulting",
//   "Education",
//   "Electronics",
//   "Energy",
//   "Engineering",
//   "Entertainment",
//   "Environmental",
//   "Fashion",
//   "Finance",
//   "Food",
//   "Health",
//   "Hospitality",
//   "Human Resources",
//   "Information Technology",
//   "Insurance",
// ];

export default CompanyProfileUpdatingComponent;
