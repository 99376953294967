import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { addSocial } from "../../firebase/functions";
import { toast, ToastContainer } from "react-toastify";
const CompanyProfileUpdatingSocialNetwork = ({ activeStep, setActiveStep,setValue,register ,errors }) => {
  // const { handleSubmit, register, errors } = useForm();

  return (
    <Box
      component="form"
      //onSubmit={handleSubmit(onSubmit)}
      sx={{ width: "100%" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Social Network
        </Typography>
      </Box>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: "30px" }}
      >
        <Grid item xs={6}>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Facebook
            </Typography>
            <TextField
              // {...register("facebook")}
              {...register("facebook", {
                required: {
                  value: false,
                  message: "Url is required",
                },
                pattern: {
                  value:
                    /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/,
                  message: "Please enter a valid url",
                },
              })}
              error={!!errors?.facebook}
              helperText={errors?.facebook ? errors.facebook.message : null}
              // error={Boolean(errors.facebook)}
              // helperText={errors.facebook && errors.facebook.message}
              placeholder="https://www.googleplus.com/"
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Twitter
            </Typography>
            <TextField
              {...register(
                "twitter",

                {
                  required: {
                    value: false,
                    message: "Url is required",
                  },
                  pattern: {
                    value:
                      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/,
                    message: "Please enter a valid url",
                  },
                }
              )}
              error={!!errors?.twitter}
              helperText={errors?.twitter ? errors.twitter.message : null}
              // error={Boolean(errors.twitter)}
              // helperText={errors.twitter && errors.twitter.message}
              placeholder="https://www.twitter.com/"
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Linkedin
            </Typography>
            <TextField
              {...register("linkedin", {
                required: {
                  value: false,
                  message: "Url is required",
                },
                pattern: {
                  value:
                    /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/,
                  message: "Please enter a valid url",
                },
              })}
              error={!!errors?.linkedin}
              helperText={errors?.linkedin ? errors.linkedin.message : null}
              // error={Boolean(errors.linkedin)}
              // helperText={errors.linkedin && errors.linkedin.message}
              placeholder="https://www.linkedin.com/"
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Google Plus
            </Typography>
            <TextField
              {...register("googleplus", {
                required: {
                  value: false,
                  message: "Url is required",
                },
                pattern: {
                  value:
                    /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/,
                  message: "Please enter a valid url",
                },
              })}
              error={!!errors?.googleplus}
              helperText={errors?.googleplus ? errors.googleplus.message : null}
              // error={Boolean(errors.googleplus)}
              // helperText={errors.googleplus && errors.googleplus.message}
              placeholder="https://www.googleplus.com/"
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyProfileUpdatingSocialNetwork;
