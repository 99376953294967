import { Box, Container, Modal, Stack, Typography } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import { getUserInvites } from "../../firebase/functions";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import ApplicationCardCandidate from "./ApplicationCardCandidate";
import classes from "./CandidateDashboardAppliedJobs.module.css";

const JobInvites = () => {
  const fakeArr = [1, 2, 3, 4, 5];
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const userType = GetType();
  useEffect(() => {
    setLoading(true);
    // getUserInvites().then(({ data }) => {
    //   setLoading(false);
    //   setAppliedJobs(data);
    // }).catch((e)=>{
    //   setLoading(false);
    //   toast.error(e.message);
    // })
    if (userType.uid != undefined) {
      allJobs();
    }
  }, [userType]);
  const allJobs = async () => {
    const employerPostedJob = collection(db, `Users/${userType.uid}/Invites`);
    await getDocs(employerPostedJob)
      .then((docSnap) => {
        setAppliedJobs(docSnap.docs.map((doc) => doc.data()));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        toast.error(e.message);
      });
  };
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Job Invites
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: {xs:"10px", md:"30px"},
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              My Job Invites
            </Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#F0FAF0",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography>
            <EmployeeDashboardManageJobsDownArrowIcon />
          </Box> */}
        </Box>

        <Box sx={{ my: "0px" }}>
        <Stack
              className={classes.hidescrollbar}
              spacing={2}
              sx={{ height: "60vh", overflowY: "scroll", mt: "25px" }}
            >
              {appliedJobs?.map((item, index) => (
                <ApplicationCardCandidate data={item.jobId} status={item.status}  method="invite" item={item}  />
              ))}
            </Stack> 
        </Box>
        <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
      </Box>
    </Container>
  );
};

export default JobInvites;
