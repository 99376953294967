import { Box, Modal, Paper, Stack, Typography } from "@mui/material";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import Router from "./utilities/Router/Router";
import { toast } from "react-toastify";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { checkActive, getUserType } from "./firebase/functions";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebaseConfig";
import { Payment } from "@mui/icons-material";
import SuperAdminDashboardPaymentInvoices from "./components/SuperAdminDashboardSideNavbar/SuperAdminDashboardPaymentInvoices";
import EmployeeDashboardPaymentInvoices from "./components/EmployeeDashboardComponent/EmployeeDashboardPaymentInvoices";
import SuperAdminPlansPopup from "../src/components/SuperAdminDashboardSideNavbar/SuperAdminPlansPopup"
function App() {
 
  const [userType, setUser] = React.useState({});
  const [isActive, setIsActive] = React.useState(true);

  useEffect(() => {
   
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      const type = await getUserType();
      setUser(type.data);

      if (type.data.role == "Recruiter" && type.data.profileComplete == true) {
        checkActive().then(({ data }) => {
          console.log(data)
          if (data == true) {
            setIsActive(true);
          } else {
            setIsActive(false);
          }
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  console.log("Requesting permission...");
  try {
     const messaging = getMessaging();
  Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
      }
    })
    .then(() => {
      const prod = true;
      getToken(messaging, {
        vapidKey: prod
          ? "BBIdctTCLQ5aERD5Cj8Vvk-4bxGUDtBoRLqfA2crUhbtTc2MD3UblZKTYmiDEKyqXMd06y3z07MHPg3_zyNVPqU"
          : "BGkjfGlPxFqynS2_f62mCYZq8Kdz2G16WrJsDuaCpxdJHis4GyBo5kqJFo4KL7PsmfrWxYrZs6Mjtf5QmX6nJ6Y",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log(currentToken);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    });
  }
  catch(err) {
   console.log(err);
  }
  try {
     const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    toast.success(payload.notification.body);
    //API - https://us-central1-dev-jobboard-gcp.cloudfunctions.net/sendNotification
    //Post params - fcm, message

    //NotificationManager.success(payload.notification.body, payload.notification.title);
  });
}
catch(err) {
 console.log(err);
}
  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      {/* {JSON.stringify(userType)} */}
      <Modal
        open={isActive == false}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <Paper
            sx={{
              p: "20px",
              maxWidth: "900px",
              maxHeight: "90vh",
              overflowY: "scroll",
            }}
          >
            <EmployeeDashboardPaymentInvoices />
          </Paper>
        </Stack>
      </Modal>

      {/* {JSON.stringify(userType?.profileComplete)} */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router />
    </Box>
  );
}

export default App;
