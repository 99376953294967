import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { getFormField } from "../../firebase/functions";
import { ChipsArray } from "./CandidateDashboardMyResume";

const AddSkills = ({ skills, setValue, getValues,user }) => {
  const [skillArray, setSkillArray] = React.useState([]);
  React.useEffect(() => {
    getFormField({form:"skills"})
    .then(({ data }) => {
      console.log("Skills",data);
      setSkillArray(data)
      console.log(data)
    }).catch((e)=>{
      toast.error(e.message);
    })
  }, []);
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            color: "#202124",
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Sofia Pro",
          }}
        >
          Skills
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Button></Button>
      </Box>

      {user?.firstName ? (
        <Autocomplete
          multiple
          id="tags-outlined"
          options={skillArray}
          getOptionLabel={(option) => option}
          onChange={(event, value) => {
            setValue("skills", value);
          }}
          defaultValue={skills?.map((data) => data)}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Please enter your skills"
              placeholder="Skills"
              variant="standard"
              multiline
            />
          )}
        />
      ) : null}
    </Box>
  );
};
// const skillarray = [
//   "Painting",
//   "Sculpting",
//   "Manufacturing",
//   "Welding",
//   "Carpentry",
//   "Plumbing",
//   "Electrical",
//   "Mechanical",
//   "Cleaning",
//   "Cooking",
// ];

export default AddSkills;
