import { Box, Container, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { getUserShortlisted } from "../../firebase/functions";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsDownArrowIcon,
  EmployeeDashboardManageJobsSeeTracedIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
} from "../../utilities/Icons/Icons";
import classes from "./CandidateDashboardAppliedJobs.module.css";
import ShortlistedTableRow from "./ShortlistedTableRow";

const CandidateDashboardShortlistedJobs = () => {
  const [shortListed, setShortListed] = useState([]);
  useEffect(()=>{
    getUserShortlisted().then(({data})=>{
      
      setShortListed(data);
    })
  },[])
  const fakeArr = [1, 2, 3, 4, 5];
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Manage Jobs
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "30px",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              My Favorite Jobs
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#F0FAF0",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography>
            <EmployeeDashboardManageJobsDownArrowIcon />
          </Box>
        </Box>

        <Box sx={{ my: "40px" }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                <TableRow
                  sx={{ "&:first-child td, &:first-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Job Title
                  </TableCell>

                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Posted Date
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Status
                  </TableCell>

                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ width: "100%" }}>
                {shortListed?.map((data,index) => (
                  <ShortlistedTableRow data={data} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Container>
  );
};

export default CandidateDashboardShortlistedJobs;
