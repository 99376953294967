import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import Footer from "../../components/SharedComponents/Footer/Footer";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import FindJobsBgImage from "../../utilities/images/find-jobs-bg.png";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,Modal,Paper ,Stack
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import {
  PlansModalCrossIcon,
  PlansModalTickIcon
} from "../../utilities/Icons/Icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createCheckoutLink, listBasePlans } from "../../firebase/functions";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import ErrorPage from "../JobSearchPage/ErrorPage";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Pricing = () => {
  const [loading ,setLoading] = useState(false);
  const [subscription, setSubscription] = useState("monthly");
 const [loadingError ,setloadingError] =useState(false);
  const subscriptionTab = (subscriptionType) => {
    setSubscription(subscriptionType);
  };
  const [plans, setPlans] = React.useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    listBasePlans().then(({ data }) => {
      console.log("plaaaans", data);
      setPlans(data);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
      setloadingError(true);
      setLoading(false);
    });
  }, []);

  return (
    <Box>
      {/* <ColorNavBar /> */}
      <NavBar color={"green"}/>
      <Box
        style={{
          backgroundImage: `url(${FindJobsBgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ color: "#fff", fontSize: "30px", fontWeight: 500 }}>
            Pricing
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box >
          <Box>
          <Box>
      <TableContainer sx={{ boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)" }}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                textTransform: "uppercase",
                backgroundColor: "#25C027",
              }}
            >
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Name
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                  Seats
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                 Slots
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Interval
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Resume database
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                 Live Chat function
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
         
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Key word Matching
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                 
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Featured Employer
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                 
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
                Price
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
             
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
            
                </Typography>
                <Typography
                  sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
                >
             
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {`${JSON.stringify(plans)}`} */}
            {plans.map((plan) => (
              <>              <TableRow
              key={plan?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">
                {plan?.displayName}
                </TableCell>
                <TableCell align="center">
                {plan.seats?plan.seats:""}
                </TableCell>
                <TableCell align="center">
                 {plan.jobSlots?plan.jobSlots:""}
                </TableCell>
                <TableCell align="center">
                {plan?.plan?.recurring?.interval_count}{" "}
                {plan?.plan?.recurring?.interval}
                </TableCell>
                <TableCell align="center">
                {plan.name =="STARTER"|| plan.name =="ENTERPRISE" ||plan.name =="PREMIUM" ? (
                    <PlansModalTickIcon />
                  ) :(
                    <PlansModalCrossIcon />
                  )  }
                </TableCell>
                <TableCell align="center">
                  {plan.name =="STARTER"|| plan.name =="ENTERPRISE" ||plan.name =="PREMIUM" ? (
                    <PlansModalTickIcon />
                  ) :(
                    <PlansModalCrossIcon />
                  )  }
                </TableCell>
                <TableCell align="center">
                {plan.name =="ENTERPRISE" ||plan.name =="PREMIUM" ? (
                    <PlansModalTickIcon />
                  ) :(
                    <PlansModalCrossIcon />
                  )  }
                </TableCell>
                <TableCell align="center">
                {plan.name =="ENTERPRISE"  ? (
                    <PlansModalTickIcon />
                  ) :(
                    <PlansModalCrossIcon />
                  )  }
                </TableCell>
                <TableCell align="center">
                {plan?.plan?.unit_amount/100}$
                </TableCell>
                <TableCell align="center">
                <Box sx={{ ...flexCenter, width: "100%", mt: "20px" }}>
      </Box>
                </TableCell>
              </TableRow>
              </>
 ))}

          </TableBody>
        </Table>
      </TableContainer>

      
    </Box>
          </Box>
        </Box>
    </Box>

      <Footer />
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
      <Modal
        open={loadingError}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
             background:"white"
          }}
          justifyContent={"center"}
          alignItems="center"
        >
         <ErrorPage></ErrorPage>
        </Stack>
      </Modal>
    </Box>
  );
};

export default Pricing;

{/* <Container sx={{ my: "60px" }}>
  <Box sx={{ backdropColor: "#fff", px: "50px" }}>
  <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        textAlign: "center",
        color: "#24BD2C",
      }}
    >
      <Box
        className={
          subscription === "monthly"
            ? `${classes.subs_active}`
            : `${classes.subs_inactive}`
          }
        onClick={() => subscriptionTab("monthly")}
        sx={{ width: "100%", cursor: "pointer" }}
      >
        <Typography>Montly Subscription</Typography>
      </Box>
      <Box
        className={
          subscription === "quarterly"
          ? `${classes.subs_active}`
          : `${classes.subs_inactive}`
        }
        onClick={() => subscriptionTab("quarterly")}
        sx={{ width: "100%", cursor: "pointer" }}
      >
        <Typography>Quarterly Subscription</Typography>
      </Box>
      <Box
        className={
          subscription === "additional"
            ? `${classes.subs_active}`
            : `${classes.subs_inactive}`
        }
        onClick={() => subscriptionTab("additional")}
        sx={{ width: "100%", cursor: "pointer" }}
      >
        <Typography>Additional</Typography>
      </Box>
    </Box>
  </Box>

  <Box sx={{ textAlign: "center", mt: "70px" }}>
    <Typography
      sx={{
        color: "#202124",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Sofia Pro",
      }}
    >
      Save up to 10%
    </Typography>

    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        mt: "10px",
      }}
    >
      <Typography
        sx={{
          color: "#202124",
          fontSize: "15px",
          fontFamily: "Sofia Pro",
        }}
      >
        Monthly
      </Typography>
      <FormControlLabel
        control={<IOSSwitch sx={{ marginLeft: 3 }} defaultChecked />}
        />
      <Typography
        sx={{
          color: "#202124",
          fontSize: "15px",
          fontFamily: "Sofia Pro",
        }}
      >
      AnnualSave
      </Typography>
    </Box>
  </Box>

  <Box>
    {subscription === "monthly" && <MonthlySubscription />}
    {subscription === "quarterly" && <QuarterlySubscription />}
    {subscription === "additional" && <AdditionalSubsctiption />}
  </Box>
</Container> */}
{/* <Container sx={{ my: "60px" }}>
  <Box sx={{ backdropColor: "#fff", px: "50px" }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        textAlign: "center",
        color: "#24BD2C",
      }}
    >
    <Box
        className={
          subscription === "monthly"
            ? `${classes.subs_active}`
            : `${classes.subs_inactive}`
        }
        onClick={() => subscriptionTab("monthly")}
        sx={{ width: "100%", cursor: "pointer" }}
      >
        <Typography>Montly Subscription</Typography>
      </Box>
      <Box
        className={
          subscription === "quarterly"
            ? `${classes.subs_active}`
            : `${classes.subs_inactive}`
        }
        onClick={() => subscriptionTab("quarterly")}
        sx={{ width: "100%", cursor: "pointer" }}
      >
      <Typography>Quarterly Subscription</Typography>
      </Box>
      <Box
      className={
          subscription === "additional"
            ? `${classes.subs_active}`
            : `${classes.subs_inactive}`
        }
        onClick={() => subscriptionTab("additional")}
        sx={{ width: "100%", cursor: "pointer" }}
      >
        <Typography>Additional</Typography>
      </Box>
    </Box>
    </Box>

  <Box sx={{ textAlign: "center", mt: "70px" }}>
    <Typography
      sx={{
        color: "#202124",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Sofia Pro",
      }}
    >
      Save up to 10%
    </Typography>

    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        mt: "10px",
      }}
    >
      <Typography
        sx={{
          color: "#202124",
          fontSize: "15px",
          fontFamily: "Sofia Pro",
        }}
        >
        Monthly
      </Typography>
      <FormControlLabel
        control={<IOSSwitch sx={{ marginLeft: 3 }} defaultChecked />}
      />
      <Typography
        sx={{
          color: "#202124",
          fontSize: "15px",
          fontFamily: "Sofia Pro",
        }}
      >
      AnnualSave
      </Typography>
    </Box>
  </Box>

  <Box>
    {subscription === "monthly" && <MonthlySubscription />}
    {subscription === "quarterly" && <QuarterlySubscription />}
    {subscription === "additional" && <AdditionalSubsctiption />}
  </Box>
</Container> */}
{/* <Container sx={{ my: "60px" }}>
  <Box sx={{ backdropColor: "#fff", px: "50px" }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        textAlign: "center",
        color: "#24BD2C",
      }}
    >
      <Box
        className={
          subscription === "monthly"
            ? `${classes.subs_active}`
            : `${classes.subs_inactive}`
        }
        onClick={() => subscriptionTab("monthly")}
        sx={{ width: "100%", cursor: "pointer" }}
      >
        <Typography>Montly Subscription</Typography>
      </Box>
      <Box
        className={
          subscription === "quarterly"
          ? `${classes.subs_active}`
            : `${classes.subs_inactive}`
          }
        onClick={() => subscriptionTab("quarterly")}
        sx={{ width: "100%", cursor: "pointer" }}
        >
        <Typography>Quarterly Subscription</Typography>
      </Box>
      <Box
        className={
          subscription === "additional"
            ? `${classes.subs_active}`
            : `${classes.subs_inactive}`
        }
        onClick={() => subscriptionTab("additional")}
        sx={{ width: "100%", cursor: "pointer" }}
      >
        <Typography>Additional</Typography>
      </Box>
    </Box>
    </Box>

  <Box sx={{ textAlign: "center", mt: "70px" }}>
    <Typography
      sx={{
        color: "#202124",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Sofia Pro",
      }}
    >
      Save up to 10%
    </Typography>

    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        mt: "10px",
      }}
      >
      <Typography
        sx={{
          color: "#202124",
          fontSize: "15px",
          fontFamily: "Sofia Pro",
        }}
      >
        Monthly
        </Typography>
      <FormControlLabel
        control={<IOSSwitch sx={{ marginLeft: 3 }} defaultChecked />}
      />
      <Typography
        sx={{
          color: "#202124",
          fontSize: "15px",
          fontFamily: "Sofia Pro",
        }}
        >
        AnnualSave
        </Typography>
        </Box>
        </Box>
        
        <Box>
    {subscription === "monthly" && <MonthlySubscription />}
    {subscription === "quarterly" && <QuarterlySubscription />}
    {subscription === "additional" && <AdditionalSubsctiption />}
  </Box>
</Container> */}
    {/* <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        color: "#FFF",
        backgroundColor: "#25C027",
      }}
    >
      <TableCell
        component="th"
        scope="row"
        align="center"
        sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
      >
        Price
      </TableCell>
      <TableCell
        align="center"
        sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
      >
        $350
      </TableCell>
      <TableCell
        align="center"
        sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
      >
        $350
      </TableCell>
      <TableCell
        align="center"
        sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
      >
        $350
      </TableCell>
      <TableCell
        align="center"
        sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
      >
        $350
      </TableCell>
      <TableCell
        align="center"
        sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
      >
        $350
      </TableCell>
      <TableCell
        align="center"
        sx={{ color: "#FFF", fontSize: "10px", fontWeight: 600 }}
      >
        $350
      </TableCell>
    </TableRow> */}
    {/* <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row" align="center"></TableCell>
      <TableCell>
        <FormControlLabel
          sx={{ ...flexCenter, ml: "3%" }}
          control={
            <Checkbox
              sx={{
                color: "#25C02780",
                "&.Mui-checked": {
                  color: "#25C02780",
                },
              }}
            />
          }
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          sx={{ ...flexCenter, ml: "3%" }}
          control={
            <Checkbox
              sx={{
                color: "#25C02780",
                "&.Mui-checked": {
                  color: "#25C02780",
                },
              }}
            />
          }
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          sx={{ ...flexCenter, ml: "3%" }}
          control={
            <Checkbox
              sx={{
                color: "#25C02780",
                "&.Mui-checked": {
                  color: "#25C02780",
                },
              }}
            />
          }
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          sx={{ ...flexCenter, ml: "3%" }}
          control={
            <Checkbox
              sx={{
                color: "#25C02780",
                "&.Mui-checked": {
                  color: "#25C02780",
                },
              }}
            />
          }
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          sx={{ ...flexCenter, ml: "3%" }}
          control={
            <Checkbox
              sx={{
                color: "#25C02780",
                "&.Mui-checked": {
                  color: "#25C02780",
                },
              }}
            />
          }
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          sx={{ ...flexCenter, ml: "3%" }}
          control={
            <Checkbox
              sx={{
                color: "#25C02780",
                "&.Mui-checked": {
                  color: "#25C02780",
                },
              }}
            />
          }
        />
      </TableCell>
    </TableRow> */}