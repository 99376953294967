import { Box, Button,TextField, Container, Stack, Typography,Modal } from "@mui/material";
import classes from "./SuperAdminDashboardContactUsDetails.module.css";
import { useForm } from "react-hook-form";
import { useState ,useEffect} from "react";
import { toast, ToastContainer } from "react-toastify";
import { addContactUs,getContactUs} from "../../firebase/functions";
import { LoadingButton } from "@mui/lab";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { db } from "../../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
const SuperAdminDashboardContactUsDetails = () => {
  const [flag , setflag] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const [userTermsFromFirebase , setUserTermsFromFirebase] = useState([]);
  useEffect(() => {
    getTagsFromFirebase();
  }, [flag])

  const getTagsFromFirebase =async () => {
    setLoading(true);
    // getContactUs({ docName: "contactUs" }).then(({data}) => {
    //   console.log(data);
    //   reset({...data})
    //   setUserTermsFromFirebase(data);
    //   setLoading(false)
    // }).catch((e)=>{
    //   setLoading(false);
    //   toast.error(e.message);
    // })
    const usercollectionRef = doc(db,`superAdmin/contactUs`);
    const docSnap = await getDoc(usercollectionRef);
    if (docSnap.exists()) {
      //setAdminData(docSnap.data());
      reset({ ...docSnap.data() });
      setLoading(false);
    } else {
      //! doc.data() will be undefined in this case
      console.log("No such document!");
      setLoading(false);
      toast.error("Something went worng");
    }
  };
  const onSubmit = (data) => {console.log("data", data);
  setLoading(true);
  addContactUs(data).then((response) => {
    console.log(response);
    toast.success("Field Added", {
      onClose: () => {
        setflag(flag + 1);
      },
      autoClose: 1000
    })
      setLoading(false);
    
  }).catch((e)=>{
    setLoading(false);
    toast.error(e.message);
  })
};
  return (
    <Container>
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Contact Detail
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "60px",
          padding: "30px",
          background: "#FFFFFF",
          boxShadow: "0px 2px 20px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
        }}
      >
        <Typography
          sx={{
            color: "#202124",
            fontFamily: "Sofia Pro",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Contact Detail
        </Typography>
        <form Validate onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} sx={{ mt: "30px" }}>
            <Box 
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <Box className={classes.input} sx={{ width: "50%" }}>
                <label>Email</label>
                <TextField type="email"   {...register('email', { required: "Email is required" ,pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  }, })}    error={Boolean(errors.email)}
             helperText={errors.email&&errors.email.message} placeholder="limitation@gmail.com" />
              </Box>
              <Box className={classes.input}  sx={{ width: "50%" }}>
                <label>Phone No</label>
                <TextField type="number" {...register('phoneNo', { required: "Phone No is required", 
                
                minLength:{
                  value:10,
                  message: "Phone no is not valid",
                },
                maxLength:{
                  value:12,
                  message:"Phone no is not valid",
               
              },} )} 
              
              error={Boolean(errors.phoneNo)}
             helperText={errors.phoneNo&&errors.phoneNo.message} placeholder="00000000" />
              </Box>
            </Box>
            <Box   sx={{ width: "100%" }}>
              <label>Address</label>
               {/*<TextField  multiline type="text"    {...register('address', { required: "Address is required"} )}  
              
              error={Boolean(errors.address)}
             helperText={errors.address&&errors.address.message}  />*/}
              <TextField
              id="outlined-basic"
             // label="Write your Address"
              variant="outlined"
              color="success"
              multiline
              {...register('address', { required: "Address is required"} )} 
              rows={5}
              sx={{
                width: "100%",
                mt: "10px",
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
             error={Boolean(errors.address)}
             helperText={errors.address&&errors.address.message}
            />
            </Box>
            <Box>
              <LoadingButton
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
                
                type= "submit"
                loading={loading}
              >
                Update
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Container>
  );
};

export default SuperAdminDashboardContactUsDetails;
