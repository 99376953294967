import {
  Box,
  Button,
  Chip,
  Container, Skeleton, Stack,
  Typography,Modal
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { getJobOverview, getOrgCard } from "../../firebase/functions";
import { sdkfireBaseTime } from "../../Helpers/SdkTimeConvert";

import {
  FeturedJobsClockIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
  FeturedJobsMoneyIcon,
  WhiteFacebookIcon,
  WhiteTwitterIcon
} from "../../utilities/Icons/Icons";
import InstantMessageFeture from "../Home/InstantMessageFeture/InstantMessageFeture";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import ApplyButton from "../../ApplyJob/ApplyButton";
import { displayOnDesktop, displayOnMobile } from "../../utilities/commonStyles/commonStyles";
import { OfferStatus } from "../EmployeeDashboardComponent/ReusableComponents/OfferStatus";
import InstantFeatureMessageMobile from "../Home/InstantMessageFeture/InstantFeatureMessageMobile";
import OrgCard from "./OrgCard";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";

const JobProfileComponents = ({ jobId }) => {
  TimeAgo.addDefaultLocale(en);
  TimeAgo.addLocale(ru);
  const [jobData, setJobData] = useState({});
  const [loading, setloading] = useState(false);
  const [orgInfo, setOrgInfo] = useState({});
  const [url, setUrl] = useState("")
  const [title, setTitle] = useState("")
  useEffect(() => {
    console.log("CHANGE");

    setloading(true);
    // getJobOverview({ jobId: jobId }).then(({ data }) => {
    //   setJobData(data);
    //   console.log(data)
    //   window.scrollTo(0, 0);
    //   setloading(false);
    //   const title = data.organisationName+" is hiring for the position of "+data.jobTitle+" in "+data.city+" | View Here \n\n";
    //   setTitle(title)
    // }).catch((e)=>{
    //   setloading(false);
    //   toast.error(e.message);
    // })
    getJobDataById();
    setUrl("https://Mfgworx.com/job-profile/"+jobId);
  }, [jobId]);

  const getJobDataById = async () => {
    const usercollectionRef = doc(db, `Jobs/${jobId}`);
    const docSnap = await getDoc(usercollectionRef);
    if (docSnap.exists()) {
      const extraRef = doc(db, `Jobs/${jobId}/extraInfo/infoDoc`);
      const docSnap1 = await getDoc(extraRef);
      if (docSnap1.exists()) {
        console.log(docSnap.data());
        console.log(docSnap1.data());
        setJobData({ ...docSnap.data(), ...docSnap1.data() });
        window.scrollTo(0, 0);
         const title =
           docSnap.data().organisationName +
           " is hiring for the position of " +
           docSnap.data().jobTitle +
           " in " +
           docSnap.data().city +
           " | View Here \n\n";
         setTitle(title);
        setloading(false);
        const tempOrgId = docSnap.data().organisationId
         getOrgCard({ orgId: tempOrgId })
           .then(({ data }) => {
             console.log("haha", data);
             setOrgInfo(data);
           })
           .catch((err) => {
             console.log("haha", err);
           });
      } else {
        //! doc.data() will be undefined in this case
        console.log("No such document!");
        setloading(false);
        toast.error("job not found");
      }
    } else {
      //! doc.data() will be undefined in this case
      console.log("No such document!");
      setloading(false);
      toast.error("Job not found");
    }
  };
  const share = () =>{
    navigator.share({
      url:url,
      text:title,
      title:"Job Alerts",
      file:"../../utilities/images/mfglogo.png"
    })
  }
  return (
    <>
      <Container>
        {/* {JSON.stringify(jobData)} */}
        <Box sx={{ display: "flex", my: "60px", gap: "40px" }}>
          <Box sx={{ width: "70%" }}>
            <Box>
              <Box>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "26px",
                    fontWeight: 500,
                  }}
                >
                  {jobData.jobTitle}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    my: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FeturedJobsFordIcon />
                    <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                      {jobData?.organisationName}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FeturedJobsLocationIcon />
                    <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                      {jobData?.city}, {jobData?.state}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FeturedJobsClockIcon />
                    <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                      {jobData?.jobAddedAt ? (
                        <ReactTimeAgo
                          date={sdkfireBaseTime(
                            jobData?.jobAddedAt
                          ).toISOString()}
                        />
                      ) : null}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FeturedJobsMoneyIcon />
                    <Typography sx={{ color: "#696969", fontSize: "14px" }}>
                      {` $${jobData?.minSalary} - $${jobData?.maxSalary}/${jobData?.salaryType}`}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    mt: "15px",
                  }}
                >
                  {jobData?.jobTags?.map((item, key) => {
                    return (
                      <Box>
                        <Typography
                          sx={{
                            color: "#1967D2",
                            background: "#eee",
                            width: "90px",
                            height: "25",
                            padding: "5px 15px",
                            fontSize: "13px",
                            borderRadius: "60px",
                            textAlign: "center",
                          }}
                        >
                          {item}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>

              <Stack
                spacing={3}
                sx={{
                  width: "100%",
                  padding: "30px",
                  my: "30px",
                  boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#202124",
                      fontSize: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Job Overview
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Stack spacing={2}>
                    {/* <Box sx={{ display: "flex", gap: "20px" }}>
                    <Box>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
                          fill="#25C027"
                        />
                      </svg>
                    </Box>
                    <Box>
                      <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                        Job Title:
                      </Typography>
                      <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                        {jobData?.jobTitle}
                      </Typography>
                    </Box>
                  </Box> */}

                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
                            fill="#25C027"
                          />
                        </svg>
                      </Box>
                      <Box>
                        <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                          Date Posted:
                        </Typography>
                        <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                          {jobData?.jobAddedAt ? (
                            <ReactTimeAgo
                              date={sdkfireBaseTime(
                                jobData?.jobAddedAt
                              ).toISOString()}
                            />
                          ) : null}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 2.125C0 0.952 0.8 0 1.78571 0H15.3571C16.3429 0 17.1429 0.952 17.1429 2.125V11.475C17.1429 12.648 16.3429 13.6 15.3571 13.6H1.78571C0.8 13.6 0 12.648 0 11.475V2.125ZM4.28571 2.55V1.7H2.85714V2.55C2.85714 2.77543 2.78189 2.99163 2.64793 3.15104C2.51398 3.31045 2.3323 3.4 2.14286 3.4H1.42857V5.1H2.14286C2.71118 5.1 3.25622 4.83134 3.65809 4.35312C4.05995 3.8749 4.28571 3.2263 4.28571 2.55ZM11.4286 6.8C11.4286 5.89826 11.1276 5.03346 10.5917 4.39584C10.0559 3.75821 9.32919 3.4 8.57143 3.4C7.81367 3.4 7.08694 3.75821 6.55112 4.39584C6.01531 5.03346 5.71429 5.89826 5.71429 6.8C5.71429 7.70174 6.01531 8.56654 6.55112 9.20416C7.08694 9.84179 7.81367 10.2 8.57143 10.2C9.32919 10.2 10.0559 9.84179 10.5917 9.20416C11.1276 8.56654 11.4286 7.70174 11.4286 6.8ZM14.2857 1.7H12.8571V2.55C12.8571 3.2263 13.0829 3.8749 13.4848 4.35312C13.8866 4.83134 14.4317 5.1 15 5.1H15.7143V3.4H15C14.8106 3.4 14.6289 3.31045 14.4949 3.15104C14.361 2.99163 14.2857 2.77543 14.2857 2.55V1.7ZM4.28571 11.05C4.28571 10.3737 4.05995 9.7251 3.65809 9.24688C3.25622 8.76866 2.71118 8.5 2.14286 8.5H1.42857V10.2H2.14286C2.3323 10.2 2.51398 10.2896 2.64793 10.449C2.78189 10.6084 2.85714 10.8246 2.85714 11.05V11.9H4.28571V11.05ZM14.2857 11.9V11.05C14.2857 10.8246 14.361 10.6084 14.4949 10.449C14.6289 10.2896 14.8106 10.2 15 10.2H15.7143V8.5H15C14.4317 8.5 13.8866 8.76866 13.4848 9.24688C13.0829 9.7251 12.8571 10.3737 12.8571 11.05V11.9H14.2857ZM5 17C4.54746 17.0002 4.10647 16.8299 3.74026 16.5135C3.37405 16.1971 3.10142 15.7509 2.96143 15.2388C3.16 15.2796 3.36286 15.3 3.57143 15.3H15.3571C16.2096 15.3 17.0272 14.897 17.63 14.1797C18.2328 13.4624 18.5714 12.4895 18.5714 11.475V3.5445C18.9894 3.72035 19.3512 4.04614 19.6071 4.47697C19.863 4.90779 20.0002 5.42245 20 5.95V11.475C20 12.2006 19.8799 12.919 19.6466 13.5893C19.4133 14.2597 19.0713 14.8687 18.6401 15.3818C18.209 15.8948 17.6972 16.3018 17.1339 16.5794C16.5706 16.8571 15.9669 17 15.3571 17H5Z"
                            fill="#25C027"
                          />
                        </svg>
                      </Box>
                      <Box>
                        <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                          Salary:
                        </Typography>
                        <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                          {` $${jobData?.minSalary} - $${jobData?.maxSalary}/${jobData?.salaryType}`}
                        </Typography>
                      </Box>
                    </Box>

                    {/* <Box sx={{ display: "flex", gap: "20px" }}>
                    <Box>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
                          fill="#25C027"
                        />
                      </svg>
                    </Box>
                    <Box>
                      <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                        Rate:
                      </Typography>
                      <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                        $15 - $25 / hour
                      </Typography>
                    </Box>
                  </Box> */}
                  </Stack>

                  <Stack spacing={2}>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 17 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 0C6.24648 0.00270323 4.08602 0.914156 2.49253 2.53442C0.899053 4.15468 0.00266759 6.35146 9.04313e-06 8.64286C-0.00269001 10.5154 0.598857 12.3371 1.71237 13.8286C1.71237 13.8286 1.94419 14.1389 1.98205 14.1837L8.5 22L15.021 14.1798C15.055 14.1381 15.2876 13.8286 15.2876 13.8286L15.2884 13.8262C16.4014 12.3354 17.0026 10.5145 17 8.64286C16.9973 6.35146 16.1009 4.15468 14.5075 2.53442C12.914 0.914156 10.7535 0.00270323 8.5 0ZM8.5 11.7857C7.88868 11.7857 7.29108 11.6014 6.78278 11.256C6.27449 10.9107 5.87832 10.4199 5.64438 9.84558C5.41043 9.27129 5.34922 8.63937 5.46849 8.02972C5.58775 7.42006 5.88213 6.86006 6.3144 6.42052C6.74667 5.98098 7.29742 5.68166 7.89699 5.56039C8.49657 5.43912 9.11805 5.50136 9.68284 5.73924C10.2476 5.97711 10.7304 6.37994 11.07 6.89678C11.4096 7.41362 11.5909 8.02126 11.5909 8.64286C11.5899 9.47608 11.2639 10.2749 10.6845 10.864C10.105 11.4532 9.31945 11.7847 8.5 11.7857Z"
                            fill="#25C027"
                          />
                        </svg>
                      </Box>
                      <Box>
                        <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                          Location:
                        </Typography>
                        <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                          {jobData?.city}, {jobData?.state}
                        </Typography>
                      </Box>
                    </Box>
                    {orgInfo?.secondChanceEmployer ?
                      <Box sx={{ display: "flex", gap: "20px" }}>
                        <Box>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
                              fill="#25C027"
                            />
                          </svg>
                        </Box>
                        <Box sx={{ width: "14vw" }}>
                          <Typography
                            sx={{
                              overflowWrap: "break-word",
                              color: "#202124",
                              fontWeight: 500,
                            }}
                          >
                            We’re a second chance employer!
                          </Typography>
                        </Box>
                      </Box>
                      : <></>}
                    {/* <Box sx={{ display: "flex", gap: "20px" }}>
                    <Box>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
                          fill="#25C027"
                        />
                      </svg>
                    </Box>
                    <Box>
                      <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                        Hours:
                      </Typography>
                      <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                        50h / week
                      </Typography>
                    </Box>
                  </Box> */}
                  </Stack>
                  <Stack spacing={2}>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
                            fill="#25C027"
                          />
                        </svg>
                      </Box>
                      <Box>
                        <Typography sx={{ color: "#202124", fontWeight: 500 }}>
                          Job Title:
                        </Typography>
                        <Typography sx={{ color: "#696969", fontSize: "12px" }}>
                          {jobData?.jobTitle}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Box>

                <Box>
                  <ApplyButton text={"Apply"} id={jobId} />
                </Box>
              </Stack>
            </Box>

            <Stack spacing={2} sx={{ mt: "20px" }}>
              <Box>
                <Typography
                  sx={{
                    color: "#202124",
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Sofia Pro",
                  }}
                >
                  Job Description
                </Typography>
                <Typography
                  sx={{
                    mt: "10px",
                    color: "#696969",
                    fontSize: "15px",
                    fontFamily: "Sofia Pro",
                    marginLeft: "20px",
                  }}
                >
                  {jobData?.jobDescription}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#202124",
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Sofia Pro",
                  }}
                >
                  Key Responsiblities
                </Typography>
                <ul
                  style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                    lineHeight: "26px",
                  }}
                >
                  {jobData?.keyResponsibilities?.map((item) => {
                    return (
                      <li
                        style={{
                          color: "#696969",
                          fontSize: "15px",
                          fontFamily: "Sofia Pro",
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#202124",
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Sofia Pro",
                  }}
                >
                  Shift
                </Typography>
                <ul
                  style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                    lineHeight: "26px",
                  }}
                >
                  {jobData?.shift?.map((item) => {
                    return (
                      <li
                        style={{
                          color: "#696969",
                          fontSize: "15px",
                          fontFamily: "Sofia Pro",
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#202124",
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Sofia Pro",
                  }}
                >
                  Skill & Experience
                </Typography>
                <ul
                  style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                    lineHeight: "26px",
                  }}
                >
                  {/* skills  */}
                  <li>
                    {jobData?.skills?.map((label) => {
                      return (
                        <Chip
                          sx={{ marginRight: "10px", marginBottom: "10px" }}
                          label={label}
                        />
                      );
                    })}
                  </li>

                  {/* experience */}

                  {jobData?.skillsRequired?.map((skill, index) => {
                    return (
                      <li
                        style={{
                          color: "#696969",
                          fontSize: "15px",
                          fontFamily: "Sofia Pro",
                        }}
                      >
                        {skill}
                      </li>
                    );
                  })}
                </ul>
              </Box>
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                mt: "30px",
              }}
            >
              <Typography sx={{ fontFamily: "Sofia Pro", fontWeight: 500 }}>
                Share this job
              </Typography>

              <FacebookShareButton url={url} quote={title}>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontFamily: "Sofia Pro",
                    backgroundColor: "#3b5998",
                    color: "#fff",
                    padding: "14px 24px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#3b5998",
                      color: "#fff",
                    },
                  }}
                >
                  <WhiteFacebookIcon />{" "}
                  <Typography sx={{ ml: "10px" }}>Facebook</Typography>
                </Button>
              </FacebookShareButton>
              <TwitterShareButton url={url} title={title}>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontFamily: "Sofia Pro",
                    backgroundColor: "#00acee",
                    color: "#fff",
                    padding: "14px 24px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#00acee",
                      color: "#fff",
                    },
                  }}
                >
                  <WhiteTwitterIcon />{" "}
                  <Typography sx={{ ml: "10px" }}>Twitter</Typography>
                </Button>
              </TwitterShareButton>
              <LinkedinShareButton url={url} title={title}>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontFamily: "Sofia Pro",
                    backgroundColor: "#0A66C2",
                    color: "#fff",
                    padding: "14px 24px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#0A66C2",
                      color: "#fff",
                    },
                  }}
                >
                  <WhiteTwitterIcon />{" "}
                  <Typography sx={{ ml: "10px" }}>Linkedin</Typography>
                </Button>
              </LinkedinShareButton>
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontFamily: "Sofia Pro",
                  backgroundColor: "#ff0000",
                  color: "#fff",
                  padding: "14px 24px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#ff0f00",
                    color: "#fff",
                  },
                }}
                onClick={() => {
                  share();
                }}
              >
                <Typography>Other</Typography>
              </Button>
              <OfferStatus offerStatus={"applied"} />
            </Box>
          </Box>
          {jobData.organisationId ? (
            <OrgCard orgId={jobData?.organisationId} />
          ) : null}
        </Box>

        <Box sx={displayOnDesktop}>
          <InstantMessageFeture />
        </Box>
        <Box sx={displayOnMobile}>
          <InstantFeatureMessageMobile />
        </Box>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </>
  );
};

export default JobProfileComponents;
