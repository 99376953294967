import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React from "react";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";

const ErrorComponent = () => {
  return (
    <Container sx={{ my: "30px" }}>
      <Box sx={{...flexCenter, flexDirection:"column",  gap:"20px", textAlign: "center" }}>
      <LoadingButton  loadingIndicator={<CircularProgress size="1.5em" sx={{  color: "white" }} />}
              loading={true}
              sx={{
               width:"20vw",
               height:"10vw",
                textTransform: "none",
                fontSize: "50px",
                fontFamily: "",
                backgroundColor: "#24BD2C",
                color: "#fff",
                padding: "8px 17px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#24BD2C",
                },
              }} variant="outlined">
        Reload
      </LoadingButton>
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontSize: "48px",
              fontFamily: "Poppins",
            }}
          >
           Sorry ! something went wrong.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default ErrorComponent;

