import {
  Box,
  CircularProgress,
  Container,
  Switch,
  Typography,Modal,Stack
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  listAllEmployees,
  resetPassword,
  toggleEmployeeStatus
} from "../../../firebase/functions";
import {
  flexBetweenWithItemsCenter,
  itemsCenter
} from "../../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import InviteUsersModalComponent from "./InviteUsersModalComponent";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function Usertable({ seat }) {
  const [users, setUsers] = React.useState([]);
  const [loadingAbout , setLoadingAbout] = useState(false);
  const [userData, setUserData] = React.useState([]);
  const [toggle, setToggle] = React.useState(false);
  const [loading, setLoading] = React.useState({});
  useEffect(() => {
    setLoadingAbout(true);
    listAllEmployees().then(({ data }) => {
      console.log(data);
      setUsers(data);
      setLoadingAbout(false);
    }).catch((e)=>{
      setLoadingAbout(false);
      toast.error(e.message);
    })
  }, [toggle]);
  const toggleStatus = (user) => {
    setLoading({ loading, [user.uid]: true });
    toggleEmployeeStatus({ uid: user.id })
      .then(({ data }) => {
        setLoading({ ...loading, [user.uid]: false });
        toast.success(data.message);
        setToggle(!toggle);
      })
      .catch((e) => {
        setLoading({ ...loading, [user.uid]: false });
        toast.error(e.message);
      });
  };
  const handleResetPassword = (user) => {
    console.log(user);
    resetPassword({ email: user.email })
      .then((res) => {
        toast.success("Password reset link sent to user's email");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  return (
    <Container sx={{ mt: "30px"}}>
      <Box sx={{ ...flexBetweenWithItemsCenter }}>
        <Box sx={{ ...itemsCenter, gap: "10px" }}>
          {/* <Typography
            sx={{
              color: "#FFF",
              backgroundColor: "#25C027",
              padding: "10px 20px",
              borderRadius: "10px",
            }}
          >
            {`${users?.length}/${seat?.totalSeats}`}
          </Typography>
          <Typography>Seats used</Typography> */}
        </Box>

        <Box>
          <InviteUsersModalComponent />
        </Box>

        {/*         <Typography
          sx={{
            mt: "10px",
            mb: "10px",
          }}
          variant="h4"
        >
          {`${
            seat?.totalSeats -
            users?.filter((user) => {
              return user.isActive === true;
            }).length
          }`}{" "}
          Seats remaining
        </Typography>
        <Typography
          sx={{
            mt: "10px",
            mb: "10px",
          }}
          variant="4"
        >
          {`${
            users?.filter((user) => {
              return user.isActive === true;
            }).length
          }/${users.length}`}{" "}
          Active Users
        </Typography> */}
      </Box>
      <TableContainer
        sx={{
          borderRadius: "20px",
          boxShadow: 1,
          mt: "20px",
          maxWidth: "100%",
        }}
      >
        <Table sx={{}} aria-label="simple table">
          <TableHead sx={{ color: "#FFF", backgroundColor: "#25C027" }}>
            <TableRow>
              <TableCell sx={{ color: "#FFF" }}>First name</TableCell>
              <TableCell align="center" sx={{ color: "#FFF" }}>
                Last name
              </TableCell>
              {/* <TableCell align="center">Email </TableCell> */}
              <TableCell align="center" sx={{ color: "#FFF" }}>
                Jobs posted
              </TableCell>
              <TableCell align="center" sx={{ color: "#FFF" }}>
                Status
              </TableCell>
              {/* <TableCell align="center">Reset Password</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => {
              console.log(user);
              return (
                <TableRow
                  key={user.firstName}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {user.firstName}
                  </TableCell>
                  <TableCell align="center">{user.lastName}</TableCell>
                  {/* <TableCell align="center">{user.email}</TableCell> */}
                  {/* <TableCell align="center">{user?.addedOn}</TableCell> */}
                  <TableCell align="center">{user?.jobsAdded}</TableCell>
                  <TableCell align="center">
                    {!user.isAdmin ? (
                      loading[user.uid] ? (
                        <>
                          <CircularProgress
                            sx={{
                              width: "10px",
                              height: "10px",
                            }}
                          />
                        </>
                      ) : (
                        <Switch
                          onClick={() => toggleStatus(user)}
                          checked={user.isActive}
                        />
                      )
                    ) : (
                      <Typography>ADMIN</Typography>
                    )}
                  </TableCell>
                  {/* <TableCell align="center">
                    <Button
                      onClick={() => handleResetPassword(user)}
                      size="small"
                    >
                      Reset Password
                    </Button>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Container>
  );
}

export default Usertable;
