import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";

import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { getCandidateEdu } from "../../firebase/functions";

const CandidateProfileWorksExperience = ({ applicantId }) => {
  const [work, setWork] = React.useState([]);
  useEffect(() => {
    getCandidateEdu({ applicantId }).then(({ data }) => {
      setWork(data);
      
    });
  }, [applicantId]);
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            color: "#202124",
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Sofia Pro",
          }}
        >
          Work & Experience
        </Typography>
      </Box>
      <Timeline className={"works-timeline"}>
        {work.map((work, index) => {
          return (
            <TimelineItem>
              <TimelineSeparator className={"works-separator_padding"}>
                <TimelineDot className={"works-timeline_dot"}>
                  <Typography
                    sx={{
                      fontFamily: "Sofia Pro",
                      fontWight: 700,
                      fontSize: "11px",
                      color: "#1967D2",
                    }}
                  >
                    {work?.institution ? work.institution.substring(0, 1) : ""}
                  </Typography>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {work?.designation}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1967D2",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {work?.institution}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        display: "inline-block",
                        background: "#DDE8F8",
                        color: "#1967D2",
                        padding: "5px 20px",
                        borderRadius: "20px",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {`${work?.startYear} - ${work?.endYear}`}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ py: "50px" }}>
                  <Typography>{work?.description}</Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default CandidateProfileWorksExperience;
