import { Box, Container, Typography,Modal,Stack, Badge } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { itemsCenter } from "../../utilities/commonStyles/commonStyles";
import {
  CandidateDashboardJobInvitesIcons,
  EmployeeDashboardAllApplicantsIcon,
  EmployeeDashboardChangePasswordIcon,
  EmployeeDashboardCompanyProfileIcon, EmployeeDashboardIcon,
  EmployeeDashboardLogoutIcon,
  EmployeeDashboardManageJobsIcon,
  EmployeeDashboardMessageIcon,
  EmployeeDashboardPaymentIcons,
  EmployeeDashboardPostNewJobIcon,
  EmployeeDashboardResumeAlertsIcon
} from "../../utilities/Icons/Icons";
import classes from "./EmployeeDashboardSideNavbar.module.css";
import { useState } from "react";
import { logOut } from "../../firebase/firebaseConfig";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { AllAplicantCount, AllUnreadMessageCount, fetchOrgNotifs, getUserType } from "../../firebase/functions";
import { useEffect } from "react";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
const EmployeeDashboardSideNavbarMobile = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [allApplicantNotificationCount, setAllApplicantsNotificationCount] =
    useState(0);
  const [allNotificationCount, setAllNotificationCount] = useState(0);
  const [acceptInvitaionCount, setAcceptInvitaionCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const handleLogout = () => {
    setloading(true);
    logOut().then(() => {
      setloading(false);
      navigate("/");
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
  };
  const checkUser = async()=>{
    const type = await getUserType();
    if (type.data.role == "user") {
      navigate("/");
    }
    if (type.data.role == "Admin") {
      navigate("/super-admin-dashboard/dashboard");
    }
  }
  useEffect(() => {
    checkUser();
    checkLogin();
    AllAplicantCount({})
      .then((response) => {
        console.log("Alll Aplicant Cou" + response);
        console.log(response);
        setAllApplicantsNotificationCount(response.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
    AllUnreadMessageCount({})
      .then((response) => {
        console.log("Alll Unread Message Notification" + response);
        console.log(response);
        if (Array.isArray(response.data)) {
          setUnreadMessageCount(response.data.length);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
    fetchOrgNotifs({})
      .then((response) => {
        console.log("hello mr. bean");
        if (Array.isArray(response.data)) {
          console.log(response.data);
          let tempNotificationCount = 0;
          let tempAcceptInvitaion = 0;
          for (let i = 0; i < response.data.length; i++) {
            if (!response.data[i].read) {
              tempNotificationCount++;
            }
            if (
              response.data[i].title == "Job Invitation Accepted" &&
              !response.data[i].read
            ) {
              tempAcceptInvitaion++;
            }
          }
          setAllNotificationCount(tempNotificationCount);
          setAcceptInvitaionCount(tempAcceptInvitaion);
        }
        console.log(response);
      })
      .catch((e) => {
        console.error(e.message);
      });
  }, []);
  const checkLogin=()=>{
    const auth = getAuth();
    auth.onAuthStateChanged((user)=> {
    if (user) {
    } else {
      navigate("/");
    }
   });
   }
  return (
    <Box
      sx={{
        height: "90vh",
        position: "sticky",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
      }}
    >
      <Container
        sx={{ ...itemsCenter, backgroundColor: "#25C027", py: "20px" }}
      >
        <Box>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
              fill="white"
            />
          </svg>
        </Box>
        <Typography
          sx={{
            width: "90%",
            textAlign: "center",
            color: "#FFF",
            fontSize: "20px",
          }}
        >
          Menu
        </Typography>
      </Container>
      <Box
        className={classes.sidebar}
        sx={{ boxShadow: 1, minHeight: "100%", padding: "20px" }}
      >
        <Box>
          <NavLink
            to="dashboard"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Dashboard
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="company-profile"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardCompanyProfileIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Company Profile
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to="manage-seats"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardManageJobsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Manage Seats
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="manage-payment"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardPaymentIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Manage Payment
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="post-new-job"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardPostNewJobIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Post a New Job
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="manage-jobs"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardManageJobsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Manage Jobs
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="all-applicants"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardAllApplicantsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                All Applicants
              </Typography>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={allApplicantNotificationCount}
              ></Badge>
            </Box>
          </NavLink>

          <NavLink
            to="invite-applicants"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <CandidateDashboardJobInvitesIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Invited Applicants
              </Typography>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={acceptInvitaionCount}
              ></Badge>
            </Box>
          </NavLink>

          {/* <NavLink
            to="shortlisted-resumes"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardShortlistedResumeIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Shortlisted Resumes
              </Typography>
            </Box>
          </NavLink> */}

          <NavLink
            to="message"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardMessageIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Message
              </Typography>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={unreadMessageCount}
              ></Badge>
            </Box>
          </NavLink>

          <NavLink
            to="notification"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardResumeAlertsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Notification
              </Typography>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={allNotificationCount}
              ></Badge>
            </Box>
          </NavLink>

          <NavLink
            to="change-password"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardChangePasswordIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Change Password
              </Typography>
            </Box>
          </NavLink>
          <Box
            onClick={handleLogout}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "15px",
              gap: "15px",
              backgroundColor: "#fff",
              cursor: "pointer",
            }}
          >
            <EmployeeDashboardLogoutIcon />
            <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
              Logout
            </Typography>
          </Box>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "15px",
              gap: "15px",
              backgroundColor: "#fff",
              cursor: "pointer",
            }}
          >
            <EmployeeDashboardDeleteProfileIcon />
            <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
              Delete Profile
            </Typography>
          </Box> */}
        </Box>
        <Modal
          open={loading}
          // onClose={}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack
            border={5}
            sx={{
              width: "100%",
              height: "100vh",
            }}
            justifyContent={"center"}
            alignItems="center"
          >
            <LoadingSearchComponents />
          </Stack>
        </Modal>
      </Box>
    </Box>
  );
};

export default EmployeeDashboardSideNavbarMobile;
