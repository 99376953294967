import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  collectionGroup,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import * as React from "react";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { GetType } from "../../context/authContext";
import { auth, db } from "../../firebase/firebaseConfig";
import {
  AllUnreadMessageCountCandidates,
  sendChat,
  SetMessageAsReadCand,
} from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  flexBetweenWithItemsCenter,
} from "../../utilities/commonStyles/commonStyles";
import { MessangerSearchIcon } from "../../utilities/Icons/Icons";
import MessageDefaultScreen from "../SharedComponents/MessageDefaultScreen/MessageDefaultScreen";
import classes from "../CandidateDashboard/CandidateDashboardMessage.module.css"
import { toast } from "react-toastify";

const useStyles = makeStyles({
  drawerPaper: {
    borderRadius: "20px 0 0 20px",
  },
});

const TeamDashboardMessage = () => {
  const myClasses = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  TimeAgo.addDefaultLocale(en);
  TimeAgo.addLocale(ru);
  const userType = GetType();
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const [userData, setUserData] = useState({});
  const [userImage, setUserImage] = useState({});
  const [organisationId, setOrganisationId] = useState([]);
  const [organisationIdCopy, setOrganisationIdCopy] = useState([]);
  const [organisationName, setOrganisationName] = useState("");
  const [organisation, setOrganisation] = useState({});
  const [overViewId, setOverViewId] = useState(-1);
  const [AllUnread, setAllUnread] = useState([]);
  useEffect(() => {
    AllUnreadMessageCountCandidates({})
      .then((response) => {
        console.log("Alll Unread Message Notification" + response);
        console.log(response);
        if (Array.isArray(response.data)) {
          //   setUnreadMessageCount(response.data.length);
          let arr = [];
          for (var i = 0; i < response.data.length; i++) {
            arr.push(response.data[i].orgId);
          }
          console.log("Please check it");
          console.log(arr);
          setAllUnread(arr);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);
  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSearchChange = (event) => {
    setOrganisationName(event.target.value);
    const oName = event.target.value;
    let searchOrg = [];
    for (let i = 0; i < organisationIdCopy.length; i++) {
      if (
        organisationIdCopy[i].name.toLowerCase().includes(oName.toLowerCase())
      ) {
        searchOrg.push(organisationIdCopy[i]);
      }
    }
    setOrganisationId(searchOrg);
  };
  const [loading, setloading] = useState(false);
  const handleSubmit = (event) => {
    setloading(true);
    sendMessage();
    console.log(auth.currentUser.displayName);
  };

  const sendMessage = async () => {
    console.log("scknf");
    sendChat({
      organisation: organisation.id,
      applicant: userData,
      direction: "inbound",
      message: message,
    })
      .then(() => {
        console.log("Chat Sent");
        setMessage("");
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
        toast.error(e.message);
      });
  };
  //  SetMessageAsReadCand;
  useEffect(() => {
    SetMessageAsReadCand({ orgainizationId: organisation.id })
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userData, organisation]);
  // const sendChat=async()=>{
  //   const params = new URLSearchParams();
  //   params.append('organisation', organisation.id);
  //   params.append("applicant",userData);
  //   params.append("direction","inbound");
  //   params.append("message",message);
  //   axios.post("https://us-central1-dev-jobboard-gcp.cloudfunctions.net/sendMessage",
  //   params,
  //   {

  //   }).then(()=>{
  //     console.log("Chat Sent");
  //     setMessage("");
  //   }).catch((e)=>{
  //     console.log(e.message)
  //   })
  // }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log("Getting auth", auth.currentUser);
      setUserData(auth.currentUser.uid);
      console.log(auth.currentUser.uid)
      setUserImage(auth.currentUser.photoURL);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const col = collectionGroup(db, "Applicants");
    const q = query(
      col,
      orderBy("updateTimeStamp", "desc"),
      where("uid", "==", userData)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log(snapshot.docs)
      //UNCOMMENT TO ACTIVATE USE CHAT
      setOrganisationId(
        snapshot.docs.map((doc) => ({
          data: doc.data(),
          name: doc.data().orgName,
          id: doc.data().organisationId,
          image: doc.data().orgLogo,
        }))
      );
      setOrganisationIdCopy(
        snapshot.docs.map((doc) => ({
          data: doc.data(),
          name: doc.data().orgName,
          id: doc.data().organisationId,
          image: doc.data().orgLogo,
        }))
      );
      console.log("hello");
    });
    return () => {
      unsubscribe();
    };
  }, [userData]);

  useEffect(() => {
    const col = collection(
      db,
      "Organisations/" +
        organisation.id +
        "/Applicants/" +
        userData +
        "/Messages"
    );
    console.log(
      "Organisations/" +
        organisation.id +
        "/Applicants/" +
        userData +
        "/Messages"
    );
    const q = query(col, orderBy("timeStamp", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setChat(
        snapshot.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }))
      );
    });
    return () => {
      unsubscribe();
    };
  }, [userData, organisation]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const ud = () => {
    console.log(organisationId);
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
      role="presentation"
    >
      <Box
        className={classes.hidescrollbar}
        sx={{
          width: "100%",
          padding: "30px",
          border: "1px solid #ECEDF2",
          boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
          borderRadius: "8px",
          overflowY: "scroll",
        }}
      >
        <Box
          className={classes.input}
          sx={{
            width: "100%",
            borderRight: "1px solid #ECEDF2",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <MessangerSearchIcon />
          <input
            onChange={handleSearchChange}
            value={organisationName}
            id="organisationName"
            name="organisationName"
            type="text"
            placeholder="Search"
          />
        </Box>

        <Stack spacing={3} sx={{ py: "30px" }}>
          {organisationId.map((item, id) => (
            <a
              onClick={() => {
                setOrganisation(item);
                setOverViewId(id);
                let arr = [];
                for (var i = 0; i < AllUnread.length; i++) {
                  if (item.id != AllUnread[i]) {
                    arr.push(AllUnread[i]);
                  }
                }
                setAllUnread(arr);
              }}
            >
              <Box
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  //  gap: { xs: "10px", md: "20px" },
                  background: overViewId === id ? "#25C027" : "#FFFFFF",
                  color: overViewId === id ? "#FFFFFF" : "#000",
                  fill: overViewId === id ? "#FFFFFF" : "#000",
                  padding: { xs: "15px", md: "30px" },
                  borderRadius: "20px",
                  boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
                  "&:hover": {
                    backgroundColor: "#25C027",
                    color: "#FFF",
                    fill: "#FFF",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#007C84",
                    }}
                  >
                    <Avatar
                      style={{ width: "100%", height: "100%" }}
                      src={item.image}
                      alt=""
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      {item.name}
                      <Badge></Badge>{" "}
                      {AllUnread.includes(item.id) ? (
                        <Badge
                          classes={{ badge: classes.customBadge }}
                          //   color="secondary"
                          variant="dot"
                        ></Badge>
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      color: "#696969",
                      fontFamily: "Sofia Pro",
                      fontSize: "14px",
                    }}
                  >
                    <ReactTimeAgo
                      date={item.data.updateTimeStamp}
                      locale="en-US"
                    />
                  </Typography>
                </Box>
              </Box>
            </a>
          ))}
        </Stack>
      </Box>
    </Box>
  );
  return (
    <Box>
      <Container sx={displayOnMobile}>
        <Box>
          <Container>
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Box
                  sx={{
                    ...flexBetweenWithItemsCenter,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#25C027",
                      fontFamily: "Sofia Pro",
                      fontSize: "30px",
                      fontWeight: 500,
                    }}
                  >
                    Message
                  </Typography>
                  <Box onClick={toggleDrawer(anchor, true)}>
                    <PeopleOutlineIcon
                      sx={{ color: "#25C027", fontSize: "30px" }}
                    />
                  </Box>
                </Box>

                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  classes={{ paper: classes.drawerPaper }}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </Container>
        </Box>
        {organisation.name ? (
          <Box
            sx={{
              mt: "30px",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "90vh",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                borderRadius: "0px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px 15px 20px 30px",
                  borderBottom: "1px solid #ECEDF2",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#007C84",
                    }}
                  >
                    <Avatar
                      style={{ width: "100%", height: "100%" }}
                      src={organisation.image}
                      alt=""
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      {organisation.name}
                    </Typography>
                    {/* <Typography
                  sx={{
                    color: "#696969",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Active
                </Typography> */}
                  </Box>
                </Box>
                {/* <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  color: "#D93025",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Delete Conversation
              </Typography>
            </Box> */}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  border: "1px solid #ECEDF2",
                  boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                  borderRadius: "0px",
                  height: "50vh",
                  position: "relative",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                {/* .slice(0).reverse() */}
                {chat.map((item, id) => (
                  <>
                    {item.data.type == "outbound" ? (
                      <Box
                        className={classes.hidescrollbar}
                        sx={{
                          padding: "20px 15px 20px 30px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#007C84",
                            }}
                          >
                            <Avatar
                              style={{ width: "100%", height: "100%" }}
                              src={organisation.image}
                              alt=""
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#202124",
                                fontFamily: "Sofia Pro",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {item.data.hr_name}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#696969",
                                fontFamily: "Sofia Pro",
                                fontSize: "14px",
                              }}
                            >
                              <ReactTimeAgo
                                date={item.data.timeStamp}
                                locale="en-US"
                              />
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            maxWidth: "65%",
                            padding: "20px",
                            mt: "15px",
                            backgroundColor: "#F0FAF0",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#696969",
                              fontFamily: "Sofia Pro",
                              fontSize: "14px",
                            }}
                          >
                            {item.data.content}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            padding: "20px 15px 20px 30px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                style={{ width: "100%", height: "100%" }}
                                src={userType.displayImage}
                                alt=""
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#202124",
                                  fontFamily: "Sofia Pro",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                You
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#696969",
                                  fontFamily: "Sofia Pro",
                                  fontSize: "14px",
                                }}
                              >
                                <ReactTimeAgo
                                  date={item.data.timeStamp}
                                  locale="en-US"
                                />
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Box
                              sx={{
                                maxWidth: "65%",
                                padding: "20px",
                                mt: "15px",
                                backgroundColor: "#F0FAF0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#1967D2",
                                  fontFamily: "Sofia Pro",
                                  fontSize: "14px",
                                }}
                              >
                                {item.data.content}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </>
                ))}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                  padding: "20px 15px 20px 30px",
                  borderTop: "1px solid #ECEDF2",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                    height: "10vh",
                  }}
                >
                  <input
                    onChange={handleChange}
                    value={message}
                    id="message"
                    name="message"
                    autoComplete="off"
                    placeholder="Type a Message"
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      color: "#696969",
                      fontFamily: "Sofia Pro",
                      fontSize: "14px",
                    }}
                    type="text"
                  />
                </Box>
                <LoadingButton loading={loading} onClick={() => handleSubmit()}>
                  <svg
                    width="24"
                    height="23"
                    viewBox="0 0 24 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.6504 0.225183C22.4104 0.350419 19.8074 1.70515 16.8658 3.2357C13.9243 4.76625 9.00607 7.32538 5.93644 8.92269C2.86686 10.52 0.304336 11.8737 0.241986 11.9309C0.10157 12.0598 0 12.2899 0 12.4794C0 12.665 0.15768 12.9715 0.301568 13.0654C0.465581 13.1725 6.71335 15.2921 6.78461 15.2648C6.81769 15.2522 7.71671 14.4983 8.78238 13.5896C9.84809 12.6808 12.7678 10.1946 15.2707 8.0645C17.7736 5.93447 19.8732 4.14732 19.9365 4.09305L20.0515 3.99436L19.9365 4.13629C19.8732 4.21436 17.5731 6.97721 14.825 10.2759C12.077 13.5747 9.82328 16.2878 9.81675 16.305C9.80779 16.3288 19.8824 19.7848 20.3939 19.9334C20.6312 20.0023 21.0479 19.7962 21.178 19.5454C21.2721 19.3641 24.0371 0.779044 23.9996 0.579948C23.9618 0.379262 23.7655 0.14403 23.5666 0.0612407C23.2988 -0.0502514 23.1151 -0.0173881 22.6504 0.225183ZM8.77304 19.9842C8.77304 22.6027 8.76793 22.5488 9.03628 22.7591C9.34554 23.0015 9.76369 22.979 10.0251 22.7058C10.2144 22.5081 12.82 18.9735 12.7949 18.9485C12.7762 18.9299 8.82709 17.5744 8.79139 17.5744C8.7813 17.5744 8.77304 18.6588 8.77304 19.9842Z"
                      fill="#25C027"
                    />
                  </svg>
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        ) : (
          <MessageDefaultScreen wi={100} />
        )}
      </Container>
      <Box sx={{ ...displayOnDesktop }}>
        <Container>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            Message
          </Typography>
        </Container>
        <Box
          sx={{
            display: "flex",
            gap: "25px",
            mt: "30px",
            height: "70vh",
          }}
        >
          <Box
            className={classes.hidescrollbar}
            sx={{
              width: "40%",
              padding: "30px",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              overflowY: "scroll",
            }}
          >
            <Box
              className={classes.input}
              sx={{
                width: "100%",
                borderRight: "1px solid #ECEDF2",
                padding: "20px",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <MessangerSearchIcon />
              <input
                onChange={handleSearchChange}
                value={organisationName}
                id="organisationName"
                name="organisationName"
                type="text"
                placeholder="Search"
              />
            </Box>

            <Stack spacing={3} sx={{ py: "30px" }}>
              {organisationId.map((item, id) => (
                <a
                  onClick={() => {
                    setOrganisation(item);
                    setOverViewId(id);
                    let arr = [];
                    for (var i = 0; i < AllUnread.length; i++) {
                      if (item.id != AllUnread[i]) {
                        arr.push(AllUnread[i]);
                      }
                    }
                    setAllUnread(arr);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      //  gap: { xs: "10px", md: "20px" },
                      background: overViewId === id ? "#25C027" : "#FFFFFF",
                      color: overViewId === id ? "#FFFFFF" : "#000",
                      fill: overViewId === id ? "#FFFFFF" : "#000",
                      padding: { xs: "15px", md: "30px" },
                      borderRadius: "20px",
                      boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
                      "&:hover": {
                        backgroundColor: "#25C027",
                        color: "#FFF",
                        fill: "#FFF",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#007C84",
                        }}
                      >
                        <Avatar
                          style={{ width: "100%", height: "100%" }}
                          src={item.image}
                          alt=""
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#202124",
                            fontFamily: "Sofia Pro",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {item.name}
                          <Badge></Badge>{" "}
                          {AllUnread.includes(item.id) ? (
                            <Badge
                              classes={{ badge: classes.customBadge }}
                              //   color="secondary"
                              variant="dot"
                            ></Badge>
                          ) : (
                            <></>
                          )}
                        </Typography>
                        {/* <Typography
                      sx={{
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                        fontSize: "14px",
                      }}
                    >
                      {item.id}
                    </Typography> */}
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          color: "#696969",
                          fontFamily: "Sofia Pro",
                          fontSize: "14px",
                        }}
                      >
                        <ReactTimeAgo
                          date={item.data.updateTimeStamp}
                          locale="en-US"
                        />
                      </Typography>
                      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      sx={{
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                        fontSize: "10px",
                        backgroundColor: "#F9AB00",
                        display: "inline-block",
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                      }}
                    >
                      2
                    </Typography>
                  </Box> */}
                    </Box>
                  </Box>
                </a>
              ))}
            </Stack>
          </Box>
          {organisation.name ? (
            <Box
              sx={{
                width: "60%",
                height: "100%",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "15%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px 15px 20px 30px",
                  borderBottom: "1px solid #ECEDF2",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#007C84",
                    }}
                  >
                    <Avatar
                      style={{ width: "100%", height: "100%" }}
                      src={organisation.image}
                      alt=""
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      {organisation.name}
                    </Typography>
                    {/* <Typography
                  sx={{
                    color: "#696969",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Active
                </Typography> */}
                  </Box>
                </Box>
                {/* <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  color: "#D93025",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Delete Conversation
              </Typography>
            </Box> */}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  border: "1px solid #ECEDF2",
                  boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                  borderRadius: "0px",
                  height: "70%",
                  position: "relative",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                {/* .slice(0).reverse() */}
                {chat.map((item, id) => (
                  <>
                    {item.data.type == "outbound" ? (
                      <Box
                        className={classes.hidescrollbar}
                        sx={{
                          padding: "20px 15px 20px 30px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#007C84",
                            }}
                          >
                            <Avatar
                              style={{ width: "100%", height: "100%" }}
                              src={organisation.image}
                              alt=""
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#202124",
                                fontFamily: "Sofia Pro",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {item.data.hr_name}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#696969",
                                fontFamily: "Sofia Pro",
                                fontSize: "14px",
                              }}
                            >
                              <ReactTimeAgo
                                date={item.data.timeStamp}
                                locale="en-US"
                              />
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            maxWidth: "65%",
                            padding: "20px",
                            mt: "15px",
                            backgroundColor: "#F0FAF0",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#696969",
                              fontFamily: "Sofia Pro",
                              fontSize: "14px",
                            }}
                          >
                            {item.data.content}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            padding: "20px 15px 20px 30px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                style={{ width: "100%", height: "100%" }}
                                src={userType.displayImage}
                                alt=""
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#202124",
                                  fontFamily: "Sofia Pro",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                You
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#696969",
                                  fontFamily: "Sofia Pro",
                                  fontSize: "14px",
                                }}
                              >
                                <ReactTimeAgo
                                  date={item.data.timeStamp}
                                  locale="en-US"
                                />
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Box
                              sx={{
                                maxWidth: "65%",
                                padding: "20px",
                                mt: "15px",
                                backgroundColor: "#F0FAF0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#1967D2",
                                  fontFamily: "Sofia Pro",
                                  fontSize: "14px",
                                }}
                              >
                                {item.data.content}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </>
                ))}
              </Box>

              <Box
                sx={{
                  height: "15%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                  padding: "20px 15px 20px 30px",
                  borderTop: "1px solid #ECEDF2",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <input
                    onChange={handleChange}
                    value={message}
                    id="message"
                    name="message"
                    autoComplete="off"
                    placeholder="Type a Message"
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      color: "#696969",
                      fontFamily: "Sofia Pro",
                      fontSize: "14px",
                    }}
                    type="text"
                  />
                </Box>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    fontSize: "18px",
                    fontFamily: "",
                    backgroundColor: "#24BD2C",
                    color: "#fff",
                    padding: "10px 50px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#24BD2C",
                    },
                  }}
                  onClick={() => handleSubmit()}
                  loading={loading}
                >
                  Send
                </LoadingButton>
              </Box>
            </Box>
          ) : (
            <MessageDefaultScreen wi={50} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TeamDashboardMessage;
