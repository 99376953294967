import { Badge, Box, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AllAplicantCount, AllNotification, AllUnreadMessageCount, fetchOrgNotifs, GetAllInvite, getUserType } from "../../firebase/functions";
// import BadgeProps from '@mui/material/Badge';
import {
  CandidateDashboardJobInvitesIcons,
  EmployeeDashboardAllApplicantsIcon,
  EmployeeDashboardChangePasswordIcon,
  EmployeeDashboardCompanyProfileIcon,
  EmployeeDashboardIcon,
  EmployeeDashboardManageJobsIcon,
  EmployeeDashboardMessageIcon,
  EmployeeDashboardPaymentIcons,
  EmployeeDashboardPostNewJobIcon,
  EmployeeDashboardResumeAlertsIcon,
  EmployeeDashboardShortlistedResumeIcon
} from "../../utilities/Icons/Icons";
import classes from "./EmployeeDashboardSideNavbar.module.css";
import JobNotFound from "../../pages/JobSearchPage/JobNotFound";

const SideNavbar = () => {
  const [allApplicantNotificationCount , setAllApplicantsNotificationCount] = useState(0);
  const [allNotificationCount , setAllNotificationCount] = useState(0);
  const [acceptInvitaionCount, setAcceptInvitaionCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [currentClass, setCurrentClass] = useState("");
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const checkUser = async()=>{
    const type = await getUserType();
    if (type.data.role == "user") {
      navigate("/");
    }
    if (type.data.role == "Admin") {
      navigate("/super-admin-dashboard/dashboard");
    }
  }
  useEffect(() => {
    checkUser();
    checkLogin();
    
    GetAllInvite({})
      .then((response) => {
        console.log("Alll Invite User" + response);
        console.log(response);
        if (Array.isArray(response.data)) {
          let inCount = 0;
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].status == "invited") {
              inCount++;
            }
          }
          setAcceptInvitaionCount(inCount);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
    AllAplicantCount({}).then((response) => {
      console.log("Alll Aplicant Cou"+response);
      console.log(response);
     setAllApplicantsNotificationCount(response.data);
    })
    .catch((e)=>{
      console.log(e.message);
    })
    AllUnreadMessageCount({}).then((response) => {
      console.log("Alll Unread Message Notification"+response);
      console.log(response);
      if (Array.isArray(response.data)) {
        setUnreadMessageCount(response.data.length);
      }
    })
    .catch((e)=>{
      console.log(e.message);
    })
    fetchOrgNotifs({}).then((response) => {
        console.log("hello mr. bean");
        if (Array.isArray(response.data)) {
              console.log(response.data);
              let tempNotificationCount = 0;
              let tempAcceptInvitaion =  0;
              for(let i =0;i<response.data.length;i++){
               if(!response.data[i].read){
                 tempNotificationCount++;
               }
               if (
                 response.data[i].title == "Job Invitation Accepted" && !response.data[i].read
               ) {
                 tempAcceptInvitaion++;
               }
              }
              setAllNotificationCount(tempNotificationCount);
             // setAcceptInvitaionCount(tempAcceptInvitaion);
        }
        console.log(response);
      })
      .catch((e) => {
          console.error(e.message);
      });
  }, [toggle]);
  const checkLogin=()=>{
    const auth = getAuth();
    auth.onAuthStateChanged((user)=> {
    if (user) {
    } else {
      navigate("/");
    }
   });
  }
  const update =()=> {
    setToggle(!toggle);
  }
  return (
    <Box
      sx={{
        height: "90vh",
        position: "sticky",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
      }}
    >
      <Box
        className={classes.sidebar}
        sx={{ boxShadow: 1, minHeight: "100%", padding: "20px" }}
      >
        <Box>
          <NavLink
            to="dashboard"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={() => {
              update();
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Dashboard
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="company-profile"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={() => {
              update();
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardCompanyProfileIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Company Profile
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to="manage-seats"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={() => {
              update();
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardManageJobsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Manage Seats
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="manage-payment"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={() => {
              update();
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardPaymentIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Manage Payment
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="post-new-job"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={() => {
              update();
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardPostNewJobIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Post a New Job
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="manage-jobs"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={() => {
              update();
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardManageJobsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Manage Jobs
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="all-applicants"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("All");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={() => {
              update();
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardAllApplicantsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                All Applicants
              </Typography>
              {currentClass == "All" ? (
                <></>
              ) : (
                <Badge
                  classes={{ badge: classes.customBadge }}
                  badgeContent={allApplicantNotificationCount}
                ></Badge>
              )}
            </Box>
          </NavLink>

          <NavLink
            to="invite-applicants"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("Inv");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={update}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <CandidateDashboardJobInvitesIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Invited Applicants
              </Typography>
              {currentClass == "Inv" ? (
                <></>
              ) : (
                <Badge
                  classes={{ badge: classes.customBadge }}
                  badgeContent={acceptInvitaionCount}
                ></Badge>
              )}
            </Box>
          </NavLink>

          <NavLink
            to="recruiter"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardShortlistedResumeIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Recruiter
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="message"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("Mes");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={update}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardMessageIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Message
              </Typography>
              {currentClass == "Mes" ? (
                <></>
              ) : (
                <Badge
                  classes={{ badge: classes.customBadge }}
                  badgeContent={unreadMessageCount}
                ></Badge>
              )}
            </Box>
          </NavLink>

          <NavLink
            to="notification"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("Not");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={update}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardResumeAlertsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Notification
              </Typography>
              {currentClass == "Not" ? (
                <></>
              ) : (
                <Badge
                  classes={{ badge: classes.customBadge }}
                  badgeContent={allNotificationCount}
                ></Badge>
              )}
            </Box>
          </NavLink>

          <NavLink
            to="change-password"
            className={({ isActive }) => {
              if (isActive) {
                setCurrentClass("");
              }
              return isActive ? classes.active : classes.inactive;
            }}
            onClick={update}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardChangePasswordIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Change Password
              </Typography>
            </Box>
          </NavLink>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "15px",
              gap: "15px",
              backgroundColor: "#fff",
              cursor: "pointer",
            }}
          >
            <EmployeeDashboardLogoutIcon />
            <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
              Logout
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "15px",
              gap: "15px",
              backgroundColor: "#fff",
              cursor: "pointer",
            }}
          >
            <EmployeeDashboardDeleteProfileIcon />
            <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
              Delete Profile
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default SideNavbar;
