import { Route, Routes } from "react-router-dom";
import Bookmarkedjobs from "../../components/CandidateDashboard/BookmarkedJobs";
import CandidateDashboardAppliedJobs from "../../components/CandidateDashboard/CandidateDashboardAppliedJobs";
import CandidateDashboardChangePassword from "../../components/CandidateDashboard/CandidateDashboardChangePassword";
import CandidateDashboardComponent from "../../components/CandidateDashboard/CandidateDashboardComponent";
import CandidateDashboardCVManager from "../../components/CandidateDashboard/CandidateDashboardCVManager";
import CandidateDashboardJobAlerts from "../../components/CandidateDashboard/CandidateDashboardJobAlerts";
import CandidateDashboardMessage from "../../components/CandidateDashboard/CandidateDashboardMessage";
import CandidateDashboardMyProfile from "../../components/CandidateDashboard/CandidateDashboardMyProfile";
import CandidateDashboardMyResume from "../../components/CandidateDashboard/CandidateDashboardMyResume";
import CandidateDashboardShortlistedJobs from "../../components/CandidateDashboard/CandidateDashboardShortlistedJobs";
import CandidateNotification from "../../components/CandidateDashboard/CandidateNotification";
import JobInvites from "../../components/CandidateDashboard/JobInvites";
import JobOffers from "../../components/CandidateDashboard/JobOffers";
import SuggestedJobs from "../../components/CandidateDashboard/SuggestedJobs";
import EmployeeDashboardAllApplicants from "../../components/EmployeeDashboardComponent/EmployeeDashboardAllApplicants";
import EmployeeDashboardCompanyProfile from "../../components/EmployeeDashboardComponent/EmployeeDashboardCompanyProfile";
import EmployeeDashboardComponent from "../../components/EmployeeDashboardComponent/EmployeeDashboardComponent";
import EmployeeDashboardInviteCandidates from "../../components/EmployeeDashboardComponent/EmployeeDashboardInviteCandidates";
import EmployeeDashboardManageJobs from "../../components/EmployeeDashboardComponent/EmployeeDashboardManageJobs";
import EmployeeDashboardMessage from "../../components/EmployeeDashboardComponent/EmployeeDashboardMessage";
import EmployeeDashboardPaymentInvoices from "../../components/EmployeeDashboardComponent/EmployeeDashboardPaymentInvoices";
import EmployeeDashboardPostNewJob from "../../components/EmployeeDashboardComponent/EmployeeDashboardPostNewJob";
import EmployeeDashboardResumeAlerts from "../../components/EmployeeDashboardComponent/EmployeeDashboardResumeAlerts";
import EmployeeDashboardShorlistedResumes from "../../components/EmployeeDashboardComponent/EmployeeDashboardShorlistedResumes";
import EmployeeDashboardViewProfile from "../../components/EmployeeDashboardComponent/EmployeeDashboardViewProfile";
import ManageUsersPage from "../../components/EmployeeDashboardComponent/manageUsers/ManageUsersPage";
import Login from "../../components/Login/Login";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import MessageDefaultScreen from "../../components/SharedComponents/MessageDefaultScreen/MessageDefaultScreen";
import SignUp from "../../components/SignUp/SignUp";
import NotificationDetails from "../../components/SuperAdminDashboardSideNavbar/NotificationDetails";
import SuperAdminDashboardCompanyView from "../../components/SuperAdminDashboardSideNavbar/SupeAdminDashboardCompanyView";
import SuperAdminDashboardAboutUs from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardAboutUs";
import SuperAdminDashboardCandidateProfile from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardCandidateProfile";
import SuperAdminDashboardCompanyList from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardCompanyList";
import SuperAdminDashboardComponent from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardComponent";
import SuperAdminDashboardContactUsDetails from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardContactUsDetails";
import SuperAdminDashboardEditPlans from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardEditPlans";
import SuperAdminDashboardFaq from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardFaq";
import SuperAdminDashboardFormField from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardFormField";
import SuperAdminDashboardJobSeeker from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardJobSeeker";
import SuperAdminDashboardNoPlans from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardNoPlans";
import SuperAdminDashboardPaymentInvoices from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardPaymentInvoices";
import SuperAdminDashboardPolicy from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardPolicy";
import SuperAdminDashboardPricing from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardPricing";
import SuperAdminDashboardRefundRequest from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardRefundRequest";
import SuperAdminDashboardTerms from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardTerms";
import About from "../../pages/AboutUs/About";
import CandidatesList from "../../pages/CandidatesList/CandidatesList";
import CanditateProfile from "../../pages/CanditateProfile/CanditateProfile";
import CompanyProfile from "../../pages/CompanyProfile/CompanyProfile";
import ContactDetails from "../../pages/ContactDetails/ContactDetails";
import CandidateDashboard from "../../pages/Dashboard/CandidatesDashboard";
import EmployeeDashboard from "../../pages/Dashboard/EmployeeDashboard";
import SuperAdminDashboard from "../../pages/Dashboard/SuperAdminDashboard";
import EmployeeSearchPage from "../../pages/EmployeeSearchPage/EmployeeSearchPage";
import Faq from "../../pages/Faq/Faq";
import Homepage from "../../pages/HomePage/HomePage";
import JobProfile from "../../pages/JobProfile/JobProfile";
import ErrorPage from "../../pages/JobSearchPage/ErrorPage";
import ErrorComponent from "../../pages/JobSearchPage/ErrorComponent";
import JobNotFound from "../../pages/JobSearchPage/JobNotFound";
import JobSearchPage from "../../pages/JobSearchPage/JobSearchPage";
import PolicyComponent from "../../pages/Policy/PolicyComponent";
import Pricing from "../../pages/Pricing/Pricing";
import CandidateProfileUpadtingPage from "../../pages/ProfileUpadatingPages/CandidateProfileUpadtingPage";
import CompanyProfileUpadtingPage from "../../pages/ProfileUpadatingPages/CompanyProfileUpadtingPage";
import Register from "../../pages/Register/Register";
import Terms from "../../pages/Terms/Terms";
import CandidateResumeUpdatingPages from "../../pages/ResumeUpdatingPages/CandidateResumeUpdatingPages";
import Test from "../../Test";
import EmployeeDashboardEditJob from "../../components/EmployeeDashboardComponent/EmployeeDashboardEditJob";
import AdminManageUsersPage from "../../components/SuperAdminDashboardSideNavbar/manageUsers/AdminManageUsersPage";
import SuperAdminDashboardMemberView from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardMemberView.js";
import TeamDashboardDashboard from "../../components/TeamDashboard/TeamDashboardDashboard";
import TeamDashborad from "../../pages/Dashboard/TeamDashborad";
import TeamDashboardManageResume from "../../components/TeamDashboard/TeamDashboardManageResume";
import TeamDashboardCandidateView from "../../components/TeamDashboard/TeamDashboardCandidateView";
import TeamDashboardAllApplicant from "../../components/TeamDashboard/TeamDashboardAllApplicant";
import TeamDashboardPostJob from "../../components/TeamDashboard/TeamDashboardPostJob";
import TeamDashboardManageJob from "../../components/TeamDashboard/TeamDashboardManageJob";
import TeamDashboardMessage from "../../components/TeamDashboard/TeamDashboardMessage";
import Recruiter from "../../components/EmployeeDashboardComponent/Recruiter";
import EmployeeDashboardCandidate from "../../components/EmployeeDashboardComponent/EmployeeDashboardCandidate";
const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/test" element={<Test />} />
      <Route path="/job-search" element={<JobSearchPage />} />
      <Route path="/employee-search" element={<EmployeeSearchPage />} />
      <Route path="/job-profile" element={<JobProfile />}>
        <Route path=":id" element={<JobProfile />} />
      </Route>
      <Route
        path="/candidate-profile/:id/:jobId"
        element={<CanditateProfile />}
      />
      <Route path="/candidate-list" element={<CandidatesList />} />
      <Route path="/about-Us" element={<About />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/policy" element={<PolicyComponent />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/contact-us" element={<ContactDetails />} />
      <Route path="/company-profile">
        <Route path=":id" element={<CompanyProfile />} />
      </Route>
      <Route path="/pricing" element={<Pricing />} />
      <Route
        path="/company-profile-updating"
        element={<CompanyProfileUpadtingPage />}
      />
      <Route
        path="/candidate-profile-updating"
        element={<CandidateProfileUpadtingPage />}
      />
      <Route
        path="/candidate-resume-updating"
        element={<CandidateResumeUpdatingPages />}
      />
      {/* <Route path="/search" element={<SearchProvider />} /> */}
      <Route path="/registration" element={<Register />}>
        <Route path="register" element={<SignUp />} />
        <Route path="login" element={<Login />} />
      </Route>

      {/* User Dashboard */}
      <Route path="/employee-dashboard" element={<EmployeeDashboard />}>
        <Route path="" element={<EmployeeDashboardComponent />} />
        <Route path="manage-seats" element={<ManageUsersPage />} />
        <Route
          path="manage-payment"
          element={<EmployeeDashboardPaymentInvoices />}
        />
        <Route path="dashboard" element={<EmployeeDashboardComponent />} />
        <Route
          path="company-profile"
          element={<EmployeeDashboardCompanyProfile />}
        />
        <Route path="post-new-job" element={<EmployeeDashboardPostNewJob />} />
        <Route path="manage-jobs">
          <Route path="" element={<EmployeeDashboardManageJobs />} />
          <Route path=":id" element={<EmployeeDashboardEditJob />} />
        </Route>
        <Route
          path="all-applicants"
          element={<EmployeeDashboardAllApplicants />}
        />
        <Route
          path="invite-applicants"
          element={<EmployeeDashboardInviteCandidates />}
        />
        <Route
          path="shortlisted-resumes"
          element={<EmployeeDashboardShorlistedResumes />}
        />
        <Route path="recruiter">
          <Route path="" element={<Recruiter />} />
          <Route path=":id/:jobId" element={<EmployeeDashboardCandidate />} />
        </Route>

        <Route path="message" element={<EmployeeDashboardMessage />} />
        {/* <Route path="notification" element={<EmployeeNotification />} /> */}
        <Route
          path="notification"
          element={<EmployeeDashboardResumeAlerts />}
        />
        <Route
          path="change-password"
          element={<CandidateDashboardChangePassword />}
        />
        <Route path="view-profile" element={<EmployeeDashboardViewProfile />} />
      </Route>
      <Route path="/candidate-dashboard" element={<CandidateDashboard />}>
        <Route path="" element={<CandidateDashboardComponent />} />
        <Route path="dashboard" element={<CandidateDashboardComponent />} />
        <Route path="my-profile" element={<CandidateDashboardMyProfile />} />
        <Route path="my-resume" element={<CandidateDashboardMyResume />} />
        <Route path="job-invites" element={<JobInvites />} />
        <Route path="job-offer" element={<JobOffers />} />
        <Route path="bookmarked-jobs" element={<Bookmarkedjobs />} />
        <Route path="suggested-jobs" element={<SuggestedJobs />} />
        <Route
          path="applied-jobs"
          element={<CandidateDashboardAppliedJobs />}
        />
        <Route path="job-alerts" element={<CandidateDashboardJobAlerts />} />
        <Route
          path="shortlisted-jobs"
          element={<CandidateDashboardShortlistedJobs />}
        />
        <Route path="message" element={<CandidateDashboardMessage />} />
        <Route path="notification" element={<CandidateNotification />} />
        <Route path="cv-manager" element={<CandidateDashboardCVManager />} />
        <Route
          path="change-password"
          element={<CandidateDashboardChangePassword />}
        />
      </Route>
      <Route path="/super-admin-dashboard" element={<SuperAdminDashboard />}>
        <Route path="dashboard" element={<SuperAdminDashboardComponent />} />
        <Route path="company-list">
          <Route path="" element={<SuperAdminDashboardCompanyList />} />
          <Route path=":id" element={<SuperAdminDashboardCompanyView />} />
        </Route>
        <Route path="member-list">
          <Route path="" element={<AdminManageUsersPage />} />
          <Route path=":id" element={<SuperAdminDashboardMemberView />} />
        </Route>
        <Route path="job-seeker">
          <Route path="" element={<SuperAdminDashboardJobSeeker />} />
          <Route path=":id" element={<SuperAdminDashboardCandidateProfile />} />
        </Route>
        <Route path="edit-plans" element={<SuperAdminDashboardEditPlans />} />
        <Route path="about-us" element={<SuperAdminDashboardAboutUs />} />
        <Route path="form-field" element={<SuperAdminDashboardFormField />} />
        <Route path="terms" element={<SuperAdminDashboardTerms />} />
        <Route path="policy" element={<SuperAdminDashboardPolicy />} />
        <Route path="faq" element={<SuperAdminDashboardFaq />} />
        <Route
          path="contact-us-details"
          element={<SuperAdminDashboardContactUsDetails />}
        />
        <Route path="notification" element={<NotificationDetails />} />
        <Route
          path="price-update"
          element={<SuperAdminDashboardRefundRequest />}
        />
        <Route
          path="payment-invoices"
          element={<SuperAdminDashboardPaymentInvoices />}
        />
      </Route>
      <Route path="/team" element={<TeamDashborad />}>
        <Route path="dashboard" element={<TeamDashboardDashboard />} />
        <Route path="manage-resume">
          <Route path="" element={<TeamDashboardManageResume />} />
          <Route path=":id/:jobId" element={<TeamDashboardCandidateView />} />
        </Route>
        <Route path="All-Applicant">
          <Route path="" element={<TeamDashboardAllApplicant />} />
          <Route path=":id" element={<SuperAdminDashboardMemberView />} />
        </Route>
        <Route path="post-job">
          <Route path="" element={<TeamDashboardPostJob />} />
          <Route path=":id" element={<SuperAdminDashboardCandidateProfile />} />
        </Route>
        <Route path="manage-jobs" element={<TeamDashboardManageJob />} />
        <Route path="messages" element={<TeamDashboardMessage />} />
      </Route>

      <Route path="/no-plans" element={<SuperAdminDashboardNoPlans />} />
      <Route path="/job-not-found" element={<JobNotFound />} />
      <Route path="/error-page" element={<ErrorPage />} />
      <Route path="/error" element={<ErrorComponent />} />
      <Route
        path="/super-admin-dashboard-pricing"
        element={<SuperAdminDashboardPricing />}
      />
      <Route
        path="/loading-search-components"
        element={<LoadingSearchComponents />}
      />
      <Route path="/message" element={<MessageDefaultScreen />} />
    </Routes>
  );
};

export default Router;
