import { Box, Container, Typography, Modal, Stack } from "@mui/material";
import ContactInformation from "./ContactInformation";
import classes from "./EmployeeDashboardComponent.module.css";
import PostJob from "./PostJob";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { addJob, updateJob } from "../../firebase/functions";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import EditJob from "./EditJob";
import EditContactInformation from "./EditContactInformation";
import { Flag } from "@mui/icons-material";
const EmployeeDashboardEditJob = () => {
    const { id } = useParams();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resJobType, setResJobType] = useState('');
  const [resSkillsType, setResSkillsType] = useState([]);
  const [skills, setSkills] = useState([]);
  const [flag, setFlag] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getJobDataById();
    }, [flag])
    const getJobDataById = async () => {
      const usercollectionRef = doc(db, `Jobs/${id}`);
      const docSnap = await getDoc(usercollectionRef);
      if (docSnap.exists()) {
        const extraRef = doc(db, `Jobs/${id}/extraInfo/infoDoc`);
        const docSnap1 = await getDoc(extraRef);
        if (docSnap1.exists()) {
          console.log(docSnap.data());
          console.log(docSnap1.data());
          setResJobType(docSnap.data().jobType)
          setResSkillsType(docSnap.data().skills);
          setLoading(false);
          // setJobData({ ...docSnap.data(), ...docSnap1.data() })
          var temp = "";
          for (var i = 0; i < docSnap1.data().keyResponsibilities.length; i++) {
            if (i == docSnap1.data().keyResponsibilities.length-1) {
              temp += docSnap1.data().keyResponsibilities[i];
            } else {
              temp += docSnap1.data().keyResponsibilities[i] + "\n";
            }
              
          }
          var temp1 = "";
          for (var i = 0; i < docSnap1.data().skillsRequired.length; i++) {
            if (i == docSnap1.data().skillsRequired.length - 1) {
              temp1 += docSnap1.data().skillsRequired[i];
            } else {
              temp1 += docSnap1.data().skillsRequired[i] + "\n";
            }
              
          }
             reset({
               ...docSnap.data(),
               ...docSnap1.data(),
               keyResponsibilities: temp,
               skillsRequired:temp1
             });
        //   window.scrollTo(0, 0);
        //   const title =
        //     docSnap.data().organisationName +
        //     " is hiring for the position of " +
        //     docSnap.data().jobTitle +
        //     " in " +
        //     docSnap.data().city +
        //     " | View Here \n\n";
        //   setTitle(title);
         setLoading(false);
        } else {
          //! doc.data() will be undefined in this case
          console.log("No such document!");
          setLoading(false);
          toast.error("job not found");
        }
      } else {
        //! doc.data() will be undefined in this case
        console.log("No such document!");
        // setloading(false);
        toast.error("Job not found");
      }
    };
  const onSubmit = async (data) => {
    console.log(data);
    if (parseInt(data.maxSalary) <= parseInt(data.minSalary)) {
      console.log(data.minSalary, data.maxSalary);
      setError("minSalary", {
        type: "custom",
        message: "Minimum salary must be less than maximum salary",
        shouldFocus: true,
      });
      return;
    }
    //
    setLoading(true);
    const tempskill = data.skillsRequired;
    const tempRes = data.keyResponsibilities;
    try {
      const result = await updateJob({

        ...data,
        // skillsRequired: tempskill.split("."),
        // keyResponsibilities: tempRes.split("."),
        skillsRequired: tempskill.split(/\r?\n/),
        keyResponsibilities: tempRes.split(/\r?\n/),
        jobId:id
      });
      console.log(result.data);
      setLoading(false);
      toast.success("Job updated successfully");
      setFlag(!flag);
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error(`${err.message}`);
      setLoading(false);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Container>
        <Box>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Edit Job
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <EditJob
              setValue={setValue}
              getValues={getValues}
              skills={{ setSkills, skills }}
              register={register}
              resJobType={resJobType}
              setResJobType={setResJobType}
              resSkillsType={resSkillsType}
              setResSkillsType={setResSkillsType}
              setTags={setTags}
              errors={errors}
            />
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <EditContactInformation
              loading={loading}
              register={register}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
            />
          </Box>
        </Box>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default EmployeeDashboardEditJob;
