import styled from "@emotion/styled";
import { Box, Container, Modal, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCountries } from "use-react-countries";
import { db } from "../../firebase/firebaseConfig";
import { getAdminOrgInfo, getFormField } from "../../firebase/functions";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import {
  displayOnDesktop,
  displayOnMobile,
  itemsCenter
} from "../../utilities/commonStyles/commonStyles";
import { SuperAdminOrganizationSearchIcon } from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./SuperAdminDashboardCompanyList.module.css";
const StyledFilter = styled("select")({
  // width: "150px",
  height: "40px",
  fontSize: "14px",
  color: "#B7B7B7",
  backgroundColor: "transparent",
  padding: "0px 20px",
  borderRadius: "8px",
  border: "1px solid #25C027",
  outline: "none",

  option: {
    color: "#B7B7B7",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});
const SuperAdminDashboardCompanyList = () => {
  const { countries } = useCountries();
  const names = countries.map((country) => country.name);
  const [location, setLocation] = useState({});
  const [allApplicants, setAllApplicants] = useState([]);
  const [noofAcceptReject, setNoofAcceptReject] = useState([]);
  const [status, setStatus] = useState({});
  const [titles, setTitles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const { jobTitles, setJobTitles } = useState([]);
  const [toggle, setToggle] = useState(true);
  const [experienceArray, setExperienceArray] = useState([]);
  const [educationArray, setEducationArray] = useState([]);
  const [skillArray, setSkillArray] = useState([]);
  const [sort, setSort] = useState({ order: "desc" });
  const [companyData, setcompanyData] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setloading(true);

    // getAdminOrgInfo({})
    //   .then((response) => {
    //     console.log(Array.isArray(response));
    //     if (Array.isArray(response.data)) {
    //       setcompanyData(response.data);
    //       setAllApplicants(response.data);
    //       setOriginalData(response.data);
    //     }
    //     setloading(false);
    //     console.log(response);
    //   })
    //   .catch((e) => {
    //     setloading(false);
    //     toast.error(e.message);
    //   });
    allJobs();
  }, []);
  const allJobs = async () => {
    const employerPostedJob = collection(db, `Organisations`);
    await getDocs(employerPostedJob)
      .then((docSnap) => {
        setcompanyData(docSnap.docs.map((doc) => { return ({ ...doc.data(), id: doc.id }) }));
        setAllApplicants(
          docSnap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        );
        setOriginalData(
          docSnap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        );
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
        toast.error(e.message);
      });
  };
  useEffect(() => {
    getFormField({ form: "experience" }).then(({ data }) => {
      console.log("Experience", data);
      setExperienceArray(data);
    }).catch((e)=>{
     console.log(e);
    })
    getFormField({ form: "education" }).then(({ data }) => {
      console.log("Education", data);
      setEducationArray(data);
    }).catch((e)=>{
   console.log(e);
    })
    getFormField({ form: "skills" }).then(({ data }) => {
      console.log("Skills", data);
      setSkillArray(
        data.map((skill) => {
          return {
            value: skill,
            label: skill,
          };
        })
      );
    }).catch((e)=>{
     console.log(e);
    })
    setStatus({
      ...status,
      // jobTitles: data[0].id,
      // status: "All",
      // experience: "Any",
      // educationLevel: "Any",
      // skills: [],
      name: "",
      country: "",
      state: "",
      city: "",
    });
  }, []);
  useEffect(() => {
    console.log("USEEFFECT100", status);
    const filterData = originalData
      .filter((item) => {
        return status.status == "All"
          ? 1
          : item.status === status.status ||
              (status.status == "hired" && item.status == "offerRevised");
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.country == "" ? 1 : item.country === status.country;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.state == "" ? 1 : item.state === status.state;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.city == "" ? 1 : item.city === status.city;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        let nameData = item.organisationName.toLowerCase();
        let nameUser = status.name.toLowerCase();
        let flag = false;
        if (nameData.includes(nameUser)) {
          flag = true;
        }
        return status.name == "" ? 1 : flag;
      });
    let sortedData = filterData;
    if (sort.method == "firstName") {
      sortedData = filterData.sort((a, b) =>
        a.organisationName.localeCompare(b.organisationName)
      );
    } else if (sort.method == "appliedAt") {
      sortedData = filterData.sort(
        (a, b) =>
          fireBaseTime(a.organisationAddedOn) -
          fireBaseTime(b.organisationAddedOn)
      );
      console.log("SORTEDDATATIME");
    }
    if (sort.order == "asc") {
      sortedData = sortedData.reverse();
    }
    console.log("SORTEDDATA", sortedData);
    setAllApplicants(sortedData);
    // if(sorted)
    console.log("useEffectfilter", filterData);
    // setAllApplicants(filterData);
  }, [status, toggle, sort]);

  console.log(allApplicants);
  const getDataCompantList = () => {
    
  }
  return (
    <Box
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Container>
        <Box sx={{ mt: "30px" }}>
          <Box sx={{ ...displayOnDesktop }}>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Organization List
            </Typography>
          </Box>

          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { md: "row", xs: "column-reverse" },
              gap: "30px",
              mt: "40px",
            }}
            direction
          >
            <Box
              className={classes.input}
              sx={{
                width: "100%",
                minWidth: { md: "auto", xs: "100%" },
                display: "flex",
                alignItems: "center",
                gap: "20px",
                border: "1px solid #25C027",
              }}
            >
              <SuperAdminOrganizationSearchIcon />
              <input
                style={{ minWidth: "85%" }}
                type="text"
                placeholder="Search for Company name"
                onChange={(e) => {
                  setStatus({ ...status, name: e.target.value });
                }}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "15px" }}>
              {status.status != "resumeDatabase" ? (
                <>
                  {" "}
                  <StyledFilter
                    onChange={(e) => {
                      setSort({ ...sort, method: e.target.value });
                    }}
                    value={sort.method}
                  >
                    <option value="firstName">First Name</option>;
                    <option value="appliedAt">Date Created</option>;
                  </StyledFilter>
                  <StyledFilter
                    onChange={(e) => {
                      setSort({ ...sort, order: e.target.value });
                    }}
                    value={sort.order}
                  >
                    <option value="desc">Descending</option>;
                    <option value="asc">Ascending</option>;
                  </StyledFilter>
                </>
              ) : null}
            </Box>
          </Stack>

          {/* <Stack sx={{ display: "block" }} direction>
            <Box sx={{ display: "flex", gap: "25px" }}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Country
                </Typography>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                <CountrySelector
                  onChange={(e) => {
                    //  setValue("country", e.name);
                    console.log(e);
                    setLocation({ ...location, country: e });
                    if (e == null) {
                      setStatus({
                        ...status,
                        country: "",
                        city: "",
                        state: "",
                      });
                    } else {
                      setStatus({
                        ...status,
                        country: e.name,
                        city: "",
                        state: "",
                      });
                    }
                  }}
                  name="country"
                  placeholder="Select a country"
                  value={location.country}
                />
                </FormControl>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  State*
                </Typography>
                <StateSelector
                  country={location.country}
                  name="state"
                  value={location.state}
                  countryPlaceholder="Select a country first"
                  onChange={(e) => {
                    // setValue("state", e.name);

                    setLocation({ ...location, state: e });
                    if (e == null) {
                      setStatus({ ...status, state: "", city: "" });
                    } else {
                      setStatus({ ...status, state: e.name, city: "" });
                    }
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  City*
                </Typography>
                <CitySelector
                  country={location.country}
                  state={location.state}
                  name="city"
                  value={location.city}
                  statePlaceholder="Select a state first"
                  onChange={(e) => {
                    // setValue("city", e.name);
                    setLocation({ ...location, city: e });
                    if (e == null) {
                      setStatus({ ...status, city: "" });
                    } else {
                      setStatus({ ...status, city: e.name });
                    }
                  }}
                />
              </Box>
            </Box>
          </Stack> */}
          <Box
            sx={{
              mt: "30px",
            }}
          >
            <Box
              className={classes.hidescrollbar}
              sx={{
                ...displayOnDesktop,
                height: "45vh",
                overflowY: "scroll",
                my: "40px",
              }}
            >
              <TableContainer sx={{ borderRadius: "8px" }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead
                    sx={{
                      backgroundColor: "#25C027",
                      textTransform: "uppercase",
                      color: "#FFF",
                    }}
                  >
                    <TableRow
                      sx={{
                        "&:first-child td, &:first-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#FFF",
                          fontFamily: "Sofia Pro",
                          fontSize: "10px",
                          fontWeight: 500,
                          miWidth: "150px",
                        }}
                      >
                        Sl. No
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#FFF",
                          fontFamily: "Sofia Pro",
                          fontSize: "10px",
                          fontWeight: 500,
                        }}
                      >
                        Company Name
                      </TableCell>
                      <TableCell
                        sx={{
                          miWidth: "150px",
                          color: "#FFF",
                          fontFamily: "Sofia Pro",
                          fontSize: "10px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Active/Expired
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#FFF",
                          fontFamily: "Sofia Pro",
                          fontSize: "10px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Current Plan
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#FFF",
                          fontFamily: "Sofia Pro",
                          fontSize: "10px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        No Of Active Jobs
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#FFF",
                          fontFamily: "Sofia Pro",
                          fontSize: "10px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        View Company Profile
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Action
                      </TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ width: "100%" }}>
                    {allApplicants ? (
                      allApplicants.map((arr, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "50px",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ minWidth: "10px" }}
                          >
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ maxWidth: "100px" }}>
                            {arr.organisationName}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ miWidth: "150px", textAlign: "center" }}
                          >
                            {/* <img
                            style={{
                              width: "37px",
                              height: "37px",
                              background: "#D9D9D9",
                              borderRadius: "50%",
                            }}
                            src=""
                            alt="img"
                          /> */}
                            {arr.active ? "Active" : "Expired"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "110px", textAlign: "center" }}
                          >
                            {arr?.plan}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "100px", textAlign: "center" }}
                          >
                            {arr?.jobs?.length}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "100px", textAlign: "center" }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(
                                  `/super-admin-dashboard/company-list/${arr.id}`
                                )
                              }
                            >
                              <svg
                                width="18"
                                height="11"
                                viewBox="0 0 18 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.45 0.01718C5.47814 0.188422 2.67324 1.78025 0.437032 4.56463C-0.146851 5.29164 -0.146394 5.42081 0.442727 6.15632C2.67433 8.94256 5.4889 10.5335 8.47478 10.6964C11.8542 10.8809 15.0474 9.28417 17.563 6.15193C18.1469 5.42492 18.1464 5.29575 17.5573 4.56024C15.9271 2.52494 13.9876 1.12657 11.8429 0.440119C11.1766 0.226875 10.3694 0.0710623 9.73811 0.03377C9.04917 -0.00689659 8.90064 -0.0087946 8.45 0.01718ZM9.74342 1.65361C10.7536 1.84377 11.7243 2.53088 12.2501 3.42797C12.8846 4.51057 12.9515 5.82013 12.4289 6.9267C12.225 7.35853 12.0301 7.6394 11.6944 7.98537C9.81555 9.92158 6.61445 9.32962 5.52478 6.84442C5.13154 5.94758 5.12413 4.85204 5.50499 3.9172C5.87553 3.0077 6.68127 2.21135 7.59649 1.85013C8.2925 1.57544 8.98921 1.51165 9.74342 1.65361ZM8.63094 3.35342C8.05936 3.47704 7.55344 3.83358 7.26371 4.31701C7.05071 4.6724 6.97925 4.93625 6.98171 5.35828C6.98414 5.76551 7.02038 5.92976 7.18315 6.27122C7.33274 6.58495 7.7696 7.02044 8.0899 7.17513C9.21785 7.71979 10.5298 7.16578 10.9357 5.97337C11.0519 5.63226 11.0551 5.09748 10.9432 4.74171C10.6877 3.92975 9.94197 3.36397 9.08787 3.33405C8.91389 3.32797 8.70827 3.33669 8.63094 3.35342Z"
                                  fill="#B0AFAF"
                                />
                              </svg>
                            </Box>
                          </TableCell>
                          {/* <TableCell align="left">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#EAF9EA",
                              }}
                            >
                              <EmployeeDashboardComponentChattingTracedIcon />
                            </Box>
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#EAF9EA",
                              }}
                            >
                              <EmployeeDashboardComponentSeeTracedIcon />
                            </Box>
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#EAF9EA",
                              }}
                            >
                              <EmployeeDashboardComponentTickIcon />
                            </Box>
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#EAF9EA",
                              }}
                            >
                              <EmployeeDashboardComponentCrossedIcon />
                            </Box>
                          </Box>
                        </TableCell> */}
                        </TableRow>
                      ))
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Stack spacing={3} sx={{ ...displayOnMobile }}>
              {allApplicants ? (
                allApplicants.map((arr, index) => (
                  <Stack
                    key={index}
                    spacing={1}
                    sx={{
                      boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
                      padding: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    <Box sx={{ ...itemsCenter, gap: "10px" }}>
                      <Typography
                        sx={{
                          width: "30%",
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#25C027",
                          fontWeight: 600,
                        }}
                      >
                        Company name
                      </Typography>
                      <Typography
                        sx={{
                          width: "65%",
                          fontSize: "12px",
                          color: "#425466",
                          fontWeight: 600,
                        }}
                      >
                        {arr.organisationName}
                      </Typography>

                      <Box
                        sx={{
                          cursor: "pointer",
                          width: "5%",
                        }}
                        onClick={() =>
                          navigate(
                            `/super-admin-dashboard/company-list/${arr.id}`
                          )
                        }
                      >
                        <svg
                          width="18"
                          height="11"
                          viewBox="0 0 18 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.45 0.01718C5.47814 0.188422 2.67324 1.78025 0.437032 4.56463C-0.146851 5.29164 -0.146394 5.42081 0.442727 6.15632C2.67433 8.94256 5.4889 10.5335 8.47478 10.6964C11.8542 10.8809 15.0474 9.28417 17.563 6.15193C18.1469 5.42492 18.1464 5.29575 17.5573 4.56024C15.9271 2.52494 13.9876 1.12657 11.8429 0.440119C11.1766 0.226875 10.3694 0.0710623 9.73811 0.03377C9.04917 -0.00689659 8.90064 -0.0087946 8.45 0.01718ZM9.74342 1.65361C10.7536 1.84377 11.7243 2.53088 12.2501 3.42797C12.8846 4.51057 12.9515 5.82013 12.4289 6.9267C12.225 7.35853 12.0301 7.6394 11.6944 7.98537C9.81555 9.92158 6.61445 9.32962 5.52478 6.84442C5.13154 5.94758 5.12413 4.85204 5.50499 3.9172C5.87553 3.0077 6.68127 2.21135 7.59649 1.85013C8.2925 1.57544 8.98921 1.51165 9.74342 1.65361ZM8.63094 3.35342C8.05936 3.47704 7.55344 3.83358 7.26371 4.31701C7.05071 4.6724 6.97925 4.93625 6.98171 5.35828C6.98414 5.76551 7.02038 5.92976 7.18315 6.27122C7.33274 6.58495 7.7696 7.02044 8.0899 7.17513C9.21785 7.71979 10.5298 7.16578 10.9357 5.97337C11.0519 5.63226 11.0551 5.09748 10.9432 4.74171C10.6877 3.92975 9.94197 3.36397 9.08787 3.33405C8.91389 3.32797 8.70827 3.33669 8.63094 3.35342Z"
                            fill="#B0AFAF"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <Box sx={{ ...itemsCenter, gap: "10px" }}>
                      <Typography
                        sx={{
                          width: "30%",
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#25C027",
                          fontWeight: 600,
                        }}
                      >
                        {arr.active ? "Active" : "Expired"}
                      </Typography>
                      <Typography
                        sx={{
                          width: "70%",
                          fontSize: "12px",
                          color: "#425466",
                          fontWeight: 600,
                        }}
                      >
                        Active
                      </Typography>
                    </Box>
                    <Box sx={{ ...itemsCenter, gap: "10px" }}>
                      <Typography
                        sx={{
                          width: "30%",
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#25C027",
                          fontWeight: 600,
                        }}
                      >
                        active jobs
                      </Typography>
                      <Typography
                        sx={{
                          width: "70%",
                          fontSize: "12px",
                          color: "#425466",
                          fontWeight: 600,
                        }}
                      >
                        {arr?.jobs?.length}
                      </Typography>
                    </Box>
                    <Box sx={{ ...itemsCenter, gap: "10px" }}>
                      <Typography
                        sx={{
                          width: "30%",
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "#25C027",
                          fontWeight: 600,
                        }}
                      >
                        current plan
                      </Typography>
                      <Typography
                        sx={{
                          width: "70%",
                          fontSize: "12px",
                          color: "#425466",
                          fontWeight: 600,
                        }}
                      >
                        {arr?.plan}
                      </Typography>
                    </Box>
                  </Stack>
                ))
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Box>
        <Modal
          open={loading}
          // onClose={}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack
            border={5}
            sx={{
              width: "100%",
              height: "100vh",
            }}
            justifyContent={"center"}
            alignItems="center"
          >
            <LoadingSearchComponents />
          </Stack>
        </Modal>
      </Container>
    </Box>
  );
};

export default SuperAdminDashboardCompanyList;
