import styled from "@emotion/styled";
import TelegramIcon from "@mui/icons-material/Telegram";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  handleStatusChange,
  inviteCheck,
  inviteUser
} from "../../../firebase/functions";
import HireModal from "./HireModal";
const StyledFilter = styled("select")({
  // width: "150px",
  height: "40px",
  fontSize: "14px",
  color: "white",
  backgroundColor: "#24BD2C",
  // margin: "20px",
  padding: "0px 10px 0px 10px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "1px solid #FFF",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});

export const StatusDrop = ({
  overView,
  setStatus,
  jobId,
  noofAcceptReject,
  setNoofAcceptReject,
  tag,
  offerStatus,
  info,
}) => {
  const [open, setOpen] = useState(false);
  const [invite, setInvite] = useState("Invite");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("overView", overView);
    // console.log(overView, "Overview");
    console.log("useeffectoverview");
    inviteCheck({
      jobId: jobId ? jobId : overView.jobId,
      userId: overView?.appliedBy,
    })
      .then(({ data }) => {
        if (data == true) {
          setInvite("Invited");
        }
        if (data == false) {
          setInvite("Invite");
        }
      })
      .catch((e) => {});
  }, [info]);
  const handleChange = (e) => {
    if (e.target.value == "hired") {
      setOpen(true);
    } else {
      handleStatusChange({
        jobId: overView?.jobId ? overView.jobId : jobId,
        applicantId: overView?.appliedBy,
        status: e.target.value,
      })
        .then(() => {
          if (noofAcceptReject) {
            setNoofAcceptReject(noofAcceptReject[tag] - 1);
            setNoofAcceptReject(noofAcceptReject[e.target.value] + 1);
          }
          toast.success("Status updated successfully");
          setStatus(e.target.value);
        })
        .catch((e)=>{
          toast.error(e.message);
        })
    }
  };
  const handleInvite = () => {
    setLoading(true);
    inviteUser({ candidateId: overView.appliedBy, jobId: jobId }).then(
      ({ data }) => {
        toast.success("Invitation sent successfully");
        setLoading(false);
        setInvite("Invited");
      }
    ).catch((e)=>{
      setLoading(false);
      toast.error(e.message);
    })
  };
  return (
    <>
      {/* {`${JSON.stringify(overView.status)}`} */}
      <HireModal
        open={open}
        setOpen={setOpen}
        jobId={overView?.jobId ? overView.jobId : jobId}
        applicantId={overView?.appliedBy}
        setStatus={setStatus}
        offerStatus={offerStatus}
      />
      {/* {`${JSON.stringify(overView.status)}`} */}
      {overView?.status == "resumeDatabase" ||  overView?.status=="suggestedCandidates" ||
      invite == "invited" ||
      !overView?.status ? (
        <LoadingButton
          variant="contained"
          sx={{
            color: "#fff",
            // bgColor:"#24BD2C",
            backgroundColor: "#24BD2C",
            "&:hover": {
              backgroundColor: "#28b52a",
            },
            "&:focus": {
              backgroundColor: "#28b52a",
            },
          }}
          loadingIndicator={<CircularProgress sx={{ color: "#28b52a" }} />}
          loading={loading}
          disabled={invite == "Invited"}
          onClick={() => {
            handleInvite();
          }}
          startIcon={<TelegramIcon />}
        >
          {invite}
        </LoadingButton>
      ) : offerStatus == "offerRejected" ? (
        <>
          <Button onClick={() => setOpen(true)} sx={{ color: "#28b52a" }}>
            Re Offer <TelegramIcon />
          </Button>
        </>
      ) : (
        <StyledFilter
          onChange={(e) => {
            handleChange(e);
          }}
        >
          {/* <option selected disabled value="">
            status
          </option> */}
          {overView?.status != "hired" && overView?.status != "offerRevised" ? (
            <>
              {" "}
              <option selected={overView?.status == "pending"} value="pending">
                Yet To Be Reviewed
              </option>
              <option
                selected={overView?.status == "accepted"}
                value="accepted"
              >
                Shortlisted
              </option>{" "}
            </>
          ) : null}
          <option selected={overView?.status == "hired" ||overView?.status == "offerRevised"} value="hired">
            Hired
          </option>
          <option selected={overView?.status == "rejected"} value="rejected">
            Rejected
          </option>
        </StyledFilter>
      )}
    </>
  );
};
