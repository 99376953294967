import { Box, Container, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import {
  getAllJobsAppliedId,
  getBookmarks,
  getOffers,
  suggestedJobs,
} from "../../firebase/functions";
import JobcardCompanyProfile from "../../pages/CompanyProfile/JobcardCompanyProfile";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsDownArrowIcon,
  EmployeeDashboardManageJobsSeeTracedIcon,
} from "../../utilities/Icons/Icons";
import ApplicationCardCandidate from "./ApplicationCardCandidate";
import BookmarkedCard from "./BookmarkedCard";
import classes from "./CandidateDashboardAppliedJobs.module.css";
import TableRowComponent from "./TableRowComponent";

const SuggestedJobs = () => {
  const fakeArr = [1, 2, 3, 4, 5];
  const [bookmarks, setBookmarks] = useState([]);
  useEffect(() => {
    suggestedJobs().then(({ data }) => {
      console.log(data)
      setBookmarks(data);
    });
  }, []);
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Suggested Jobs
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "30px",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            ></Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#F0FAF0",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography>
            <EmployeeDashboardManageJobsDownArrowIcon />
          </Box> */}
        </Box>

        <Box sx={{ my: "0px" }}>
          <Stack
            className={classes.hidescrollbar}
            spacing={2}
            sx={{ height: "60vh", overflowY: "scroll", mt: "25px" }}
          >
            {bookmarks?.map((item, index) => (
              <BookmarkedCard jobId={item} />
            ))}
          </Stack>

          {/* <TableContainer>
          
          </TableContainer> */}
        </Box>
      </Box>
    </Container>
  );
};

export default SuggestedJobs;
