import styled from "@emotion/styled";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "react-select";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  FormControl,
  Link,
  Modal,
  Pagination,
  Skeleton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { City, Country, State } from "country-state-city";
import { useCountries } from "use-react-countries";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import MobileMenu from "../../components/SharedComponents/MobileMenu/MobileMenu";
import JobNotFound from "../JobSearchPage/JobNotFound";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import {
  getApplfronJobId,
  getFormField,
  getJobTitles,
  resumeDatabase,
  suggestedCandidates
} from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveFlexDirection,
  responsiveWidth
} from "../../utilities/commonStyles/commonStyles";
import CandidateCard from "./CandidateCard";
import OverView from "./OverView";
//import { CitySelector, CountrySelector, StateSelector } from "volkeno-react-country-state-city";
import { collection, collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { GetType } from "../../context/authContext";
const StyledFilter = styled("select")({
  width: "80%",
  height: "40px",
  fontSize: "14px",
  color: "black",
  padding: "0px 20px 0px 20px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "1px solid #25C027",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});

const CandidatesList = () => {
  // const { countries } = useCountries();
  // const names = countries.map((country) => country.name);
  const [location, setLocation] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [candidatesStatus, setcandidatesStatus] = useState("All");
  const [overView, setOverView] = useState({});
  const [jobTitles, setJobTitles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setloading] = useState(false);
  const [options, setOptions] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [experienceArray, setExperienceArray] = useState([]);
  const [educationArray, setEducationArray] = useState([]);
  const [skillArray, setSkillArray] = useState([]);
  const userType = GetType();
  //!Country-State-City
  const [values, setValues] = useState({
    country: null,
    state: null,
    city: null,
  });
  const [cityMulti, setCityMulti] = useState([]);

  const countries = Country.getAllCountries();
  const data = () => {
    console.log(countries);
    console.log(updatedStates);
    console.log(values.state);
    console.log(values.country);
    const value = updatedCities(
      // "AF",
      // "BDS"
      values.country ? values.country.value : null,
      values.state ? values.state : null
    );
    // const value = updatedStates(values.country ? values.country.value : null);
    console.log(value);
  };
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));
  const updatedStates = (countryId) =>
    State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.isoCode,
    }));
  const updatedCities = (countryId, stateId) =>
    City.getCitiesOfState(countryId, stateId).map((city) => ({
      label: city.name,
      value: city.name,
    }));


  useEffect(() => {
    getFormField({ form: "skills" })
      .then(({ data }) => {
        console.log("Skills", data);
        setSkillArray(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    getFormField({ form: "experience" })
      .then(({ data }) => {
        console.log("Experience", data);
        setExperienceArray(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    getFormField({ form: "education" })
      .then(({ data }) => {
        console.log("Education", data);
        setEducationArray(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setloading(true);
    // getJobTitles()
    //   .then(({ data }) => {
    //     setJobTitles(data);
    //     setOptions({
    //       ...options,
    //       jobTitles: data[0].id,
    //       status: "all",
    //       experience: "Any",
    //       educationLevel: "Any",
    //       skills: [],
    //       country: "",
    //       state: "",
    //       city: "",
    //     });
    //   })
    //   .catch((e) => {
    //     setloading(false);
    //     toast.error(e.message);
    //   });
    if (userType.organisation != undefined) {
      soln();
    }
  }, [userType]);
 const soln = async () => {
   const fetchApplications = query(
     collection(db, "Jobs"),
     where("organisationId", "==", userType.organisation)
   );
   await getDocs(fetchApplications)
     .then((data) => {
       setloading(false);
       setJobTitles(
         data.docs.map((job) => {
           console.log(job.data());
           return { title: job.data().jobTitle, id: job.id };
         })
       );
       setOptions({
         ...options,
         jobTitles: data.docs[0].id,
         status: "all",
          experience: "Any",
          educationLevel: "Any",
          skills: [],
          country: "",
          state: "",
          city: "",
       });
     })
     .catch((e) => {
       console.log(e);
       setloading(false);
     });
 };
  useEffect(() => {
    setloading(true);
    if (options.jobTitles != undefined) {
     getJobData();
      // getApplfronJobId({ jobId: options.jobTitles })
      //   .then(({ data }) => {
      //     setCandidates(data);
      //     setOverView(data[0]);
      //     console.log(data[0]);
      //     setloading(false);
      //     setOriginalData(data);
      //     setToggle(!toggle);
      //   })
      //   .catch((e) => {
      //     setloading(false);
      //     toast.error(e.message);
      //   });
    }
  }, [options.jobTitles]);
  const getJobData = async () => {
    const fetchApplications = query(
      collectionGroup(db, "Applications"),
      where("jobId", "==", options.jobTitles)
    );
    await getDocs(fetchApplications)
      .then((data) => {
        if (data.size != 0) {
          console.log(data);
          setCandidates(data.docs.map((doc) => doc.data()).reverse());
          setOriginalData(data.docs.map((doc) => doc.data()).reverse());
          setOverView(data.docs[0].data());
          setToggle(!toggle);
          setloading(false);
        } else {
          setToggle(!toggle);
          setloading(false);
          
        }
      })
      .catch((e) => {
       setloading(false);
        toast.error(e.message);
      });
  };
  useEffect(() => {
    if (options.status == "resumeDatabase") {
      setloading(true);
      resumeDatabase({
        jobId: options.jobTitles,
        options,
        candidateStatus: candidatesStatus,
      })
        .then(({ data }) => {
          const mappedData = data.map((data) => {
            return {
              appliedBy: data.id,
              status: "resumeDatabase",
            };
          });
          setCandidates(mappedData);
          setOverView(mappedData[0]);
          setloading(false);
        })
        .catch((e) => {
          setloading(false);
          toast.error(e.message);
         // alert("268")
        });
      return;
    }
    if (options.status == "suggestedCandidates") {
      setloading(true);
      suggestedCandidates({ jobId: options.jobTitles, options })
        .then(({ data }) => {
          const mappedData = data.map((data) => {
            return {
              appliedBy: data.id,
              status: "resumeDatabase",
            };
          });
          setCandidates(mappedData);
          setOverView(mappedData[0]);
          setloading(false);
        })
        .catch((e) => {
          setloading(false);
          toast.error(e.message);
          //alert("289")
        });
      return;
    }
    console.log(options);
    const filterData = originalData
      .filter((item) => {
        return options.status == "all"
          ? 1
          : item.status === options.status ||
              (options.status == "hired" && item.status == "offerRevised");
      })
      ?.filter((item) => {
        return options.experience == "Any"
          ? 1
          : item.experience === options.experience;
      })

      ?.filter((item) => {
        return options.educationLevel == "Any"
          ? 1
          : item.educationLevel === options.educationLevel;
      })
      ?.filter((item) => {
        return options.skills.length == 0
          ? 1
          : options?.skills?.some((skill) => item?.skills?.includes(skill));
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return options.country == "" ? 1 : item.country === options.country;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return options.state == "" ? 1 : item.state === options.state;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return options.city.length == 0 ? 1 : options?.city?.includes(item?.city);
      })
      // ?.filter((item) => {
      //   // console.log(item, "working");
      //   return options.city == "" ? 1 : item.city === options.city;
      // });
    console.log(filterData);
    setCandidates(filterData);
    setOverView(filterData[0]);
  }, [options, toggle, candidatesStatus]);

  // useEffect(() => {
  //   getCandidateIds().then(({ data }) => {
  //     //
  //     setCandidates(data);
  //     setOriginalData(data);
  //     setOverView(data[0]);
  //   });
  //   getJobTitles().then(({ data }) => {
  //     setJobTitles(data);
  //     setOptions({
  //       ...options,
  //       jobTitles: data[0].id,
  //       status: "all",
  //       experience: "Any",
  //     });
  //   });
  // }, []);

  // useEffect(() => {
  //   if (options.status == "resumeDatabase") {
  //
  //     resumeDatabase({ jobId: options.jobTitles }).then(({ data }) => {
  //
  //       const mappedData = data.map((data) => {
  //         return {
  //           appliedBy: data.id,
  //           status: "resumeDatabase",
  //         };
  //       });
  //       setCandidates(mappedData);
  //       setOverView(mappedData[0]);
  //     });
  //     return;
  //   }
  //   //
  //
  //   const filteredData = originalData.filter((item) => {
  //     return item.jobId === options.jobTitles && options.status == "all"
  //       ? 1
  //       : item.status === options.status && options.experience == "Any"
  //       ? 1
  //       : item.experience == options.experience;
  //   });
  //
  //   setCandidates(filteredData);
  //   setOverView(filteredData[0]);
  // }, [options]);

  return (
    <Box>
      {/* <ColorNavBar /> */}
      {/* {JSON.stringify(options)} */}
      <Box sx={displayOnDesktop}>
        <NavBar color={"green"} />
      </Box>
      <Box sx={displayOnMobile}>
        <MobileMenu color={"green"} />
      </Box>
      {/* <Box
        style={{
          backgroundImage: `url(${FindJobsBgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ color: "#fff", fontSize: "30px", fontWeight: 500 }}>
            All Resumes
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "15px",
              fontWeight: 500,
              mt: "15px",
            }}
          >
            Home / Jobs
          </Typography>
        </Box>
      </Box> */}
      {/* filters */}

      <Box
        sx={{
          ...responsiveFlexDirection,
          width: "100%",
          px: { xs: "20px", md: "50px" },
          display: "flex",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "20%" },
            boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
          }}
        >
          <Box sx={{ py: "40px", borderBottom: "1px solid #ECEDF2" }}>
            <StyledFilter
              onChange={(e) => {
                setOptions({ ...options, jobTitles: e.target.value });
              }}
              style={{ backgroundColor: "#25C027", color: "#FFF" }}
            >
              {jobTitles.map((job, index) => {
                return (
                  <option key={job.id} value={job.id}>
                    {job.title}
                  </option>
                );
              })}
            </StyledFilter>
          </Box>

          {/* <Box
            sx={{
              border: "1px solid #B7B7B7",
              borderRadius: "10px",
              overflow: "hidden",
              width: "80%",
            }}
          >
            <Box
              sx={{
                ...flexBetweenWithItemsCenter,
                padding: "10px",
                borderBottom: "1px solid #B7B7B7",
                backgroundColor: "#D3F2D4",
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>All Applicants</Typography>
              <Typography
                sx={{
                  backgroundColor: "#B5D4B6",
                  padding: "2px",
                  borderRadius: "10px",
                  fontSize: "8px",
                  minWidth: "30px",
                  textAlign: "center",
                }}
              >
                200
              </Typography>
            </Box>

            <Box
              sx={{
                ...flexBetweenWithItemsCenter,
                padding: "10px",
                borderBottom: "1px solid #B7B7B7",
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>
                Yet to be reviewed
              </Typography>
              <Typography
                sx={{
                  backgroundColor: "#FFDA41",
                  padding: "2px",
                  borderRadius: "10px",
                  fontSize: "8px",
                  minWidth: "30px",
                  textAlign: "center",
                }}
              >
                80
              </Typography>
            </Box>

            <Box
              sx={{
                ...flexBetweenWithItemsCenter,
                padding: "10px",
                borderBottom: "1px solid #B7B7B7",
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>Shortlisted</Typography>
              <Typography
                sx={{
                  backgroundColor: "#FFDA41",
                  padding: "2px",
                  borderRadius: "10px",
                  fontSize: "8px",
                  minWidth: "30px",
                  textAlign: "center",
                }}
              >
                40
              </Typography>
            </Box>

            <Box
              sx={{
                ...flexBetweenWithItemsCenter,
                padding: "10px",
                borderBottom: "1px solid #B7B7B7",
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>Rejected</Typography>
              <Typography
                sx={{
                  backgroundColor: "#FF3365",
                  padding: "2px",
                  borderRadius: "10px",
                  fontSize: "8px",
                  minWidth: "30px",
                  textAlign: "center",
                }}
              >
                100
              </Typography>
            </Box>

            <Box
              sx={{
                ...flexBetweenWithItemsCenter,
                padding: "10px",
                borderBottom: "1px solid #B7B7B7",
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>Hired</Typography>
              <Typography
                sx={{
                  backgroundColor: "#25C027",
                  padding: "2px",
                  borderRadius: "10px",
                  fontSize: "8px",
                  minWidth: "30px",
                  textAlign: "center",
                }}
              >
                10
              </Typography>
            </Box>

            <Box
              sx={{
                ...flexBetweenWithItemsCenter,
                padding: "10px",
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>Resume database</Typography>
              <Typography
                sx={{
                  backgroundColor: "#FFDA41",
                  py: "2px",
                  borderRadius: "10px",
                  fontSize: "8px",
                  minWidth: "30px",
                  textAlign: "center",
                }}
              >
                70
              </Typography>
            </Box>
          </Box> */}

          <Stack spacing={3} sx={{ mt: "40px" }}>
            <Typography>Filters</Typography>
            <StyledFilter
              onChange={async (e) => {
                setOptions({ ...options, status: e.target.value });
              }}
            >
              {/* “Yet To Be Reviewed”, “Shortlisted”, “Hired", "Offer Accepted", "Offer Declined”, “Rejected” */}
              <option selected value="all">
                All Applicants
              </option>
              <option value="pending">Yet To Be Reviewed</option>
              <option value="accepted">Short Listed</option>
              <option value="rejected">Rejected</option>
              <option value="hired">Hired</option>
              <option value="resumeDatabase">Resume Database</option>
              <option value="suggestedCandidates">Suggested candidates</option>
              {/* <option value="offerAccepted">Offer Accepted</option>
                    <option value="offerDeclined">Offer Declined</option> */}
            </StyledFilter>
            <Box>
              {options.status != "suggestedCandidates" ? (
                <Autocomplete
                  color="green"
                  sx={{
                    width: "80%",
                    borderRadius: "10px",
                    color: "#000",
                  }}
                  multiple
                  id="tags-standard"
                  options={skillArray}
                  getOptionLabel={(option) => option}
                  value={options.skills}
                  onChange={(e, value) => {
                    console.log(value);

                    setOptions({ ...options, skills: value });
                  }}
                  defaultValue={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Skills"
                      placeholder="Skills"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              ) : null}
            </Box>
            {options.status == "resumeDatabase" ? (
              <StyledFilter
                onChange={async (e) => {
                  setcandidatesStatus(e.target.value);
                }}
              >
                <option selected value="All">
                  Status
                </option>
                <option value="Actively looking">Actively looking</option>
                <option value="Open to New Opportunities">
                  Open to New Opportunities
                </option>
                <option value="Not Looking For Work At This Time">
                  Not Looking For Work At This Time
                </option>
              </StyledFilter>
            ) : null}
            <StyledFilter
              onChange={(e) => {
                setOptions({ ...options, experience: e.target.value });
              }}
            >
              <option selected value="Any">
                Experience
              </option>
              {experienceArray.map((exp, index) => {
                return <option value={exp}>{exp}</option>;
              })}
            </StyledFilter>
            <StyledFilter
              onChange={(e) => {
                setOptions({ ...options, educationLevel: e.target.value });
              }}
            >
              <option selected value="Any">
                Education Level
              </option>
              {educationArray.map((exp, index) => {
                return <option value={exp}>{exp}</option>;
              })}
            </StyledFilter>
            {/* <Autocomplete
              sx={{
                minWidth: "300px",
              }}
              multiple
              id="tags-standard"
              options={languageArray}
              getOptionLabel={(option) => option}
              value={options.skills}
              onChange={(e, value) => {
                console.log(value);

                setOptions({ ...options, languages: value });
              }}
              defaultValue={[]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Languages"
                  placeholder="Languages"
                />
              )}
            /> */}
          </Stack>
          <Stack spacing={3}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              ></Typography>
              <FormControl sx={{ width: "80%", mt: "20px" }}>
                <Select
                  id="country"
                  name="country"
                  placeholder="Country"
                  label="country"
                  isClearable={true}
                  options={updatedCountries}
                  value={values.country}
                  onChange={(value) => {
                    console.log(value);
                    if (value != null) {
                      setOptions({
                        ...options,
                        country: value.label,
                        state: "",
                        city: "",
                      });
                      setValues({ country: value, state: null, city: null });
                    } else {
                      setOptions({
                        ...options,
                        country: "",
                        state: "",
                        city: "",
                      });
                      setValues({
                        country: value,
                        state: null,
                        city: null,
                      });
                    }
                    setCityMulti([]);
                  }}
                />
                {/* <CountrySelector
                onChange={(e) => {
                  //  setValue("country", e.name);
                  console.log(e);
                  setLocation({ ...location, country: e });
                  if (e == null) {
                    setOptions({
                      ...options,
                      country: "",
                      city: "",
                      state: "",
                    });
                  } else {
                    setOptions({
                      ...options,
                      country: e.name,
                      city: "",
                      state: "",
                    });
                  }
                }}
                name="country"
                placeholder="Select a country"
                value={location.country}
              /> */}
              </FormControl>
            </Box>
            <Box sx={{ width: "80%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              ></Typography>
              <Select
                id="state"
                name="state"
                isClearable
                placeholder="State"
                options={updatedStates(
                  values.country ? values.country.value : null
                )}
                value={values.state}
                onChange={(value) => {
                  if (value != null) {
                    setOptions({
                      ...options,
                      state: value.label,
                      city: "",
                    });
                    setValues({ ...values, state: value, city: null });
                  } else {
                    setOptions({
                      ...options,
                      state: "",
                      city: "",
                    });
                    setValues({ ...values, state: value, city: null });
                  }

                  setCityMulti([]);
                }}
              />
              {/* <StateSelector
                country={location.country}
                name="state"
                value={location.state}
                countryPlaceholder="Select a country first"
                onChange={(e) => {
                  // setValue("state", e.name);

                  setLocation({ ...location, state: e });
                  if (e == null) {
                    setOptions({ ...options, state: "", city: "" });
                  } else {
                    setOptions({ ...options, state: e.name, city: "" });
                  }
                }}
              /> */}
            </Box>
            <Box sx={{ width: "80%" }}>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
              ></Typography>
              <Select
                id="city"
                name="city"
                isMulti
                placeholder="City"
                options={updatedCities(
                  values.country ? values.country.value : null,
                  values.state ? values.state.value : null
                )}
                value={cityMulti}
                onChange={(e) => {
                  console.log(e);
                  let arr = [];
                  for (let i = 0; i < e.length; i++) {
                    arr.push(e[i].label);
                  }
                  setOptions({
                    ...options,
                    city: arr,
                  });
                  setCityMulti(Array.isArray(e) ? e.map((x) => x) : []);
                }}
                //onChange={(value) => setValues({...values,city: value})}
              />
              {/* <CitySelector
                country={location.country}
                state={location.state}
                name="city"
                value={location.city}
                statePlaceholder="Select a state first"
                onChange={(e) => {
                  // setValue("city", e.name);
                  setLocation({ ...location, city: e });
                  if (e == null) {
                    setOptions({ ...options, city: "" });
                  } else {
                    setOptions({ ...options, city: e.name });
                  }
                }}
              /> */}
            </Box>
          </Stack>
        </Box>

        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          {false ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="500px"
            />
          ) : (
            <Box>
              <Stack>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" key="1" color="inherit">
                    Applicants
                  </Link>
                  ,
                  <Link underline="hover" key="2" color="inherit">
                    {
                      jobTitles?.filter(
                        (item) => item.id === options.jobTitles
                      )[0]?.title
                    }
                  </Link>
                  ,
                  <Typography key="3" color="text.primary">
                    {overView?.firstName}
                  </Typography>
                  ,
                </Breadcrumbs>
              </Stack>
              {candidates.length == 0 && !loading ? (
                <JobNotFound />
              ) : (
                <Box sx={{ display: "flex", gap: "40px", my: "70px" }}>
                  <Box sx={{ ...responsiveWidth }}>
                    <Stack spacing={2}>
                      {candidates
                        ?.map((candidate, index) => {
                          return (
                            <CandidateCard
                              overView={overView}
                              setOverView={setOverView}
                              id={candidate}
                              jobId={options.jobTitles}
                              filter={options.status}
                            />
                          );
                        })
                        .slice((pageNumber - 1) * 5, (pageNumber - 1) * 5 + 5)}
                    </Stack>
                  </Box>
                  <Box sx={{ ...displayOnDesktop, width: "50%" }}>
                    {overView?.status ? (
                      <OverView
                        jobId={options.jobTitles}
                        filter={options.status}
                        overView={overView}
                      />
                    ) : null}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          onChange={(e, pageNumber) => {
            setPageNumber(pageNumber);
          }}
          // color="primary.main"
          sx={{
            backgroundColor: "#fff",
            color: "#25C027",
          }}
          count={
            Math.ceil(candidates.length / 5)
              ? Math.ceil(candidates.length / 5)
              : 1
          }
        />
      </Box>
      {/* <InstantMessageFeture />
      <Recruiting /> */}
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

const languageArray = [
  // international languages
  "English",
  "French",
  "German",
  "Spanish",
  "Chinese",
  "Japanese",
  "Korean",
  "Hindi",
  "Arabic",
  "Portuguese",
  "Russian",
  "Turkish",
  "Urdu",
  "Persian",
  "Telugu",
  "Marathi",
  "Tamil",
  "Bengali",
  "Punjabi",
  "Gujarati",
  "Oriya",
  "irish",
];

export default CandidatesList;
