import { Box, Button, Typography } from "@mui/material";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import {
  addBookmarks,
  getJobOverview,
  getLogo
} from "../../firebase/functions";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import {
  flexCenter, itemsCenter
} from "../../utilities/commonStyles/commonStyles";
import {
  FeturedJobsClockIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
  FeturedJobsMoneyIcon
} from "../../utilities/Icons/Icons";

function BookmarkedCard({ jobId, orgData, book }) {
  const navigate = useNavigate();
  TimeAgo.addDefaultLocale(en);
  TimeAgo.addLocale(ru);
  const [jobData, setJobData] = React.useState({});
  const [image, setImage] = useState("");
  const [removed, setRemoved] = useState(false);
  useEffect(() => {
    getJobOverview({ jobId }).then(({ data }) => {
      setJobData(data);
      getLogo({ orgId: data.organisationId }).then(({ data }) => {
        setImage(data);
      });
    });
  }, []);
  const handleRemove = (e) => {
    e.stopPropagation();
    addBookmarks({ jobId }).then(({ data }) => {
      setRemoved(true);
    });
  };
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        return navigate(`/job-profile/${jobId}`);
      }}
      sx={{
        display: removed ? "none" : "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: { xs: "10px", md: "30px" },
        background: "#fff",
        padding: { xs: "15px", md: "30px" },
        borderRadius: "20px",
        boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
        "&:hover": {
          background: "#25C027",
          color: "#fff",
          transition: "0.4s ease",
        },
      }}
    >
      {/* {JSON.stringify(jobData)} */}
      <Box sx={{ display: "flex", gap: { xs: "10px", md: "20px" } }}>
        <Box
          sx={{
            backgroundColor: "#262E50",
            width: "60px",
            height: "60px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ width: "52px", height: "20px" }} src={image} alt="" />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ...itemsCenter,
              gap: { xs: "10px", md: "20px" },
              flexWrap: "wrap",
            }}
          >
            <Typography
              sx={{
                color: "#0E0E0E",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              {jobData?.jobTitle}
            </Typography>
            {book ? (
              <Button color="error" onClick={(e) => handleRemove(e)}>
                Remove
              </Button>
            ) : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "5px", md: "30px" },
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsFordIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {jobData?.organisationName}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsLocationIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {jobData.city},{jobData.state}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsClockIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {jobData.jobAddedAt ? (
                  <ReactTimeAgo
                    date={fireBaseTime(jobData?.jobAddedAt).toUTCString()}
                    locale="en-US"
                  />
                ) : null}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsMoneyIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {`$${jobData.minSalary} - $${jobData.maxSalary} /${jobData.salaryType}`}
              </Typography>
            </Box>

            <Box
              sx={{
                ...flexCenter,
                color: "#1967D2",
                background: "#DDE8F8",
                width: "90px",
                height: "25px",
                fontFamily: "Sofia Pro",
                fontSize: "13px",
                borderRadius: "20px",
                mt:"10px"
              }}
            >
              {jobData.jobType}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BookmarkedCard;
