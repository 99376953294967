import { Box, Container, Stack, Typography } from "@mui/material";
import LetTheCompanyFindYouImage from "../../../utilities/images/let-the-company-find-you.png";

const LetTheCompanyFindYouMobile = () => {
  return (
    <Box sx={{ my: "100px" }}>
      <Container>
        <Stack spacing={3} sx={{ textAlign: "center" }}>
          <Box>
            <Typography
              sx={{ color: "#202124", fontWeight: 700, fontSize: "28px" }}
            >
              Make your <span style={{ color: "#24BD2C" }}>Job </span>search{" "}
              <span style={{ color: "#24BD2C" }}> stress-free </span>
            </Typography>
          </Box>
          <Box>
            <img
              style={{ width: "100%" }}
              src={LetTheCompanyFindYouImage}
              alt=""
            />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
                mt: "10px",
              }}
            >
              MFGWorx is a mobile-friendly platform built for job seekers. Not
              only can you apply to top jobs in the industry, MFGWorx has
              features to make your job search experience seamless. As a job
              seeker, you can create a profile, upload your resume (don’t have
              one? No problem! Use our simple resume builder), apply to amazing
              opportunities, accept job offers, and more!
            </Typography>
            {/* <Button
              sx={{
                textTransform: "none",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontFamily: "",
                backgroundColor: "#24BD2C",
                color: "#fff",
                padding: "14px 24px",
                borderRadius: "8px",
                mt: "20px",
                "&:hover": {
                  backgroundColor: "#24BD2C",
                },
              }}
            >
              Get Started
            </Button> */}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default LetTheCompanyFindYouMobile;
