import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { recruiterSignUp, signUp } from "../../firebase/functions";
import {
  RegisterCandidateIcon,
  RegisterEmployerIcon,
  RegisterFacebookIcon,
  RegisterGoogleIcon
} from "../../utilities/Icons/Icons";
import classes from "../Login/Login.module.css";
// import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";

import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import "./SignUp.css";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { db } from "../../firebase/firebaseConfig";
import { collection, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";

const SignUp = () => {
  const [userStatus, setUserStatus] = useState("Candidate");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [body, setBody] = useState({});
  const [phvalid, setPhvalid] = useState(true);
  const {
    handleSubmit,
    setValue,
    register,
    setError,
    formState: { errors },
  } = useForm();
  const statusTab = (status) => {
    setUserStatus(status);
  };
  const handleSignup = async (data) => {
    setIsLoading(true);
    if (data.cPassword !== data.password) {
      setError("cPassword", {
        type: "custom",
        message: "Password do not match",
      });
      setError("password", {
        type: "custom",
        message: "Password do not match",
      });

      setIsLoading(false);
      return;
    }
    try {
      if (userStatus === "Candidate") {
        await signUp({
          ...data,
          phoneNumber: "+" + data.phoneNumber,
        }).then(() => {
          toast.success("Signup Successful");
          navigate("/registration/login");
            setIsLoading(false);
        }).catch((e) => {
          toast.success("Something Went Wrong");
          // navigate("/registration/login");
            setIsLoading(false);
        })
        
      } else {
       await recruiterSignUp({
          ...data,
          phoneNumber: "+" + data.phoneNumber,
        }).then(() => {
          toast.success("Signup Successful");
          navigate("/registration/login");
            setIsLoading(false);
        }).catch((e) => {
          setIsLoading(false);
           toast.success("Something Went Wrong");
        })
        
      
      }
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };
  const googleLogin=async()=>{
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        console.log("LOGGED IN",user);
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          if (userStatus === "Candidate") {
            await setDoc(doc(db, "Users", user.uid), {
                firstName: user.displayName.split(' ')[0],
                lastName: user.displayName.split(' ').length>1?user.displayName.split(' ')[1]:"",
                email: user.email,
                phoneNumber: user.phoneNumber,
                type: "user",
                isPremium: false,
                profileComplete: false,
                createdAt: Timestamp.now(),
                displayImage:user.photoURL
              });
              toast.success("Signup Successful");
              navigate("/");
          }
          else{
            await setDoc(doc(db, "Users", user.uid), {
                firstName: user.displayName.split(' ')[0],
                lastName: user.displayName.split(' ').length>1?user.displayName.split(' ')[1]:"",
                email: user.email,
                phoneNumber: user.phoneNumber,
                type: "Recruiter",
                isPremium: false,
                profileComplete: false,
                createdAt: Timestamp.now(),
                displayImage:user.photoURL
              });
              toast.success("Signup Successful");
              navigate("/");
          }
        }
        else{
          toast.success("Signin Successful");
          navigate("/");
        }
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage)

        // const email = error.customData.email;
        // const credential = GoogleAuthProvider.credentialFromError(error);
      });
  }
  const onSubmit = (data) => {};
  return (
    <Box sx={{ width: {xs:"90%", md:"80%"}, mt: { md: "90px", xs: "30px" } }}>
      <Typography
        sx={{
          fontFamily: "Sofia Pro",
          fontWeight: 500,
          fontSize: { md: "24px", xs: "16px" },
          color: "#202124",
        }}
      >
        Create a Free MFGWorx Account
      </Typography>
      <Box sx={{ mt: "40px" }}>
        <form noValidate onSubmit={handleSubmit(handleSignup)}>
          <Stack spacing={3}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box
                className={
                  userStatus === "Candidate"
                    ? "status-active"
                    : "status-inactive"
                }
                sx={{ ...flexCenter, fontSize: "12px",
                fontFamily: "Sofia Pro",
                fontWeight: 600}}
                onClick={() => statusTab("Candidate")}
              >
                <RegisterCandidateIcon /> Candidate
              </Box>
              <Box
                className={
                  userStatus === "Employer"
                    ? "status-active"
                    : "status-inactive"
                }
                sx={{ ...flexCenter,fontSize: "12px",
                fontFamily: "Sofia Pro",
                fontWeight: 600 }}
                onClick={() => statusTab("Employer")}
              >
                <RegisterEmployerIcon /> Employer
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                className={classes.input}
                sx={{ width: { md: "50%", xs: "100%" } }}
              >
                <label>First name</label>
                <TextField
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                  type="text"
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName ? errors.firstName.message : ""}
                />
              </Box>
              <Box
                className={classes.input}
                sx={{ width: { md: "50%", xs: "100%" } }}
              >
                <label>Last name</label>
                <TextField
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName ? errors.lastName.message : ""}
                  type="text"
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                className={classes.input}
                sx={{ width: { md: "50%", xs: "100%" } }}
              >
                <label>Phone number</label>
                <PhoneInput
                  containerStyle={{ minHeight: "53px", marginRight: "10px" }}
                  inputStyle={{
                    minHeight: "53px",
                    marginLeft: "15%",
                    width: "85%",
                    borderRadius: "5px",
                    border: !phvalid ? "1px solid red" : "",
                    backgroundColor: "#f0faf0",
                  }}
                  country={"us"}
                  value={body?.phoneNumber ? body.phonenumber : "00000000000"}
                  onBlur={(e) => {}}
                  defaultErrorMessage="It doesn't works, why?"
                  isValid={(value, country) => {
                    if (value.length <= 13) {
                      setPhvalid(true);
                      return true;
                    } else {
                      setPhvalid(false);
                      return false;
                    }
                  }}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  onChange={(phone) => {
                    setBody({ ...body, phoneNumber: phone });
                    return setValue("phoneNumber", phone);
                  }}
                />
                {!phvalid && body?.phoneNumber?.length > 10 ? (
                  <Typography sx={{ ml: 2 }} fontSize={"12px"} color={"error"}>
                    Invalid phone number
                  </Typography>
                ) : null}
              </Box>
              <Box
                className={classes.input}
                sx={{ width: { md: "50%", xs: "100%" } }}
              >
                <label>Email address*</label>
                <TextField
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={errors.email ? true : false}
                  helperText={errors.email ? errors.email.message : ""}
                  type="email*"
                />
              </Box>
            </Box>

            <Box className={classes.input}>
              <label>Password</label>
              <TextField
                type="password"
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                    message:
                      "Password must contain at least one uppercase, one lowercase and a number ",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                })}
                error={errors.password}
                helperText={errors.password && errors.password.message}
              />
            </Box>
            <Box className={classes.input}>
              <label>Confirm password</label>
              <TextField
                {...register("cPassword", {
                  required: "Please confirm your password",
                })}
                error={errors.cPassword ? true : false}
                helperText={errors.cPassword ? errors.cPassword.message : ""}
                type="password"
              />
              {/* <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                sx={{border:"none"}}
                {...register("cPassword", {
                  required: "Please confirm your password",
                })}
                error={errors.cPassword ? true : false}
                helperText={errors.cPassword ? errors.cPassword.message : ""}
                type="password"
                placeholder="******"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  <VisibilityOff />
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          /> */}
            </Box>
            <Box>
              <LoadingButton
                loading={isLoading}
                loadingIndicator={
                  <CircularProgress
                    sx={{
                      color: "#fff",
                    }}
                    size={24}
                  />
                }
                type="submit"
                sx={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 24px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
              >
                Register
              </LoadingButton>

              <Typography
                sx={{
                  fontFamily: "Sofia Pro",
                  fontSize: "20px",
                  textAlign: "center",
                  py: "15px",
                  fontWeight: 500,
                  fontSize: { md: "20px", xs: "12px" },
                  color: "#B7B7B7",
                }}
              >
                Already have an account?{" "}
                <span
                  onClick={() => navigate("/registration/login")}
                  style={{
                    fontWeight: 500,
                    cursor: "pointer",
                    color: "#25C027",
                  }}
                >
                  Login
                </span>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "" }}>
              <Box
                sx={{ width: "45%", height: "1px", backgroundColor: "#ECEDF2" }}
              ></Box>
              <Typography sx={{ width: "10%", textAlign: "center" }}>
                or
              </Typography>
              <Box
                sx={{ width: "45%", height: "1px", backgroundColor: "#ECEDF2" }}
              ></Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "20px",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  width: { md: "50%", xs: "100%" },
                  fontSize: "14px",
                  fontFamily: "Sofia Pro",
                  backgroundColor: "#fff",
                  color: "#1967D2",
                  padding: "14px 24px",
                  borderRadius: "8px",
                  border: "1px solid #1967D2",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#1967D2",
                  },
                }}
              >
                <RegisterFacebookIcon />{" "}
                <Typography sx={{ ml: "10px" }}>Log In via Facebook</Typography>
              </Button>

              <LoadingButton
                sx={{
                  textTransform: "none",
                  width: { md: "50%", xs: "100%" },
                  fontSize: "14px",
                  fontFamily: "Sofia Pro",
                  backgroundColor: "#fff",
                  color: "#D93025",
                  padding: "14px 24px",
                  borderRadius: "8px",
                  border: "1px solid #D93025",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#D93025",
                  },
                }}
                onClick = {()=>googleLogin()}
              >
                <RegisterGoogleIcon />{" "}
                <Typography sx={{ ml: "10px" }}>Log In via Google+</Typography>
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default SignUp;
