import { Box, Container } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {
    flexCenter,
    itemsCenter
} from "../../../utilities/commonStyles/commonStyles";
import LoadingSearch1 from "../../../utilities/images/svg images/loading-search-bg1.svg";
import LoadingSearch2 from "../../../utilities/images/svg images/loading-search-bg2.svg";
import LoadingSearch3 from "../../../utilities/images/svg images/loading-search-bg3.svg";
import LoadingSearch4 from "../../../utilities/images/svg images/loading-search-bg4.svg";

function LoadingSearchComponents() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <Container>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "transparent",
        }}
        open={true}
        onClick={handleClose}
      >
        <Stack gap={1} sx={{ position: "relative" }}>
          <Box sx={{ ...itemsCenter, gap: {xs:"10px", sm:"20px", md:"70px"} }}>
            <img src={LoadingSearch1} alt="" />
            <img src={LoadingSearch2} alt="" />
            <img src={LoadingSearch3} alt="" />
            <img src={LoadingSearch4} alt="" />
          </Box>
          <Box sx={{ ...flexCenter, position: "absolute", top: "-15%" }}>
            <CircularProgress size={"150px"} sx={{ color: "#CACED5" }} />
          </Box>
        </Stack>
      </Backdrop>
    </Container>
  );
}


export default LoadingSearchComponents;