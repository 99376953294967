import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import JobProfileComponents from "../../components/JobProfileComponents/JobProfileComponents";
import JobProfileComponentsMobile from "../../components/JobProfileComponents/JobProfileComponentsMobile";
import FindJob from "../../components/SharedComponents/FindJob/FindJob";
import MobileMenu from "../../components/SharedComponents/MobileMenu/MobileMenu";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import {
  displayOnDesktop,
  displayOnMobile
} from "../../utilities/commonStyles/commonStyles";
const JobProfile = () => {
  const { id } = useParams();
  return (
    <Box>
      {/* <ColorNavBar /> */}
      <Box sx={displayOnDesktop}>
        <NavBar color={"green"} />
      </Box>
      <Box sx={displayOnMobile}>
        <MobileMenu color={"green"} />
      </Box>
      <FindJob />
      <Box sx={displayOnDesktop}>
        <JobProfileComponents jobId={id} />
      </Box>
      <Box sx={displayOnMobile}>
        <JobProfileComponentsMobile jobId={id} />
      </Box>
    </Box>
  );
};

export default JobProfile;
