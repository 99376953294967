import { Box, Button, Container, Typography } from "@mui/material";
import { responsiveWidth } from "../../../utilities/commonStyles/commonStyles";
import RecruitingImg from "../../../utilities/images/svg images/recruiting.svg";
import { GetType } from "../../../context/authContext";
import { auth } from "../../../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
const Recruiting = () => {
  const user = auth.currentUser;
  const navigate = useNavigate();
  const userType = GetType();
  return (
    <Box sx={{ my: "50px" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column-reverse" },
            gap: "70px",
            alignItems: "center",
            backgroundColor: "#25C027",
            borderRadius: "8px",
            padding: { md: "0px", xs: "30px" },
          }}
        >
          <Box
            sx={{
              ...responsiveWidth,
              padding: { md: "60px" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Sofia Pro",
                fontWeight: 500,
                fontSize: "30px",
              }}
            >
              Looking for top industrial or manufacturing professionals?
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
                mt: "20px",
              }}
            >
              Post your openings and advertise your awesome company to top candidates in the industry instantly!
            </Typography>
            {!user ? (
                        <>
                            <Button
                            onClick={() => navigate("/registration/login")}
              sx={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: "Sofia Pro",
                color: "#25C027",
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "15px 30px",
                mt: "25px",
                "&:hover": {
                  color: "#25C027",
                  backgroundColor: "#fff",
                },
              }}
            >
              Create Company Profile
            </Button>
                        </>
                      ) : userType.role == "user" ? (
                        <></>
                      ) : (
                        <Button
                        onClick={() => navigate("/employee-dashboard/all-applicants")}
          sx={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: "Sofia Pro",
            color: "#25C027",
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "15px 30px",
            mt: "25px",
            "&:hover": {
              color: "#25C027",
              backgroundColor: "#fff",
            }
          }}
        >
          Create Company Profile
        </Button>
                      )}
          
          </Box>
          <Box sx={{ ...responsiveWidth, padding: "10px" }}>
            <img style={{ width: "100%" }} src={RecruitingImg} alt="" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Recruiting;
