import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,Modal
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InstantFeatureMessageMobile from "../../components/Home/InstantMessageFeture/InstantFeatureMessageMobile";
import InstantMessageFeture from "../../components/Home/InstantMessageFeture/InstantMessageFeture";
import Recruiting from "../../components/SharedComponents/EmployersFindYou/Recruiting";
import Footer from "../../components/SharedComponents/Footer/Footer";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import MobileMenu from "../../components/SharedComponents/MobileMenu/MobileMenu";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import { getOrgData } from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  flexBetweenWithItemsCenter,
  responsiveFlexDirection
} from "../../utilities/commonStyles/commonStyles";
import { FacebookIcon, TwitterIcon } from "../../utilities/Icons/Icons";
import companyLeft from "../../utilities/images/companyLeft.png";
import companyRight from "../../utilities/images/companyRight.png";
import JobcardCompanyProfile from "./JobcardCompanyProfile";
const CompanyProfile = () => {
  const [orgData, setOrgData] = useState({});
  const [jobs, setJobs] = useState([]);
  const [loading , setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    getOrgData({ organisationId: id })
      .then(({ data }) => {
        console.log("haha", data);
        setOrgData(data);
        setJobs(data.jobs);
        setLoading(false);
      })
      .catch((e) => {
        console.log("HAHA", e);
        setLoading(false);
          toast.error(e.message);
      });
  }, []);
  return (
    <Box>
      {/* {JSON.stringify(orgData)} */}
      <Box sx={displayOnDesktop}>
        <NavBar color={"green"} />
      </Box>
      <Box sx={displayOnMobile}>
        <MobileMenu color={"green"} />
      </Box>

      <Box sx={{ backgroundColor: "#25C027", pt: "20px" }}>
        <Container>
          <Box sx={{ ...flexBetweenWithItemsCenter }}>
            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={companyLeft}
                alt=""
              />
            </Box>
            <Box sx={{ width: "40%", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "30px" },
                  color: "#FFF",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                }}
              >
                Company Profile
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", md: "15px" },
                  color: "#FFF",
                  fontFamily: "Poppins",
                }}
              >
                Home / Companies
              </Typography>
            </Box>
            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img style={{ width: "100%" }} src={companyRight} alt="" />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* <Box sx={{ backgroundColor: "#25C027", height: "300px", mt: "10px" }}>
        <Container sx={{ height: "100%" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                height: "290px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <img src={left} alt="" />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{ color: "#fff", fontSize: "30px", fontWeight: 500 }}
              >
                Company Profile
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: 500,
                  mt: "15px",
                }}
              >
                Home / Companies
              </Typography>
            </Box>
            <Box
              sx={{
                height: "290px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <img src={right} alt="" />
            </Box>
          </Box>
        </Container>
      </Box> */}
      <Container>
        <Box
          sx={{
            ...responsiveFlexDirection,
            display: "flex",
            my: "60px",
            gap: "40px",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "70%" } }}>
            <Stack spacing={3}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 500,
                  fontFamily: "Sofia Pro",
                  color: "#202124",
                }}
              >
                About Company
              </Typography>
              <Typography
                component={"p"}
                sx={{
                  fontSize: "15px",
                  fontFamily: "Sofia Pro",
                  color: "#696969",
                }}
              >
                {orgData.organisationDescription}
              </Typography>
            </Stack>
            <Box sx={{ mt: "40px" }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "26px",
                    fontFamily: "Sofia Pro",
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  {jobs.length} Jobs
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontFamily: "Sofia Pro",
                    color: "#696969",
                    mt: "10px",
                  }}
                >
                  {jobs?.length} jobs live
                </Typography>
              </Box>
              <Stack spacing={2} sx={{ mt: "20px" }}>
                {jobs.map((job) => {
                  return (
                    <JobcardCompanyProfile orgData={orgData} jobId={job} />
                  );
                })}
              </Stack>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "30%" } }}>
            <Stack
              spacing={2}
              sx={{ padding: "30px", backgroundColor: "#F0FBF0" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Box
                  sx={{
                    //  backgroundColor: "#FF3365",
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "100%" }}
                    src={orgData.organisationLogo}
                    alt=""
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 500,
                      fontFamily: "Sofia Pro",
                      color: "#202124",
                    }}
                  >
                    {orgData.organisationName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Sofia Pro",
                      color: "#25C027",
                    }}
                  >
                    {/* View company profile */}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ py: "10px" }}>
                <Stack spacing={3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      Primary industry
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      {orgData?.categories?.slice(0, 1).toString(",")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      Company Size
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      {orgData.teamSize}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      Founded in:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      {orgData.estSince}
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      Phone:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      {orgData.organisationPhone}
                    </Typography>
                  </Box> */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      {orgData?.organisationEmail}
                    </Typography>
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      Location:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      {orgData?.city},{orgData?.state}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                      }}
                    >
                      Social media:
                    </Typography>
                    <IconButton
                      sx={{
                        backgroundColor: "#00acee",
                        "&:hover": {
                          backgroundColor: "#00acee",
                        },
                      }}
                      onClick={() => {
                        window.open(orgData?.twitter, "_blank");
                      }}
                    >
                      <TwitterIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        backgroundColor: "#3b5998",
                        "&:hover": {
                          backgroundColor: "#3b5998",
                        },
                      }}
                      onClick={() => {
                        window.open(orgData?.facebook, "_blank");
                      }}
                    >
                      <FacebookIcon />
                    </IconButton>
                  </Box>
                  {orgData?.secondChanceEmployer ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#202124",
                          fontFamily: "Sofia Pro",
                        }}
                      >
                        We’re a second chance employer!
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Box>

              <Box>
                <Button
                  href={orgData.organisationWebsite}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    fontSize: "18px",
                    fontFamily: "",
                    backgroundColor: "#D2F3D2",
                    color: "#25C027",
                    padding: "14px 24px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#D2F3D2",
                    },
                  }}
                >
                  {orgData.organisationWebsite}
                  <a href={orgData.organisationWebsite} />
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box sx={displayOnDesktop}>
          <InstantMessageFeture />
        </Box>
        <Box sx={displayOnMobile}>
          <InstantFeatureMessageMobile />
        </Box>
      </Container>
      <Recruiting />
      <Footer />
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default CompanyProfile;
