import { Avatar, Box, Grid, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getOrgCard } from "../../firebase/functions";
import { RecentJobsLocationIcon } from "../../utilities/Icons/Icons";

function CompanyCard({ data }) {
  const [orgData, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate=useNavigate();
  useEffect(() => {
    getOrgCard({ orgId: data.orgId }).then(({ data }) => {
      
      setData(data);
      setLoading(false);
    });
  }, []);
  return (
    <Grid item xs={2} sm={4} md={3}>
      {loading ? (
        <Skeleton variant="rect" width={200} height={200} />
      ) : (
        <Box
          // component="div"
          onClick={()=>navigate(`/company-profile/${data.orgId}`)} 
          sx={{
            boxShadow: "0px 5px 10px rgba(71, 119, 54, 0.15)",
            borderRadius: "20px",
            padding: "10px",
            mt: "30px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#34A853",
                background: "#eee",
                width: "90px",
                height: "25",
                padding: "5px 15px",
                fontSize: "13px",
                borderRadius: "60px",
              }}
            >
              Featured
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#262E50",
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{ height: "100%", width: "100%" }}
                src={orgData.organisationLogo}
                alt=""
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
              mt: "10px",
              mb: "25 px",
            }}
          >
            <Typography sx={{ color: "#34A853", fontSize: "18px" }}>
              {orgData.organisationName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <RecentJobsLocationIcon />
              <Typography sx={{ fontSize: "14px", color: "#696969" }}>
                {orgData.organisationCity}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "#0E0E0E",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              {/* Accounting / Finance
               */}
              {orgData.organisationWebsite}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: "25px",
            }}
          >
            <Typography
              sx={{
                color: "#1967D2",
                background: "#eee",
                width: "120px",
                height: "25",
                padding: "5px 15px",
                fontSize: "13px",
                borderRadius: "60px",
                fontFamily: "Sofia Pro",
              }}
            >
              Open Jobs - {data.noOfJobs}
            </Typography>
          </Box>
        </Box>
      )}
    </Grid>
  );
}

export default CompanyCard;
