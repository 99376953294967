import { Box, TextField } from "@material-ui/core";
import { Button } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

function Test() {
  const { register, handleSubmit,formState:{errors} } = useForm();
  const [form, setForm] = React.useState({});
  const onSubmit = (data) => {
    
    setForm(data);
  };
  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        {JSON.stringify(form)}
      <TextField
        {...register("password", {
          required: "Password is required",
          pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
            message:
              "Password must contain at least one uppercase, one lowercase and a number ",
          },
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters long",
          },
        })}
        error={errors.password}
        helperText={errors.password && errors.password.message}
      />
      <Button type="submit">kdsjfkls</Button>
    </Box>
  );
}

export default Test;
