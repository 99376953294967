import { Box, Container, Modal, Stack, TextField, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InstantFeatureMessageMobile from "../../components/Home/InstantMessageFeture/InstantFeatureMessageMobile";
import InstantMessageFeture from "../../components/Home/InstantMessageFeture/InstantMessageFeture";
import Recruiting from "../../components/SharedComponents/EmployersFindYou/Recruiting";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import MobileMenu from "../../components/SharedComponents/MobileMenu/MobileMenu";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import classes from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardContactUsDetails.module.css";
import { getContactUs, mail_support } from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  flexBetweenWithItemsCenter,
  responsiveFlexDirection,
  responsiveWidth
} from "../../utilities/commonStyles/commonStyles";
import findLeft from "../../utilities/images/findLeft.png";
import findRight from "../../utilities/images/findRight.png";

import { toast } from "react-toastify";
import Footer from "../../components/SharedComponents/Footer/Footer";
const ContactDetails = () => {
  const [contactData, setContactData] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingError, setloadingError] = useState(false);
  const [flag, setflag] = useState("");
  // useEffect(() => {
  //   setLoading(true);
  //   getContactUs({ docName: "contactUs" })
  //     .then(({ data }) => {
  //       console.log(data);
  //       setContactData(data);
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setloadingError(true);
  //       setLoading(false);
  //     });
  // }, [flag]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    console.log("data", data);
    mail_support(data)
      .then(({ data }) => {
        console.log(data);
        setLoading(false);
        toast.success(data);
        reset();
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.message);
      });
  };
  return (
    <>
      <Box>
        <Box sx={displayOnDesktop}>
          <NavBar color={"green"} />
        </Box>
        <Box sx={displayOnMobile}>
          <MobileMenu color={"green"} />
        </Box>
        {/* <Box
        style={{
          backgroundImage: `url(${map})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            ...flexCenter,
            gap: { xs: "40px", md: "120px" },
            padding: { xs: "20px", md: "40px" },
            background: "#FFFFFF",
            boxShadow: "0px 2px 20px rgba(71, 119, 54, 0.15)",
            width: { xs: "90%", md: "70%" },
            display: "flex",
            borderRadius: "8px",
            position: "relative",
            bottom: "-50%",
            border: "1px solid #24BD2C",
          }}
        >
          <Box>
            <Box sx={displayOnDesktop}>
              <MapIcon />
            </Box>
            <Box sx={displayOnMobile}>
              <ResponsiveMapIcon />
            </Box>
            <Typography
              sx={{
                color: "#202124",
                fontWeight: 500,
                fontSize: { xs: "8px", md: "18px" },
                my: "10px",
              }}
            >
              Address
            </Typography>
            <Typography
              sx={{
                color: "#696969",
                fontSize: { xs: "8px", md: "15px" },
              }}
            >
              {contactData.address}
            </Typography>
          </Box>
          <Box>
            <Box sx={displayOnDesktop}>
              <SmartPhone />
            </Box>
            <Box sx={displayOnMobile}>
              <ResponsiveSmartPhone />
            </Box>
            <Typography
              sx={{
                color: "#202124",
                fontWeight: 500,
                fontSize: { xs: "8px", md: "18px" },
                my: "10px",
              }}
            >
              Call Us
            </Typography>
            <Typography
              sx={{
                color: "#24BD2C",
                fontWeight: 500,
                fontSize: { xs: "8px", md: "18px" },
              }}
            >
              {contactData.phoneNo}
            </Typography>
          </Box>
          <Box>
            <Box sx={displayOnDesktop}>
              <MailIcon />
            </Box>
            <Box sx={displayOnMobile}>
              <ResponsiveMailIcon />
            </Box>
            <Typography
              sx={{
                color: "#202124",
                fontWeight: 500,
                fontSize: { xs: "8px", md: "18px" },
                my: "10px",
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                color: "#696969",
                fontSize: { xs: "8px", md: "15px" },
              }}
            >
              {contactData.email}
            </Typography>
          </Box>
        </Box>
      </Box> */}

        <Box sx={{ backgroundColor: "#25C027", pt: "20px" }}>
          <Container>
            <Box sx={{ ...flexBetweenWithItemsCenter }}>
              <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={findLeft}
                  alt=""
                />
              </Box>
              <Box sx={{ width: "40%", textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "20px", md: "30px" },
                    color: "#FFF",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                  }}
                >
                  Contact Us
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "10px", md: "15px" },
                    color: "#FFF",
                    fontFamily: "Poppins",
                  }}
                >
                  Home / Contact
                </Typography>
              </Box>
              <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
                <img style={{ width: "100%" }} src={findRight} alt="" />
              </Box>
            </Box>
          </Container>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: { xs: "50px", md: "80px" },
          }}
        >
          <Box
            sx={{
              padding: "30px",
              background: "#FFFFFF",
              border: "1px solid #24BD2C",
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "8px",
              width: { xs: "90%", md: "50%" },
            }}
          >
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              Leave A Message
            </Typography>
            <form Validate onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4} sx={{ mt: "30px" }}>
                <Box
                  sx={{
                    ...responsiveFlexDirection,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <Box className={classes.input} sx={{ ...responsiveWidth }}>
                    <label>Your Name</label>
                    <TextField
                      id="outlined-basic"
                      // label="Write your Address"
                      variant="outlined"
                      color="success"
                      {...register("name", { required: "Name is required" })}
                      error={Boolean(errors.name)}
                      helperText={errors.name && errors.name.message}
                    />
                  </Box>
                  <Box className={classes.input} sx={{ ...responsiveWidth }}>
                    <label>E-Mail</label>
                    <TextField
                      type="email"
                      {...register("to", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "invalid email address",
                        },
                      })}
                      error={Boolean(errors.to)}
                      helperText={errors.to && errors.to.message}
                      placeholder="limitation@gmail.com"
                    />
                  </Box>
                </Box>
                <Box className={classes.input} sx={{ width: "100%" }}>
                  <label>Subject</label>
                  <TextField
                    id="outlined-basic"
                    // label="Write your Address"
                    variant="outlined"
                    color="success"
                    {...register("subject", {
                      required: "Subject is required",
                    })}
                    error={Boolean(errors.subject)}
                    helperText={errors.subject && errors.subject.message}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <label
                    style={{
                      fontFamily: "Sofia Pro",
                      fontSize: "15px",
                      color: "#202124",
                      marginBottom: "10px",
                    }}
                  >
                    Message
                  </label>
                  <TextField
                    id="outlined-basic"
                    // label="Write your Address"
                    variant="outlined"
                    color="success"
                    multiline
                    {...register("message", {
                      required: "Comment is required",
                    })}
                    rows={5}
                    sx={{
                      width: "100%",
                      mt: "10px",
                      color: "#696969",
                      fontFamily: "Sofia Pro",
                      fontSize: "15px",
                    }}
                    error={Boolean(errors.message)}
                    helperText={errors.message && errors.message.message}
                  />
                </Box>
                <Box sx={{ textAlign: { md: "left", xs: "center" } }}>
                  <LoadingButton
                    sx={{
                      textTransform: "none",
                      fontSize: "15px",
                      fontFamily: "",
                      backgroundColor: "#25C027",
                      color: "#fff",
                      padding: "14px 50px",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#25C027",
                      },
                    }}
                    type="submit"
                    loading={loading}
                  >
                    Send Message
                  </LoadingButton>
                </Box>
              </Stack>
            </form>
          </Box>
        </Box>
        <Box sx={displayOnDesktop}>
          <InstantMessageFeture />
        </Box>
        <Box sx={displayOnMobile}>
          <InstantFeatureMessageMobile />
        </Box>
        <Recruiting />
        <Box sx={{ ...displayOnDesktop, mt: "10vw" }}>
          {" "}
          <Footer></Footer>
        </Box>
        <Modal
          open={loading}
          // onClose={}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack
            border={5}
            sx={{
              width: "100%",
              height: "100vh",
            }}
            justifyContent={"center"}
            alignItems="center"
          >
            <LoadingSearchComponents />
          </Stack>
        </Modal>
      </Box>
    </>
  );
};

export default ContactDetails;
