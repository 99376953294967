import { Box, Button, Grid, TextField,Stack, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { inviteEmployee, inviteEmployeeMfgworx } from "../../../firebase/functions";
import { flexCenter } from "../../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AdminInviteUsersModalComponent({ toggle, setToggle }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    setloading(true);
    inviteEmployeeMfgworx({ ...data })
      .then(() => {
        toast.success("Invitation sent successfully");
        handleClose();
        setToggle(toggle + 1);
        reset();
        setloading(false);
      })
      .catch((e) => {
        toast.error(e.message);
        setloading(false);
      });
  };
  return (
    <Box>
      <Button
        onClick={handleOpen}
        sx={{
          color: "#FFF",
          backgroundColor: "#25C027",
          textTransform: "uppercase",
          fontSize: "10px",
          fontWeight: 600,
          borderRadius: "10px",
          padding: "10px 25px",
          "&:hover": {
            backgroundColor: "#25C027",
          },
        }}
      >
        send invite
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: { xs: "95%", md: "50%" } }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: "100%" }}>
              <Box>
                <Typography
                  sx={{
                    color: "#25C027",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  Add Member
                </Typography>
              </Box>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ width: "100%", mt: "10px", borderRadius: "20px" }}
                    {...register("firstName", {
                      required: "This field is mandatory",
                    })}
                    error={Boolean(errors?.firstName)}
                    helperText={errors?.firstName?.message}
                    label="First name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ width: "100%", mt: "10px", borderRadius: "20px" }}
                    {...register("lastName", {
                      required: "This field is mandatory",
                    })}
                    error={Boolean(errors?.lastName)}
                    helperText={errors?.lastName?.message}
                    label="Last name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ width: "100%", mt: "10px", borderRadius: "20px" }}
                    {...register("email", {
                      required: "This field is mandatory",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    error={Boolean(errors?.email)}
                    helperText={errors?.email?.message}
                    label="Email"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ width: "100%", mt: "10px", borderRadius: "20px" }}
                    {...register("phoneNumber", {
                      required: "This field is mandatory",
                    })}
                    error={Boolean(errors?.phoneNumber)}
                    helperText={errors?.phoneNumber?.message}
                    label="Phone Number"
                  />
                </Grid>

                <Box sx={{ ...flexCenter, mt: "30px" }}>
                  <Button
                    type="submit"
                    sx={{
                      color: "#FFF",
                      backgroundColor: "#25C027",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      fontWeight: 600,
                      borderRadius: "10px",
                      padding: "10px 25px",
                      "&:hover": {
                        backgroundColor: "#25C027",
                      },
                    }}
                  >
                    Add Member
                  </Button>
                </Box>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
}

export default AdminInviteUsersModalComponent;
