import { Box, Container, Stack, Typography } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { db } from "../../firebase/firebaseConfig";
import { getAboutUs } from "../../firebase/functions";
import {
  flexBetweenWithItemsCenter,
  flexCenter
} from "../../utilities/commonStyles/commonStyles";
import img1 from "../../utilities/images/img-1.png";
import img2 from "../../utilities/images/img-2.png";
import img3 from "../../utilities/images/img-3.png";
import img4 from "../../utilities/images/img-4.png";
import img5 from "../../utilities/images/img-5.png";
import img6 from "../../utilities/images/img-6.png";
import img7 from "../../utilities/images/img-7.png";
import testimonialBg from "../../utilities/images/testimonialBg.png";
const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const [testimonialData, setTestimonialData] = useState([]);
  const [loadingAbout, setLoadingAbout] = useState(true);
  useEffect(() => {
    getTagsFromFirebase();
  }, []);
  const getTagsFromFirebase = async() => {

    const usercollectionRef = collection(db,`superAdmin/testimonial/infoDoc`);
    await getDocs(usercollectionRef).then((data)=>{
      setTestimonialData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      // setLoadingAbout(false);
    }).catch((e) => {
      console.log(e);
      // setloadingError(true);
      // setLoadingAbout(false);
    });
    // getAboutUs({ docName: "testimonial" })
    //   .then(({ data }) => {
    //     console.log(data);
    //     setTestimonialData(data.value);
    //     setLoadingAbout(false);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };
  return (
    <Box sx={{ my: "50px" }}>
      <Slider {...settings}>
        {testimonialData.map((item) => (
          <Container sx={{ position: "relative", marginBottom: "5vw" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  position: "relative",
                  width: "75%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={testimonialBg}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "10%",
                    left: "20%",
                    width: "60%",
                  }}
                >
                  <Stack
                    spacing={{ xs: 0.5, md: 4 }}
                    sx={{ textAlign: "center" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { xs: "16px", md: "30px" },
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          color: "#202124",
                        }}
                      >
                        Testimonials From Our Customers
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "8px", md: "14px" },
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          color: "#202124",
                        }}
                      >
                        Lorem ipsum dolor sit amet elit, sed do eiusmod tempor
                      </Typography>
                    </Box>
                    <Box sx={flexCenter}>
                      <Box
                        sx={{
                          width: { xs: "40px", md: "100px" },
                          height: { xs: "40px", md: "100px" },
                        }}
                      >
                        <img
                          src={item.image}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { xs: "8px", md: "18px" },
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          color: "#2A77CC",
                        }}
                      >
                        {item.tagline}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "8px", md: "15px" },
                          fontFamily: "Poppins",
                          color: "#717971",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { xs: "8px", md: "18px" },
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          color: "#3C4340",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "7px", md: "12px" },
                          fontFamily: "Poppins",
                          color: "#7C817C",
                        }}
                      >
                        {item.occupation}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Box>
            <Box>
              {/* <Box sx={{ position: "absolute", bottom: "10%", left: "0%" }}>
                <img src={testimonialUser} alt="" />
              </Box>
              <Box sx={{ position: "absolute", top: "20%", left: "10%" }}>
                <img src={testimonialUser} alt="" />
              </Box>
              <Box sx={{ position: "absolute", top: "30%", right: "10%" }}>
                <img src={testimonialUser} alt="" />
              </Box> */}
            </Box>
          </Container>
        ))}
      </Slider>
{/* 
      <Container sx={{ mt: "100px" }}>
        <Box
          sx={{
            ...flexBetweenWithItemsCenter,
            flexWrap: "wrap",
            gap: { xs: "10px", md: "0px" },
          }}
        >
          <Box>
            <img src={img1} alt="" />
          </Box>
          <Box>
            <img src={img2} alt="" />
          </Box>
          <Box>
            <img src={img3} alt="" />
          </Box>
          <Box>
            <img src={img4} alt="" />
          </Box>
          <Box>
            <img src={img5} alt="" />
          </Box>
          <Box>
            <img src={img6} alt="" />
          </Box>
          <Box>
            <img src={img7} alt="" />
          </Box>
        </Box>
      </Container> */}
    </Box>
  );
};

export default Testimonials;
