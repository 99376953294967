import { async } from "@firebase/util";
import { Box, Container, Stack, Typography,Modal } from "@mui/material";
import { collection, collectionGroup, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import {
  fetchApplications,
  fetchMessages,
  fetchOrgPhone,
  fetchPostedJobs,
  fetchShortlisted,
  getAllJobsRecruiter,
  getRecentApplicants
} from "../../firebase/functions";
import { responsiveFlexDirection } from "../../utilities/commonStyles/commonStyles";
import {
  EmployeeDashboardComponentApplicationIcon,
  EmployeeDashboardComponentMessagesIcon,
  EmployeeDashboardComponentPostedJobIcon,
  EmployeeDashboardComponentShortlistIcon
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./EmployeeDashboardComponent.module.css";
import JobApplicantCard from "./JobApplicantCard";
const EmployeeDashboardComponent = () => {
  const [userData, setUserData] = useState([]);
  const userType = GetType();
  const [recentApplicants, setRecentApplicants] = useState([]);
  const [postedJobs, setPostedJobs] = useState(0);
  const [applications, setApplications] = useState(0);
  const [messages, setMessages] = useState(0);
  const [shortlisted, setShortlisted] = useState(0);
  const [loading , setloading] = useState(false);
  const [phone, setPhone] = useState("");
  useEffect(() => {
    setloading(true);
    if (userType.organisation!=undefined) {
      fetchData();
    }
  }, [userType]);
  const fetchData = async() => {
     const usercollectionRef = collection(db,`Organisations/${userType.organisation}/applications`);
    await getDocs(usercollectionRef).then((dat) => {
      console.log(dat);
      setRecentApplicants(
        dat.docs.map((doc) => ({data:doc.data() }))
      );
      setloading(false);
      console.log(recentApplicants);
    }).catch((e) => {
      console.log(e);
     setloading(false);
    });
    const employerPostedJob = doc(db, `Organisations/${userType.organisation}`);
    const docSnap = await getDoc(employerPostedJob);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setPostedJobs(docSnap.data().jobs.length);
      setloading(false);
    } else {
      //! doc.data() will be undefined in this case
      console.log("No such document!");
      setloading(false);
    }
    const fetchApplications = query(
      collectionGroup(db, "Applications"),
      where("organisationId", "==", userType.organisation)
      );
      await getDocs(fetchApplications).then((querySnapshot) => {
        setApplications(querySnapshot.size);
        setloading(false);
      }).catch((e)=>{
      setloading(false);
      console.log(e);
     })
     const userMessageCollection = collection(
       db,
      `Organisations/${userType.organisation}/Applicants`
      );
      await getDocs(userMessageCollection)
      .then((dat) => {
        setMessages(dat.size);
        console.log(recentApplicants);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
      });
      const fetchShortlisted = query(
      collectionGroup(db, "Applications"),
      where("organisationId", "==", userType.organisation),where("status","==","accepted")
    );
    await getDocs(fetchShortlisted)
      .then((querySnapshot) => {
        setShortlisted(querySnapshot.size);
        setloading(false);
      })
      .catch((e) => {
        setloading(false);
        console.log(e);
      });
    
    //!Api Function 
      // getRecentApplicants().then((res) => {
      //   console.log(res.data);
      //   setRecentApplicants(res.data);
      //   setloading(false);
      // }).catch((e)=>{
      //   setloading(false);
      // })
     
      // getAllJobsRecruiter().then(({ data }) => { setloading(false);}).catch((e)=>{
      //   setloading(false);
      // })
     // querySnapshot.forEach((doc) => {
       //   console.log(doc.id, " => ", doc.data());
     // });
     // const fetchApplications = doc(db, `Organisations/${userType.organisation}`);
     // const docSnapFetchApplication = await getDoc(employerPostedJob);
     // if (docSnapFetchApplication.exists()) {
     //   console.log("Document data:", docSnapFetchApplication.data());
     //   setPostedJobs(docSnapFetchApplication.data().jobs.length);
     //   setloading(false);
     // } else {
     //   //! doc.data() will be undefined in this case
     //   console.log("No such document!");
     //   setloading(false);
     // }
     // fetchApplications({}).then((response) => {
     //   setApplications(response.data);
     //   setloading(false);
     // }).catch((e)=>{
     //   setloading(false);
     // })
     // fetchPostedJobs({}).then((response) => {
       //   setPostedJobs(response.data);
       //   setloading(false);
       // }).catch((e)=>{
     //   setloading(false);
     // })
     // fetchMessages({}).then((response) => {
       //   setMessages(response.data);
     //   setloading(false);
     // }).catch((e)=>{
     //   setloading(false);
     // })
     // fetchShortlisted({}).then((response) => {
     //   setShortlisted(response.data);
     //   setloading(false);
     // }).catch((e)=>{
     //   setloading(false);
     // })
 }
  useEffect(() => {
    fetchOrgPhone({ orgId: userType.organisation }).then((response) => {
      console.log(userType.organisation, response);
      setPhone(response.data);
    }).catch((err) => {
      console.log("error "+err.messages);
      });
  }, [userType]);

  return (
    <Container>
      <Box
        className={classes.hidescrollbar}
        sx={{ height: "90vh", overflowY: "scroll" }}
      >
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: 500,
            }}
          >
            {/* {`${JSON.stringify(recentApplicants)}`} */}
            Howdy, {userType.displayName ? userType.displayName : ""}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "18px",
              fontWeight: 400,
            }}
          >
            Your MFGWorx Phone Number {phone ? phone : ""}
          </Typography>
        </Box>

        <Stack spacing={3} sx={{ mt: "50px" }}>
          <Box
            sx={{
              ...responsiveFlexDirection,
              display: "flex",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E8F0FB",
                }}
              >
                <EmployeeDashboardComponentPostedJobIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#1967D2",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {postedJobs}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Posted Jobs
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FCEBEA",
                }}
              >
                <EmployeeDashboardComponentApplicationIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#D93025",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {applications}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Application
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ...responsiveFlexDirection,
              display: "flex",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FEF3D9",
                }}
              >
                <EmployeeDashboardComponentMessagesIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#F9AB00",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {messages}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Messages
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "350px" },
                padding: "30px",
                borderRadius: "8px",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E1F2E5",
                }}
              >
                <EmployeeDashboardComponentShortlistIcon />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: "#34A853",
                    fontFamily: "Sofia Pro",
                    fontSize: "30px",
                    fontWeight: 500,
                  }}
                >
                  {shortlisted}
                </Typography>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Shortlisted
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "8px",
              padding: "30px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                Recent Applicant
              </Typography>
            </Box>

            <Stack
              className={classes.hidescrollbar}
              spacing={2}
              sx={{ height: "60vh", overflowY: "scroll" }}
            >

              {recentApplicants?recentApplicants.map((info) => {
               return <JobApplicantCard info={info} />;
              }):<></>}
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Container>
  );
};

export default EmployeeDashboardComponent;
