import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GetType } from "../../context/authContext";
import { handleStatusChange } from "../../firebase/functions";
import {
  EmployeeDashboardComponentSeeTracedIcon
} from "../../utilities/Icons/Icons";

function ApplicantCardActions({ ids, status, setStatus, selection }) {
  const navigate = useNavigate();
  const user = GetType();

  const handleToggle = async (status) => {
    const applicantId = ids?.applicantId
      ? ids.applicantId
      : ids?.applicationId
      ? ids?.applicationId
      : ids?.appliedBy;
    const jobId = ids.jobId;

    await handleStatusChange({ jobId, applicantId, status: status });
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {/* {JSON.stringify(ids)} */}

      {/* <Box
        sx={{
          width: "30px",
          height: "30px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#EAF9EA",
        }}
      >
        <Tooltip placement="top" arrow title="Message">
          <IconButton>
            <EmployeeDashboardComponentChattingTracedIcon />
          </IconButton>
        </Tooltip>
      </Box> */}
      {user.role == "Agent" ? (
        <></>
      ) : (
        <Box
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: { xs: "#FFF", md: "#EAF9EA" },
          }}
        >
          <Tooltip placement="top" arrow title="View profile">
            <IconButton
              onClick={() => {
                navigate(
                  `/candidate-profile/${
                    ids?.applicantId
                      ? ids.applicantId
                      : ids?.applicationId
                      ? ids?.applicationId
                      : ids?.appliedBy
                  }/${ids.jobId}/?filter=${
                    selection?.status ? selection?.status : "resumeDatabase"
                  }`
                );
              }}
            >
              <EmployeeDashboardComponentSeeTracedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {/* <Box
        sx={{
          width: "30px",
          height: "30px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#EAF9EA",
        }}
      >
        <AcceptButton ids={ids} status={status} />
      </Box> */}
      {/* <IconButton
        sx={{
          bgcolor: "#EAF9EA",
          borderRadius: "8px",
          maxWidth: "30px",
          maxHeight: "30px",
        }}
      >
        <DoneAllIcon
          onClick={() => handleToggle("hired")}
          sx={{ color: "green", maxWidth: "20px" }}
          ids={ids}
          status={status}
        />
        {/* </Box> */}
      {/* </IconButton> */}
      {/* <Box
        sx={{
          width: "30px",
          height: "30px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#EAF9EA",
        }}
      >
        <RejectButton ids={ids} status={status} />
      </Box> */}
    </Box>
  );
}

export default ApplicantCardActions;
