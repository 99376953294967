import {
  Box,
  Button,
  Divider,
  IconButton, Modal, Stack,
  Switch,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getNoOfApplicantsFromId,
  handleJobToggle,
  renewJob
} from "../../firebase/functions";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import {
  EmployeeDashboardManageJobsPencilTracedIcon,
  EmployeeDashboardManageJobsSeeTracedIcon
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";

function ManageJobtableRow({ job, id }) {
  const navigate = useNavigate();
  const [loading , setloading] = useState(false);
  const [applicants, setApplicants] = useState(0);
  const [specJob, setSpecJob] = useState({});
  useEffect(() => {
    setSpecJob(job.active);
    getNoOfApplicantsFromId({ jobId: id }).then(({ data }) => {
      setApplicants(data);
    });
  }, []);
  const handleJobRenwal = () => {
    setloading(true);
    renewJob({ jobId: id }).then(({ data }) => {
      setloading(false);
        toast.success("Job renewed successfully");
      })
      .catch((e) => {
        toast.error(e.message);
        setloading(false);
      });
  };

  const handleJobsToggle = () => {
    console.log("toggle", id);
    setloading(true);
    handleJobToggle({ jobId: id })
      .then(({ data }) => {
        setSpecJob(data);
        console.log(data);
        setloading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setloading(false);
      });
  };
  return (
    <TableRow
      key={id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        minHeight: "50px",
        padding: "50px",
      }}
    >
      {/* {`${JSON.stringify(job)}`} */}
      <TableCell component="th" scope="row">
        <Typography>{job.jobTitle}</Typography>
      </TableCell>
      <TableCell align="left">
        {applicants ? `${applicants}+ applications` : "0 applications"}
      </TableCell>
      <TableCell align="left">
        <Typography>{fireBaseTime(job?.jobAddedAt).toDateString()}</Typography>
        <Divider />
        <Typography>
          {new Date(
            fireBaseTime(job?.jobAddedAt).setDate(
              fireBaseTime(job?.jobAddedAt).getDate() + 30
            )
          ).toDateString()}
        </Typography>
      </TableCell>
      <TableCell align="left">
        {!job.expred ? (
          <Switch checked={specJob} onClick={() => handleJobsToggle()}></Switch>
        ) : (
          <Stack justifyContent={"center"}>
            <Typography textAlign={"center"} color={"error"}>
              Expired
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                return handleJobRenwal();
              }}
            >
              Renew
            </Button>
          </Stack>
        )}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100px",
        }}
      >
        <Box
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#F0FAF0",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(`/job-profile/${id}`);
            }}
          >
            <EmployeeDashboardManageJobsSeeTracedIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#F0FAF0",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(`${id}`);
            }}
          >
            <EmployeeDashboardManageJobsPencilTracedIcon />
          </IconButton>
        </Box>
        {/* <Box
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#F0FAF0",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(`/job-profile/${id}`);
            }}
          ></IconButton>
          <EmployeeDashboardManageJobsPencilTracedIcon />
        </Box> */}
        {/* <Box
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#F0FAF0",
          }}
        >
          <EmployeeDashboardManageJobsDeleteTracedIcon />
        </Box> */}
      </TableCell>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </TableRow>
  );
}

export default ManageJobtableRow;
