import {
    Box,
    Button,
    Divider,
    IconButton,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    getNoOfApplicantsFromId,
    handleJobToggle,
    renewJob
} from "../../firebase/functions";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import { itemsCenter } from "../../utilities/commonStyles/commonStyles";
import { EmployeeDashboardManageJobsPencilTracedIcon, EmployeeDashboardManageJobsSeeTracedIcon } from "../../utilities/Icons/Icons";
const EmployeeDashboardManageJobsMobile = ({ job, id }) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [applicants, setApplicants] = useState(0);
  const [specJob, setSpecJob] = useState({});
  useEffect(() => {
    setSpecJob(job.active);
    getNoOfApplicantsFromId({ jobId: id }).then(({ data }) => {
      setApplicants(data);
    });
  }, []);
  const handleJobRenwal = () => {
    setloading(true);
    renewJob({ jobId: id })
      .then(({ data }) => {
        setloading(false);
        toast.success("Job renewed successfully");
      })
      .catch((e) => {
        toast.error(e.message);
        setloading(false);
      });
  };

  const handleJobsToggle = () => {
    console.log("toggle", id);
    setloading(true);
    handleJobToggle({ jobId: id })
      .then(({ data }) => {
        setSpecJob(data);
        console.log(data);
        setloading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setloading(false);
      });
  };
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
        borderRadius: "20px",
      }}
    >
      <Stack spacing={3} sx={{ mt: "20px" }}>
        <Stack
          spacing={1}
          sx={{
            width: "100%",
            boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
            padding: "15px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Typography
              sx={{
                width: "30%",
                fontSize: "10px",
                textTransform: "uppercase",
                color: "#25C027",
                fontWeight: 600,
              }}
            >
              Title
            </Typography>
            <Typography
              sx={{
                width: "65%",
                fontSize: "12px",
                color: "#425466",
                fontWeight: 600,
              }}
            >
              {job.jobTitle}
            </Typography>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Typography
              sx={{
                width: "30%",
                fontSize: "10px",
                textTransform: "uppercase",
                color: "#25C027",
                fontWeight: 600,
              }}
            >
              Applications
            </Typography>
            <Typography
              sx={{
                width: "65%",
                fontSize: "12px",
                color: "#425466",
                fontWeight: 600,
              }}
            >
              {applicants ? `${applicants}+ applications` : "0 applications"}
            </Typography>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Typography
              sx={{
                width: "30%",
                fontSize: "10px",
                textTransform: "uppercase",
                color: "#25C027",
                fontWeight: 600,
              }}
            >
              Created & Expired
            </Typography>
            <Box
              sx={{
                width: "65%",
                fontSize: "12px",
                color: "#425466",
                fontWeight: 600,
              }}
            >
              <Typography
                sx={{ fontSize: "12px", color: "#425466", fontWeight: 600 }}
              >
                {fireBaseTime(job?.jobAddedAt).toDateString()}
              </Typography>
              <Divider />
              <Typography
                sx={{ fontSize: "12px", color: "#425466", fontWeight: 600 }}
              >
                {new Date(
                  fireBaseTime(job?.jobAddedAt).setDate(
                    fireBaseTime(job?.jobAddedAt).getDate() + 30
                  )
                ).toDateString()}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Typography
              sx={{
                width: "30%",
                fontSize: "10px",
                textTransform: "uppercase",
                color: "#25C027",
                fontWeight: 600,
              }}
            >
              Status
            </Typography>
            <Box
              sx={{
                width: "65%",
              }}
            >
              {!job.expred ? (
                <Switch
                  checked={specJob}
                  onClick={() => handleJobsToggle()}
                ></Switch>
              ) : (
                <Stack justifyContent={"center"}>
                  <Typography textAlign={"center"} color={"error"}>
                    Expired
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      return handleJobRenwal();
                    }}
                  >
                    Renew
                  </Button>
                </Stack>
              )}
            </Box>
          </Box>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Typography
              sx={{
                width: "30%",
                fontSize: "10px",
                textTransform: "uppercase",
                color: "#25C027",
                fontWeight: 600,
              }}
            >
              Action
            </Typography>
            <Box
              sx={{
                width: "15%",
              }}
            >
              <IconButton
                onClick={() => {
                  navigate(`/job-profile/${id}`);
                }}
              >
                <EmployeeDashboardManageJobsSeeTracedIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: "15%",
              }}
            >
              <IconButton
                onClick={() => {
                  navigate(`${id}`);
                }}
              >
                <EmployeeDashboardManageJobsPencilTracedIcon />
              </IconButton>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EmployeeDashboardManageJobsMobile;
