import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetType } from "../../../context/authContext";
import { auth } from "../../../firebase/firebaseConfig";
import {
  CongratulationsCards,
  JobVacanciesCards,
  TrustedUserCards
} from "../../../utilities/Cards/Cards";
import {
  displayOnDesktop,
  displayOnMobile,
  flexBetweenWithItemsCenter,
  responsiveFlexDirection,
  responsiveWidth
} from "../../../utilities/commonStyles/commonStyles";
import {
  HiringPostJobIcon,
  UploadYourResumeIcon,
  ViewApplicantsIcon
} from "../../../utilities/Icons/Icons";
import HeaderBgLeft from "../../../utilities/images/header-bg-left.png";
import headerImg from "../../../utilities/images/svg images/header.svg";
import MobileMenu from "../../SharedComponents/MobileMenu/MobileMenu";
import NavBar from "../../SharedComponents/NavBar/NavBar";
const Header = () => {
  const navigate = useNavigate();
  const userType = GetType();
  const user = auth.currentUser;

  return (
    <Box sx={{ backgroundColor: "#24BD2C", position: "relative" }}>
      <Box sx={displayOnDesktop}>
        <NavBar />
      </Box>
      <Box sx={displayOnMobile}>
        <MobileMenu />
      </Box>
      <Box>
        <Box
          style={{
            backgroundImage: `url(${HeaderBgLeft})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom left",
          }}
        >
          <Container>
            <Box>
              <Box
                sx={{
                  ...responsiveFlexDirection,
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    ...responsiveWidth,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: { xs: "38px", md: "64px" },
                          textAlign: { xs: "center", md: "left" },
                          fontWeight: 500,
                          fontFamily: "Sofia Pro",
                          lineHeight: "1.2",
                        }}
                      >
                        True
                        <br />
                        <span style={{ fontWeight: 900 }}>
                          Industrial Hiring
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: { xs: "18px", md: "25px" },
                          textAlign: { xs: "center", md: "left" },
                          fontWeight: 500,
                          mt: "20px",
                        }}
                      >
                        MFGWorx is your go-to industrial hiring and industrial
                        job search platform connecting hiring managers to
                        candidates instantly. <br/> Our features streamline your
                        recruiting process and makes the job searching <br/> easy!
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "flex-start" },
                        alignItems: "center",
                        gap: "25px",
                        mt: "30px",
                      }}
                    >
                      {!user ? (
                        <>
                          <Button
                            onClick={() => navigate("/registration/login")}
                            sx={{
                              textTransform: "none",
                              backgroundColor: "#71DE76",
                              padding: "10px 20px",
                              display: "flex",
                              alignItems: "center",
                              gap: "7px",
                              borderRadius: "6px",
                              "&:hover": {
                                backgroundColor: "#71DE76",
                              },
                            }}
                          >
                            <HiringPostJobIcon />
                            <Typography
                              sx={{
                                color: "#fff",
                                fontFamily: "Sofia Pro",
                                fontSize: { xs: "12px", md: "18px" },
                                fontWeight: 500,
                              }}
                            >
                              Hiring? Find Talent
                            </Typography>
                          </Button>
                          <Button
                            onClick={() => navigate("/registration/login")}
                            sx={{
                              textTransform: "none",
                              backgroundColor: "#71DE76",
                              padding: "10px 20px",
                              display: "flex",
                              alignItems: "center",
                              gap: "7px",
                              borderRadius: "6px",
                              "&:hover": {
                                backgroundColor: "#71DE76",
                              },
                            }}
                          >
                            <ViewApplicantsIcon />
                            <Typography
                              sx={{
                                color: "#fff",
                                fontFamily: "Sofia Pro",
                                fontSize: { xs: "12px", md: "18px" },
                                fontWeight: 500,
                              }}
                            >
                              Find a job
                            </Typography>
                          </Button>
                        </>
                      ) : userType.role == "user" ? (
                        <Button
                          onClick={() =>
                            navigate("/candidate-dashboard/my-resume")
                          }
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#71DE76",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            gap: "7px",
                            borderRadius: "6px",
                            "&:hover": {
                              backgroundColor: "#71DE76",
                            },
                          }}
                        >
                          <UploadYourResumeIcon />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontFamily: "Sofia Pro",
                              fontSize: "18px",
                              fontWeight: 500,
                            }}
                          >
                            Upload Your Resume
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          onClick={() =>
                            navigate("/employee-dashboard/post-new-job")
                          }
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#71DE76",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            gap: "7px",
                            borderRadius: "6px",
                            "&:hover": {
                              backgroundColor: "#71DE76",
                            },
                          }}
                        >
                          <HiringPostJobIcon />
                          <Typography
                            sx={{
                              color: "#fff",
                              fontFamily: "Sofia Pro",
                              fontSize: "18px",
                              fontWeight: 500,
                            }}
                          >
                            Hiring? Post a job
                          </Typography>
                        </Button>
                      )}
                      {}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ ...responsiveWidth }}>
                  <Box sx={displayOnDesktop}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                        paddingTop: "150px",
                        position: "relative",
                      }}
                    >
                      {/* {JSON.stringify(userType)} */}
                      <img style={{ width: "100%" }} src={headerImg} alt="" />

                      <Box
                        sx={{
                          position: "absolute",
                          top: "5%",
                          left: "20%",
                          bottom: "",
                        }}
                      >
                        <CongratulationsCards />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "-5%",
                          left: "",
                          bottom: "",
                        }}
                      >
                        <TrustedUserCards />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",

                          left: "-10%",
                          bottom: "",
                        }}
                      >
                        <JobVacanciesCards />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={displayOnMobile}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                        paddingTop: "150px",
                        position: "relative",
                      }}
                    >
                      {/* {JSON.stringify(userType)} */}
                      <img style={{ width: "100%" }} src={headerImg} alt="" />

                      <Box
                        sx={{
                          ...flexBetweenWithItemsCenter,
                          position: "absolute",
                          top: "10%",
                          left: "0px",
                          right: "0px",
                        }}
                      >
                        <Box>
                          <CongratulationsCards />
                        </Box>
                        <Box>
                          <TrustedUserCards />
                        </Box>
                        <Box>
                          <JobVacanciesCards />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
