import { LoadingButton } from "@mui/lab";
import {
  Box, CircularProgress, Stack,
  TextField,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { useCountries } from "use-react-countries";
import {
  CitySelector,
  CountrySelector,
  StateSelector
} from "volkeno-react-country-state-city";
import { responsiveFlexDirection, responsiveWidth } from "../../utilities/commonStyles/commonStyles";
import "./country.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ContactInformation = ({ register, errors, loading, setValue }) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const { countries } = useCountries();
  const names = countries.map((country) => country.name);
  const [location, setLocation] = React.useState({});

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Contact Information
        </Typography>
      </Box>
      <Stack spacing={3} sx={{ mt: "30px" }}>
        <Box sx={{ ...responsiveFlexDirection, display: "flex", gap: "25px" }}>
          <Box sx={{ ...responsiveWidth }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Country
            </Typography>
            {/* <FormControl sx={{ width: "100%", mt: "10px" }}> */}
            <CountrySelector
              onChange={(e) => {
                setValue("country", e.name);
                setLocation({ ...location, country: e });
              }}
              name="country"
              placeholder="Select a country"
              value={location.country}
            />
            {/* </FormControl> */}
          </Box>
          <Box sx={{ ...responsiveWidth }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              State*
            </Typography>
            <StateSelector
              country={location.country}
              name="state"
              value={location.state}
              countryPlaceholder="Select a country first"
              onChange={(e) => {
                setValue("state", e.name);
                setLocation({ ...location, state: e });
              }}
            />
          </Box>
          <Box sx={{ ...responsiveWidth }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              City*
            </Typography>
            <CitySelector
              state={location.state}
              name="city"
              value={location.city}
              statePlaceholder="Select a state first"
              onChange={(e) => {
                setValue("city", e.name);
                setLocation({ ...location, city: e });
              }}
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          >
            Complete Address
          </Typography>

          <TextField
            id="outlined-basic"
            // label="Complete Address"
            variant="outlined"
            {...register("address")}
            sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
          />
        </Box>

        <LoadingButton
          loadingIndicator={<CircularProgress />}
          loading={loading}
          type="submit"
          sx={{
            width: "150px",
            textTransform: "none",
            fontSize: "14px",
            fontFamily: "Sofia Pro",
            backgroundColor: "#25C027",
            color: "#fff",
            padding: "15px 40px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#25C027",
            },
          }}
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ContactInformation;
