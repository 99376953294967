import {
  Box, Stack,
  TextField,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { toast } from "react-toastify";
import { useCountries } from "use-react-countries";
import {
  CitySelector,
  CountrySelector,
  StateSelector
} from "volkeno-react-country-state-city";
import { setOrgContact } from "../../firebase/functions";
import { responsiveFlexDirection, responsiveWidth } from "../../utilities/commonStyles/commonStyles";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const CompanyProfileUpdatingContactInformation = ({
  activeStep,
  setActiveStep,
  register,
  setValue,
  getValues,
}) => {
  const { countries } = useCountries();
  const names = countries.map((country) => country.name);
  const theme = useTheme();
  const [country, setCountry] = React.useState([]);
  const [location, setLocation] = React.useState({});

  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCountry(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setValue("country", value);
  };
  const onSubmit = async (event) => {
    setOrgContact({
      contactInfo: { ...event },
    }).then(({ data }) => {
      if (data == "success") {
        toast.success(data);
        setActiveStep(activeStep + 1);
      }
    });
  };
  return (
    <Box className="">
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Address
        </Typography>
      </Box>
      <Stack spacing={3} sx={{ mt: "30px" }}>
        <Box sx={{...responsiveFlexDirection, display: "flex", gap: "25px" }}>
          <Box sx={{...responsiveWidth}}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Country
            </Typography>
            {/* <FormControl sx={{ width: "100%", mt: "10px" }}> */}
            <CountrySelector
              onChange={(e) => {
                console.log(e.name);
                setLocation({ ...location, country: e });
                setValue("country", e.name);
              }}
              name="country"
              placeholder={
                getValues("country") ? getValues("country") : "Select Country"
              }
              value={location.country}
            />
            {/* </FormControl> */}
          </Box>
          <Box sx={{...responsiveWidth}}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              State*
            </Typography>
            <StateSelector
              country={location.country}
              name="state"
              value={location.state}
              placeholder={
                getValues("state") ? getValues("state") : "Select State"
              }
              countryPlaceholder={
                getValues("state") ? getValues("state") : "Select country first"
              }
              onChange={(e) => {
                console.log(e);
                setLocation({ ...location, state: e });
                setValue("state", e.name);
              }}
            />
          </Box>
          <Box sx={{ ...responsiveWidth }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              City*
            </Typography>
            <CitySelector
              state={location.state}
              name="city"
              value={location.city}
              placeholder={
                getValues("city") ? getValues("city") : "Select City"
              }
              statePlaceholder={
                getValues("city") ? getValues("city") : "Select state first"
              }
              onChange={(e) => {
                console.log(e);
                setLocation({ ...location, city: e });
                setValue("city", e.name);
              }}
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          >
            Complete Address
          </Typography>

          <TextField
            id="outlined-basic"
            // label="Complete Address"
            variant="outlined"
            {...register("address")}
            sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default CompanyProfileUpdatingContactInformation;
