import { Box, Container, Stack, Typography,Modal } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import { getAllJobsAppliedId, getOffers } from "../../firebase/functions";
import {
  EmployeeDashboardManageJobsDeleteTracedIcon,
  EmployeeDashboardManageJobsDownArrowIcon,
  EmployeeDashboardManageJobsSeeTracedIcon,
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import ApplicationCardCandidate from "./ApplicationCardCandidate";
import classes from "./CandidateDashboardAppliedJobs.module.css";
import TableRowComponent from "./TableRowComponent";

const JobOffers = () => {
  const [loading , setLoading] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const userType = GetType();
  useEffect(() => {
    setLoading(true);
    // getOffers().then(({ data }) => {
    //   console.log("offers",data)
    //   setAppliedJobs(data);
    //   setLoading(false);
    // }).catch((e)=>{
    //   setLoading(false);
    //   toast.error(e.message);
    // })
    if (userType.uid != undefined) {
      allJobs();
    }
  }, [userType]);
  const allJobs = async () => {
    const employerPostedJob =  query(collectionGroup(db,"Applications")
      ,where("status", "in", ["hired", "offerRevised"])
      ,where("appliedBy", "==", userType.uid));
    await getDocs(employerPostedJob)
      .then((docSnap) => {
        setAppliedJobs(docSnap.docs.map((doc) => doc.data()));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        toast.error(e.message);
      });
  };
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Jobs Offers
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "30px",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              My Job Offers
            </Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#F0FAF0",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography>
            <EmployeeDashboardManageJobsDownArrowIcon />
          </Box> */}
        </Box>

        <Box sx={{ my: "0px" }}>
        <Stack
              className={classes.hidescrollbar}
              spacing={2}
              sx={{ height: "60vh", overflowY: "scroll", mt: "25px" }}
            >
              {appliedJobs?.map((item, index) => (
                <ApplicationCardCandidate data={item.jobId} status={item.status} item={item} invite={"invite"} method={item?.method} />
              ))}
            </Stack>

          {/* <TableContainer>
          
          </TableContainer> */}
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Container>
  );
};

export default JobOffers;
