import { Autocomplete, Box, Button, Chip, Container, MenuItem, Modal, Stack, TextField, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import styled from "@emotion/styled";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import Swal from "sweetalert2";
import {
  GetAllPlanAdmin,
  listBasePlans,
  updatePrice,
  createProduct,
  assingOrg,
} from "../../firebase/functions";

import {
    PlansModalCrossIcon,
    PlansModalTickIcon
} from "../../utilities/Icons/Icons";
import classes from "./SuperAdminDashboardCompanyList.module.css";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { set } from "date-fns";
import { toast } from "react-toastify";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import SuperAdminCreateProduct from "./SuperAdminCreateProduct";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
}
const SuperAdminDashboardRefundRequest = () => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const [productId, setProductId] = useState("");
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [flag, setflag] = useState(0);
  const [open, setOpen] = useState(false);
  const [openCompanyPopup, setopenCompanyPopup] = useState(false);
  const [companyArray, setCompanyArray] = useState([]);
  const [companyId , setCompanyId] = useState('')
  const [openCustom, setOpenCustom] = useState(false);
  const handleCloseCustom = () => setOpenCustom(false);
  const handleCloseCompanyPopup = () => setopenCompanyPopup(false);
   const {
     register,
     handleSubmit,
     formState: { errors },
     reset,
   } = useForm();
   const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const allJobs = async () => {
    
    const orgCollectionRef = query(
      collection(db, "Organisations"),
      // where("active", "!=", true)
    );
    await getDocs(orgCollectionRef)
      .then((docSnap) => {
         let temp = docSnap.docs.map((doc) => {
           return { ...doc.data(), id: doc.id };
         });
        let filterArray = [];
         for(let i=0;i<temp.length;i++){
          if (temp[i]?.active == true) {
          } else {
            filterArray.push(temp[i]);
            
          }
         }
        setCompanyArray(
          filterArray
        );
        setopenCompanyPopup(true);
        setLoadingCompany(false);
      })
      .catch((e) => {
        console.log(e);
        setLoadingCompany(false);
        toast.error(e.message);
      });
  };
   useEffect(() => {
     setLoading(true);
     GetAllPlanAdmin()
       .then(({ data }) => {
         console.log("plaaaans", data);

        //  let temp=[];
        //  for(let i=0;i<data.length;i++){
        //   if(data[i]?.isNew==true){
        //     temp.push(data[i]);
        //   }
        //  }
         setPlans(data);
         setLoading(false);
       })
       .catch((e) => {
         console.log(e);
         setLoading(false);
       });
   }, [flag]);
   
  const onSubmit = (data) => {
    setLoading(true);
    console.log({ ...data, productId: productId });
    updatePrice({ ...data, productId: productId })
      .then((response) => {
        console.log(response);
        toast.success("Product Updated", {
          onClose: () => {
             reset();
             setflag(flag + 1);
             setOpen(false);
          },
          autoClose: 1000,
        });
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.message);
      });
  };

  return (
    <Box
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Container>
        <Box sx={{ mt: "60px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Product
            </Typography>

            <LoadingButton
              sx={{
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "",
                backgroundColor: "#25C027",
                color: "#fff",
                padding: "10px 10px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#25C027",
                },
              }}
              onClick={() => {
                setOpenCustom(true);
              }}
              // loading={loadingAddNotes}
            >
              Create Custom Product
            </LoadingButton>
          </Box>
          <Box>
            <Box>
              <Box>
                <Box>
                  <TableContainer
                    sx={{ boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)" }}
                  >
                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            textTransform: "uppercase",
                            backgroundColor: "#25C027",
                          }}
                        >
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Name
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell>
                          {/* <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Seats
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell> */}
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Slots
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Interval
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Resume database
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Live Chat function
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Key word Matching
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Featured Employer
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Price
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Update
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Price
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "10px",
                                fontWeight: 600,
                              }}
                            >
                              Assign
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {`${JSON.stringify(plans)}`} */}
                        {plans.map((plan) => (
                          <>
                            {" "}
                            <TableRow
                              key={plan?.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {plan?.displayName}
                              </TableCell>
                              {/* <TableCell align="center">
                                {plan.seats ? plan.seats : "0"}
                              </TableCell> */}
                              <TableCell align="center">
                                {plan.jobSlots ? plan.jobSlots : "0"}
                              </TableCell>
                              <TableCell align="center">
                                {plan?.plan?.recurring?.interval_count}{" "}
                                {plan?.plan?.recurring?.interval}
                              </TableCell>
                              <TableCell align="center">
                                <PlansModalTickIcon />
                              </TableCell>
                              <TableCell align="center">
                                <PlansModalTickIcon />
                              </TableCell>
                              <TableCell align="center">
                                <PlansModalTickIcon />
                              </TableCell>
                              <TableCell align="center">
                                <PlansModalTickIcon />
                              </TableCell>
                              {/* <TableCell align="center">
                                {plan.name == "STARTER" ||
                                plan.name == "STARTER" ||
                                plan.name == "CUSTOM" ? (
                                  <PlansModalTickIcon />
                                ) : (
                                  <PlansModalCrossIcon />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {plan.name == "STARTER" ||
                                plan.name == "STARTER" ||
                                plan.name == "CUSTOM" ? (
                                  <PlansModalTickIcon />
                                ) : (
                                  <PlansModalCrossIcon />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {plan.name == "STARTER" ||
                                plan.name == "STARTER" ||
                                plan.name == "CUSTOM" ? (
                                  <PlansModalTickIcon />
                                ) : (
                                  <PlansModalCrossIcon />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {plan.name == "STARTER" ||
                                plan.name == "CUSTOM" ||
                                plan.name == "PROFESSIONAL" ? (
                                  <PlansModalTickIcon />
                                ) : (
                                  <PlansModalCrossIcon />
                                )}
                              </TableCell> */}
                              <TableCell align="center">
                                {plan?.plan?.unit_amount / 100}$
                              </TableCell>

                              <TableCell align="center">
                                <Box
                                  sx={{
                                    ...flexCenter,
                                    width: "100%",
                                    mt: "20px",
                                  }}
                                >
                                  {" "}
                                  <Button
                                    onClick={() => {
                                      reset({
                                        ...plan,
                                        unit_amount:
                                          plan?.plan?.unit_amount / 100,
                                      });
                                      setOpen(true);
                                      setProductId(plan.id);
                                    }}
                                    sx={{
                                      textTransform: "none",
                                      fontSize: "13px",
                                      fontFamily: "",
                                      backgroundColor: "#24BD2C",
                                      color: "#fff",
                                      padding: "5px 10px",
                                      borderRadius: "8px",
                                      marginBottom: "1vw",
                                      "&:hover": {
                                        backgroundColor: "#24BD2C",
                                      },
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                <Box
                                  sx={{
                                    ...flexCenter,
                                    width: "100%",
                                    mt: "20px",
                                  }}
                                >
                                  {" "}
                                  {plan.itemType == "BASE" ? <></> :
                                    <LoadingButton
                                      loading={loadingCompany}
                                      onClick={() => {
                                        //  reset({
                                        //    ...plan,
                                        //    unit_amount:
                                        //      plan?.plan?.unit_amount / 100,
                                        //  });
                                        setLoadingCompany(true);
                                        allJobs();

                                        setProductId(plan.id);
                                      }}
                                      sx={{
                                        textTransform: "none",
                                        fontSize: "13px",
                                        fontFamily: "",
                                        backgroundColor: "#24BD2C",
                                        color: "#fff",
                                        padding: "5px 10px",
                                        borderRadius: "8px",
                                        marginBottom: "1vw",
                                        "&:hover": {
                                          backgroundColor: "#24BD2C",
                                        },
                                      }}
                                      disabled={plan?.orgId}
                                    >
                                      Assign
                                    </LoadingButton>}
                                </Box>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form Validate onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} sx={{ mt: "30px" }}>
              <TextField
                id="outlined-basic"
                label={"Name"}
                variant="outlined"
                color="success"
                {...register("displayName", {})}
                sx={{
                  width: "100%",
                  mt: "10px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
                error={Boolean(errors.displayName)}
                helperText={errors.displayName && errors.displayName.message}
              />
              <TextField
                id="outlined-basic"
                label={"Job Slot"}
                variant="outlined"
                color="success"
                type="number"
                inputProps={{ min: 0 }}
                {...register("jobSlots", {})}
                sx={{
                  width: "100%",
                  mt: "10px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
                error={Boolean(errors.jobSlots)}
                helperText={errors.jobSlots && errors.jobSlots.message}
              />
              <TextField
                id="outlined-basic"
                label={"Amount"}
                type="number"
                inputProps={{ min: 0 }}
                variant="outlined"
                color="success"
                {...register("unit_amount", {})}
                sx={{
                  width: "100%",
                  mt: "10px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
                error={Boolean(errors.unit_amount)}
                helperText={errors.unit_amount && errors.unit_amount.message}
              />
              {/* <TextField
                id="outlined-basic"
                label={"Price Id"}
                variant="outlined"
                color="success"
                {...register("priceId", { required: "Price Id is required" })}
                sx={{
                  width: "100%",
                  mt: "10px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
                error={Boolean(errors.priceId)}
                helperText={errors.priceId && errors.priceId.message}
              /> */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "",
                    backgroundColor: "#25C027",
                    color: "#fff",
                    padding: "14px 50px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#25C027",
                    },
                  }}
                  type="submit"
                  // loading={loadingAddNotes}
                >
                  Save
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </Box>
      </Modal>
      <Modal
        open={openCompanyPopup}
        onClose={handleCloseCompanyPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack spacing={4} sx={{ mt: "30px" }}>
            <TextField
              labelId="demo-multiple-chip-label"
              label="Company Name"
              name="teamSize"
              select
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value);
                console.log(e.target.value);
              }}
              color="success"
              MenuProps={MenuProps}
            >
              {companyArray.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  {name.organisationName}
                </MenuItem>
              ))}
            </TextField>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <LoadingButton
                loading={loadingAssign}
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
                onClick={() => {
                  if (companyId == "") {
                    toast.error("Please Select Company");
                    return;
                  }
                  setLoadingAssign(true);
                  assingOrg({ orgId: companyId, productId: productId })
                    .then((response) => {
                      console.log(response);
                      toast.success("Product Assigned");
                      setopenCompanyPopup(false);
                      setflag(flag + 1);
                      setLoadingAssign(false);
                    })
                    .catch((e) => {
                      setLoadingAssign(false);
                      toast.error(e.message);
                    });
                }}
              >
                Save
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>

      {/* custom Product Create */}
      <SuperAdminCreateProduct
        openCustom={openCustom}
        setOpenCustom={setOpenCustom}
        handleCloseCustom={handleCloseCustom}
        loading={loading}
        setLoading={setLoading}
        setflag={setflag}
        flag={flag}
      />
    </Box>
  );
};

export default SuperAdminDashboardRefundRequest;

{/* 
<Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                mt: "30px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                  >
                  Occupation
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
              <Box
              sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
              <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Date Added
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
                </Box>
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                Flagged
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
                </Box>
                <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                Featured
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
              </Box>

              <Box
              className={classes.input}
              sx={{
                width: "100%",
                borderRight: "1px solid #ECEDF2",
                padding: "20px",
                display: "flex",
                alignItems: "center",
                gap: "15px",
                mt: "30px",
              }}
            >
            <MessangerSearchIcon />
              <input type="text" placeholder="Search for employer" />
            </Box>
          </Box> */}
            {/* <Box
              sx={{
                backgroundColor: "#fff",
            
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
                borderRadius: "20px",
                mt: "60px",
              }}
              >
              <Box>
                
              </Box>
            
              <Box
                className={classes.hidescrollbar}
                sx={{ height: "70vh", overflowY: "scroll", my: "40px" }}
                >
                <TableContainer>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                      <TableRow
                        sx={{
                          "&:first-child td, &:first-child th": { border: 0 },
                        }}
                      >
                      
                        <TableCell
                          sx={{
                            minWidth: "150px",
                            color: "#1E1E1E",
                            fontFamily: "Sofia Pro",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                         Company Name
                        </TableCell>
                        <TableCell
                          sx={{
                            miWidth: "150px",
                            color: "#1E1E1E",
                            fontFamily: "Sofia Pro",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                          >
                          Date of Request
                        </TableCell>
                        <TableCell
                          sx={{
                            minWidth: "150px",
                            color: "#1E1E1E",
                            fontFamily: "Sofia Pro",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                          Date of Order
                        </TableCell>
                        <TableCell
                          sx={{
                            minWidth: "150px",
                            color: "#1E1E1E",
                            fontFamily: "Sofia Pro",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                         Type of Subscription
                        </TableCell>
                        <TableCell
                          sx={{
                            minWidth: "150px",
                            color: "#1E1E1E",
                            fontFamily: "Sofia Pro",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            minWidth: "150px",
                            color: "#1E1E1E",
                            fontFamily: "Sofia Pro",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ width: "100%" }}>
                      {fakeArr.map((arr, index) => (
                        <TableRow
                        key={arr}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "50px",
                          }}
                          >
                        
                          <TableCell align="left" sx={{ maxWidth: "100px" }}>
                           KrishWorks
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ miWidth: "150px", textAlign: "center" }}
                          >
                           
                            Active/Expired
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "110px", textAlign: "center" }}
                          >
                            No
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "10px", textAlign: "center" }}
                            >
                            8
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "10px", textAlign: "center" }}
                            >
                          f
                          </TableCell>
                          <TableCell align="left">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                              >
                              <Box
                                sx={{
                                  width: "20px",
                                  height: "30px",
                                  borderRadius: "8px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  
                                }}
                                >
                              
                              </Box>
                              <Box
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "8px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: "#EAF9EA",
                                }}
                              >
                                <EmployeeDashboardComponentTickIcon />
                              </Box>
                              <Box
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "8px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: "#EAF9EA",
                                  cursor:"pointer"
                                }}
                                onClick={()=>{
                                  Swal.fire({
                                      title: 'Enter Your Reason',
                                      input: 'text',
                                      inputAttributes: {
                                        autocapitalize: 'off',
                                        
                                      },
                                      showCancelButton: true,
                                      confirmButtonText: 'Submit',
                                      showLoaderOnConfirm: true,
                                      preConfirm: (login) => {
                                        
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        Swal.fire({
                                          title: `Refund Request Cancelled`,
                                          imageUrl: result.value.avatar_url
                                        })
                                      }
                                    })
                                }}
                              >
                                <EmployeeDashboardDeleteProfileIcon />
                              </Box>
                          
                              
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box> */}