import { Box } from "@material-ui/core";
import { Avatar, Stack, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logOut } from "../../../firebase/firebaseConfig";
import {  getUserType } from "../../../firebase/functions";
import { image } from "./sampleimage";
import Ellipse from "../../../utilities/images/Ellipse.svg";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
function NavBarLoggedInComponent({ userData }) {
  const [loading , setloading] = React.useState(false);
  const [user, setUser] = React.useState(auth.currentUser);
  
  const navigate = useNavigate();
    const goToLogin= () => navigate("/");
  const checkLogin=()=>{
    const auth = getAuth();
    auth.onAuthStateChanged((user)=> {
    if (user) {
    } else {
     goToLogin();
    }
   });
   }
   
  const handleLogout=()=>{
    setloading(true);
    logOut().then(()=>{
      setloading(false);
      navigate("/");
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
  }
 useEffect(()=>{
    checkLogin();
 },[]);


 const data = async()=>{
  const type = await getUserType();
  if (type.data.role != "Admin") {
    navigate("/");
  }
 };
  useEffect(() => {
     data();
  }, []);

 
 //const navigate = useNavigate();
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
  //     setloading(true);
  //     const type = await getUserType();
  //     const prod = false;
  //     const messaging = getMessaging();
  //     Notification.requestPermission()
  //     .then((permission) => {
  //       if (permission === "granted") {
  //         console.log("Notification permission granted.");
  //       }
  //     })
  //     .then(() => {
  //       getToken(messaging, {
  //         vapidKey:prod?'BF5Ng-E54KelzrR9OmPZN68Mr7cHElsVRZIMcWsugwsUh0OM8OmuySNiRXDk6Awkd8vN37Ni0YscmE99dRddEvw':
  //           "BGkjfGlPxFqynS2_f62mCYZq8Kdz2G16WrJsDuaCpxdJHis4GyBo5kqJFo4KL7PsmfrWxYrZs6Mjtf5QmX6nJ6Y",
  //       })
  //         .then((currentToken) => {
  //           if (currentToken) {
  //             console.log(
  //               "Token: " + currentToken + "\nUID: " + auth.currentUser.uid
  //             );
  //             saveFCM({ fcm: currentToken });
  //           } else {
  //             console.log(
  //               "No registration token available. Request permission to generate one."
  //             );
  //           }
  //         })
  //         .catch((err) => {
  //           console.log("An error occurred while retrieving token. ", err);
  //         });
  //     });
  //     if (type.data.role == "Recruiter" && !type.data.organisation) {
  //       navigate("company-profile-updating");
  //       setloading(false);
  //     }
  //     if (!type.data.profileComplete && type.data.role == "user") {
  //       navigate("/candidate-profile-updating");
  //       setloading(false);
  //     }
  //     if (!type.data.profileComplete && type.data.role == "Admin") {
  //       navigate("/super-admin-dashboard/dashboard");
  //       setloading(false);
  //     }
  //   });
  // }, []);
  return (
    <Stack gap={2} direction alignItems={"center"}>
      <Stack gap={2} direction alignItems={"center"}>
        <Box sx={{ position: "relative" }}>
          {/* <Badge badgeContent={4} color="secondary">
            <Favorite />
          </Badge> */}
          <Typography
            sx={{
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             // backgroundColor: "#1967D2",
              fontSize: "8px",
              fontWeight: 700,
              color: "#fff",
              position: "absolute",
              top: "-10px",
              right: "-10px",
            }}
          >
            {/* 2 */}
          </Typography>
        </Box>
        <Box>
          {/* <Badge badgeContent={4} color="secondary">
             <NotificationsTwoTone htmlColor="#696969" /> 
          </Badge> */}
        </Box>
      </Stack>
      <Stack direction gap={1}>
      <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              backgroundColor: "#7BDF80",
              padding: "5px 10px",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#C4C4C4",
                border: "2px solid #FFFCFC",
              }}
            > 
            {/* <img src={DashboardNavbarLogo}  style={{verticalAlign: "middle",
  width: "50px",
  height: "50px",
  borderRadius: "50%"}} alt="Avatar" class="avatar"></img> */}
              <Avatar src={Ellipse} />
            </Box>
            <Box sx={{ color: "#fff"  , fontSize: "30px",
              fontWeight: 500, }}>
              <Typography>Super Admin</Typography>
            </Box>
          </Box>
        {/* <Box>
          <Avatar src={DashboardNavbarLogo} />
        </Box> */}
        <Stack gap={1} direction alignItems={"center"}>
          {/* <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          >
            SuperAdmin
          </Typography> */}
          {/* <LoadingButton loading={loading} sx={{color:true?"#25c027":"#fff",  fontFamily: "Sofia Pro",
              fontSize: "15px",}} onClick={handleLogout}>Logout</LoadingButton> */}
          {/* <IconButton onClick={handleLogout}>
          <DashboardNavbarDownArrowIcon htmlColor="#696969" />
          </IconButton> */}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NavBarLoggedInComponent;
