import { Box, Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getShortlistedApplicants } from "../../firebase/functions";
import {
    EmployeeDashboardAllApplicantsDownArrowIcon,
    EmployeeDashboardComponentDeleteIcon,
    FeturedJobsLocationIcon,
    FeturedJobsMoneyIcon,
    MessangerSearchIcon
} from "../../utilities/Icons/Icons";
import EmployeeDashboardComponentRecentApplicationImg from "../../utilities/images/svg images/dashboard-component-recent-application.svg";
import classes from "./EmployeeDashboardAllApplicants.module.css";
import JobApplicantCard from "./JobApplicantCard";

const EmployeeDashboardShorlistedResumes = () => {
  const [applicants, setApplicants] = useState([]);
  useEffect(()=>{
    getShortlistedApplicants().then(({data})=>{
      
      setApplicants(data);
    })
  },[])
  const fakeArr = [1, 2, 3, 4, 5];
  return (
    <Container>
      <Box
        className={classes.hidescrollbar}
        sx={{ height: "80vh", overflowY: "scroll" }}
      >
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            Short List Resume
          </Typography>
        </Box>

        <Stack spacing={3} sx={{ mt: "50px" }}>
          <Box
            sx={{
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "8px",
              padding: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box sx={{ width: "40%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Shortlist Resume
                </Typography>
              </Box>

              <Box
                className={classes.input}
                sx={{
                  width: "40%",
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                <MessangerSearchIcon />
                <input type="text" placeholder="Search" />
              </Box>

              <Box
                sx={{
                  width: "20%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#24BD2C",
                  padding: "15px",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Newest
                </Typography>
                <EmployeeDashboardAllApplicantsDownArrowIcon />
              </Box>
            </Box>

            <Stack
              className={classes.hidescrollbar}
              spacing={2}
              sx={{ height: "60vh", overflowY: "scroll" }}
            >
              {applicants.map((item,index) => (
               <JobApplicantCard info={item} status={item.data.status} />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default EmployeeDashboardShorlistedResumes;
