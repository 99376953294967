import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "../../components/SharedComponents/DashboardNavbar/DashboardNavbar";
import SuperAdminDashboardMobileMenu from "../../components/SuperAdminDashboardSideNavbar/SuperAdminDashboardMobileMenu";
import TeamDashboardSideNavBar from "../../components/TeamDashboard/TeamDashboardSideNavBar";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveFlexDirection,
} from "../../utilities/commonStyles/commonStyles";

const TeamDashborad = () => {
  return (
    <Box>
      <Box sx={{ ...displayOnDesktop }}>
        <DashboardNavbar />
      </Box>

      <Box
        sx={{
          ...responsiveFlexDirection,
          height: { md: "85vh" },
          display: "flex",
          gap: "25px",
        }}
      >
        <Box sx={{ ...displayOnDesktop, width: "20%", px: "20px", mt: "20px" }}>
          <TeamDashboardSideNavBar />
        </Box>
        <Box sx={{ ...displayOnMobile }}>
          <SuperAdminDashboardMobileMenu />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "80%" }, mt: "30px" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default TeamDashborad;
