import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../firebase/firebaseConfig";
import { createOrg1, getOrgInfoAdmin } from "../../firebase/functions";
import CompanyProfileUpdatingContactInformation from "./CompanyProfileUpdatingContactInformation";
import CompanyProfileUpdatingComponent from "./CompanyProfileUpdatingCoponent";
import CompanyProfileUpdatingSocialNetwork from "./CompanyProfileUpdatingSocialNetwork";
const CompanyProfileUpadtingPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orgData, setOrgData] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  useEffect(() => {
    getOrgInfoAdmin().then(({ data }) => {
      setOrgData(data);
      console.log(data);
    });
  }, []);
  const onSubmit = async (data) => {
    setLoading(true);
    createOrg1({ ...data }).then(({ data }) => {
      setLoading(false);
      navigate("/employee-dashboard");
    });
  };
  return (
    <Container>
      {/* <Button onClick={()=>{  
    logOut().then(() => {
      navigate("/");
    })}}>hello</Button> */}
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "40px 0px 100px 0px",
          }}
        >
          <Stack justifyContent={"center"}>
            {/* <Box sx={{ width: "80%" }}> */}
            <Stack direction justifyContent={"space-between"}>
              <Typography
                sx={{
                  color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "30px",
                  fontWeight: 500,
                }}
              >
                Fill The Required Fields
              </Typography>
              <IconButton disabled={!activeStep}>
                <ArrowForwardIcon
                  onClick={() => navigate("/")}
                  color="success"
                />
              </IconButton>
            </Stack>
            <Box
              sx={{
                background: "#FFFFFF",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                borderRadius: "8px",
                padding: "40px 30px",
                my: "30px",
              }}
            >
              <CompanyProfileUpdatingComponent
                setValue={setValue}
                register={register}
                errors={errors}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                getValues={getValues}
                data={orgData}
              />
            </Box>
            <Box
              sx={{
                background: "#FFFFFF",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                borderRadius: "8px",
                padding: "40px 30px",
                margin: "60px 0px 30px 0px",
              }}
            >
              <CompanyProfileUpdatingSocialNetwork
                setValue={setValue}
                register={register}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </Box>
            <Box
              sx={{
                background: "#FFFFFF",
                border: "1px solid #ECEDF2",
                boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                borderRadius: "8px",
                padding: "40px 30px",
                margin: "60px 0px 30px 0px",
              }}
            >
              <CompanyProfileUpdatingContactInformation
                setValue={setValue}
                getValues={getValues}
                register={register}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </Box>
            {/* </Box> */}
            <LoadingButton
              loading={loading}
              loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
              type="submit"
              fullWidth
              sx={{
                width: "150px",
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "Sofia Pro",
                backgroundColor: "#25C027",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#25C027",
                },
              }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Container>
      </Box>
    </Container>
  );
};

export default CompanyProfileUpadtingPage;
