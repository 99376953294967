import {
  Box,
  Container,
  Stack,
  Typography,
  Modal,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import JobNotFound from "../../pages/JobSearchPage/JobNotFound";
import styled from "styled-components";
import { City, Country, State } from "country-state-city";
import {
  getApplfronJobId,
  getFormField,
  getJobTitles,
  getNoofAcceptReject,
  resumeDatabase,
  suggestedCandidates,
  teamOrgDetail,
} from "../../firebase/functions";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import { displayOnDesktop } from "../../utilities/commonStyles/commonStyles";
import { EmployeeDashboardAllApplicantsDownArrowIcon } from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "../EmployeeDashboardComponent/EmployeeDashboardAllApplicants.module.css";
import { ApplicantDetails } from "../EmployeeDashboardComponent/ReusableComponents/ApplicantDetails";
import { GetType } from "../../context/authContext";
import {
  collection,
  collectionGroup,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
const TeamDashboardAllApplicant = () => {
  const [location, setLocation] = useState({});
  const [companyName, setCompanyName] = useState([]);
  const [allApplicants, setAllApplicants] = useState([]);
  const [candidatesStatus, setcandidatesStatus] = useState("All");
  const [noofAcceptReject, setNoofAcceptReject] = useState([]);
  const [status, setStatus] = useState({});
  const [titles, setTitles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const { jobTitles, setJobTitles } = useState([]);
  const [toggle, setToggle] = useState(true);
  const [experienceArray, setExperienceArray] = useState([]);
  const [educationArray, setEducationArray] = useState([]);
  const [skillArray, setSkillArray] = useState([]);
  const [sort, setSort] = useState({ order: "desc" });
  const [loadingAbout, setLoadingAbout] = useState(true);
  const userType = GetType();
  const [values, setValues] = useState({
    country: null,
    state: null,
    city: null,
  });
  const [cityMulti, setCityMulti] = useState([]);

  const countries = Country.getAllCountries();
  const data = () => {
    console.log(countries);
    console.log(updatedStates);
    console.log(values.state);
    console.log(values.country);
    const value = updatedCities(
      // "AF",
      // "BDS"
      values.country ? values.country.value : null,
      values.state ? values.state : null
    );
    // const value = updatedStates(values.country ? values.country.value : null);
    console.log(value);
  };
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));
  const updatedStates = (countryId) =>
    State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.isoCode,
    }));
  const updatedCities = (countryId, stateId) =>
    City.getCitiesOfState(countryId, stateId).map((city) => ({
      label: city.name,
      value: city.name,
    }));

  useEffect(() => {
    //  setLoadingAbout(true);
    console.log("USEEFFECT50");
    // getJobTitles()
    //   .then(({ data }) => {
    //     setTitles(data);
    //     setStatus({
    //       ...status,
    //       jobTitles: data[0].id,
    //       status: "All",
    //       experience: "Any",
    //       educationLevel: "Any",
    //       skills: [],
    //       country: "",
    //       state: "",
    //       city: "",
    //     });
    //   })
    //   .catch((e) => {
    //     toast.error(e.message);
    //   });
    if (userType?.organisation != undefined) {
      getInvitedTitleJob(userType.uid);
    }
  }, [userType]);
  useEffect(() => {
    if (userType.organisation != undefined && status.companyName != undefined) {
      soln();
    }
  }, [userType, status.companyName]);
  const soln = async () => {
    setTitles([]);
    let fetchApplications;
      fetchApplications = query(
        collection(db, "Jobs"),
        where("organisationId", "==", status.companyName)
      );

    await getDocs(fetchApplications)
      .then((data) => {
        let tempData;
        let orgName = new Set();
        console.log(data.size);
        if (data.size != 0) {
          setTitles(
            data.docs.map((job) => {
              console.log(job.data());
              return { title: job.data().jobTitle, id: job.id };
            })
          );
          setStatus({
            ...status,
            jobTitles: data.docs[0].id,
            status: "All",
            experience: "Any",
            educationLevel: "Any",
            skills: [],
            country: "",
            state: "",
            city: "",
          });
        } else {
          setLoadingAbout(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getInvitedTitleJob = async (id) => {
     setStatus({
       ...status,
       companyName: "",
     });
      teamOrgDetail({ id: id }).then(async({ data }) => {
        console.log(data);
        if (Array.isArray(data.orgArray) && data.orgArray.length>0) {
           let orgInvited = data.orgArray.map((job) => {
             return {
               title: job.organisationName,
               id: job.id,
             };
           });
          setStatus({
            ...status,
            companyName: data.orgArray[0].id,
          });
           setCompanyName([
             ...new Map(orgInvited.map((item) => [item["id"], item])).values(),
           ]);
        } else {
          setLoadingAbout(false)
        }
   
      });
  };
  useEffect(() => {
    getFormField({ form: "skills" })
      .then(({ data }) => {
        console.log("Skills", data);

        setSkillArray(
          data.map((skill) => {
            return {
              value: skill,
              label: skill,
            };
          })
        );
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    getFormField({ form: "experience" })
      .then(({ data }) => {
        console.log("Experience", data);
        setExperienceArray(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    getFormField({ form: "education" })
      .then(({ data }) => {
        console.log("Education", data);
        setEducationArray(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    console.log("USEEFFECT70");
    setLoadingAbout(true);
    //  alert("outside if");
    if (status.jobTitles != undefined) {
      getJobData();
      getNoofAcceptReject({ jobId: status.jobTitles })
        .then((data) => {
          setNoofAcceptReject(data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [status.jobTitles, sort]);
  const getJobData = async () => {
    const fetchApplications = query(
      collectionGroup(db, "Applications"),
      where("jobId", "==", status.jobTitles)
    );
    await getDocs(fetchApplications)
      .then((data) => {
        setAllApplicants(data.docs.map((doc) => doc.data()).reverse());
        setOriginalData(data.docs.map((doc) => doc.data()).reverse());
        setToggle(!toggle);
        setLoadingAbout(false);
      })
      .catch((e) => {
        setLoadingAbout(false);
        toast.error(e.message);
        console.log(e.message);
      });
  };
  useEffect(() => {
    console.log("USEEFFECT100", status);
    // console.log("RESUMEDBMAP",status);
    if (status.status == "resumeDatabase") {
      setLoadingAbout(true);
      resumeDatabase({
        jobId: status.jobTitles,
        status,
        sort,
        candidateStatus: candidatesStatus,
      })
        .then(({ data }) => {
          setLoadingAbout(false);
          //  alert("resumeDatabase then");
          console.log(data);
          const mappedData = data.map((data) => {
            return {
              appliedBy: data.id,
              status: "resumeDatabase",
              city: data.city,
            };
          });
          const filterData = mappedData.filter((item) => {
            // console.log(item, "working");
            return status.city.length == 0
              ? 1
              : status?.city?.includes(item?.city);
          });
          console.log("RESUMEDBMAP", mappedData);

          setAllApplicants(filterData);
        })
        .catch((e) => {
          toast.error(e.message);
          setLoadingAbout(false);
          console.log("ERRORRESUMEDATABASE", e);
          // alert("resumeDatabase error");
        });
      return;
    }
    if (status.status == "suggestedCandidates") {
      setLoadingAbout(true);
      suggestedCandidates({ jobId: status.jobTitles, status, sort })
        .then(({ data }) => {
          setLoadingAbout(false);
          console.log("Suggested candidates", data);
          const mappedData = data.map((data) => {
            return {
              appliedBy: data.id,
              status: "resumeDatabase",
              city: data.city,
            };
          });
          console.log("RESUMEDBMAP", mappedData);
          const filterData = mappedData.filter((item) => {
            // console.log(item, "working");
            return status.city.length == 0
              ? 1
              : status?.city?.includes(item?.city);
          });
          setAllApplicants(filterData);
          // alert("suggested then");
        })
        .catch((e) => {
          toast.error(e.message);
          console.log("Suggested candidates", e);
          setLoadingAbout(false);
          // alert("Suggested error");
        });
      return;
    }

    // console.log(status);
    const filterData = originalData
      .filter((item) => {
        return status.status == "All"
          ? 1
          : item.status === status.status ||
              (status.status == "hired" && item.status == "offerRevised");
      })
      ?.filter((item) => {
        return status.experience == "Any"
          ? 1
          : item.experience === status.experience;
      })
      ?.filter((item) => {
        return status.educationLevel == "Any"
          ? 1
          : item.educationLevel === status.educationLevel;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.skills.length == 0
          ? 1
          : status?.skills?.every((skill) => item?.skills?.includes(skill));
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.country == "" ? 1 : item.country === status.country;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.state == "" ? 1 : item.state === status.state;
      })
      ?.filter((item) => {
        // console.log(item, "working");
        return status.city.length == 0 ? 1 : status?.city?.includes(item?.city);
      });
    let sortedData = filterData;
    if (sort.method == "firstName") {
      sortedData = filterData.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
    } else if (sort.method == "appliedAt") {
      sortedData = filterData.sort(
        (a, b) => fireBaseTime(a.appliedAt) - fireBaseTime(b.appliedAt)
      );
      console.log("SORTEDDATATIME");
    }
    if (sort.order == "asc") {
      sortedData = sortedData.reverse();
    }
    console.log("SORTEDDATA", sortedData);
    setAllApplicants(sortedData);
    // if(sorted)
    console.log("useEffectfilter", filterData);
    // setAllApplicants(filterData);
  }, [status, toggle, sort, candidatesStatus]);

  const StyledFilter = styled("select")({
    height: "40px",
    fontSize: "14px",
    color: "#7C7C7C",
    backgroundColor: "transparent",
    padding: "0px 20px 0px 20px",
    minHeight: "45px",
    borderRadius: "8px",
    border: "1px solid #0A7ABF",

    option: {
      color: "black",
      backgroundColor: "white",
      border: "none",
      minHeight: "50px",
      borderRadius: "0px",
      padding: "10px",
    },
  });

  return (
    <Container sx={{ mt: "30px" }}>
      {/* {`${JSON.stringify(loadingAbout)}`} */}
      {/* <Button onClick={data}>check</Button> */}

        <Box
          className={classes.hidescrollbar}
          sx={{ height: "80vh", overflowY: "scroll" }}
        >
          <Box>
            <Typography
              sx={{
                color: "#0A7ABF",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              All Applicants
              {/*{`${JSON.stringify(status)}`}{`${JSON.stringify(location)}`} */}
            </Typography>
          </Box>

          <Stack spacing={3} sx={{ mt: "30px" }}>
            <Box
              sx={{
                boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
                borderRadius: "8px",
              }}
            >
              <Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: { xs: "10px", md: "20px" },
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <StyledFilter
                      onChange={(e) => {
                        console.log(e);
                        setStatus({ ...status, companyName: e.target.value });

                        // return handleJobChange();
                      }}
                      value={status.companyName}
                    >
                      {companyName?.map((title) => {
                        return <option value={title.id}>{title.title}</option>;
                      })}
                    </StyledFilter>
                  </Box>
                  <Box>
                    <StyledFilter
                      onChange={(e) => {
                        console.log(e);
                        setStatus({ ...status, jobTitles: e.target.value });

                        // return handleJobChange();
                      }}
                      value={status.jobTitles}
                    >
                      {titles?.map((title) => {
                        return <option value={title.id}>{title.title}</option>;
                      })}
                    </StyledFilter>
                  </Box>
                  <Box>
                    {status?.value}
                    <Select
                      onChange={(e, value) =>
                        setStatus({
                          ...status,
                          status: e.value,
                          originalStatus: e,
                        })
                      }
                      // value={status.status}
                      defaultValue={{ value: "All", label: "All Applicants" }}
                      value={status.originalStatus}
                      // inputValue={status.status}
                      options={
                        userType?.planData?.resumeDatabase
                          ? [
                              { value: "All", label: "All Applicants" },
                              { value: "accepted", label: "Shortlisted" },
                              { value: "pending", label: "Yet to be reviewed" },
                              { value: "rejected", label: "Rejected" },
                              { value: "hired", label: "Hired" },
                              {
                                value: "resumeDatabase",
                                label: "Resume Database",
                              },
                              {
                                value: "suggestedCandidates",
                                label: "Suggested Candidates",
                              },
                            ]
                          : [
                              { value: "All", label: "All Applicants" },
                              { value: "accepted", label: "Shortlisted" },
                              { value: "pending", label: "Yet to be reviewed" },
                              { value: "rejected", label: "Rejected" },
                              { value: "hired", label: "Hired" },
                              // {
                              //   value: "resumeDatabase",
                              //   label: "Resume Database",
                              // },
                              {
                                value: "suggestedCandidates",
                                label: "Suggested Candidates",
                              },
                            ]
                      }
                    />
                  </Box>

                  {status.status == "resumeDatabase" ? (
                    <StyledFilter
                      value={candidatesStatus}
                      onChange={async (e) => {
                        setcandidatesStatus(e.target.value);
                      }}
                    >
                      <option selected value="All">
                        Candidate Status
                      </option>
                      <option value="Actively looking">Actively looking</option>
                      <option value="Open to New Opportunities">
                        Open to New Opportunities
                      </option>
                      <option value="Not Looking For Work At This Time">
                        Not Looking For Work At This Time
                      </option>
                    </StyledFilter>
                  ) : null}

                  {status.status != "suggestedCandidates" ? (
                    <Box>
                      <Select
                        id="tags-standard"
                        isMulti
                        isSearchable
                        placeholder="Skill"
                        onChange={(e, value) => {
                          // console.log(e)
                          let arr = [];
                          // arr = (Array.isArray(e) ? e.map(x => x.value) : []);
                          // console.log(arr)
                          for (let i = 0; i < e.length; i++) {
                            arr.push(e[i].value);
                          }
                          setStatus({ ...status, skills: arr });
                        }}
                        options={skillArray}
                        // getOptionLabel={(option) => option}
                      />
                    </Box>
                  ) : null}
                  {/* {`${JSON.stringify(status)}`} */}
                  {/* <Select
                    
                    getOptionLabel={(option) => option}
                    value={status.skills}
                    onChange={(e, value) => {
                      // console.log(value);
                      
                      
                    }}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <TextField
                      sx={{
                        maxHeight: "10px",
                          zIndex:0
                        }}
                        {...params}
                        variant="outlined"
                        label="Skills"
                        placeholder="Skills"
                      />
                    )}
                  /> */}
                  {/* {`${JSON.stringify(updatedCountries)}`} */}
                  <StyledFilter
                    onChange={(e) => {
                      setStatus({ ...status, experience: e.target.value });
                    }}
                    value={status.experience}
                  >
                    <option selected value="Any">
                      Experience
                    </option>
                    {experienceArray.map((exp, index) => {
                      return <option value={exp}>{exp}</option>;
                    })}
                  </StyledFilter>
                  <StyledFilter
                    onChange={(e) => {
                      setStatus({ ...status, educationLevel: e.target.value });
                    }}
                    value={status.educationLevel}
                  >
                    <option selected value="Any">
                      Education Level
                    </option>
                    {educationArray.map((exp, index) => {
                      return (
                        <option selected={index == 0} value={exp}>
                          {exp}
                        </option>
                      );
                    })}
                  </StyledFilter>
                  {status.status != "resumeDatabase" ? (
                    <>
                      {" "}
                      <StyledFilter
                        onChange={(e) => {
                          setSort({ ...sort, method: e.target.value });
                        }}
                        value={sort.method}
                      >
                        <option value="firstName">First Name</option>;
                        <option value="appliedAt">Date Applied</option>;
                      </StyledFilter>
                      <StyledFilter
                        onChange={(e) => {
                          setSort({ ...sort, order: e.target.value });
                        }}
                        value={sort.order}
                      >
                        <option value="desc">Descending</option>;
                        <option value="asc">Ascending</option>;
                      </StyledFilter>
                    </>
                  ) : null}

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: { xs: "10px", md: "20px" },
                    }}
                  >
                    <Box>
                      <Select
                        id="country"
                        name="country"
                        label="country"
                        isClearable={true}
                        options={updatedCountries}
                        value={values.country}
                        onChange={(value) => {
                          console.log(value);
                          if (value != null) {
                            setStatus({
                              ...status,
                              country: value.label,
                              state: "",
                              city: "",
                            });
                            setValues({
                              country: value,
                              state: null,
                              city: null,
                            });
                          } else {
                            setStatus({
                              ...status,
                              country: "",
                              state: "",
                              city: "",
                            });
                            setValues({
                              country: value,
                              state: null,
                              city: null,
                            });
                          }
                          setCityMulti([]);
                        }}
                      />
                      {/* <CountrySelector
                      onChange={(e) => {
                        //  setValue("country", e.name);
                        console.log(e);
                        setLocation({ ...location, country: e });
                        if (e == null) {
                          setStatus({
                            ...status,
                            country: "",
                            city: "",
                            state: "",
                          });
                        } else {
                          setStatus({
                            ...status,
                            country: e.name,
                            city: "",
                            state: "",
                          });
                        }
                      }}
                      name="country"
                      placeholder="Select a country"
                      value={location.country}
                    /> */}
                    </Box>
                    <Box>
                      <Select
                        id="state"
                        name="state"
                        isClearable
                        options={updatedStates(
                          values.country ? values.country.value : null
                        )}
                        value={values.state}
                        onChange={(value) => {
                          if (value != null) {
                            setStatus({
                              ...status,
                              state: value.label,
                              city: "",
                            });
                            setValues({ ...values, state: value, city: null });
                          } else {
                            setStatus({
                              ...status,
                              state: "",
                              city: "",
                            });
                            setValues({ ...values, state: value, city: null });
                          }

                          setCityMulti([]);
                        }}
                      />
                      {/* <StateSelector
                      country={location.country}
                      name="state"
                      value={location.state}
                      countryPlaceholder="Select a country first"
                      onChange={(e) => {
                        // setValue("state", e.name);

                        setLocation({ ...location, state: e });
                        if (e == null) {
                          setStatus({ ...status, state: "", city: "" });
                        } else {
                          setStatus({ ...status, state: e.name, city: "" });
                        }
                      }}
                    /> */}
                    </Box>
                    <Box>
                      <Select
                        id="city"
                        name="city"
                        isMulti
                        options={updatedCities(
                          values.country ? values.country.value : null,
                          values.state ? values.state.value : null
                        )}
                        value={cityMulti}
                        onChange={(e) => {
                          console.log(e);
                          let arr = [];
                          for (let i = 0; i < e.length; i++) {
                            arr.push(e[i].label);
                          }
                          setStatus({
                            ...status,
                            city: arr,
                          });
                          setCityMulti(Array.isArray(e) ? e.map((x) => x) : []);
                        }}
                        //onChange={(value) => setValues({...values,city: value})}
                      />
                      {/* <CitySelector
                      country={location.country}
                      state={location.state}
                      name="city"
                      value={location.city}
                      statePlaceholder="Select a state first"
                      onChange={(e) => {
                        // setValue("city", e.name);
                        setLocation({ ...location, city: e });
                        if (e == null) {
                          setStatus({ ...status, city: "" });
                        } else {
                          setStatus({ ...status, city: e.name });
                        }
                      }}
                    /> */}
                    </Box>
                  </Box>
                </Box>
              </Stack>

              <Box sx={{ ...displayOnDesktop }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f0faf0",
                    padding: "15px",
                    borderRadius: "8px",
                    gap: "20px",
                    mt: "40px",
                  }}
                >
                  <Box sx={{ width: "25%" }}>
                    <Typography
                      component={"a"}
                      onClick={() =>
                        setStatus({
                          ...status,
                          status: "All",
                          originalStatus: {
                            value: "All",
                            label: "All Applicants",
                          },
                        })
                      }
                      sx={{
                        color: "#1967D2",
                        fontFamily: "Sofia Pro",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Total(s): {originalData?.length}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <Typography
                      component={"a"}
                      onClick={() =>
                        setStatus({
                          ...status,
                          status: "accepted",
                          originalStatus: {
                            value: "accepted",
                            label: "Shortlisted",
                          },
                        })
                      }
                      sx={{
                        color: "#1967D2",
                        fontFamily: "Sofia Pro",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Shortlisted: {noofAcceptReject.accepted}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <Typography
                      component={"a"}
                      onClick={() =>
                        setStatus({
                          ...status,
                          status: "hired",
                          originalStatus: { value: "hired", label: "Hired" },
                        })
                      }
                      sx={{
                        color: "#1967D2",
                        fontFamily: "Sofia Pro",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Hired: {noofAcceptReject?.hired}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <Typography
                      component={"a"}
                      onClick={() =>
                        setStatus({
                          ...status,
                          status: "pending",
                          originalStatus: {
                            value: "pending",
                            label: "Yet to be reviewed",
                          },
                        })
                      }
                      sx={{
                        color: "#1967D2",
                        fontFamily: "Sofia Pro",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Yet To Be Reviewed: {noofAcceptReject?.pending}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component={"a"}
                      onClick={() =>
                        setStatus({
                          ...status,
                          status: "rejected",
                          originalStatus: {
                            value: "rejected",
                            label: "Rejected",
                          },
                        })
                      }
                      sx={{
                        color: "#D93025",
                        fontFamily: "Sofia Pro",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Rejected(s): {noofAcceptReject.rejected}
                    </Typography>
                    <EmployeeDashboardAllApplicantsDownArrowIcon />
                  </Box>
                </Box>
              </Box>

              <Box
                className={classes.hidescrollbar}
                spacing={2}
                sx={{
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "75vw",
                  flexWrap: "wrap",
                  marginTop: "1vw",
                  gap: "10px",
                }}
              >
                {/* {JSON.stringify(allApplicants[0])} */}
                {allApplicants.length == 0 && !loadingAbout ? (
                  <JobNotFound />
                ) : (
                  <>
                    {allApplicants.map((applicant, index) => {
                      console.log(applicant);
                      return (
                        <ApplicantDetails
                          key={index}
                          info={{
                            data: {
                              applicantId: applicant.appliedBy,
                              jobId: applicant.jobId
                                ? applicant.jobId
                                : status.jobTitles,
                            },
                          }}
                          offerStatus={applicant.offerStatus}
                          method={applicant?.method}
                          status={applicant.status}
                          selections={status}
                          skills={applicant.skills}
                          ApplicantDetails={applicant}
                          showAction={true}
                          noofAcceptReject={noofAcceptReject}
                          setNoofAcceptReject={setNoofAcceptReject}
                        />
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          </Stack>
        </Box>
      <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default TeamDashboardAllApplicant;
