import { async } from "@firebase/util";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, TextField, Stack, MenuItem, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import userEvent from "@testing-library/user-event";
import { collection, FieldPath, getDocs, query, where } from "firebase/firestore";
import * as React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import {
  inviteEmployee,
  inviteEmployeeMfgworx,
  teamAssignCompany,
} from "../../firebase/functions";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function AssignCompany({array , userId , flag , setflag}) {
    const [open, setOpen] = React.useState(false);
    const [companyId, setCompanyId] = React.useState('');
    const [loadingAssign, setLoadingAssign] = React.useState(false);

    const [companyArray, setCompanyArray] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    React.useEffect(() => {
        console.log(array)
        companyFunction();
    }, [array])
    const companyFunction = async () => {
        if (array.length > 0) {
            const orgCollectionRef = query(
                collection(db, "Organisations"),
                where("__name__", "not-in", array)
            );
            await getDocs(orgCollectionRef)
                .then((docSnap) => {
                    let temp = docSnap.docs.map((doc) => {
                        return { ...doc.data(), id: doc.id };
                    });
                    console.log(temp)
                    setCompanyArray(
                        temp
                    );
                })
                .catch((e) => {
                    console.log(e);
                    toast.error(e.message);
                });
        } else {
            const orgCollectionRef = collection(db, "Organisations")
            await getDocs(orgCollectionRef)
              .then((docSnap) => {
                let temp = docSnap.docs.map((doc) => {
                  return { ...doc.data(), id: doc.id };
                });
                console.log(temp);
                setCompanyArray(temp);
              })
              .catch((e) => {
                console.log(e);
                toast.error(e.message);
              });
        }
    }
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    setloading(true);
    inviteEmployeeMfgworx({ ...data })
      .then(() => {
        toast.success("Invitation sent successfully");
        handleClose();
        reset();
        setloading(false);
      })
      .catch((e) => {
        toast.error(e.message);
        setloading(false);
      });
  };
  return (
    <Box>
      <Button
        onClick={handleOpen}
        sx={{
          color: "#FFF",
          backgroundColor: "#25C027",
          fontSize: "10px",
          fontWeight: 600,
          borderRadius: "10px",
          padding: "10px 25px",
          "&:hover": {
            backgroundColor: "#25C027",
          },
        }}
      >
        Assign Organizations
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: { xs: "95%", md: "50%" } }}>
          <Stack spacing={4} sx={{ mt: "30px" }}>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "30px",
                  fontWeight: 500,
                }}
              >
                Assign Organizations
              </Typography>
            </Box>
            <TextField
              labelId="demo-multiple-chip-label"
              label="Company Name"
              name="teamSize"
              select
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value);
                console.log(e.target.value);
              }}
              color="success"
              MenuProps={MenuProps}
            >
              {companyArray.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  {name.organisationName}
                </MenuItem>
              ))}
            </TextField>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <LoadingButton
                loading={loadingAssign}
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
                              onClick={() => {
                                  setLoadingAssign(true);
                  teamAssignCompany({
                    companyId: companyId,
                    id: userId,
                  })
                    .then((response) => {
                      console.log(response);
                      toast.success("Company Assigned");
                      setOpen(false);
                      setflag(flag + 1);
                      setLoadingAssign(false);
                    })
                    .catch((e) => {
                      setLoadingAssign(false);
                      toast.error(e.message);
                    });
                }}
              >
                Assign Organizations
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
}

export default AssignCompany;
