import * as React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Input,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import {
  EmployeeDashboardComponentSeeTracedIcon,
  EmployeeDashboardManageJobsDeleteTracedIcon
} from "../../utilities/Icons/Icons";
import "./CandidateDashboardMyResume.css";

import { LoadingButton } from "@mui/lab";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteResume,
  editUserProfile,
  myProfile,
  uploadResume
} from "../../firebase/functions";
import convertBase64 from "../../Helpers/fileConvert";
import {
  displayOnDesktop,
  displayOnMobile,
  flexCenter
} from "../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import AddSkills from "./AddSkills";
import Awards from "./Awards";
import EducationTimeline from "./EducationTimeline";
import WorksExperience from "./WorksExperience";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const ChipsArray = () => {
  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Angular" },
    { key: 1, label: "jQuery" },
    { key: 2, label: "Polymer" },
    { key: 3, label: "Vue.js" },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };
  return (
    <Box
      sx={{
        minHeight: "150px",
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        backgroundColor: "#F0FAF0",
        padding: "25px",
        borderRadius: "8px",
        my: "20px",
      }}
      component="ul"
    >
      {chipData.map((data) => {
        return (
          <ListItem key={data.key}>
            <Chip
              label={data.label}
              onDelete={data.label === "" ? undefined : handleDelete(data)}
              sx={{ backgroundColor: "#D0E4EB", color: "#1967D2" }}
            />
          </ListItem>
        );
      })}
    </Box>
  );
};

const CandidateDashboardMyResume = () => {
  const [upload, setUpload] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [btnText, setBtnText] = React.useState("Select file");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const [skill, setSkill] = React.useState([]);
  React.useEffect(() => {
    setloading(true);
    myProfile().then(({ data }) => {
      console.log("HEY THIS (IS USR DATA", data);
      if (data.resume) {
        setBtnText("Change file");
      }
      setSkill(data.skills);
      setUser(data);
      reset({
        ...data,
      });
      // setSkill(data.skills);
      setloading(false);
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
  }, [upload]);

  const [view, setView] = React.useState(false);
  const [resume, setResume] = React.useState("");
  const [delStat, setDelete] = React.useState(false);
  const getResume = async (file) => {
    console.log("getResume");
    convertBase64(file).then((res) => {
      setResume(res);
      handleUploadResume(res);
    });
  };
  const handleUploadResume = async (res) => {
    console.log("handleUploadResume");
    setBtnText("Uploading");
    await uploadResume({
      resume: res,
    }).then(({ data }) => {
      setResume("");
      setUpload(!upload);
      toast.success("Resume Uploaded Successfully");
      setUser({ resume: window.URL.createObjectURL(data.resume), ...user });
      setDelete(false);
      setBtnText("Uploaded");
    }).catch((e)=>{
      setloading(false);
    })
  };
  const onSubmit = (data) => {
    if (data.skills.length == 0) {
      toast.error("Skills not added");
      return;
    }
    setloading(true);
    editUserProfile({ ...data }).then(({ data }) => {
      console.log(data);
      setloading(false);
      toast.success("Profile Updated");
      navigate("/job-search");
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
  };
  const handleDelete = async () => {
    console.log("handleDelete");
    await deleteResume();
    setUpload(false);
    setBtnText("Select file");
    setUser({ resume: null, ...user });
    // setResume("")
    setDelete(true);
    toast.success("Profile Updated");
    setUpload(!upload);
  };
  return (
    <Container
      // component="form"
      // onSubmit={handleSubmit(onSubmit)}
      className="hidescrollbar"
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          My Resume
        </Typography>
      </Box>

      <Stack
        spacing={3}
        sx={{
          mt: "60px",
          background: "#FFFFFF",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          padding: "40px 30px",
        }}
      >
        <Box>
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontSize: "18px",
                fontWeight: 500,
                fontFamily: "Sofia Pro",
              }}
            >
              My Resume
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              mt: "20px",
            }}
          >
            <label htmlFor="contained-button-file">
              <Input
                onChange={(e) => {
                  // console.log(e.target.files[0]);
                  return getResume(e.target.files[0]);
                }}
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf"
                id="contained-button-file"
                multiple
                type="file"
                sx={{ display: "none" }}
              />
              <Stack>
                <LoadingButton
                  loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
                  loading={btnText === "Uploading"}
                  component="span"
                  sx={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "Sofia Pro",
                    backgroundColor: "#24BD2C",
                    color: "#fff",
                    padding: "15px 40px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#24BD2C",
                    },
                  }}
                >
                  {btnText}
                </LoadingButton>
                {/* {resume ? (
                  <Stack direction>
                    <Button sx={{ width: "50%" }} onClick={() => setResume("")}>
                      Clear
                    </Button>
                    <Button
                      sx={{ width: "50%" }}
                      color="error"
                      onClick={() => handleUploadResume()}
                    >
                      Upload
                    </Button>
                  </Stack>
                ) : null} */}
              </Stack>
            </label>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              {/* {JSON.stringify(resume)} */}
              {/* <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#F0FAF0",
                }}
              >
                <Tooltip title="Delete Resume" arrow placement="top">
                  <IconButton
                    disabled={!user?.resume}
                    onClick={() => handleDelete()}
                  >
                    <EmployeeDashboardManageJobsDeleteTracedIcon />
                  </IconButton>
                </Tooltip>
              </Box> */}
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#F0FAF0",
                }}
                component={"a"}
                href={user.resume}
                target="_blank"
              >
                <Tooltip arrow title="View" placement="top">
                  <IconButton disabled={!user?.resume}>
                    <EmployeeDashboardComponentSeeTracedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* {upload ? (
          <Typography variant="h6" color="success.main">
            Resume Uploaded Successfully
          </Typography>
        ) : null} */}
        {delStat ? (
          <Typography variant="h6" color="error">
            Resume Deleted
          </Typography>
        ) : null}
        {user?.resume ? (
          <Typography variant="h6" color="success.main">
            Resume : {user.resume.split("?")[0].split("/").pop()}
          </Typography>
        ) : (
          <Typography variant="h6" color="error">
            Resume doesn't exist
          </Typography>
        )}
        <Box sx={{ ...displayOnDesktop }}>
          <Divider>
            <Typography
              sx={{ textAlign: "center", fontSize: "11px", px: "5px" }}
            >
              Don't have a resume ? create one by filling the details here
            </Typography>
          </Divider>
        </Box>
        <Box sx={{ ...displayOnMobile }}>
          <Typography sx={{ textAlign: "center", fontSize: "11px", px: "5px" }}>
            Don't have a resume ? create one by filling the details here
          </Typography>

          <Box sx={{ ...flexCenter }}>
            <Box
              sx={{
                width: "100px",
                height: "2px",
                backgroundColor: "#696969",
                mt: "5px",
              }}
            ></Box>
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          >
            Description
          </Typography>
          <TextField
            {...register("description", {
              required: true,
            })}
            id="outlined-basic"
            variant="outlined"
            color="success"
            multiline
            rows={10}
            sx={{
              width: "100%",
              mt: "10px",
              color: "#696969",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          />
        </Box>

        <EducationTimeline />

        <WorksExperience />

        <Awards />

        <AddSkills
          skills={skill}
          user={user}
          getValues={getValues}
          setValue={setValue}
        />
      </Stack>
      <Button
        // type="submit"
        onClick={(e) => {
          return onSubmit(getValues());
        }}
        sx={{
          width: "200px",
          textTransform: "none",
          fontSize: "14px",
          fontFamily: "Sofia Pro",
          backgroundColor: "#25C027",
          color: "#fff",
          padding: "15px 50px",
          borderRadius: "8px",
          mt: "30px",
          "&:hover": {
            backgroundColor: "#25C027",
          },
        }}
      >
        Save
      </Button>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default CandidateDashboardMyResume;
