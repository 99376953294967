import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
// import {
//   ,
//   signInWithEmailAndPassword,
// } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db, sendPasswordResetLink, signIn } from "../../firebase/firebaseConfig";

import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { itemsCenter } from "../../utilities/commonStyles/commonStyles";
import {
  RegisterFacebookIcon,
  RegisterGoogleIcon
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./Login.module.css";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

const Login = () => {
  const [body, setBody] = useState({});
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
  } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const result = await signIn(data.email, data.password);
      if (result == "success") navigate("/");
      else
        setError("password", {
          type: "custom",
          message: "Invalid Credentials",
        });
      setLoading(false);
    } catch (e) {
      console.error("EEROORRR", e);
    }
  };
  const handlePasswordReset = async () => {
    try {
      const email = getValues("email");
      if (!email) {
        setError("email", {
          type: "custom",
          message: "Email is required",
        });
        return 0;
      }
      const result = await sendPasswordResetLink(email, {
        continueUrl: "www.google.com",
      });
      toast.success("Password reset link sent to your email");
    } catch (e) {
      setError("email", {
        type: "custom",
        message:
          e.message == "FirebaseError: Firebase: Error (auth/user-not-found)."
            ? "User not found please check your email id"
            : e.message,
      });
    }
  };
  const googleLogin=async()=>{
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        console.log("LOGGED IN",user);
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          toast.error("User not Found. Please Register");
          navigate("/registration/register");
        }
        else{
          toast.success("Signin Successful");
          navigate("/");
        }
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage);
        console.log(errorMessage)

        // const email = error.customData.email;
        // const credential = GoogleAuthProvider.credentialFromError(error);
      });
  }
  return (
    <Box sx={{ width: {xs:"90%", md:"80%"}, mt: { md: "90px", xs: "30px" } }}>
      <Typography
        sx={{
          fontFamily: "Sofia Pro",
          fontWeight: 500,
          fontSize: { md: "24px", xs: "16px" },
          color: "#202124",
        }}
      >
        Login to MFGWorx
      </Typography>
      <Box sx={{ mt: "40px" }}>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Box className={classes.input}>
              <label>Email</label>
              <TextField
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  },
                })}
                error={!!errors?.email}
                helperText={errors?.email ? errors.email.message : null}
              />
            </Box>
            <Box className={classes.input}>
              <label>Password</label>
              <TextField
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
                error={!!errors?.password}
                helperText={errors?.password ? errors.password.message : null}
                type="password"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ ...itemsCenter }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{
                        color: "#25C02780",
                        "&.Mui-checked": {
                          color: "#25C02780",
                        },
                      }}
                    />
                  }
                />
                <Typography
                  sx={{
                    fontSize: {
                      md: "20px",
                      xs: "12px",
                    },
                    fontFamily: "Sofia Pro",
                    fontWeight: 500,
                    color: "#B7B7B7",
                    position: "relative",
                    left: "-20px",
                  }}
                >
                  Remember Me
                </Typography>
              </Box>
              <Typography
                onClick={() => handlePasswordReset()}
                sx={{
                  fontFamily: "Sofia Pro",
                  fontWeight: 500,
                  fontSize: { md: "20px", xs: "12px" },
                  color: "#B7B7B7",
                  cursor: "pointer",
                }}
              >
                Forgot your password?
              </Typography>
            </Box>
            <Box>
              <LoadingButton
                loading={loading}
                type="submit"
                sx={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 24px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
              >
                Login
              </LoadingButton>

              <Typography
                sx={{
                  fontFamily: "Sofia Pro",
                  fontSize: "20px",
                  textAlign: "center",
                  py: "15px",
                  fontWeight: 500,
                  fontSize: { md: "20px", xs: "12px" },
                  color: "#B7B7B7",
                }}
              >
                Don't have an account?{" "}
                <span
                  onClick={() => navigate("/registration/register")}
                  style={{
                    fontWeight: 500,
                    cursor: "pointer",
                    color: "#25C027",
                  }}
                >
                  Signup
                </span>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "" }}>
              <Box
                sx={{ width: "45%", height: "1px", backgroundColor: "#ECEDF2" }}
              ></Box>
              <Typography sx={{ width: "10%", textAlign: "center" }}>
                or
              </Typography>
              <Box
                sx={{ width: "45%", height: "1px", backgroundColor: "#ECEDF2" }}
              ></Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "20px",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  width: { md: "50%", xs: "100%" },
                  fontSize: "14px",
                  fontFamily: "Sofia Pro",
                  backgroundColor: "#fff",
                  color: "#1967D2",
                  padding: "14px 24px",
                  borderRadius: "8px",
                  border: "1px solid #1967D2",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#1967D2",
                  },
                }}
              >
                <RegisterFacebookIcon />{" "}
                <Typography sx={{ ml: "10px" }}>Log In via Facebook</Typography>
              </Button>

              <Button
                sx={{
                  textTransform: "none",
                  width: { md: "50%", xs: "100%" },
                  fontSize: "14px",
                  fontFamily: "Sofia Pro",
                  backgroundColor: "#fff",
                  color: "#D93025",
                  padding: "14px 24px",
                  borderRadius: "8px",
                  border: "1px solid #D93025",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#D93025",
                  },
                }}
                onClick = {()=>googleLogin()}
              >
                <RegisterGoogleIcon />{" "}
                <Typography sx={{ ml: "10px" }}>Log In via Google+</Typography>
              </Button>
            </Box>
          </Stack>
        </Box>
        <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
      </Box>
    </Box>
  );
};

export default Login;
