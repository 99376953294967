import { Box, Container, Stack, Typography, Modal } from "@mui/material";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
import { collection, collectionGroup, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { GetType } from "../../context/authContext";
// import { db } from "../../firebase/firebaseConfig";

import {
  getAllJobsAppliedId,
  getBookmarks,
  getOffers,
} from "../../firebase/functions";

// import JobcardCompanyProfile from "../../pages/CompanyProfile/JobcardCompanyProfile";
// import {
//   EmployeeDashboardManageJobsDeleteTracedIcon,
//   EmployeeDashboardManageJobsDownArrowIcon,
//   EmployeeDashboardManageJobsSeeTracedIcon,
// } from "../../utilities/Icons/Icons";

import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
//import ApplicationCardCandidate from "./ApplicationCardCandidate";
import BookmarkedCard from "./BookmarkedCard";
import classes from "./CandidateDashboardAppliedJobs.module.css";
//import TableRowComponent from "./TableRowComponent";

const Bookmarkedjobs = () => {
  const fakeArr = [1, 2, 3, 4, 5];
  const [loading, setloading] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  //const userType = GetType();
  useEffect(() => {
    setloading(true);
    getBookmarks().then(({ data }) => {
      setBookmarks(data);
      setloading(false);
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
    // if (userType.uid != undefined) {
    //   soln();
    // }
  }, []);

  // const soln = async () => {
  //   const employerPostedJob = collection(db, `Users/${userType.uid}/Bookmarks`);
  //   await getDocs(employerPostedJob)
  //     .then((docSnap) => {
  //       setBookmarks(docSnap.docs.map((doc) => doc.id));
  //       setloading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setloading(false);
  //       toast.error(e.message);
  //     });
        
  // };
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Bookmarked Jobs
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "30px",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            ></Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#F0FAF0",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography>
            <EmployeeDashboardManageJobsDownArrowIcon />
          </Box> */}
        </Box>

        <Box sx={{ my: "0px" }}>
          <Stack
            className={classes.hidescrollbar}
            spacing={2}
            sx={{ height: "60vh", overflowY: "scroll", mt: "25px" }}
          >
            {bookmarks?.map((item, index) => (
              <BookmarkedCard book={true} jobId={item} />
            ))}
          </Stack>

          {/* <TableContainer>
          
          </TableContainer> */}
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default Bookmarkedjobs;
