import { Avatar, Box, Button, Typography,Modal,Stack } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetType } from "../../../context/authContext";
import { auth, logOut } from "../../../firebase/firebaseConfig";
import { getOrgInfoAdmin } from "../../../firebase/functions";
import DashboardNavbarLogo from "../../../utilities/images/svg images/dashboard-navbar-logo.svg";
import UpdatedDashboardNavbarLogo from "../../../utilities/images/Asset-10.svg";
import WhiteLogo from "../../../utilities/images/white-logo.png";
import UpdatedWhiteLogo from "../../../utilities/images/Asset-9.svg";
import LoadingSearchComponents from "../LoadingSearchComponents/LoadingSearchComponents";
import LoginComponent from "./LoginComponent";
import classes from "./NavBar.module.css";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
const NavBar = ({ color }) => {
  const userType = GetType();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [organisationLogo , setOrganisationLogo] = useState('');
  const [loading ,setloading] = useState(false);
  const [user, setUser] = useState(true);
  const [company, setCompany] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUserData(auth.currentUser);
      console.log(userData);
      console.log(userType);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = () => {
    setloading(true);
    logOut().then(() => {
      navigate("/");
      setloading(false);
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
  };
  useEffect(() => {
    
      getOrgInfoAdmin().then(({ data }) => {
        console.log(data);
        if (data.organisationLogo) {
          setOrganisationLogo(data.organisationLogo);
        }
      });
  }, []);
  // useEffect(()=>{
  //   const auth = getAuth();
  // console.log(auth.AuthProvider());
  // },[])
  return (
    <Box
      sx={{
        padding: "2.5vh 50px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Link to="/">
          <img
            style={{ width: "200px" }}
            src={
              color == "green" ? UpdatedDashboardNavbarLogo : UpdatedWhiteLogo
            }
            //src={WhiteLogo}
            alt=""
          />
        </Link>
      </Box>
      {/* {`${JSON.stringify(userType)}`} */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Box
          className={color == "green" ? classes.colorNavLink : classes.navLink}
          sx={{ display: "flex", alignItems: "center", gap: "20px" }}
        >
          <Link to="/">Home</Link>
          {userData?.email ? (
            <Link
              to={
                userType.role == "user"
                  ? "/candidate-dashboard/dashboard"
                  : "/employee-dashboard/dashboard"
              }
            >
              Dashboard
            </Link>
          ) : null}
          {userType.role == "Recruiter" && !company && (
            <Link to="/candidate-list">Candidate List</Link>
          )}
          {!user && company && <Link to="/company-list">Company List</Link>}
          {userType.role == "Recruiter" ? null : (
            <Link to="/job-search">Find Job</Link>
          )}
          {<Link to="/about-us">About Us</Link>}
          {userType.role == "Recruiter" && !company && (
            <Link to="/pricing">Pricing</Link>
          )}
          <Link to="/policy">Policy</Link>
          <Link to="/terms">Terms</Link>
          <Link to="/faq">Faq</Link>
          <Link to="/contact-us">Contact Us</Link>
          {auth.currentUser ? (
            <Button
              sx={{ color: color ? "#25c027" : "#fff" }}
              onClick={() => handleLogout()}
            >
              Logout
            </Button>
          ) : null}
        </Box>
        {!userData?.email ? <LoginComponent /> : null}

        {userData?.email ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              backgroundColor: "#7BDF80",
              padding: "5px 10px",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#C4C4C4",
                border: "2px solid #FFFCFC",
              }}
            >
              {userType?.role == "user" ? (
                <Avatar src={userType.displayImage} />
              ) : null}
              {userType.role == "Recruiter" && !company && (
                <Avatar src={organisationLogo} />
              )}
            </Box>
            <Box sx={{ color: "#fff" }}>
              <Typography>{userType.displayName}</Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
      {/* {`${JSON.stringify(userType)}`} */}
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default NavBar;
