import { Avatar, Box, Chip, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebaseConfig";
import {
  getJobInfoForApplicantCard,
  userInfoForApplicantCard
} from "../../firebase/functions";
import { responsiveFlexDirection } from "../../utilities/commonStyles/commonStyles";
import { FeturedJobsLocationIcon } from "../../utilities/Icons/Icons";
import ApplicantCardActions from "./ApplicantCardActions";
function JobApplicantCard({ info, status }) {
  const [jobInfo, setJobInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    // getJobInfoForApplicantCard({ jobId: info.data.jobId }).then((data) => {
    //   console.log(data.data);
    //   setJobInfo(data.data);
    // });
    getdata();
    soln();
    // userInfoForApplicantCard({
    //   applicantId: info.data.applicationId
    //     ? info.data.applicationId
    //     : info.data.appliedBy
    //     ? info.data.appliedBy
    //     : info.data.applicantId,
    // }).then((data) => {
    //   console.log(data.data);
    //   setUserInfo(data.data);
    // });
  }, []);
  const getdata = async() => {
    const usercollectionRef = doc(db, `Jobs/${info.data.jobId}`);
   const docSnap = await getDoc(usercollectionRef)
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const jobData = docSnap.data();
        setJobInfo({
          jobTitle: jobData.jobTitle,
          jobLocation: `${jobData.city} ${jobData.state}`,
          salaryRange: jobData.salaryRange,
        });
      } else {
        //! doc.data() will be undefined in this case
        console.log("No such document!");
      }
  }
  const soln = async () => {
    const usercollectionRef = doc(
      db,
      `Users/${
        info.data.applicationId
          ? info.data.applicationId
          : info.data.appliedBy
          ? info.data.appliedBy
          : info.data.applicantId
      }`
    );
    const docSnap = await getDoc(usercollectionRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      // setAboutUsData(docSnap.data().about);
       setUserInfo({ applicantData: docSnap.data() });
      console.log(docSnap.data());
    } else {
      //! doc.data() will be undefined in this case
      // console.log(error);
    }
  };
  return (
    <Box
      sx={{
        ...responsiveFlexDirection,
        display: "flex",
        justifyContent: { md: "space-between" },
        alignItems: "center",
        gap: "30px",
        background: "#fff",
        padding: {xs:"10px", md:"30px"},
        borderRadius: "8px",
        mt: "30px",
        border: "1px solid #ECEDF2",
      }}
    >
      {/* {`${JSON.stringify(userInfo)}`} */}
      <Box
        sx={{
          display: "flex",
          alignItems: {md:"center"},
          gap: { xs: "10px", md: "20px" },
        }}
      >
        <Box
          sx={{
            // backgroundColor: "#262E50",
            width: { xs: "50px", md: "90px" },
            height: { xs: "50px", md: "90px" },
            display: "flex",
            // borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
            src={userInfo?.applicantData?.displayImage}
            alt=""
          />
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: "#0E0E0E",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              {/* {JSON.stringify(userInfo.applicantData.firstName)} */}
              {userInfo?.applicantData?.firstName}
              {/* {JSON.stringify(userInfo)} */}
              {/* {userInfo?.userInfo?.displayName
                ? userInfo?.userInfo?.displayName
                : userInfo.applicantData.firstName?userInfo.applicantData.firstName:"none"} */}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              mt: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {jobInfo.jobTitle}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsLocationIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {jobInfo.jobLocation}
              </Typography>
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsMoneyIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "14px",
                }}
              >
                {`${jobInfo?.minSalary} - ${jobInfo?.maxSalary} $`}
              </Typography>
            </Box> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              mt: "10px",
            }}
          >
            {userInfo.applicantData?.skills?.map((skill) => {
              return (
                <Chip
                  sx={{ backgroundColor: "#F0FBF0" }}
                  label={
                    <Typography
                      sx={{
                        color: "#696969",
                        fontFamily: "Sofia Pro",
                        fontSize: "14px",
                        backgroundColor: "#F0FBF0",
                        display: "inline-block",
                      }}
                    >
                      {skill}
                    </Typography>
                  }
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <ApplicantCardActions status={status} ids={info.data} />
    </Box>
  );
}

export default JobApplicantCard;
