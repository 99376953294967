import { Box, Container, Typography,Modal,Stack, Badge } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logOut } from "../../firebase/firebaseConfig";
import { AllUnreadMessageCountCandidates, fetchNotifs, getOffers, getUserInvites, getUserType } from "../../firebase/functions";
import { itemsCenter } from "../../utilities/commonStyles/commonStyles";
import {
  CandidateDashboardBookMarkedIcons,
  CandidateDashboardJobAlertsIcons,
  CandidateDashboardJobInvitesIcons,
  CandidateDashboardNotificationIcons,
  CandidateDashboardResumeIcons,
  EmployeeDashboardChangePasswordIcon,
  EmployeeDashboardIcon,
  EmployeeDashboardLogoutIcon,
  EmployeeDashboardManageJobsIcon,
  EmployeeDashboardMessageIcon,
  EmployeeDashboardViewProfileIcon,
  JobOfferIcon
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./CandidateDashboardSideNavbar.module.css";
const CandidateDashboardSideNavbarMobile = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
   const [allNotificationCount, setAllNotificationCount] = useState(0);
   const [unreadMessageCount, setUnreadMessageCount] = useState(0);
   const [offerCountPending, setOfferCounterPending] = useState(0);
   const [inviteCountPending, setInviteCountPending] = useState(0);
   const [toggle, setToggle] = useState(false);
   useEffect(() => {
     fetchNotifs({})
       .then((response) => {
         console.log("hello mr. bean");
         if (Array.isArray(response.data)) {
           console.log(response.data);
           let tempNotificationCount = 0;
           let tempAcceptInvitaion = 0;
           for (let i = 0; i < response.data.length; i++) {
             if (!response.data[i].read) {
               tempNotificationCount++;
             }
             if (
               response.data[i].title == "Job Invitation Accepted" &&
               !response.data[i].read
             ) {
               tempAcceptInvitaion++;
             }
           }
           setAllNotificationCount(tempNotificationCount);
           // setAcceptInvitaionCount(tempAcceptInvitaion);
         }
         console.log(response);
       })
       .catch((e) => {
         console.error(e.message);
       });
     AllUnreadMessageCountCandidates({})
       .then((response) => {
         console.log("hello mr. Charlie");
         console.log(response);
         if (Array.isArray(response.data)) {
           setUnreadMessageCount(response.data.length);
         }
       })
       .catch((e) => {
         console.error(e.message);
       });
     getOffers()
       .then((response) => {
         console.log("hello mr. hashmukh rai");
         console.log(response);
         if (Array.isArray(response.data)) {
           // setUnreadMessageCount(response.data.length);
           let countOffer = 0;
           for (let i = 0; i < response.data.length; i++) {
             console.log("checkin");
             console.log(response.data[i].offerStatus == undefined);
             if (
               response.data[i].offerStatus == "" ||
               response.data[i].offerStatus == undefined
             ) {
               countOffer++;
             }
           }
           console.log("countOffer" + countOffer);
           setOfferCounterPending(countOffer);
         }
       })
       .catch((e) => {
         console.error(e.message);
       });
     getUserInvites()
       .then(({ data }) => {
         console.log("hello mr. jack");
         console.log(data);
         if (Array.isArray(data)) {
           // setUnreadMessageCount(response.data.length);
           let countInvite = 0;
           for (let i = 0; i < data.length; i++) {
             if (data[i].status == "invited") {
               countInvite++;
             }
           }
           // console.log("countOffer" + countOffer);
           setInviteCountPending(countInvite);
         }
       })
       .catch((e) => {
         console.log(e);
       });
   }, [toggle]);
  const checkUser = async()=>{
    const type = await getUserType();
    if (type.data.role == "Recruiter") {
      navigate("/");
    }
    if (type.data.role == "Admin") {
      navigate("/super-admin-dashboard/dashboard");
    }
  }
  useEffect(() => {
    checkUser();
    checkLogin();
  }, []);
  const handleLogout = () => {
    setloading(true);
    logOut().then(() => {
      setloading(false);
      navigate("/");
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
  };
  const checkLogin=()=>{
    const auth = getAuth();
    auth.onAuthStateChanged((user)=> {
    if (user) {
    } else {
      navigate("/");
    }
   });
   }
  return (
    <Box
      sx={{
        height: "90vh",
        position: "sticky",
        top: "0px",
        left: "0px",
        right: "0px",
      }}
    >
      <Container
        sx={{ ...itemsCenter, backgroundColor: "#25C027", py: "20px" }}
      >
        <Box>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
              fill="white"
            />
          </svg>
        </Box>
        <Typography
          sx={{
            width: "90%",
            textAlign: "center",
            color: "#FFF",
            fontSize: "20px",
          }}
        >
          Menu
        </Typography>
      </Container>
      <Box
        className={classes.sidebar}
        sx={{ boxShadow: 1, minHeight: "100%", padding: "20px" }}
      >
        <Box>
          <NavLink
            to="dashboard"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Dashboard
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="my-profile"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardViewProfileIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                My Profile
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="my-resume"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <CandidateDashboardResumeIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                My Resume
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="applied-jobs"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardManageJobsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Applied Jobs
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to="bookmarked-jobs"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <CandidateDashboardBookMarkedIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Bookmarked jobs
              </Typography>
            </Box>
          </NavLink>
          {/* <NavLink
            to="suggested-jobs"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardManageJobsIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Suggested Jobs
              </Typography>
            </Box>
          </NavLink> */}

          <NavLink
            to="job-alerts"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <CandidateDashboardNotificationIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Job Alerts
              </Typography>
            </Box>
          </NavLink>

          <NavLink
            to="job-invites"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <CandidateDashboardJobInvitesIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Job Invites
              </Typography>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={inviteCountPending}
              ></Badge>
            </Box>
          </NavLink>

          <NavLink
            to="job-offer"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <JobOfferIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Job Offers
              </Typography>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={offerCountPending}
              ></Badge>
            </Box>
          </NavLink>

          {/* <NavLink
            to="shortlisted-jobs"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardShortlistedResumeIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Shortlisted Jobs
              </Typography>
            </Box>
          </NavLink> */}

          <NavLink
            to="message"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardMessageIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Message
              </Typography>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={unreadMessageCount}
              ></Badge>
            </Box>
          </NavLink>
          <NavLink
            to="notification"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <CandidateDashboardJobAlertsIcons />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Notification
              </Typography>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={allNotificationCount}
              ></Badge>
            </Box>
          </NavLink>
          <NavLink
            to="change-password"
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardChangePasswordIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Change Password
              </Typography>
            </Box>
          </NavLink>
          <NavLink
            to="#"
            className={({ isActive }) =>
              false ? classes.active : classes.inactive
            }
            onClick={handleLogout}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <EmployeeDashboardLogoutIcon />
              <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Logout
              </Typography>
            </Box>
          </NavLink>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "15px",
              gap: "15px",
              backgroundColor: "#fff",
              cursor: "poitner",
            }}
          >
            <EmployeeDashboardLogoutIcon />
            <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
              Logout
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "15px",
              gap: "15px",
              backgroundColor: "#fff",
              cursor: "pointer",
            }}
          >
            <EmployeeDashboardDeleteProfileIcon />
            <Typography sx={{ fontFamily: "Poppins", fontSize: "15px" }}>
              Delete Profile
            </Typography>
          </Box> */}
        </Box>
        <Modal
          open={loading}
          // onClose={}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack
            border={5}
            sx={{
              width: "100%",
              height: "100vh",
            }}
            justifyContent={"center"}
            alignItems="center"
          >
            <LoadingSearchComponents />
          </Stack>
        </Modal>
      </Box>
    </Box>
  );
};

export default CandidateDashboardSideNavbarMobile;
