import { Box, Typography } from "@mui/material";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { getJobOverview } from "../../firebase/functions";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import {
  FeturedJobsClockIcon,
  FeturedJobsFordIcon,
  FeturedJobsLocationIcon,
  FeturedJobsMoneyIcon
} from "../../utilities/Icons/Icons";

function JobcardCompanyProfile({ jobId, orgData }) {
  const navigate = useNavigate();
  TimeAgo.addDefaultLocale(en);
  TimeAgo.addLocale(ru);
  const [jobData, setJobData] = React.useState({});
  useEffect(() => {
    getJobOverview({ jobId }).then(({ data }) => {
      setJobData(data);
    });
  }, []);
  return (
    <Box
      onClick={() => navigate(`/job-profile/${jobId}`)}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "30px",
        background: "#fff",
        padding: { xs: "10px", md: "30px" },
        borderRadius: "20px",
        mt: "30px",
        boxShadow: "0px 5px 20px rgba(71, 119, 54, 0.15)",
        "&:hover": {
          background: "#25C027",
          color: "#fff",
          transition: "0.4s ease",
        },
      }}
    >
      {/* {JSON.stringify(jobData)} */}
      <Box sx={{ display: "flex", alignItems: { md: "center" }, gap: {xs:"10px", md:"20px"} }}>
        <Box
          sx={{
            backgroundColor: "#262E50",
            width: "60px",
            height: "60px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "52px", height: "20px" }}
            src={orgData?.organisationLogo}
            alt=""
          />
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: "#0E0E0E",
                fontFamily: "Sofia Pro",
                fontSize: { xs: "8px", md: "18px" },
                fontWeight: 500,
              }}
            >
              {jobData?.jobTitle}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "10px", md: "30px" },
              mt: "5px",
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsFordIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: { xs: "8px", md: "14px" },
                }}
              >
                {jobData?.organisationName}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsLocationIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: { xs: "8px", md: "14px" },
                }}
              >
                {jobData.city},{jobData.state}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsClockIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: { xs: "8px", md: "14px" },
                }}
              >
                {jobData.jobAddedAt ? (
                  <ReactTimeAgo
                    date={fireBaseTime(jobData?.jobAddedAt).toUTCString()}
                    locale="en-US"
                  />
                ) : null}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FeturedJobsMoneyIcon />
              <Typography
                sx={{
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: { xs: "8px", md: "14px" },
                }}
              >
                {`$${jobData.minSalary} - $${jobData.maxSalary} /${jobData.salaryType}`}
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{
                  color: "#1967D2",
                  background: "#DDE8F8",
                  width: "90px",
                  height: "25",
                  padding: "5px 15px",
                  fontFamily: "Sofia Pro",
                  fontSize: { xs: "8px", md: "14px" },
                  borderRadius: "60px",
                  textAlign: "center",
                }}
              >
                {jobData.jobType}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default JobcardCompanyProfile;
