import {
  Box,
  Button,
  Container,
  Modal,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import { getAllJobsRecruiter, getTotalSlots } from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile
} from "../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./EmployeeDashboardComponent.module.css";
import EmployeeDashboardManageJobsMobile from "./EmployeeDashboardManageJobsMobile";
import ManageJobtableRow from "./ManageJobtableRow";

const EmployeeDashboardManageJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [temp, settemp] = useState([]);
  const [loading, setloading] = useState(false);
  const userType = GetType();
  const [totalSlots, setTotalSlots] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    setloading(true);
    getAllJobsRecruiter().then(({ data }) => {
      setloading(false);
      console.log(data)
      setJobs(data);
    }).catch((e)=>{
      setloading(false);
      toast.error(e.message);
    })
    getTotalSlots()
      .then(({ data }) => {
        setTotalSlots(data);
      })
      .catch((e) => {
        setTotalSlots({ error: e.message });
      });
    // if (userType.organisation != undefined) {
      
    //   soln();
    // }
  }, [userType]);
  const soln = async() =>{
    const fetchApplications = query(
      collection(db, "Jobs"),
      where("organisationId", "==", userType.organisation)
    );
    await getDocs(fetchApplications).then((data) => {
      setJobs(data.docs.map((doc) => { console.log(doc.data()); return ({ data: doc.data(), id: doc.id }) }));
        setloading(false);
      }).catch((e)=>{
      setloading(false);
      console.log(e);
     })
  }
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      {/* {`${JSON.stringify(temp)}`} */}
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Manage Jobs
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: {xs:"10px", md:"30px"},
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* {JSON.stringify(totalSlots)} */}
          <Stack
            width={"100%"}
            alignItems="center"
            direction
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              My Job Listings
            </Typography>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              {`Remaining Job Slots: ${
                totalSlots?.slots - totalSlots?.activeJobs || 0
              }`}
            </Typography>
            {totalSlots?.slots - totalSlots?.activeJobs > 0 ? (
              <Button
                onClick={() => navigate("/employee-dashboard/post-new-job")}
              >
                Post Job
              </Button>
            ) : (
              <Button
                onClick={() => navigate("/employee-dashboard/manage-payment")}
              >
                Buy Slots
              </Button>
            )}
          </Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              //   backgroundColor: "#24BD2C",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            {/* <Typography
              sx={{
                color: "#fff",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography> 
            <EmployeeDashboardManageJobsDownArrowIcon />
            */}
          </Box>
        </Box>

        <Box sx={{ ...displayOnDesktop, my: "40px" }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                <TableRow
                  sx={{ "&:first-child td, &:first-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Applications
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Created & Expired
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Skeleton variant="rect" width="800%" height={170} />
              ) : (
                <TableBody>
                  {jobs.map((item, index) => (
                    <ManageJobtableRow job={item.data} id={item.id} />
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ ...displayOnMobile, my: "20px" }}>
          {loading ? (
            <Skeleton variant="rect" width="800%" height={170} />
          ) : (
            <Box>
              {jobs.map((item, index) => (
                <EmployeeDashboardManageJobsMobile
                  job={item.data}
                  id={item.id}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default EmployeeDashboardManageJobs;
