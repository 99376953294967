import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Container, Modal, Stack, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import { editUserProfile, myProfile } from "../../firebase/functions";
import CandidateProfileUpdatingComponent from "../../pages/ProfileUpadatingPages/CandidateProfileUpdatingComponent";
import CandidateProfileUpdatingContactInformation from "../../pages/ProfileUpadatingPages/CandidateProfileUpdatingContactInformation";
import CandidateProfileUpdatingSocialNetwork from "../../pages/ProfileUpadatingPages/CandidateProfileUpdatingSocialNetwork";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./CandidateDashboardMyProfile.module.css";

const CandidateDashboardMyProfile = () => {
  const [user, setUser] = useState({});
  const userType = GetType();
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    // myProfile().then(({ data }) => {
    //   setUser(data);
    //   setLoading(false);
    //   console.log("USER", data);
    //   reset({
    //     ...data,
    //   });
    // }).catch((e)=>{
    //   setLoading(false);
    //   toast.error(e.message);
    // })
    if (userType.uid!= undefined) {
      getData();
    }
  }, [userType]);
  const getData = async () => {
    const usercollectionRef = doc(db, `Users/${userType.uid}`);
    const docSnap = await getDoc(usercollectionRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      // (docSnap.data().about);
      setUser(docSnap.data());
      setLoading(false);
      console.log("USER", docSnap.data());
      reset({
        ...docSnap.data(),
      });
      setLoading(false);
    } else {
      //! doc.data() will be undefined in this case
      console.log("No such document!");
      setLoading(false);
        toast.error("user not found");
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    console.log(data);
    editUserProfile({
      ...data,
    }).then(() => {
      setLoading(false);
      toast.success("Profile updated successfully");
      // navigate("/candidate-dashboard/my-resume");
    }).catch((e)=>{
      setLoading(false);
      toast.error(e.message);
    })
  };

  return (
    <Box
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
      component={"form"}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* {JSON.stringify(getValues())}sdfsdf */}
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: { md: "40px 0px 100px 0px" },
        }}
      >
        <Box sx={{ width: "95%" }}>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              My Profile
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              my: "30px",
            }}
          >
            {user.firstName ? (
              <CandidateProfileUpdatingComponent
                user={user}
                setValue={setValue}
                register={register}
                errors={errors}
              />
            ) : null}
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <CandidateProfileUpdatingContactInformation
              register={register}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
            />
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              border: "1px solid #ECEDF2",
              boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
              borderRadius: "8px",
              padding: "40px 30px",
              margin: "60px 0px 30px 0px",
            }}
          >
            <CandidateProfileUpdatingSocialNetwork
              register={register}
              errors={errors}
            />
          </Box>
          <Box>
            <LoadingButton
              loadingIndicator={<CircularProgress sx={{ color: "#fff" }} />}
              loading={loading}
              type="submit"
              fullWidth
              sx={{
                // width: "150px",
                textTransform: "none",
                width:"150px",
                fontSize: "18px",
                fontFamily: "Sofia Pro",
                backgroundColor: "#25C027",
                color: "#fff",
                padding: "10px 40px",
                borderRadius: "8px",
                mt: "30px",
                "&:hover": {
                  backgroundColor: "#25C027",
                },
              }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Box>
  );
};

export default CandidateDashboardMyProfile;
