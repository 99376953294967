import { Box, Container, Modal, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { auth, db } from "../../firebase/firebaseConfig";
import Footer from "../../components/SharedComponents/Footer/Footer";
import {
  collection,
  getDocs
} from "firebase/firestore";
import NavBar from "../../components/SharedComponents/NavBar/NavBar";
import { getAboutUs } from "../../firebase/functions";
import findLeft from "../../utilities/images/findLeft.png";
import findRight from "../../utilities/images/findRight.png";
//import CandidateCard from "./CandidateCard";
//import OverView from "./OverView";
import styled from "@emotion/styled";
import LoadingSearchComponents from "../../components/SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import MobileMenu from "../../components/SharedComponents/MobileMenu/MobileMenu";
import {
  displayOnDesktop,
  displayOnMobile,
  flexBetweenWithItemsCenter,
  flexCenter
} from "../../utilities/commonStyles/commonStyles";
import { ExpandMoreIcon, MinimizeIcon } from "../../utilities/Icons/Icons";
import ErrorPage from "../JobSearchPage/ErrorPage";

const StyledFilter = styled("select")({
  // width: "150px",
  height: "40px",
  fontSize: "14px",
  color: "white",
  backgroundColor: "#24BD2C",
  margin: "20px",
  padding: "0px 20px 0px 20px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "none",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});

const Faq = () => {
  const [expanded, setExpanded] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [overView, setOverView] = useState({});
  const [jobTitles, setJobTitles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [options, setOptions] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingAbout, setLoadingAbout] = useState(true);
  const [show, setShow] = useState(false);
  const [showData, setShowData] = useState(0);
  const [faqData, setFaqData] = useState([]);
  const [loadingError , setloadingError] = useState(false);
  useEffect(() => {
    setLoadingAbout(true);
    getTagsFromFirebase();
  }, []);
  const getTagsFromFirebase = async() => {
    //!Using SDK Mr. Bean
    const usercollectionRef = collection(db, `superAdmin/faq/infoDoc`)
    await getDocs(usercollectionRef).then((data)=>{
      setFaqData(data.docs.map((doc) => ({ ...doc.data(), index: -1, id: doc.id })));
      setLoadingAbout(false);
    }).catch((e) => {
      console.log(e);
      setloadingError(true);
      setLoadingAbout(false);
    });
    
    
    //  const categories = await admin.firestore().collection("superAdmin").doc(docName).collection("infoDoc").get();
    //     return {value:categories.docs.map((data)=>{return{...data.data(),id:data.id}})}
    // getAboutUs({ docName: "faq" })
    //   .then(({ data }) => {
    //     console.log(data);
    //     // reset({...data});
    //     setFaqData([]);
    //     if (Array.isArray(data.value)) {
    //       let temp = data.value;
    //       for (var i = 0; i < temp.length; i++) {
    //         const query = {
    //           heading: temp[i].heading,
    //           description: temp[i].description,
    //           index: -1,
    //         };
    //         setFaqData((faqData) => faqData.concat(query));
    //       }
    //       //setFaqData(data.value);
    //       setLoadingAbout(false);
    //     }
    //     setLoadingAbout(false);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     setloadingError(true);
    //     setLoadingAbout(false);
    //   });
  };

  console.log(faqData);
  const handleChange = (index) => {
    setExpanded(index);
  };
  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <NavBar color={"green"} />
      </Box>
      <Box sx={displayOnMobile}>
        <MobileMenu color={"green"} />
      </Box>

      <Box sx={{ backgroundColor: "#25C027", pt: "20px" }}>
        <Container>
          <Box sx={{ ...flexBetweenWithItemsCenter }}>
            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={findLeft}
                alt=""
              />
            </Box>
            <Box sx={{ width: "40%", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "30px" },
                  color: "#FFF",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                }}
              >
                Faq
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", md: "15px" },
                  color: "#FFF",
                  fontFamily: "Poppins",
                }}
              >
                Home / Faq
              </Typography>
            </Box>
            <Box sx={{ width: "30%", display: "flex", alignItems: "end" }}>
              <img style={{ width: "100%" }} src={findRight} alt="" />
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ minHeight: "50vh", mt: "50px", mb: { xs: "40px", md: "0" }, }}>
        <Container sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: 500,
              fontFamily: "Poppins",
              color: "#202124",
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            sx={{ fontSize: "15px", fontFamily: "Poppins", color: "#696969" }}
          >
            Home / Faq
          </Typography>
        </Container>
        <Container sx={{ ...flexCenter, my: "50px" }}>
          <Stack spacing={2} sx={{ width: { xs: "90%", md: "70%" } }}>
            {faqData.map((item, index) => (
              <Accordion
                sx={{ boxShadow: "none", border: "1px solid #ECEDF2" }}
                expanded={expanded === index}
                onChange={() => handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === index ? <MinimizeIcon /> : <ExpandMoreIcon />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderBottom: expanded === index && "1px solid #ECEDF2",
                    color: expanded === index && "#25C027",
                    fontWeight: 500,
                  }}
                >
                  <Typography>{item.heading}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#696969",
                      fontSize: "15px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {item.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </Container>
      </Box>

      <Box sx={{ ...displayOnDesktop, mt: "10vw" }}>
        <Footer></Footer>
      </Box>
      <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
      <Modal
        open={loadingError}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
             background:"white"
          }}
          justifyContent={"center"}
          alignItems="center"
        >
         <ErrorPage></ErrorPage>
        </Stack>
      </Modal>
    </Box>
  );
};

export default Faq;
