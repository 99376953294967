import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { flexCenter } from "../../utilities/commonStyles/commonStyles";
import JobNotFoundImg from "../../utilities/images/svg images/job-not-found-img.svg";

const JobNotFound = () => {
  return (
    <Container sx={{ my: "30px" }}>
      <Box sx={{...flexCenter, flexDirection:"column", gap:"20px", textAlign: "center" }}>
        <Box sx={{ width: "60%", height: "50vh" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={JobNotFoundImg}
            alt=""
          />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontSize: "48px",
              fontFamily: "Poppins",
            }}
          >
            Sorry! No Candidate matched your search
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default JobNotFound;
