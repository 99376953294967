import {
  Box,
  Container,
  Stack,
  Typography,
  Modal,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import { initMessageTeam, teamOrgDetail } from "../../firebase/functions";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import {
  flexBetweenWithItemsCenter,
  responsiveFlexDirection,
} from "../../utilities/commonStyles/commonStyles";
import classesss from "../SuperAdminDashboardSideNavbar/SuperAdminDashboardCompanyList.module.css"
import classes from "../SuperAdminDashboardSideNavbar/SuperAdminDashboardSideNavbar.module.css";
import classess from "../EmployeeDashboardComponent/EmployeeDashboardComponent.module.css";
import { GetType } from "../../context/authContext";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  SuperAdminOrganizationSearchIcon,
} from "../../utilities/Icons/Icons";
import { async } from "@firebase/util";
import AssignCompany from "../SuperAdminDashboardSideNavbar/AssignCompany";
import TeamDashboardSendResume from "./TeamDashboardSendResume";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TeamDashboardManageResume = () => {
  const [companyArray, setCompanyArray] = useState([]);
  const [loadingButton, setLoadingAbout] = useState(false);
  const [jobArray, setJobArray] = useState([]);
  const [studentArray, setStudentArray] = useState([]);
  const [jobId, setJobId] = useState('');
  const navigate = useNavigate();
  const user = GetType();
  const [loading, setloading] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [flag, setflag] = useState(0);
  const [flag1, setflag1] = useState(0);
  const [orgArray, setOrgArray] = useState([]);
  const [orgIdArray, setOrgIdArray] = useState([]);
  const [companyId, setCompanyId] = useState("");
  useEffect(() => {
    console.log("inside userEffect");
    setloading(true);
    if (user.uid != undefined) {
      getJobDataById(user.uid);
    }
  }, [flag, user]);
  const getJobDataById = async (id) => {
    teamOrgDetail({ id: id }).then(({ data }) => {
      console.log(data);
      setOrgIdArray(data.orgId);
      setTeamData(data);
      if (Array.isArray(data.orgArray)) {
        setOrgArray(data.orgArray);
      }
      setloading(false);
    });
  };
  useEffect(() => {
    if (orgIdArray.length > 0) {
      companyFunction();
    }
  }, [orgIdArray]);
  const companyFunction = async () => {
    const orgCollectionRef = query(
      collection(db, "Organisations"),
      where("__name__", "in", orgIdArray)
    );
    await getDocs(orgCollectionRef)
      .then((docSnap) => {
        let temp = docSnap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        console.log(temp);
        if (temp.length > 0) {
          setCompanyId(docSnap.docs[0].id);
          getDashboardDetails(docSnap.docs[0].id);
        }
        setCompanyArray(temp);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.message);
      });
  };
  const getDashboardDetails = async (id) => {
    setloading(true);
    const fetchApplications = query(
      collection(db, "Jobs"),
      where("organisationId", "==", id)
    );
    await getDocs(fetchApplications)
      .then((docSnap) => {
        let temp = docSnap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
            if (temp.length > 0) {
              setJobId(docSnap.docs[0].id);
             getCandidateDetails(docSnap.docs[0].id);
            }
       
        setJobArray(temp);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
         setloading(false);
      });
  };
  useEffect(() => {
    getCandidateDetails(jobId);
  },[flag1])
  const getCandidateDetails = async (id) => {
    setStudentArray([]);
    setloading(true);
    const fetchApplications = collection(db, `Jobs/${id}/teamApplications`);
    await getDocs(fetchApplications)
      .then((docSnap) => {
        let temp = docSnap.docs.map((doc) => {
          return { ...doc.data(), docId: doc.id };
        });
        console.log(temp);
        setStudentArray(temp);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
      });
  }
  return (
    <Container>
      <Box
        className={classess.hidescrollbar}
        sx={{ height: "80vh", overflowY: "scroll" }}
      >
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Box sx={{ gap: "10px" }}>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "30px",
                  fontWeight: 500,
                }}
              >
                Manage Resume
              </Typography>
            </Box>
          </Box>

          <Box>
            <TeamDashboardSendResume
              flag1={flag1}
              setflag1={setflag1}
              orgIdArray={companyArray}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "3vw",
          }}
        >
          <TextField
            labelId="demo-multiple-chip-label"
            label="Company Name"
            name="teamSize"
            select
            value={companyId}
            sx={{ width: "35vw" }}
            onChange={(e) => {
              setCompanyId(e.target.value);
              setStudentArray([]);
              getDashboardDetails(e.target.value);
            }}
            color="success"
            MenuProps={MenuProps}
          >
            {companyArray.map((name) => (
              <MenuItem key={name.id} value={name.id}>
                {name.organisationName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            labelId="demo-multiple-chip-label"
            label="Role"
            name="Role"
            select
            sx={{ width: "30vw", ml: "3vw" }}
            value={jobId}
            onChange={(e) => {
              setJobId(e.target.value);
              setStudentArray([]);
              getCandidateDetails(e.target.value);
            }}
            color="success"
            MenuProps={MenuProps}
          >
            {jobArray.map((name) => (
              <MenuItem key={name.id} value={name.id}>
                {name.jobTitle}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box
          sx={{
            mt: "40px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { md: "row", xs: "column-reverse" },
              gap: "30px",
              mt: "40px",
              justifyContent: "space-between",
            }}
            direction
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  color: "#25C027",

                  fontSize: "20px",
                  fontWeight: 400,
                }}
              >
                History{" "}
              </Typography>
              <Typography
                sx={{
                  ml: "0.5vw",
                  fontSize: "20px",
                  fontWeight: 400,
                }}
              >
                {" "}
                Based on selection
              </Typography>
            </Box>
            {/* <Box
              className={classesss.input}
              sx={{
                width: "35vw",
                minWidth: { md: "auto", xs: "100%" },
                display: "flex",
                alignItems: "center",
                gap: "20px",
                border: "1px solid #25C027",
              }}
            >
              <SuperAdminOrganizationSearchIcon />
              <input
                style={{ minWidth: "85%" }}
                type="text"
                placeholder="Search for Student Name"
              />
            </Box> */}

            <Box>
              <LoadingButton
                loading={loadingButton}
                onClick={() => {
                  setLoadingAbout(true);
                  initMessageTeam({ orgainisationId: companyId }).then(
                    ({ data }) => {
                      console.log("MEEEESAGWE", data);
                      setLoadingAbout(false);
                      navigate("/team/messages");
                    }
                  );
                }}
                sx={{
                  color: "#FFF",
                  backgroundColor: "#25C027",
                  fontSize: "10px",
                  fontWeight: 600,
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
              >
                Message
              </LoadingButton>
            </Box>
          </Stack>
          <Box
            className={classes.hidescrollbar}
            sx={{
              height: "70vh",
              display: "flex",
              padding: "0.5vw 4vw",
              gap: "10px",
              mt: "25px",
              alignContent: "flex-start",
              flexWrap: "wrap",
              flexDirection: "row",
            }}
          >
            {studentArray.map((data, ind) => {
              return (
                <Box
                  key={ind}
                  sx={{
                    width: { xs: "100%", md: "450px" },
                    height: { xs: "100%", md: "150px" },
                    paddingTop: "10px",
                    borderRadius: "8px",
                    border: "1px solid #ECEDF2",
                    boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/team/manage-resume/${data.docId}/${jobId}`);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          ml: "2vw",
                          fontWeight: 500,
                        }}
                      >
                        {data?.firstName} {data?.lastName}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          mr: "0.5vw",
                          fontWeight: 300,
                          color:
                            data?.status == "Pending"
                              ? "#BCAA09"
                              : data?.status == "Accepted"
                              ? "#25C027"
                              : "#9A1209",
                        }}
                      >
                        {data?.status}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "100%", md: "350px" },
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          fontSize: "17px",
                          ml: "2vw",
                          fontWeight: 300,
                        }}
                      >
                        {data?.organisationName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "100%", md: "350px" },
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          fontSize: "17px",
                          ml: "2vw",
                          fontWeight: 300,
                        }}
                      >
                        {data.jobTitle}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default TeamDashboardManageResume;
