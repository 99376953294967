import {
  Box,
  Container,
  Stack,
  Typography,
  Modal,
  TextField,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {  db } from "../../firebase/firebaseConfig";
import { teamOrgDetail } from "../../firebase/functions";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import {
  flexBetweenWithItemsCenter, responsiveFlexDirection,
} from "../../utilities/commonStyles/commonStyles";

import classes from "../SuperAdminDashboardSideNavbar/SuperAdminDashboardSideNavbar.module.css"
import classess from "../EmployeeDashboardComponent/EmployeeDashboardComponent.module.css"
import { GetType } from "../../context/authContext";
import { collection, collectionGroup, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { EmployeeDashboardComponentApplicationIcon, EmployeeDashboardComponentMessagesIcon, EmployeeDashboardComponentPostedJobIcon, EmployeeDashboardComponentShortlistIcon } from "../../utilities/Icons/Icons";
import { async } from "@firebase/util";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TeamDashboardDashboard = () => {
  const [companyArray , setCompanyArray] = useState([])
  const user = GetType();
  const [loading, setloading] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [flag, setflag] = useState(0);
  const [orgArray, setOrgArray] = useState([]);
  const [orgIdArray, setOrgIdArray] = useState([]);
  const [companyId, setCompanyId] = useState('')
  const [postedJobs, setPostedJobs] = useState(0);
  const [shortlisted, setShortlisted] = useState(0);
  const [application, setApplications] = useState(0);
  useEffect(() => {
    console.log("inside userEffect");
    setloading(true);
    if (user.uid != undefined) {
      getJobDataById(user.uid);
    }
  }, [flag,user]);
  const getJobDataById = async (id) => {
    teamOrgDetail({ id: id }).then(({ data }) => {
      console.log(data);
      setOrgIdArray(data.orgId);
      setTeamData(data);
      if (Array.isArray(data.orgArray)) {
        setOrgArray(data.orgArray);
      }
      setloading(false);
    });
  };
  useEffect(() => {
    if (orgIdArray.length > 0) {
      companyFunction();
    }
  }, [orgIdArray]);
  const companyFunction = async () => {
      const orgCollectionRef = query(
        collection(db, "Organisations"),
        where("__name__", "in", orgIdArray)
      );
      await getDocs(orgCollectionRef)
        .then((docSnap) => {
          let temp = docSnap.docs.map((doc) => {
           
            return { ...doc.data(), id: doc.id };
          });
          console.log(temp);
          if (temp.length > 0) {
            setCompanyId(docSnap.docs[0].id)
            getDashboardDetails(docSnap.docs[0].id)
                 }
          setCompanyArray(temp);
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.message);
        });
  };
  const getDashboardDetails = async(id) => {
    const employerPostedJob = doc(db, `Organisations/${id}`);
    const docSnap = await getDoc(employerPostedJob);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setPostedJobs(docSnap.data().jobs.length);
      setloading(false);
    } else {
      //! doc.data() will be undefined in this case
      console.log("No such document!");
      setloading(false);
    }
    const fetchApplications = query(
      collectionGroup(db, "Applications"),
      where("organisationId", "==", id)
      );
      await getDocs(fetchApplications).then((querySnapshot) => {
        setApplications(querySnapshot.size);
        setloading(false);
      }).catch((e)=>{
      setloading(false);
      console.log(e);
     })
      const fetchShortlisted = query(
        collectionGroup(db, "teamApplications"),
        where("organisationId", "==", id),
        where("sendBy", "==", user.uid)
      );
    await getDocs(fetchShortlisted)
      .then((querySnapshot) => {
        setShortlisted(querySnapshot.size);
        setloading(false);
      })
      .catch((e) => {
        setloading(false);
        console.log(e);
      });
  }
  return (
    <Container>
      <Box
        className={classess.hidescrollbar}
        sx={{ height: "80vh", overflowY: "scroll" }}
      >
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Box sx={{ gap: "10px" }}>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "30px",
                  fontWeight: 500,
                }}
              >
                Dashboard
              </Typography>
            </Box>
          </Box>

          <Box></Box>
        </Box>

        <Box
          sx={{
            // ...responsiveFlexDirection,
            // display: "flex",
            // alignItems: "center",
            // gap: "40px",
            mt: "40px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "20px",
                fontWeight: 500,
              }}
            >
              Organizations
            </Typography>
          </Box>
          <Box
            className={classes.hidescrollbar}
            sx={{
              height: "auto",
              display: "flex",
              padding: "0.5vw 4vw",
              gap: "10px",
              mt: "25px",
              alignContent: "flex-start",
              flexWrap: "wrap",
              flexDirection: "row",
            }}
          >
            {orgArray.map((data, ind) => {
              return (
                <Box
                  sx={{
                    width: { xs: "100%", md: "450px" },
                    height: { xs: "100%", md: "150px" },
                    padding: "30px",
                    borderRadius: "8px",
                    border: "1px solid #ECEDF2",
                    boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                    // display: "flex",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", md: "350px" },
                      // padding: "30px",
                      // borderRadius: "8px",
                      // border: "1px solid #ECEDF2",
                      // boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                      display: "flex",
                      // justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#262E50",
                        width: "100px",
                        height: "85px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          borderRadius: "8px",
                          height: "85px",
                        }}
                        src={data?.organisationLogo}
                        alt=""
                      />
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          // color: "#1967D2",
                          // fontFamily: "Sofia Pro",
                          fontSize: "20px",
                          ml: "2vw",
                          fontWeight: 400,
                        }}
                      >
                        {data?.organisationName}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <TextField
            labelId="demo-multiple-chip-label"
            label="Company Name"
            name="teamSize"
            select
            sx={{ width: "25vw" }}
            value={companyId}
            onChange={(e) => {
              setCompanyId(e.target.value);
              console.log(e.target.value);
              setPostedJobs(0);
              setApplications(0);
              setShortlisted(0);
              getDashboardDetails(e.target.value);
            }}
            color="success"
            MenuProps={MenuProps}
          >
            {companyArray.map((name) => (
              <MenuItem key={name.id} value={name.id}>
                {name.organisationName}
              </MenuItem>
            ))}
          </TextField>
          <Stack spacing={3} sx={{ mt: "50px" }}>
            <Box
              sx={{
                ...responsiveFlexDirection,
                display: "flex",
                alignItems: "center",
                gap: "40px",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "350px" },
                  padding: "30px",
                  borderRadius: "8px",
                  border: "1px solid #ECEDF2",
                  boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E8F0FB",
                  }}
                >
                  <EmployeeDashboardComponentPostedJobIcon />
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      color: "#1967D2",
                      fontFamily: "Sofia Pro",
                      fontSize: "30px",
                      fontWeight: 500,
                    }}
                  >
                    {postedJobs}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#202124",
                      fontFamily: "Sofia Pro",
                      fontSize: "15px",
                    }}
                  >
                    Posted Jobs
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "350px" },
                  padding: "30px",
                  borderRadius: "8px",
                  border: "1px solid #ECEDF2",
                  boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#FCEBEA",
                  }}
                >
                  <EmployeeDashboardComponentApplicationIcon />
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      color: "#D93025",
                      fontFamily: "Sofia Pro",
                      fontSize: "30px",
                      fontWeight: 500,
                    }}
                  >
                    {application}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#202124",
                      fontFamily: "Sofia Pro",
                      fontSize: "15px",
                    }}
                  >
                    Application
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "350px" },
                  padding: "30px",
                  borderRadius: "8px",
                  border: "1px solid #ECEDF2",
                  boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E1F2E5",
                  }}
                >
                  <EmployeeDashboardComponentShortlistIcon />
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      color: "#34A853",
                      fontFamily: "Sofia Pro",
                      fontSize: "30px",
                      fontWeight: 500,
                    }}
                  >
                    {shortlisted}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#202124",
                      fontFamily: "Sofia Pro",
                      fontSize: "15px",
                    }}
                  >
                    Send Candidate
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default TeamDashboardDashboard;
