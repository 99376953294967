import {
  Box,
  Button,
  Container,
  MenuItem,
  Modal,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetType } from "../../context/authContext";
import { db } from "../../firebase/firebaseConfig";
import { getAllJobsRecruiter, getAllJobsTeam, getTotalSlots, getTotalSlotsTeam, teamOrgDetail } from "../../firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "../EmployeeDashboardComponent/EmployeeDashboardComponent.module.css";
import EmployeeDashboardManageJobsMobile from "../EmployeeDashboardComponent/EmployeeDashboardManageJobsMobile";
import ManageJobtableRow from "../EmployeeDashboardComponent/ManageJobtableRow";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TeamDashboardManageJob = () => {
  const [jobs, setJobs] = useState([]);
    const [companyArray, setCompanyArray] = useState([]);
  const [loading, setloading] = useState(false);
  const userType = GetType();
  const [totalSlots, setTotalSlots] = useState({});
  const navigate = useNavigate();
  const [orgArray, setOrgArray] = useState([]);
  const [orgIdArray, setOrgIdArray] = useState([]);
  useEffect(() => {
    console.log("inside userEffect");
    setloading(true);
    if (userType.uid != undefined) {
      getJobDataById(userType.uid);
    }
  }, [userType]);
  const getJobDataById = async (id) => {
    teamOrgDetail({ id: id }).then(({ data }) => {
      console.log(data);
      setOrgIdArray(data.orgId);
      if (Array.isArray(data.orgArray)) {
        setOrgArray(data.orgArray);
      }
      setloading(false);
    });
  };
  useEffect(() => {
    if (orgIdArray.length > 0) {
      companyFunction();
    }
  }, [orgIdArray]);
  const companyFunction = async () => {
    const orgCollectionRef = query(
      collection(db, "Organisations"),
      where("__name__", "in", orgIdArray)
    );
    await getDocs(orgCollectionRef)
      .then((docSnap) => {
        let temp = docSnap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        console.log(temp);
        if (temp.length > 0) {
          setCompanyId(docSnap.docs[0].id);
        }
        setCompanyArray(temp);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.message);
      });
  };
  const [companyId, setCompanyId] = useState("");
  useEffect(() => {
    setloading(true);
    getAllJobsTeam({ organisationId:companyId})
      .then(({ data }) => {
        setloading(false);
        console.log(data);
        setJobs(data);
      })
      .catch((e) => {
        setloading(false);
        toast.error(e.message);
      });
    getTotalSlotsTeam({ organisationId :companyId})
      .then(({ data }) => {
        setTotalSlots(data);
      })
      .catch((e) => {
        setTotalSlots({ error: e.message });
      });
  }, [companyId]);
  const soln = async () => {
    const fetchApplications = query(
      collection(db, "Jobs"),
      where("organisationId", "==", userType.organisation)
    );
    await getDocs(fetchApplications)
      .then((data) => {
        setJobs(
          data.docs.map((doc) => {
            console.log(doc.data());
            return { data: doc.data(), id: doc.id };
          })
        );
        setloading(false);
      })
      .catch((e) => {
        setloading(false);
        console.log(e);
      });
  };
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      {/* {`${JSON.stringify(temp)}`} */}
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          Manage Jobs
        </Typography>
      </Box>
      <TextField
        labelId="demo-multiple-chip-label"
        label="Company Name"
        name="teamSize"
        select
        value={companyId}
        sx={{ width: "35vw" }}
        onChange={(e) => {
          setCompanyId(e.target.value);
          console.log(e.target.value);
        }}
        color="success"
        MenuProps={MenuProps}
      >
        {companyArray.map((name) => (
          <MenuItem key={name.id} value={name.id}>
            {name.organisationName}
          </MenuItem>
        ))}
      </TextField>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: { xs: "10px", md: "30px" },
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* {JSON.stringify(totalSlots)} */}
          <Stack
            width={"100%"}
            alignItems="center"
            direction
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              My Job Listings
            </Typography>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              {`Remaining Job Slots: ${
                totalSlots?.slots - totalSlots?.activeJobs || 0
              }`}
            </Typography>
            {/* {totalSlots?.slots - totalSlots?.activeJobs > 0 ? (
              <Button
                onClick={() => navigate("/employee-dashboard/post-new-job")}
              >
                Post Job
              </Button>
            ) : (
              <Button
                onClick={() => navigate("/employee-dashboard/manage-payment")}
              >
                Buy Slots
              </Button>
            )} */}
          </Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              //   backgroundColor: "#24BD2C",
              padding: "15px 20px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            {/* <Typography
              sx={{
                color: "#fff",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
              }}
            >
              Last 6 Months
            </Typography> 
            <EmployeeDashboardManageJobsDownArrowIcon />
            */}
          </Box>
        </Box>

        <Box sx={{ ...displayOnDesktop, my: "40px" }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                <TableRow
                  sx={{ "&:first-child td, &:first-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Applications
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Created & Expired
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#1E1E1E",
                      fontFamily: "Sofia Pro",
                      fontSize: "16px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Skeleton variant="rect" width="800%" height={170} />
              ) : (
                <TableBody>
                  {jobs.map((item, index) => (
                    <ManageJobtableRow job={item.data} id={item.id} />
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ ...displayOnMobile, my: "20px" }}>
          {loading ? (
            <Skeleton variant="rect" width="800%" height={170} />
          ) : (
            <Box>
              {jobs.map((item, index) => (
                <EmployeeDashboardManageJobsMobile
                  job={item.data}
                  id={item.id}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default TeamDashboardManageJob;
