import { Box, Button } from "@mui/material";
import React from "react";
import { flexCenter } from "../../../utilities/commonStyles/commonStyles";
import messageDefaultImg from "../../../utilities/images/svg images/messege-default-screen.svg";

const MessageDefaultScreen = ({wi}) => {
  return (
    <Box sx={{ ...flexCenter, width: `${wi}%`, height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: { xs: "10px", md: "30px" },
        }}
      >
        <img src={messageDefaultImg} alt="" />
        <Button
          sx={{
            textTransform: "none",
            backgroundColor: "transparent",
            color: "#202124",
            fontSize: "24px",
            fontWeight: 500,
            fontFamily: "Poppins",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Click on a Message
        </Button>
      </Box>
    </Box>
  );
};

export default MessageDefaultScreen;
