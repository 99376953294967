import { Box, Modal, Stack, TextField, Typography } from '@mui/material';
import React from 'react'
import { useForm } from 'react-hook-form';
import {createProduct} from "../../firebase/functions";
import { toast } from "react-toastify";
import { LoadingButton } from '@mui/lab';

function SuperAdminCreateProduct({
setLoading,
loading,
handleCloseCustom,
setOpenCustom,
openCustom,
flag,
setflag
}) {

   const {
     register,
     handleSubmit,
     formState: { errors },
     reset,
   } = useForm();

   const onSubmitCustom = (data) => {
    setLoading(true);
    console.log({
      ...data,
      name: "displayName",
      description: "This is Description",
      itemType: "service",
      statement_descriptor: null,
      currency: "usd",
      interval: "month",
    });
    createProduct({
      ...data,
      name: data.displayName,
      description: "This is Description",
      itemType: "service",
      statement_descriptor: null,
      currency: "usd",
      interval: "month",
    })
      .then((response) => {
        console.log(response);
        toast.success("Product Created", {
          onClose: () => {
            reset();
            setflag(flag + 1);
            setOpenCustom(false);
          },
          autoClose: 1000,
        });
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.message);
      });
    };

  return (
    <Box>
    <Modal
        open={openCustom}
        onClose={handleCloseCustom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}>
          <form Validate onSubmit={handleSubmit(onSubmitCustom)}>
            <Stack spacing={4} sx={{ mt: "10px"}}>
              <Typography sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "25px",
                fontWeight: 500,
              }}
              >Custom Product</Typography>
              <TextField
                id="outlined-basic"
                label={"Name"}
                variant="outlined"
                color="success"
                {...register("displayName", { required: "Name is required" })}
                sx={{
                  width: "100%",
                  mt: "10px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
                error={Boolean(errors.displayName)}
                helperText={errors.displayName && errors.displayName.message}
              />
              <TextField
                id="outlined-basic"
                label={"Job Slot"}
                variant="outlined"
                color="success"
                type="number"
                inputProps={{ min: 0}}
                {...register("jobSlots", { required: "Job Slot is required" })}
                sx={{
                  width: "100%",
                  mt: "10px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
                error={Boolean(errors.jobSlots)}
                helperText={errors.jobSlots && errors.jobSlots.message}
              />
              <TextField
                id="outlined-basic"
                label={"Amount"}
                type="number"
                inputProps={{ min: 0}}
                variant="outlined"
                color="success"
                {...register("unit_amount", { required: "Amount is required" })}
                sx={{
                  width: "100%",
                  mt: "10px",
                  color: "#696969",
                  fontFamily: "Sofia Pro",
                  fontSize: "15px",
                }}
                error={Boolean(errors.unit_amount)}
                helperText={errors.unit_amount && errors.unit_amount.message}
              />
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: "",
                    backgroundColor: "#25C027",
                    color: "#fff",
                    padding: "14px 50px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#25C027",
                    },
                  }}
                  type="submit"
                  // onClick={onSubmitCustom}
                  // loading={loadingAddNotes}
                >
                  Save
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </Box>
      </Modal>
    </Box>
  )
}

export default SuperAdminCreateProduct