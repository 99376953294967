import { Box, Typography } from "@mui/material";
import FindJobsBgImage from "../../../utilities/images/find-jobs-bg.png";
const FindJob = () => {
  return (
    <Box
      style={{
        backgroundImage: `url(${FindJobsBgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "260px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography sx={{color: "#fff", fontSize: "30px", fontWeight: 500 }}>Find Jobs</Typography>
        <Typography sx={{color: "#fff", fontSize: "15px", fontWeight: 500, mt: "15px" }}>Home / Jobs</Typography>
      </Box>
    </Box>
  );
};

export default FindJob;
