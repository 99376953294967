import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import convertBase64 from "../../../Helpers/fileConvert";
import { hire } from "../../../firebase/functions";
import { toast } from "react-toastify";
import { CircularProgress, TextField } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
};

export default function HireModal({ setOpen, open, jobId, applicantId,offerStatus,setStatus }) {
  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setValue("offerLetter", "");
  };
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
  } = useForm();
  const onSubmit = async (data) => {
    if (!data.offerLetter) {
      setError("offerLetter", "required", "File is required");
      return;
    }

    setLoading(true);
    hire({ ...data, jobId, applicantId,offerStatus }).then(({ data }) => {
      setOpen(!open);
      setLoading(false);
      toast.success("Offer Letter Sent Successfully");
      setStatus("hired");
    });
  };
  const fileTypes = ["DOCX", "DOC", "PDF", "XLSX", "XLS"];
  const handleChange = async (file) => {
    setFile(file);
    setValue("offerLetter", await convertBase64(file[0]));
  };
  return (
    <div>
      {/* {JSON.stringify(open)} */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Typography
            sx={{ mb: "30px" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Please Upload Offer Letter and Submit
          </Typography>
          <FileUploader
            multiple={true}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
          <Typography color={errors.offerLetter ? "error" : "text.primary"}>
            {file ? `File name: ${file[0].name}` : "no files uploaded yet"}
          </Typography>
          <TextField
            multiline
            fullWidth
            placeholder="Message"
            minRows={4}
            {...register("message", {
              minLength: {
                value: 10,
                message: "Message must be at least 10 characters long",
              },
            })}
            error={Boolean(errors.message)}
            helperText={errors.message && errors.message.message}
          ></TextField>
          <LoadingButton
            loading={loading}
            loadingIndicator={<CircularProgress sx={{ color: "white" }} />}
            fullWidth
            type="submit"
            sx={{
              mt: "20px",
            }}
            color="success"
          >
            UPLOAD OFFER AND HIRE
          </LoadingButton>
        </Box>
      </Modal>
    </div>
  );
}
