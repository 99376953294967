import { Box, Container, Stack, Typography, Modal, Button } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { auth, db } from "../../firebase/firebaseConfig";
import { teamDeassignCompany, teamOrgDetail } from "../../firebase/functions";
import { responsiveFlexDirection } from "../../utilities/commonStyles/commonStyles";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import {
  flexBetweenWithItemsCenter,
  itemsCenter,
} from "../../utilities/commonStyles/commonStyles";
import classes from "./SuperAdminDashboardComponent.module.css";
import AssignCompany from "./AssignCompany";
import { LoadingButton } from "@mui/lab";
const SuperAdminDashboardComponent = () => {
  const [index, setIndex] = useState(-1);
  const [loading, setloading] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [flag, setflag] = useState(0);
  const [orgArray, setOrgArray] = useState([]);
  const {id} = useParams()
  useEffect(() => {
    console.log("inside userEffect")
    setloading(true);
    getJobDataById();
  }, [flag]);
  const getJobDataById = async () => {
    teamOrgDetail({ id: id }).then(({ data }) => {
      console.log(data)
      setTeamData(data);
      if (Array.isArray(data.orgArray)) {
        setOrgArray(data.orgArray);
      }
      setloading(false);
    });
  };
  return (
    <Container>
      <Box
        className={classes.hidescrollbar}
        sx={{ height: "80vh", overflowY: "scroll" }}
      >
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Box sx={{ gap: "10px" }}>
            <Box>
              <Typography
                sx={{
                  color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "30px",
                  fontWeight: 500,
                }}
              >
                {teamData?.firstName} {teamData?.lastName}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  // color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                {teamData?.email}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  // color: "#25C027",
                  fontFamily: "Sofia Pro",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                {teamData?.phone}
              </Typography>
            </Box>
          </Box>

          <Box>
            <AssignCompany
              array={teamData?.orgId}
              userId={id}
              flag={flag}
              setflag={setflag}
            />
          </Box>
        </Box>

        <Box
          sx={{
            // ...responsiveFlexDirection,
            // display: "flex",
            // alignItems: "center",
            // gap: "40px",
            mt: "40px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Organizations ({teamData?.orgId?.length})
            </Typography>
          </Box>
          <Box
            className={classes.hidescrollbar}
            sx={{
              height: "70vh",
              display: "flex",
              overflowY: "scroll",
              padding: "0.5vw 4vw",
              gap: "10px",
              mt: "25px",
                  alignContent: "flex-start",
              flexWrap: "wrap",
              flexDirection: "row",
            }}
          >
            {orgArray.map((data, ind) => {
              return (
                <Box
                  sx={{
                    width: { xs: "100%", md: "450px" },
                    height: { xs: "100%", md: "150px" },
                    padding: "30px",
                    borderRadius: "8px",
                    border: "1px solid #ECEDF2",
                    boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                    // display: "flex",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", md: "350px" },
                      // padding: "30px",
                      // borderRadius: "8px",
                      // border: "1px solid #ECEDF2",
                      // boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
                      display: "flex",
                      // justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#262E50",
                        width: "100px",
                        height: "85px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          borderRadius: "8px",
                          height: "85px",
                        }}
                        src={data?.organisationLogo}
                        alt=""
                      />
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          // color: "#1967D2",
                          fontFamily: "Sofia Pro",
                          fontSize: "30px",
                          ml: "2vw",
                          fontWeight: 500,
                        }}
                      >
                        {data?.organisationName}
                      </Typography>
                    </Box>
                  </Box>
                  <LoadingButton
                    loading={loadingAssign && index == ind}
                    onClick={() => {
                      setIndex(ind);
                      setLoadingAssign(true);
                      teamDeassignCompany({
                        companyId: data.id,
                        id: id,
                      })
                        .then((response) => {
                          console.log(response);
                          toast.success("Company Assigned");
                          setflag(flag + 1);
                          setLoadingAssign(false);
                        })
                        .catch((e) => {
                          setLoadingAssign(false);
                          toast.error(e.message);
                        });
                    }}
                    sx={{
                      color: "#25C027",

                      marginLeft: "13vw",
                    }}
                    size="small"
                  >
                    Remove
                  </LoadingButton>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default SuperAdminDashboardComponent;
