import { Box, Button, Container, Stack, TextField, Typography , Skeleton,Modal } from "@mui/material";
import {
  EmployeeDashboardComponentChattingTracedIcon,
  EmployeeDashboardComponentSeeTracedIcon,
  EmployeeDashboardComponentTickIcon,
  EmployeeDashboardDeleteProfileIcon
} from "../../utilities/Icons/Icons";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import classes from "./SuperAdminDashboardContactUsDetails.module.css";
import { addTerms,getTerms ,deleteTerms } from "../../firebase/functions";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { db } from "../../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
const SuperAdminDashboardFaq = () => {
  const [flag , setflag] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingLive , setloadingLive] = useState(false);
  const { register, control, handleSubmit,  formState: { errors }, reset, watch } = useForm({
    defaultValues: {
      test: [{ heading: "", description: "" , docName:"faq" }]
    }
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "test"
    }
  );
  const onSubmit = (data) => {console.log("data", data);
  setLoading(true);
  addTerms(data).then((response) => {
    console.log(response);
    toast.success("Faq Added", {
      onClose: () => {
        setflag(flag + 1);
        reset();
      },
      autoClose: 2000
    })
      setLoading(false);
  }).catch((e)=>{
    setLoading(false);
    toast.error(e.message);
  })
};
  const [userTermsFromFirebase , setUserTermsFromFirebase] = useState([]);
  useEffect(() => {
    getTagsFromFirebase();
  }, [flag])

  const getTagsFromFirebase = async() => {
    setloadingLive(true);
    // getTerms({ docName: "faq" }).then((response) => {
    //   console.log(response);
    //   setUserTermsFromFirebase(response.data);
    //   setloadingLive(false);
    // }).catch((e)=>{
    //   setloadingLive(false);
    //   toast.error(e.message);
    // })


//! Sdk implemetntion
    const employerPostedJob = collection(db,`superAdmin/faq/infoDoc`);
      await getDocs(employerPostedJob)
        .then((docSnap) => {
          setUserTermsFromFirebase(
            docSnap.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            })
          );
         // setUserTermsFromFirebase(response.data);
      //    console.log(docSnap.docChanges.docs)
      setloadingLive(false);
        })
        .catch((e) => {
          console.log(e);
         setloadingLive(false);
      toast.error(e.message);
        });

  }
  const dele = (i) =>{
    console.log(i);
    deleteTerms({ form: "faq",index:i}).then((response) => {
      console.log(response);
      toast.success("Faq deleted");
      setflag(flag + 1);
    }).catch((e) => {
      console.log(e);
    })
  }
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "30px",
            fontWeight: 500,
          }}
        >
          FAQ
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "60px",
          padding: "30px",
          background: "#FFFFFF",
          boxShadow: "0px 2px 20px rgba(71, 119, 54, 0.15)",
          borderRadius: "20px",
        }}
      >
        <Typography
          sx={{
            color: "#202124",
            fontFamily: "Sofia Pro",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
           FAQ
        </Typography>
        <form Validate onSubmit={handleSubmit(onSubmit)}>
     
          <Stack spacing={4} sx={{ mt: "30px" }}>
          {fields.map((item, index) => {
          return (
  <Box key={item.id} >
            <Box     className={classes.input} sx={{ width: "50%" }}>
              <label>Heading</label>
              <TextField  {...register(`test.${index}.heading`, { required: "Heading is required"} ) }   error={Boolean(errors[`test.${index}.heading`])}
             helperText={errors[`test.${index}.heading`]&&errors[`test.${index}.heading`].message}  type="text" placeholder="Limitation" />
            </Box>
            <br/>
            <Box sx={{ width: "100%" }}>
              <TextField
              id="outlined-basic"
              label="Description"
              variant="outlined"
              color="success"
              multiline
              {...register(`test.${index}.description`, { required: "Description is required"})}
              rows={10}
              sx={{
                width: "100%",
                mt: "10px",
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
             error={Boolean(errors.address)}
             helperText={errors.address&&errors.address.message}
            />
            </Box>
            <br/>
           {index!=0?<Button
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
                onClick={() => remove(index)}
              >
                Delete
              </Button>:<></>}
            </Box>
          );
        })}
            {/* <Box className={classes.input} sx={{ width: "50%" }}>
              <label>Heading</label>
              <input type="text" placeholder="Limitation" />
            </Box>

            <Box className={classes.input} sx={{ width: "100%" }}>
              <label>Description</label>
              <textarea type="text" />
            </Box> */}

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
                onClick={() => {
                  append({ heading: "", description: "" });
                }}
              >
                Add More
              </Button>
              
              <LoadingButton
                sx={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: "",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "14px 50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
                type="submit"
                loading={loading}
              >
                Save
              </LoadingButton>
            </Box>
          </Stack>
        
        </form>
      </Box>

      <Box sx={{ mt: "40px" }}>
        <Typography>Live</Typography>

        <Stack
          spacing={3}
          className={classes.hidescrollbar}
          sx={{ mt: "20px", height: "40vh", overflowY: "scroll" }}
        >
          {loadingLive ?    <Skeleton variant="rect" height={100} width={850} /> :<>          {userTermsFromFirebase.map((arr , index ,val) => (
            <Box
              sx={{
                padding: "24px 20px",
                borderRadius: "8px",
                boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #ECEDF2",
                  padding: "20px 24px",
                  borderRadius: "8px",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography>{arr.heading}</Typography>
                  <Typography sx={{ mt: "10px" }}>
                 {arr.description}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#EAF9EA",
                      cursor:"pointer"
                    }}
                    onClick={()=>{dele(arr.id)}}
                  >
                    <EmployeeDashboardDeleteProfileIcon  />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          </>}

        </Stack>
      </Box>
      <Modal
        open={loadingLive}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
           <LoadingSearchComponents/>
         
        </Stack>
      </Modal>
    </Container>
  );
};

export default SuperAdminDashboardFaq;
