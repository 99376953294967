import { Box, Container, Modal, Stack, Typography } from "@mui/material";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getAllInvitedUsers, getJobTitles } from "../../firebase/functions";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./EmployeeDashboardAllApplicants.module.css";
import { ApplicantDetails } from "./ReusableComponents/ApplicantDetails";
import JobNotFound from "../../pages/JobSearchPage/JobNotFound";
import { UndoRounded } from "@mui/icons-material";
import { GetType } from "../../context/authContext";
import { collection, collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
const StyledFilter = styled("select")({
  height: "40px",
  fontSize: "14px",
  color: "black",
  padding: "0px 20px 0px 20px",
  minHeight: "45px",
  borderRadius: "8px",
  border: "1px solid #25C027",
  outline: "none",

  option: {
    color: "black",
    backgroundColor: "white",
    border: "none",
    minHeight: "50px",
    borderRadius: "0px",
    padding: "10px",
  },
});

const EmployeeDashboardInviteCandidates = () => {
  const [allApplicants, setAllApplicants] = useState([]);
  const [noofAcceptReject, setNoofAcceptReject] = useState([]);
  const [status, setStatus] = useState({});
  const [loadingAbout, setLoadingAbout] = useState(false);
  const [titles, setTitles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const { jobTitles, setJobTitles } = useState([]);
const userType = GetType();
  useEffect(() => {
    // getCandidateIds().then(({ data }) => {
    //   //
    //   setCandidates(data);
    //   setOriginalData(data);
    //   setOverView(data[0]);
    // });
    // setLoadingAbout(true);
    // getJobTitles().then(({ data }) => {
    //   setTitles(data);
    //   setStatus({
    //     ...status,
    //     jobTitles: data[0].id,
    //     status: "all",
    //     experience: "Any",
    //   });
    // }).catch((e)=>{
    //   setLoadingAbout(false);
    //   toast.error(e.message);
    // })
     if (userType.organisation != undefined) {
      soln();
    }
  }, [userType]);
 const soln = async () => {
    const fetchApplications = query(
      collection(db, "Jobs"),
      where("organisationId", "==", userType.organisation)
    );
    await getDocs(fetchApplications)
      .then((data) => {
        if (data.size != 0) {
          setTitles(
            data.docs.map((job) => {
              console.log(job.data());
              return { title: job.data().jobTitle, id: job.id };
            })
          );
          setStatus({
            ...status,
            jobTitles: data.docs[0].id,
            status: "All",
            experience: "Any",
          });
        } else {
          setLoadingAbout(false);
        }
      })
      .catch((e) => {
     
        console.log(e);
      });
  };
  useEffect(() => {
    setLoadingAbout(true);
    if (status.jobTitles != undefined) {   
      getJobData();
      // getAllInvitedUsers({ jobId: status.jobTitles }).then(({ data }) => {
      //   console.log(data);
      //   if(Array.isArray(data)){
      //     setAllApplicants(data);
      //     setOriginalData(data);
      //     setLoadingAbout(false);  
      //   }else{
      //   setLoadingAbout(false);
      //   }
      // }).catch((e)=>{
      //   setLoadingAbout(false);
      //   toast.error(e.message);
      // })
    }
  }, [status.jobTitles]);
  const getJobData = async () => {
       const fetchApplications = query(
      collectionGroup(db, "Invites"),
         where("organisationId", "==", userType.organisation),
      where("jobId", "==", status.jobTitles)
      );
      await getDocs(fetchApplications).then((data) => {
        setAllApplicants(
        data.docs.map((doc) => (doc.data()))
      );
        setOriginalData(
        data.docs.map((doc) => (doc.data()))
      );
        setLoadingAbout(false);
      }).catch((e)=>{
       setLoadingAbout(false);
        toast.error(e.message);
        console.log(e.message);
     })
  }
  useEffect(() => {
    const filterData = originalData.filter((item) => {
      return status.status == "All" ? 1 : item.status === status.status;
    });

    setAllApplicants(filterData);
  }, [status]);

  // useEffect(() => {
  //   //get all applicants
  //   getAllApplicants().then((data) => {
  //
  //     setAllApplicants(data.data);
  //     setOriginalData(data.data);
  //     getNoofAcceptReject().then((data) => {
  //
  //       setNoofAcceptReject(data.data);
  //     });
  //   });
  //   //get job titles
  //   getJobTitles().then(({ data }) => {
  //     setTitles(data);
  //   });
  // }, []);

  // const handleJobChange = () => {
  //   const filteredList = originalData?.filter(
  //     (applicant) => applicant.jobId === status.jobId
  //   );
  //   setAllApplicants(filteredList);
  //
  // };
  // const handleStatusChange = (e) => {
  //   setStatus({ ...status, status: e });
  //   if (e.value === "all") {
  //     setAllApplicants(originalData);
  //     return 0;
  //   }
  //
  //   const filteredList = originalData?.filter(
  //     (applicant) => applicant.status === e.value
  //   );
  //
  //   setAllApplicants(filteredList);
  // };

  /// filter all applicants by status///

  return (
    <Container>
      <Box
        className={classes.hidescrollbar}
        sx={{ height: "90vh", overflowY: "scroll" }}
      >
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            Invited Applicants
          </Typography>
        </Box>
{/* {`${JSON.stringify(status)}`} */}
        <Stack spacing={3} sx={{ mt: "50px" }}>
          <Box
            sx={{
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "8px",
            }}
          >
            <Box sx={{ display: "flex", gap: "20px" }}>
              {/* <Box sx={{ width: "20%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Applicant
                </Typography>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "80%",
                  rowGap: "10px",
                  columnGap: "1.1%",
                }}
              >
                {/* <Box
                  className={classes.input}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    color: "#696969",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                    width: "100%",
                  }}
                >
                  <MessangerSearchIcon />
                  <input type="text" placeholder="Search" />
                </Box> */}

                <Box>
                  <StyledFilter
                    onChange={(e) => {
                      setStatus({ ...status, jobTitles: e.target.value });
                      // return handleJobChange();
                    }}
                  >
                    {titles?.map((title) => {
                      return <option value={title.id}>{title.title}</option>;
                    })}
                    {/* <option value="">Job Title</option>
                    <option value="">all</option>
                    <option value="">all</option> */}
                  </StyledFilter>
                </Box>
                <Box>
                  <StyledFilter
                    // onChange={(e) => {
                    //
                    // //   setStatus({ ...status, status: e.target.value });
                    // //   // return handleStatusChange(e);
                    // // }}
                    onChange={(e, value) =>
                      setStatus({ ...status, status: e.target.value })
                    }
                    value={status.status}
                    // options={[
                    //   { value: "All", label: "All Applicants" },
                    //   { value: "invited", label: "Invited" },
                    //   { value: "invitationAccepted", label: "Accepted" },
                    //   { value: "invitationRejected", label: "Rejected" },
                    // ]}
                  >
                    <option value={"All"}>All Applicants</option>
                    <option value={"invited"}>Invited</option>
                    <option value={"invitationAccepted"}>Accepted</option>
                    <option value={"invitationRejected"}>Rejected</option>
                  </StyledFilter>
                  {/* <select
                    style={{
                      color: "#696969",
                      backgroundColor: "#f0faf0",
                      border: "none",
                      fontFamily: "Sofia Pro",
                      fontSize: "14px",
                      width: "100%",
                    }}
                    value={status.status?status.status:""}
                    onChange={(e) => {
                      setStatus({ ...status, status: e.target.value });
                      return handleStatusChange(e);
                    }}
                  >
                    <option value="haha">All Applicants</option>
                    <option value="accepted">Shortlisted</option>
                    <option value="pending">Yet to be reviewed</option>
                    <option value="rejected">Rejected</option>
                    <option value="hired">Hired</option>
                  </select> */}
                </Box>
                {/* <Box
                  sx={{
                    width: "14%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f0faf0",
                    padding: "15px",
                    borderRadius: "8px",
                  }}
                >
                  <select style={{
                      color: "#696969",
                      backgroundColor:"#f0faf0",
                      border:"none",
                      fontFamily: "Sofia Pro",
                      fontSize: "14px",
                      width: "100%"
                    }}
                    onChange={(e)=>setStatus({...status,aZ:e.target.value})}
                    >
                    <option value="">A-Z</option>
                    <option value="">all</option>
                    <option value="">all</option>
                  </select>
                </Box> */}
                {/* <Box
                  sx={{
                    width: "25%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f0faf0",
                    padding: "15px",
                    borderRadius: "8px",
                  }}
                >
                  <select
                    style={{
                      color: "#696969",
                      backgroundColor: "#f0faf0",
                      border: "none",
                      fontFamily: "Sofia Pro",
                      fontSize: "14px",
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setStatus({ ...status, Newest: e.target.value })
                    }
                  >
                    <option value="">Newest</option>
                    <option value="">all</option>
                    <option value="">all</option>
                  </select>
                </Box> */}
              </Box>
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#f0faf0",
                padding: "15px",
                borderRadius: "8px",
                gap: "20px",
                mt: "40px",
              }}
            > */}
            {/* <Box sx={{ width: "25%" }}>
                <Typography
                  sx={{
                    color: "#24BD2C",
                    fontFamily: "Sofia Pro",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Total(s): {allApplicants.length}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "25%",
                }}
              >
                <Typography
                  sx={{
                    color: "#24BD2C",
                    fontFamily: "Sofia Pro",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Approved: {noofAcceptReject.accepted}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#D93025",
                    fontFamily: "Sofia Pro",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Rejected(s): {noofAcceptReject.rejected}
                </Typography>
                <EmployeeDashboardAllApplicantsDownArrowIcon />
              </Box> */}
            {/* </Box> */}

            <Stack
              className={classes.hidescrollbar}
              spacing={2}
              sx={{ overflowY: "scroll" }}
            >
              {allApplicants.length == 0 && !loadingAbout ? <JobNotFound /> : <>
                {allApplicants.map((applicant, index) => {
                return (
                  <ApplicantDetails
                    key={index}
                    info={{
                      data: {
                        applicantId: applicant.userId,
                        jobId: applicant.jobId,
                      },
                    }}
                    offerStatus={applicant.offerStatus}
                    method={"invite"}
                    status={applicant.status}
                    showAction={false}
                  />
                  // <JobApplicantCard
                  //   key={index}
                  //   info={{
                  //     data: {
                  //       applicantId: applicant.appliedBy,
                  //       jobId: applicant.jobId,
                  //     },
                  //   }}
                  //   status={applicant.status}
                  // />
                );
              })}
                </>}

            </Stack>
          </Box>
        </Stack>
      </Box>
      <Modal
        open={loadingAbout}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default EmployeeDashboardInviteCandidates;
