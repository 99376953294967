import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import EmployeeSearchFeaturedJobs from "../../components/EmployeeSearch/EmployeeSearch";
import FeaturedJobs from "../../components/Home/FeaturedJobs/FeaturedJobs";
import InstantMessageFeture from "../../components/Home/InstantMessageFeture/InstantMessageFeture";
import RecentJobs from "../../components/Home/RecentJobs/RecentJobs";
import Recruiting from "../../components/SharedComponents/EmployersFindYou/Recruiting";
import ColorNavBar from "../../components/SharedComponents/NavBar/ColorNavBar";
import { getOrgIdAndNoJobs } from "../../firebase/functions";
import left from "../../utilities/images/svg images/left.svg";
import right from "../../utilities/images/svg images/right.svg";
const EmployeeSearchPage = () => {
  const [org, setOrg] = useState([]);
  useEffect(() => {
    getOrgIdAndNoJobs().then(({ data }) => {
      
      setOrg(data);
    });
  }, []);
  return (
    <Box>
      <ColorNavBar />

      <Box sx={{ backgroundColor: "#25C027", height: "300px" }}>
        <Container sx={{ height: "100%" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                height: "290px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <img src={left} alt="" />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{ color: "#fff", fontSize: "30px", fontWeight: 500 }}
              >
                Companies
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: 500,
                  mt: "15px",
                }}
              >
                Home / Companies
              </Typography>
            </Box>
            <Box
              sx={{
                height: "290px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <img src={right} alt="" />
            </Box>
          </Box>
        </Container>
      </Box>

      <Box>
        <EmployeeSearchFeaturedJobs data={org} />
      </Box>

      <Container sx={{ display: "flex", gap: "40px" }}>
        <FeaturedJobs />
        <RecentJobs />
      </Container>

      <Box>
        <InstantMessageFeture />
        <Recruiting />
      </Box>
    </Box>
  );
};

export default EmployeeSearchPage;
