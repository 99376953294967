import { httpsCallable } from "firebase/functions";
import { functions } from "./firebaseConfig";

const signUp = httpsCallable(functions, "signUp-signUp");
const recruiterSignUp = httpsCallable(functions, "recruiterSignup-signUp");
const getCategories = httpsCallable(functions, "getCategory-getCategory");
const getRecentJobs = httpsCallable(functions, "getRecentJobs-getRecentJobs");
const getFeaturedJobs = httpsCallable(
  functions,
  "getFeaturedJobs-getFeaturedJobs"
);
const applyForJob = httpsCallable(functions, "apllyForJob-applyJob");
const getJobOverview = httpsCallable(
  functions,
  "getJobOverview-getJobOverview"
);
const getRecentApplicants = httpsCallable(
  functions,
  "getRecentApllications-getRecentApplications"
);
const getJobInfoForApplicantCard = httpsCallable(
  functions,
  "getJobNameFromId-getJobNameFromId"
);
const userInfoForApplicantCard = httpsCallable(
  functions,
  "getUserInfoForApplicantCard-getUserInfoForApplicantCard"
);
const getAllApplicants = httpsCallable(
  functions,
  "getAllApplications-getAllApplications"
);
const getNoofAcceptReject = httpsCallable(
  functions,
  "getNoOfAcceptReject-rejectAndAcceptNo"
);
const acceptApplicant = httpsCallable(
  functions,
  "acceptApplication-acceptApplication"
);
const rejectApplication = httpsCallable(
  functions,
  "rejectApplication-rejectApplication"
);
const getCustomToken = httpsCallable(functions, "getCustomToken-signIn");
const getUserType = httpsCallable(functions, "getUserType-getUserType");
const createOrg1 = httpsCallable(
  functions,
  "createOrganisation-createOrganisation"
);
const addSocial = httpsCallable(
  functions,
  "addSocialHandles-createOrganisation"
);
const getOrgData = httpsCallable(functions, "getOrgInfo-getOrgInfo");
const addJob = httpsCallable(functions, "addJob-addJobs");
const updateJob = httpsCallable(functions, "updateJob-updateJobs");
const getAllJobsTeam = httpsCallable(
  functions,
  "getAllJobsTeam-getAllJobsTeam"
);
const getAllJobsRecruiter = httpsCallable(functions, "getAllJobs-getAllJobs");
const getNoOfApplicantsFromId = httpsCallable(
  functions,
  "getNoOfApplicantsForJob-noOfApplicationFromId"
);
const getShortlistedApplicants = httpsCallable(
  functions,
  "getShortlistedData-getShorlistedCandidates"
);
//! user modules
const getAllappliedJobs = httpsCallable(
  functions,
  "getAppliedJobs-getJobsApplied"
);
const teamAddJob = httpsCallable(functions, "teamAddJob-teamAddJob");
const myProfile = httpsCallable(functions, "myProfile-myProfilePage");
const editUserProfile = httpsCallable(functions, "editProfile-editUserProfile");
const getAllJobsAppliedId = httpsCallable(
  functions,
  "getAllJobsApplied-getAllJobsApplied"
);
const getUserShortlisted = httpsCallable(
  functions,
  "userShorlisted-getShortlistedIds"
);
const addEducation = httpsCallable(functions, "addEducation-addEducation");
const getEducation = httpsCallable(functions, "getEducation-getEducation");
const editEducation = httpsCallable(functions, "editEducation-updateEducation");
const addExperience = httpsCallable(functions, "addExperience-addExperience");
const getExperience = httpsCallable(functions, "getExperience-getExperience");
const editExperience = httpsCallable(
  functions,
  "editExperience-updateExperience"
);
const addAward = httpsCallable(functions, "addAward-addEducation");
const getAward = httpsCallable(functions, "getAward-getEducation");
const editAward = httpsCallable(functions, "editAward-updateEducation");
const uploadResume = httpsCallable(functions, "updateResume-resumeUpdate");
const teamResumeUpdate = httpsCallable(
  functions,
  "teamResumeUpdate-teamResumeUpdate"
);
const getOrgInfo = httpsCallable(functions, "getOrgInfo-getOrgInfoCard");

const setOrgContact = httpsCallable(functions, "setOrgContact-setOrgContact");
const getOrgInfoAdmin = httpsCallable(functions, "getOrgInfoAdmin-getOrgInfo");
const updateOrgInfo = httpsCallable(
  functions,
  "updateOrgInfo-editNewOrganisation"
);
const getApplicantInfo = httpsCallable(
  functions,
  "getApplicantProfile-getApplicantProfile"
);
const getAwardsApplicant = httpsCallable(
  functions,
  "getAwardApplicant-getCandiateAwards"
);
const getEducationApplicant = httpsCallable(
  functions,
  "getApplicantEducation-getCandiateAwards"
);
const getCandidateEdu = httpsCallable(
  functions,
  "getCandidateExpereince-getCandiateAwards"
);
const getOrgIdAndNoJobs = httpsCallable(
  functions,
  "getOrgIdsAndNoOfJobs-getOrgansationIds"
);
const getOrgCard = httpsCallable(functions, "getOrgCard-getOrgCard");
const getFeaturedCandidates = httpsCallable(
  functions,
  "getfeaturedCadidates-getFeaturedCandidates"
);
const getCandidateIds = httpsCallable(
  functions,
  "getCandidateIds-getCandidatesId"
);
const updateSocialContact = httpsCallable(
  functions,
  "updateUserProfile-updateUserProfile"
);
const getLogo = httpsCallable(functions, "getLogo-getLogo");
const searchDataDump = httpsCallable(
  functions,
  "getAllNamesAndCategories-getAllNamesAndCategories"
);
const deleteResume = httpsCallable(functions, "deleteResume-deleteResume");
const getJobIds = httpsCallable(functions, "getJobIds-getJobids");
const getJobTitles = httpsCallable(functions, "getAllJobTitle-getAllJobTitle");
const handleStatusChange = httpsCallable(
  functions,
  "statusToggle-toggleStatus"
);
const getApplciantsJobid = httpsCallable(
  functions,
  "getApplicantsFromJobId-getCandidatesId"
);
const getCandidatesWithStatus = httpsCallable(
  functions,
  "getCandidatesWithStatus-getCandidateswithStatus"
);
const hire = httpsCallable(functions, "hire-hire");
const getFormField = httpsCallable(functions, "getFormField-getFormField");
const sendCandidate = httpsCallable(functions, "sendCandidate-sendCandidate");
const addFormField = httpsCallable(functions, "addFormField-addFormField");
const getTerms = httpsCallable(functions, "getTerms-getTerms");
const getContactUs = httpsCallable(functions, "getContactUs-getContactUs");
const addContactUs = httpsCallable(functions, "addContactUs-addContactUs");
const addTestimonial = httpsCallable(
  functions,
  "addTestimonial-addTestimonial"
);
const getTestimonial = httpsCallable(
  functions,
  "getTestimonial-getTestimonial"
);
const getDashboardDetails = httpsCallable(
  functions,
  "getDashboardDetails-getDashboardDetails"
);
const getJobSeeker = httpsCallable(functions, "getJobSeeker-getJobSeeker");
const updateAboutUs = httpsCallable(functions, "updateAboutUs-updateAboutUs");
const getOrganisationInfo = httpsCallable(
  functions,
  "getOrganisationInfo-getOrganisationInfo"
);
const getAdminOrgInfo = httpsCallable(
  functions,
  "getAdminOrgInfo-getAdminOrgInfo"
);
const getAboutUs = httpsCallable(functions, "getAboutUs-getAboutUs");
const getdashBoard = httpsCallable(functions, "getdashBoard-getdashBoard");
const getAdminNotif = httpsCallable(functions, "fetchNotifs-getAdminNotif");
const fetchNotifs = httpsCallable(functions, "fetchNotifs-getNotificationList");
const fetchOrgNotifs = httpsCallable(
  functions,
  "fetchNotifs-getOrgNotificationList"
);
const addTerms = httpsCallable(functions, "addTerms-addTerms");
const deleteTerms = httpsCallable(functions, "deleteTerms-deleteTerms");
const deleteFormField = httpsCallable(
  functions,
  "deleteFormField-deleteFormField"
);
const getOffers = httpsCallable(functions, "getOffers-getAllOffers");
const offerActions = httpsCallable(functions, "offerActions-offerActions");
const resumeDatabase = httpsCallable(
  functions,
  "getResumeDatabase-getResumeDatabase"
);
const inviteUser = httpsCallable(functions, "inviteUser-inviteUser");
const getUserInvites = httpsCallable(
  functions,
  "getAllInvitesUserSide-getAllInviteUsers"
);
const rejectInvite = httpsCallable(functions, "rejectInvite-rejectInvite");
const acceptInvite = httpsCallable(functions, "acceptInvite-acceptInvite");
const acceptInviteCandidateTeam = httpsCallable(
  functions,
  "acceptInviteCandidateTeam-acceptInviteCandidateTeam"
);
const rejectInviteCandidateTeam = httpsCallable(
  functions,
  "rejectInviteCandidateTeam-rejectInviteCandidateTeam"
);
const inviteCheck = httpsCallable(functions, "checkInvite-checkInvite");
const getApplfronJobId = httpsCallable(
  functions,
  "getApplFronJobId-getApplFromJobId"
);
const getAllInvitedUsers = httpsCallable(
  functions,
  "getAllInvitedUsers-getAllInviteUsers"
);
const getALlobsUsers = httpsCallable(functions, "getAllJobsUsers-getAllJobs");
const deleteAward = httpsCallable(functions, "deleteAward-removeEducation");
const deleteEducation = httpsCallable(
  functions,
  "deleteEducation-removeEducation"
);
const deleteExperiencef = httpsCallable(
  functions,
  "deleteExperience-removeExperience"
);
const resetPassword = httpsCallable(functions, "resetPassword-resetPassword");
const saveFCM = httpsCallable(functions, "saveFCM");
const deleteFCM = httpsCallable(functions, "deleteFCM");

const messageCheck = httpsCallable(functions, "sendMessage-messageCheck");
const initMessage = httpsCallable(functions, "initMessage-initMessage");
const initMessageTeam = httpsCallable(functions, "initMessageTeam-initMessageTeam");
const checkBasePlan = httpsCallable(functions, "checkBasePlan-checkBasePlan");
const listBasePlans = httpsCallable(
  functions,
  "listAllBasePlan-listAllBasePlans"
);
const createCheckoutLink = httpsCallable(functions, "getLink-testLink");
const createPortalLink = httpsCallable(
  functions,
  "getCustomerportal-getCustomerPortalLink"
);
const getaddPlans = httpsCallable(functions, "getSeatSlotProd-getSeatSlotProd");
const getTotalSeatSlots = httpsCallable(
  functions,
  "getTotalSeatSlot-getTotalSeatsSlots"
);
const getProductDetails = httpsCallable(
  functions,
  "getproductDetails-getProductDetails"
);
const inviteEmployee = httpsCallable(
  functions,
  "inviteEmployee-inviteEmployee"
);
const inviteEmployeeMfgworx = httpsCallable(
  functions,
  "inviteEmployeeMfgworx-inviteEmployeeMfgworx"
);
const listAllEmployees = httpsCallable(functions, "listAllUsers-listUsers");
const listAllEmployeesAdmin = httpsCallable(
  functions,
  "listUsersAdmin-listUsersAdmin"
);
const teamOrgDetail = httpsCallable(functions, "teamOrgDetail-teamOrgDetail");
const toggleEmployeeStatus = httpsCallable(
  functions,
  "toggleUsers-toggleStatusUser"
);
const fetchPostedJobs = httpsCallable(
  functions,
  "dashboardCounts-fetchPostedJobs"
);
const fetchApplications = httpsCallable(
  functions,
  "dashboardCounts-fetchApplications"
);
const fetchMessages = httpsCallable(
  functions,
  "dashboardCounts-fetchMessagesCount"
);
const fetchShortlisted = httpsCallable(
  functions,
  "dashboardCounts-fetchShortlistedApplications"
);

const fetchPostedJobsCandidate = httpsCallable(
  functions,
  "candidateCounts-fetchPostedJobs"
);
const fetchApplicationsCandidate = httpsCallable(
  functions,
  "candidateCounts-fetchApplications"
);
const fetchMessagesCandidate = httpsCallable(
  functions,
  "candidateCounts-fetchMessagesCount"
);
const fetchShortlistedCandidate = httpsCallable(
  functions,
  "candidateCounts-fetchShortlistedApplications"
);
const checkActive = httpsCallable(functions, "checkActive-checkActiveStatus");
const handleJobToggle = httpsCallable(
  functions,
  "handleJobToggle-handleJobToggle"
);
const getTotalSlots = httpsCallable(
  functions,
  "totalSeats-getTotalJobsSlotsremaining"
);
const getTotalSlotsTeam = httpsCallable(
  functions,
  "totalSeatsTeam-getTotalJobsSlotsremainingTeam"
);
const renewJob = httpsCallable(functions, "renewJob-renewJob");
const applyCheck = httpsCallable(functions, "checkApplied-applyCheck");
const fetchOrgPhone = httpsCallable(functions, "fetchOrgPhone");
const addBookmarks = httpsCallable(functions, "addBookmark-add_bookmarks");
const getBookmarks = httpsCallable(functions, "getBookmarks-getBookmarks");
const checkBookMark = httpsCallable(functions, "checkBookmarks-checkBookmarks");
const suggestedJobs = httpsCallable(functions, "suggestedJobs-suggestedJobs");
const featuredJob = httpsCallable(functions, "getFeaturedJob-getFeaturedJobs");
const getFeaturedJobAuth = httpsCallable(
  functions,
  "getFeaturedJobAuth-getFeaturedJobsAuth"
);
const logsAdd = httpsCallable(functions, "logs-add");
const logsView = httpsCallable(functions, "logs-view");
const logsViewByIndex = httpsCallable(functions, "logs-viewByIndex");
const mail_support = httpsCallable(functions, "mail-support");
const logsUpdate = httpsCallable(functions, "logs-update");
const logsDelete = httpsCallable(functions, "logs-delete");
const suggestedCandidates = httpsCallable(
  functions,
  "suggestedCandidates-suggestedCandidates"
);
const sendChat = httpsCallable(functions, "sendChat");
const fetchTemplate = httpsCallable(functions, "fetchTemplate");
//!Notification count
const AllAplicantCount = httpsCallable(
  functions,
  "AllAplicantCount-AllAplicantCount"
);
const AllUnreadMessageCount = httpsCallable(
  functions,
  "AllUnreadMessageCount-AllUnreadMessageCount"
);
const SetMessageAsRead = httpsCallable(
  functions,
  "SetMessageAsRead-SetMessageAsRead"
);
const SetNotificationAsReadById = httpsCallable(
  functions,
  "SetNotificationAsReadById-SetNotificationAsReadById"
);
const SetAllNotificationAsRead = httpsCallable(
  functions,
  "SetAllNotificationAsRead-SetAllNotificationAsRead"
);
const AllUnreadMessageCountCandidates = httpsCallable(
  functions,
  "AllUnreadMessageCountCandidates-AllUnreadMessageCountCandidates"
);
const AllOfferCount = httpsCallable(functions, "AllOfferCount-AllOfferCount");
const SetAllNotiReadCand = httpsCallable(
  functions,
  "SetAllNotiReadCand-SetAllNotiReadCand"
);
const SetNotifCandAsReadById = httpsCallable(
  functions,
  "SetNotifCandAsReadById-SetNotifCandAsReadById"
);
const SetMessageAsReadCand = httpsCallable(
  functions,
  "SetMessageAsReadCand-SetMessageAsReadCand"
);
const GetAllInvite = httpsCallable(functions, "GetAllInvite-GetAllInvite");
const listAllCustomPlan = httpsCallable(
  functions,
  "listAllCustomPlan-listAllCustomPlan"
);
const GetAllPlanAdmin = httpsCallable(
  functions,
  "GetAllPlanAdmin-GetAllPlanAdmin"
);
const updatePrice = httpsCallable(functions, "updatePrice-updatePrice");
const createProduct = httpsCallable(functions, "createProduct-createProduct");
const assingOrg = httpsCallable(functions, "assingOrg-assingOrg");
const teamAssignCompany = httpsCallable(
  functions,
  "teamAssignCompany-teamAssignCompany"
);
const teamDeassignCompany = httpsCallable(
  functions,
  "teamDeassignCompany-teamDeassignCompany"
);
const teamStatusCompany = httpsCallable(
  functions,
  "teamStatusCompany-teamStatusCompany"
);

export {
  sendChat,
  fetchTemplate,
  recruiterSignUp,
  signUp,
  getCategories,
  getRecentJobs,
  getFeaturedJobs,
  applyForJob,
  getJobOverview,
  getRecentApplicants,
  getJobInfoForApplicantCard,
  userInfoForApplicantCard,
  getAllApplicants,
  getNoofAcceptReject,
  acceptApplicant,
  rejectApplication,
  getCustomToken,
  getUserType,
  createOrg1,
  addSocial,
  getOrgData,
  addJob,
  getAllJobsRecruiter,
  getNoOfApplicantsFromId,
  getShortlistedApplicants,
  getAllappliedJobs,
  myProfile,
  editUserProfile,
  getAllJobsAppliedId,
  getUserShortlisted,
  addEducation,
  getEducation,
  editEducation,
  addExperience,
  getExperience,
  editExperience,
  addAward,
  getAward,
  editAward,
  uploadResume,
  getOrgInfo,
  setOrgContact,
  getOrgInfoAdmin,
  updateOrgInfo,
  getApplicantInfo,
  getAwardsApplicant,
  getEducationApplicant,
  getCandidateEdu,
  getOrgIdAndNoJobs,
  getOrgCard,
  getFeaturedCandidates,
  getCandidateIds,
  updateSocialContact,
  getLogo,
  searchDataDump,
  deleteResume,
  getJobIds,
  getJobTitles,
  handleStatusChange,
  getApplciantsJobid,
  getCandidatesWithStatus,
  hire,
  getFeaturedJobAuth,
  getOffers,
  offerActions,
  resumeDatabase,
  inviteUser,
  getUserInvites,
  rejectInvite,
  acceptInvite,
  inviteCheck,
  getApplfronJobId,
  getAllInvitedUsers,
  getALlobsUsers,
  deleteAward,
  deleteEducation,
  deleteExperiencef,
  resetPassword,
  addFormField,
  deleteFormField,
  getFormField,
  addTerms,
  getTerms,
  getContactUs,
  addContactUs,
  getAboutUs,
  updateAboutUs,
  addTestimonial,
  getTestimonial,
  deleteTerms,
  getdashBoard,
  getDashboardDetails,
  saveFCM,
  messageCheck,
  initMessage,
  checkBasePlan,
  listBasePlans,
  createCheckoutLink,
  createPortalLink,
  getaddPlans,
  getTotalSeatSlots,
  getProductDetails,
  inviteEmployee,
  listAllEmployees,
  toggleEmployeeStatus,
  deleteFCM,
  getJobSeeker,
  fetchPostedJobs,
  fetchApplications,
  fetchMessages,
  fetchShortlisted,
  fetchPostedJobsCandidate,
  fetchApplicationsCandidate,
  fetchMessagesCandidate,
  fetchShortlistedCandidate,
  checkActive,
  getOrganisationInfo,
  getAdminOrgInfo,
  handleJobToggle,
  getTotalSlots,
  getTotalSlotsTeam,
  renewJob,
  getAdminNotif,
  fetchNotifs,
  fetchOrgNotifs,
  applyCheck,
  fetchOrgPhone,
  addBookmarks,
  getBookmarks,
  checkBookMark,
  suggestedJobs,
  featuredJob,
  suggestedCandidates,
  logsAdd,
  logsView,
  logsViewByIndex,
  logsUpdate,
  logsDelete,
  mail_support,
  AllAplicantCount,
  AllUnreadMessageCount,
  SetMessageAsRead,
  SetNotificationAsReadById,
  SetAllNotificationAsRead,
  AllUnreadMessageCountCandidates,
  AllOfferCount,
  SetAllNotiReadCand,
  SetNotifCandAsReadById,
  SetMessageAsReadCand,
  GetAllInvite,
  GetAllPlanAdmin,
  updatePrice,
  updateJob,
  createProduct,
  assingOrg,
  listAllCustomPlan,
  inviteEmployeeMfgworx,
  listAllEmployeesAdmin,
  teamOrgDetail,
  teamAssignCompany,
  teamDeassignCompany,
  teamStatusCompany,
  teamResumeUpdate,
  sendCandidate,
  teamAddJob,
  getAllJobsTeam,
  initMessageTeam,
  acceptInviteCandidateTeam,
  rejectInviteCandidateTeam,
};

/*

functions[us-central1-signUp-signUp]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/signUp-signUp).
+  functions[us-central1-signUp-checkAuth]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/signUp-checkAuth).
+  functions[us-central1-profileCompletion-profileCompletion]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/profileCompletion-profileCompletion).
+  functions[us-central1-recruiterSignup-signUp]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/recruiterSignup-signUp).
+  functions[us-central1-recruiterSignup-checkAuth]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/recruiterSignup-checkAuth).        
+  functions[us-central1-createOrganisation-createOrganisation]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/createOrganisation-createOrganisation).
+  functions[us-central1-addJob-addJobs]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/addJob-addJobs).
+  functions[us-central1-getOrganisationInfo-getOrganisationInfo]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getOrganisationInfo-getOrganisationInfo).
+  functions[us-central1-apllyForJob-applyJob]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/apllyForJob-applyJob).
+  functions[us-central1-editOrganisationInfo-editOrganisationInfo]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/editOrganisationInfo-editOrganisationInfo).
+  functions[us-central1-getAllJobs-getAllJobs]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getAllJobs-getAllJobs).
+  functions[us-central1-getAppliedJobs-getJobsApplied]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getAppliedJobs-getJobsApplied).
+  functions[us-central1-acceptApplication-acceptApplication]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/acceptApplication-acceptApplication).
+  functions[us-central1-rejectApplication-rejectApplication]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/rejectApplication-rejectApplication).
+  functions[us-central1-getApplicationtsByJobId-getApplicantsByJobId]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getApplicationtsByJobId-getApplicantsByJobId).
+  functions[us-central1-inviteEmployee-inviteEmployee]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/inviteEmployee-inviteEmployee).
+  functions[us-central1-addExperience-addExperience]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/addExperience-addExperience).    
+  functions[us-central1-getExperience-getExperience]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getExperience-getExperience).    
+  functions[us-central1-editExperience-updateExperience]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/editExperience-updateExperience).
+  functions[us-central1-removeExperience-removeExperience]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/removeExperience-removeExperience).
+  functions[us-central1-addEducation-addEducation]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/addEducation-addEducation).        
+  functions[us-central1-getEducation-getEducation]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getEducation-getEducation).
+  functions[us-central1-editEducation-updateEducation]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/editEducation-updateEducation).
+  functions[us-central1-removeEducation-removeEducation]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/removeEducation-removeEducation).
+  functions[us-central1-getNumberOfJobsPosted-getNumberOfJobsPosted]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getNumberOfJobsPosted-getNumberOfJobsPosted).
+  functions[us-central1-noOfApplications-noOfApplications]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/noOfApplications-noOfApplications).
+  functions[us-central1-getRecentApllications-getRecentApplications]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getRecentApllications-getRecentApplications).
+  functions[us-central1-getApplicationByJobId-getApplicationBJobId]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getApplicationByJobId-getApplicationBJobId).
+  functions[us-central1-getJobListByFilter-getJobsByFilter]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getJobListByFilter-getJobsByFilter).
+  functions[us-central1-getAlljobsUser-getAllJobs]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getAlljobsUser-getAllJobs).        
+  functions[us-central1-addSkills-addSkills]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/addSkills-addSkills).
+  functions[us-central1-removeSkill-removeSkill]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/removeSkill-removeSkill).
+  functions[us-central1-getJobRecomendations-getJobRecomendation]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getJobRecomendations-getJobRecomendation).
+  functions[us-central1-getDataForSearch-getDataForSearch]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getDataForSearch-getDataForSearch).
+  functions[us-central1-getApplicationDetails-getAllJobs]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getApplicationDetails-getAllJobs).
+  functions[us-central1-getApplicationFromJobIdAndAppId-getApplicationFromJobIdAndAppId]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getApplicationFromJobIdAndAppId-getApplicationFromJobIdAndAppId).
+  functions[us-central1-getOrgInfo-getOrgInfo]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getOrgInfo-getOrgInfo).
+  functions[us-central1-addTags-addTags]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/addTags-addTags).
+  functions[us-central1-addCategory-addCategory]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/addCategory-addCategory).
+  functions[us-central1-getCheckoutLink-createCheckoutLink]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getCheckoutLink-createCheckoutLink).
+  functions[us-central1-recievePayment-stripeWebhook]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/recievePayment-stripeWebhook).  
+  functions[us-central1-getLink-testLink]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getLink-testLink).
+  functions[us-central1-webHook-stripeWebhook]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/webHook-stripeWebhook).
+  functions[us-central1-getCustomerportal-getCustomerPortalLink]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getCustomerportal-getCustomerPortalLink).
+  functions[us-central1-createProduct-createProduct]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/createProduct-createProduct).    
+  functions[us-central1-getAllproducts-getAllproducts]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getAllproducts-getAllproducts).
+  functions[us-central1-updateProduct-updateProduct]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/updateProduct-updateProduct).    
+  functions[us-central1-deleteProduct-deleteProduct]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/deleteProduct-deleteProduct).    
+  functions[us-central1-getNoOfSeats-getNoOfSeats]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/getNoOfSeats-getNoOfSeats).        
+  functions[us-central1-deleteImmediate-canacelImmediate]: http function initialized (http://localhost:5001/dev-jobboard-gcp/us-central1/deleteImmediate-canacelImmediate).
i  functions[us-central1-onSubscriptionPayment-onSubscriptionPayment]: function ignored because the firestore emulator does not exist or is not running.
+  functions[us-central1-assignSeat-assignSeat]: http 

*/
