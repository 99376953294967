import {
  Box,
  Container,
  Modal,
  Pagination,
  Stack,
  Typography
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { collection, collectionGroup, getDocs, orderBy, Query, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import { getAdminNotif } from "../../firebase/functions";
import { sdkfireBaseTime } from "../../Helpers/SdkTimeConvert";
import { fireBaseTime } from "../../Helpers/TimeConvert";
import {
  displayOnDesktop,
  displayOnMobile,
  itemsCenter
} from "../../utilities/commonStyles/commonStyles";
import {
  EmployeeDashboardComponentCrossedIcon,
  EmployeeDashboardComponentTickIcon
} from "../../utilities/Icons/Icons";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import classes from "./SuperAdminDashboardCompanyList.module.css";
const NotificationDetails = () => {
  const [companyData, setcompanyData] = useState([]);
  const [loading, setloading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const fake = [1, 2, 3, 4, 5];
  useEffect(() => {
    setloading(true);
    // getAdminNotif({})
    //   .then((response) => {
    //     console.log(Array.isArray(response));
    //     if (Array.isArray(response.data)) {
    //       setcompanyData(response.data);
    //     }
    //     setloading(false);
    //     console.log(response);
    //   })
    //   .catch((e)=>{
    //     setloading(false);
    //     toast.error(e.message);
    //   })
    getNotificationList();
  }, []);
  const getNotificationList = async () => {
    const employerPostedJob = query(collectionGroup(db,`Notifications`),orderBy("timeStamp","desc"));
      await getDocs(employerPostedJob)
        .then((docSnap) => {
          setcompanyData(
            docSnap.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            })
          );
         // setUserTermsFromFirebase(response.data);
      //    console.log(docSnap.docChanges.docs)
     setloading(false);

        })
        .catch((e) => {
          console.log(e);
          setloading(false);
        toast.error(e.message);
        });
  }
  return (
    <Box
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Container>
        <Box sx={{ mt: "0px" }}>
          <Box>
            <Typography
              sx={{
                color: "#25C027",
                fontFamily: "Sofia Pro",
                fontSize: "30px",
                fontWeight: 500,
              }}
            >
              Notification
            </Typography>
          </Box>

          {/* <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                mt: "30px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Occupation
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Date Added
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Flagged
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#25C027",
                  padding: "15px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fill: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Sofia Pro",
                    fontSize: "14px",
                  }}
                >
                  Featured
                </Typography>
                <EmployeeDashboardManageJobsDownArrowIcon />
              </Box>
            </Box>

            <Box
              className={classes.input}
              sx={{
                width: "100%",
                borderRight: "1px solid #ECEDF2",
                padding: "20px",
                display: "flex",
                alignItems: "center",
                gap: "15px",
                mt: "30px",
              }}
            >
              <MessangerSearchIcon />
              <input type="text" placeholder="Search for employer" />
            </Box>
          </Box> */}
          <Box
            sx={{
              ...displayOnDesktop,
              backgroundColor: "#fff",
              padding: "30px",
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "20px",
              mt: "0px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#202124",
                  fontFamily: "Sofia Pro",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                Notification List
              </Typography>
            </Box>

            <Box
              className={classes.hidescrollbar}
              sx={{ height: "60vh", overflowY: "scroll", my: "40px" }}
            >
              {/* {`${JSON.stringify(companyData)}`} */}
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead sx={{ backgroundColor: "#F0FAF0" }}>
                    <TableRow
                      sx={{
                        "&:first-child td, &:first-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                          miWidth: "150px",
                        }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        Title
                      </TableCell>
                      <TableCell
                        sx={{
                          miWidth: "150px",
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Message
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Time
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px",
                          color: "#1E1E1E",
                          fontFamily: "Sofia Pro",
                          fontSize: "16px",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        Read
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ width: "100%" }}>
                    {companyData ? (
                      <>
                        {companyData
                          .map((arr, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                padding: "50px",
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ minWidth: "10px" }}
                              >
                                <Typography>{index + 1}</Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ maxWidth: "100px" }}
                              >
                                {arr.title}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ miWidth: "150px", textAlign: "center" }}
                              >
                                {/* <img
                            style={{
                              width: "37px",
                              height: "37px",
                              background: "#D9D9D9",
                              borderRadius: "50%",
                            }}
                            src=""
                            alt="img"
                          /> */}
                                {arr.message}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ minWidth: "110px", textAlign: "center" }}
                              >
                                {arr.timeStamp
                                  ? sdkfireBaseTime(arr.timeStamp).toDateString()
                                  : ""}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ minWidth: "100px", textAlign: "center" }}
                              >
                                <Box
                                  sx={{
                                    // width: "30px",
                                    height: "30px",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "#EAF9EA",
                                  }}

                                  //   onClick={() => navigate(`/super-admin-dashboard/company-list/${arr.id}`)}
                                >
                                  {arr.read == true ? (
                                    <EmployeeDashboardComponentTickIcon />
                                  ) : (
                                    <EmployeeDashboardComponentCrossedIcon />
                                  )}
                                </Box>
                              </TableCell>

                              {/* <TableCell align="left">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#EAF9EA",
                              }}
                            >
                              <EmployeeDashboardComponentChattingTracedIcon />
                            </Box>
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#EAF9EA",
                              }}
                            >
                              <EmployeeDashboardComponentSeeTracedIcon />
                            </Box>
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#EAF9EA",
                              }}
                            >
                              <EmployeeDashboardComponentTickIcon />
                            </Box>
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#EAF9EA",
                              }}
                            >
                              <EmployeeDashboardComponentCrossedIcon />
                            </Box>
                          </Box>
                        </TableCell> */}
                            </TableRow>
                          ))
                          .slice(
                            (pageNumber - 1) * 10,
                            (pageNumber - 1) * 10 + 10
                          )}
                      </>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  onChange={(e, pageNumber) => {
                    setPageNumber(pageNumber);
                  }}
                  sx={{
                    backgroundColor: "#fff",
                    color: "#25C027",
                  }}
                  count={
                    Math.ceil(companyData.length / 10)
                      ? Math.ceil(companyData.length / 10)
                      : 1
                  }
                />
              </Container>
            </Box>
          </Box>
        </Box>
      </Container>

      <Container sx={displayOnMobile}>
        <Box sx={{ mt: "20px" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
              borderRadius: "20px",
            }}
          >
            <Stack spacing={3} sx={{ mt: "20px" }}>
              {companyData.map((arr, index) => (
                <Stack
                  key={index}
                  spacing={1}
                  sx={{
                    width: "100%",
                    boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
                    padding: "15px",
                    borderRadius: "8px",
                  }}
                >
                  <Box sx={{ ...itemsCenter, gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "30%",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#25C027",
                        fontWeight: 600,
                      }}
                    >
                      Title
                    </Typography>
                    <Typography
                      sx={{
                        width: "65%",
                        fontSize: "12px",
                        color: "#425466",
                        fontWeight: 600,
                      }}
                    >
                      {arr.title}
                    </Typography>
                  </Box>
                  <Box sx={{ ...itemsCenter, gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "30%",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#25C027",
                        fontWeight: 600,
                      }}
                    >
                      Message
                    </Typography>
                    <Typography
                      sx={{
                        width: "70%",
                        fontSize: "12px",
                        color: "#425466",
                        fontWeight: 600,
                      }}
                    >
                      {arr.message}
                    </Typography>
                  </Box>
                  <Box sx={{ ...itemsCenter, gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "30%",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#25C027",
                        fontWeight: 600,
                      }}
                    >
                      Time
                    </Typography>
                    <Typography
                      sx={{
                        width: "70%",
                        fontSize: "12px",
                        color: "#425466",
                        fontWeight: 600,
                      }}
                    >
                      {arr.timeStamp
                        ? sdkfireBaseTime(arr.timeStamp).toDateString()
                        : ""}
                    </Typography>
                  </Box>
                  <Box sx={{ ...itemsCenter, gap: "10px" }}>
                    <Typography
                      sx={{
                        width: "30%",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "#25C027",
                        fontWeight: 600,
                      }}
                    >
                      Read
                    </Typography>
                    <Typography
                      sx={{
                        width: "70%",
                        fontSize: "12px",
                        color: "#425466",
                        fontWeight: 600,
                      }}
                    >
                      {arr.read == true ? (
                        <EmployeeDashboardComponentTickIcon />
                      ) : (
                        <EmployeeDashboardComponentCrossedIcon />
                      )}
                    </Typography>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Box>
      </Container>
      <Modal
        open={loading}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Box>
  );
};

export default NotificationDetails;
