import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { getEducationApplicant } from "../../firebase/functions";

const CandidateProfileEducation = ({ applicantId }) => {
  const [education, setEducation] = React.useState([]);
  useEffect(() => {
    getEducationApplicant({ applicantId }).then(({ data }) => {
      setEducation(data);
    });
  }, [applicantId]);
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            color: "#202124",
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Sofia Pro",
          }}
        >
          Education
        </Typography>
      </Box>
      <Timeline className={"education-timeline"}>
        {education.map((education, index) => {
          return (
            <TimelineItem key={education.institution}>
              <TimelineSeparator className={"education-separator_padding"}>
                <TimelineDot className={"education-timeline_dot"}>
                  <Typography
                    sx={{
                      fontFamily: "Sofia Pro",
                      fontWight: 700,
                      fontSize: "11px",
                      color: "#D93025",
                    }}
                  >
                    {education?.institution
                      ? education.institution.substring(0, 1)
                      : ""}
                  </Typography>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#202124",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {education?.degree}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#D93025",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {education?.institution}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        display: "inline-block",
                        background: "#f9e0df",
                        color: "#D93025",
                        padding: "5px 20px",
                        borderRadius: "20px",
                        fontFamily: "Sofia Pro",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {education?.startYear} - {education?.endYear}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ py: "50px" }}>
                  <Typography>{education?.description}</Typography>
           
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default CandidateProfileEducation;
