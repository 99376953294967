import {
  Box,
  Container,
  Stack,
  Typography,
  Modal,
  TextField,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebaseConfig";
import { teamOrgDetail } from "../../firebase/functions";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import {
  flexBetweenWithItemsCenter,
  responsiveFlexDirection,
} from "../../utilities/commonStyles/commonStyles";
import classesss from "../SuperAdminDashboardSideNavbar/SuperAdminDashboardCompanyList.module.css";
import classes from "../SuperAdminDashboardSideNavbar/SuperAdminDashboardSideNavbar.module.css";
import classess from "../EmployeeDashboardComponent/EmployeeDashboardComponent.module.css";
import { GetType } from "../../context/authContext";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  EmployeeDashboardComponentApplicationIcon,
  EmployeeDashboardComponentMessagesIcon,
  EmployeeDashboardComponentPostedJobIcon,
  EmployeeDashboardComponentShortlistIcon,
  SuperAdminOrganizationSearchIcon,
} from "../../utilities/Icons/Icons";
import { async } from "@firebase/util";
import AssignCompany from "../SuperAdminDashboardSideNavbar/AssignCompany";
import TeamDashboardSendResume from "./TeamDashboardSendResume";
import { useNavigate, useParams } from "react-router-dom";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TeamDashboardCandidateView = () => {
  const user = GetType();
  const [loading, setloading] = useState(false);
  const [userData, setUserData] = useState({});
  const {id,jobId} = useParams()
  useEffect(() => {
    console.log("inside userEffect");
    setloading(true);
    if (id!= undefined) {
      getCandidateDetails();
    }
  }, [id]);
  const getCandidateDetails = async () => {
    setloading(true);
    const employerPostedJob = doc(db, `Jobs/${jobId}/teamApplications/${id}`);
    const docSnap = await getDoc(employerPostedJob);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setUserData(docSnap.data());
      setloading(false);
    } else {
      //! doc.data() will be undefined in this case
      console.log("No such document!");
      setloading(false);
    }
  };
 
  return (
    <Container>
      <Box
        className={classess.hidescrollbar}
        sx={{ height: "80vh", overflowY: "scroll" }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "90%" },
            height: { xs: "100%", md: "150px" },
            paddingTop: "10px",
            borderRadius: "8px",
            border: "1px solid #ECEDF2",
            boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  color: "#25C027",

                  fontSize: "20px",
                  ml: "2vw",
                  fontWeight: 500,
                }}
              >
                {userData?.firstName} {userData?.lastName}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  mr: "0.5vw",
                  fontWeight: 300,
                  color:
                    userData?.status == "Pending"
                      ? "#BCAA09"
                      : userData?.status == "Approved"
                      ? "#25C027"
                      : "#9A1209",
                }}
              >
                {userData?.status}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "350px" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontSize: "17px",
                  ml: "2vw",
                  fontWeight: 300,
                }}
              >
                {userData?.organisationName}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "350px" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontSize: "17px",
                  ml: "2vw",
                  fontWeight: 300,
                }}
              >
                {userData.jobTitle}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "350px" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              href={userData?.resumeUrl}
              target="_blank"
              component={"a"}
              sx={{ textAlign: "right", cursor: "pointer" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  ml: "2vw",
                  fontWeight: 300,
                  color: "#25C027",
                }}
              >
                View Resume
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "90%" },
            height: "auto",
            mt: "20px",
            padding: "10px",
            borderRadius: "8px",
            border: "1px solid #ECEDF2",
            boxShadow: "0px 6px 15px rgba(64, 79, 104, 0.05)",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {userData?.teamNote ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      // color: "#1967D2",

                      fontSize: "20px",
                      ml: "2vw",
                      fontWeight: 500,
                    }}
                  >
                    Note
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      mr: "0.5vw",
                      fontWeight: 300,
                    }}
                  >
                    Send To Organizations
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "100%" },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "17px",
                      //   ml: "2vw",
                      fontWeight: 300,
                    }}
                  >
                    {userData?.teamNote}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
          {userData?.orgNote ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      // color: "#1967D2",

                      fontSize: "20px",
                      ml: "2vw",
                      fontWeight: 500,
                    }}
                  >
                    Note
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      mr: "0.5vw",
                      fontWeight: 300,
                    }}
                  >
                    Received By Organization
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "100%" },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "17px",
                      //   ml: "2vw",
                      fontWeight: 300,
                    }}
                  >
                    {userData?.orgNote}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default TeamDashboardCandidateView;
