import { Box, Button, Container, Grid, Input, Stack, TextField, Skeleton ,Typography,Modal } from "@mui/material";
import { useState ,useEffect} from "react";
import { useForm } from "react-hook-form";
import { EmployeeDashboardComponentChattingTracedIcon,EmployeeDashboardDeleteProfileIcon, EmployeeDashboardComponentSeeTracedIcon, EmployeeDashboardComponentTickIcon } from "../../utilities/Icons/Icons";
import ProfileLogo from "../../utilities/images/svg images/ProfileLogo.svg";
import classes from "./SuperAdminDashboardContactUsDetails.module.css";
import convertBase64 from "../../Helpers/fileConvert";
import { toast, ToastContainer } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import {updateAboutUs,getAboutUs ,addTestimonial ,getTestimonial ,deleteTerms} from "../../firebase/functions";
import LoadingSearchComponents from "../SharedComponents/LoadingSearchComponents/LoadingSearchComponents";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
const SuperAdminDashboardAboutUs = () => {
  const [image, setImage] = useState("");
  const [displayImage, setDisplayImage] = useState("");
  const [TestimonialData , setTestimonialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAbout, setLoadingAbout] = useState(true);
  const [loadingLive , setloadingLive] = useState(false);
  const [flag , setflag] = useState(0);
  const [flagtest , setflagtest] = useState(0);
  const fakeArr = [1, 2, 3, 4, 5, 6];
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const onSubmitAboutUs = (data) => { console.log(data);
    setLoadingAbout(true);
    updateAboutUs(data).then((response) => {
      console.log(response);
      toast.success("About Us updated", {
        onClose: () => {
          setflag(flag + 1);
        },
        autoClose: 1000
      })
        setLoadingAbout(false);
      
    }).catch((e)=>{
      setLoadingAbout(false);
      toast.error(e.message);
    })
  
  };
  const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, reset:reset2} = useForm();
  const onSubmitTest = (data) => {
    setLoading(true);
    console.log(JSON.stringify(data));
    console.log({
      ...data,
      image: displayImage,
    });
 //   console.log(data);
    addTestimonial({
      ...data,
      image: displayImage,
    }).then((response) => {
      console.log(response);
      toast.success("Testimonial Added", {
        onClose: () => {
          setflagtest(flagtest + 1);
          reset2();
          setLoading(false);
          setImage('');
        },
        autoClose: 1000
      })
        
      
    }).catch((e)=>{
      setLoading(false);
      toast.error(e.message);
    })
  };
  const handleFileChange = async (file) => {
    setImage(file);
    const base64 = await convertBase64(file);
    setDisplayImage(base64);
  };
  useEffect(() => {
    getTagsFromFirebase();
  }, [flag])

  const getTagsFromFirebase = async() => {
    // getAboutUs({ docName: "aboutUs" }).then(({data}) => {
    //   console.log(data);
    //   reset({...data});
    //   setLoadingAbout(false);
    // }).catch((e)=>{
    //   setLoadingAbout(false);
    //   toast.error(e.message);
    // })
    const usercollectionRef = doc(db, `superAdmin/aboutUs`);
     const docSnap = await getDoc(usercollectionRef);
     if (docSnap.exists()) {
      reset({ ...docSnap.data() });
       setLoadingAbout(false);
     } else {
       //! doc.data() will be undefined in this case
       console.log("No such document!");
      setLoadingAbout(false);
       toast.error("Something went worng");
     }
  }
  useEffect(() => {
    getTestFromFirebase();
  }, [flagtest])

  const getTestFromFirebase = async() => {
    setloadingLive(true);
    // getTestimonial({ docName: "aboutUs" }).then(({data}) => {
    //   console.log(data);
    //     setloadingLive(false);
    //   setTestimonialData(data);
    // }).catch((e)=>{
    //   setloadingLive(false);
    //   toast.error(e.message);
    // })
//!Sdk implementing
    const employerPostedJob = collection(db,`superAdmin/testimonial/infoDoc`);
      await getDocs(employerPostedJob)
        .then((docSnap) => {
          setTestimonialData(
            docSnap.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            })
          );
      setloadingLive(false);
        })
        .catch((e) => {
          console.log(e);
         setloadingLive(false);
      toast.error(e.message);
        });
  }
  const dele = (i) =>{
    console.log(i);
    deleteTerms({ form: "testimonial",index:i}).then((response) => {
      console.log(response);
      toast.success("Testimonial deleted");
      setflagtest(flagtest + 1);
    }).catch((e) => {
      console.log(e);
    })
  }
  return (
    <Container
      className={classes.hidescrollbar}
      sx={{ height: "80vh", overflowY: "scroll" }}
    >
      <Box sx={{ mt: "60px" }}>
        <Box>
          <Typography
            sx={{
              color: "#25C027",
              fontFamily: "Sofia Pro",
              fontSize: "30px",
              fontWeight: 500,
            }}
          >
            About Us
          </Typography>
        </Box>
        <Box
          component={"form"}
          noValidate
          onSubmit={handleSubmit(onSubmitAboutUs)}
          sx={{
            width: "100%",
            mt: "40px",
            background: "#FFFFFF",
            boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
            borderRadius: "8px",
            padding: "30px 20px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              About Us Intro
            </Typography>
            <TextField
              id="outlined-basic"
              //   label="Write something about company"
              variant="outlined"
              color="success"
              multiline
              {...register("about", { required: "About Us is required" })}
              rows={10}
              sx={{
                width: "100%",
                mt: "10px",
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
              error={Boolean(errors.aboutUs)}
              helperText={errors.aboutUs && errors.aboutUs.message}
            />
          </Box>
          <LoadingButton
            sx={{
              width: "150px",
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Sofia Pro",
              backgroundColor: "#25C027",
              color: "#fff",
              padding: "15px 40px",
              borderRadius: "8px",
              mt: "30px",
              "&:hover": {
                backgroundColor: "#25C027",
              },
            }}
            type="submit"
            loading={loadingAbout}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
      {/* <SuperAdminDashBoardAboutUsAddTersimonial/> */}
      <Box
        sx={{
          width: "100%",
          mt: "30px",
          padding: "30px 20px",
          backgroundColor: "#fff",
          boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
          borderRadius: "8px",
        }}
        component={"form"}
        noValidate
        onSubmit={handleSubmit2(onSubmitTest)}
      >
        <Typography
          sx={{
            color: "",
            fontFamily: "Sofia Pro",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Testimonial
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            mt: "20px",
          }}
        >
          <Box>
            <img
              style={{ width: "87px", height: "87px", borderRadius: "50%" }}
              src={image ? URL.createObjectURL(image) : ProfileLogo}
              alt=""
            />
          </Box>
          <Box>
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                sx={{ display: "none" }}
                onChange={(e) => {
                  handleFileChange(e.target.files[0]);
                }}
              />
              <Button
                variant="contained"
                component="span"
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontFamily: "Sofia Pro",
                  backgroundColor: "#25C027",
                  color: "#fff",
                  padding: "15px 30px",
                  borderRadius: "8px",
                  mt: "30px",
                  "&:hover": {
                    backgroundColor: "#25C027",
                  },
                }}
              >
                Upload Photo
              </Button>
            </label>

            <Typography
              sx={{
                color: "#696969",
                fontFamily: "Sofia Pro",
                fontSize: "14px",
                mt: "30px",
              }}
            >
              Max file size is 1MB, Minimum dimension: 330x300 And Suitable
              files are .jpg & .png
            </Typography>
          </Box>
        </Box>

        <Stack spacing={4} sx={{ mt: "50px" }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Name
                </Typography>
                <TextField
                  id="outlined-basic"
                  //    label="John"
                  variant="outlined"
                  sx={{ width: "100%", mt: "10px" }}
                  {...register2("name", { required: "Name is required" })}
                  error={Boolean(errors2.name)}
                  helperText={errors2.name && errors2.name.message}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Occupation
                </Typography>
                <TextField
                  id="outlined-basic"
                  //  label="plumber"
                  variant="outlined"
                  sx={{ width: "100%", mt: "10px" }}
                  {...register2("occupation", {
                    required: "Occupation is required",
                  })}
                  error={Boolean(errors2.occupation)}
                  helperText={errors2.occupation && errors2.occupation.message}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Tag Line
                </Typography>
                <TextField
                  id="outlined-basic"
                  // label="Great quality!"
                  variant="outlined"
                  sx={{ width: "100%", mt: "10px" }}
                  {...register2("tagLine", {
                    required: "Tag Line is required",
                  })}
                  error={Boolean(errors2.tagLine)}
                  helperText={errors2.tagLine && errors2.tagLine.message}
                />
              </Box>
              <Box>
                <LoadingButton
                  sx={{
                    width: "150px",
                    textTransform: "none",
                    fontSize: "14px",
                    fontFamily: "Sofia Pro",
                    backgroundColor: "#25C027",
                    color: "#fff",
                    padding: "15px 40px",
                    borderRadius: "8px",
                    mt: "30px",
                    "&:hover": {
                      backgroundColor: "#25C027",
                    },
                  }}
                  type="submit"
                  loading={loading}
                >
                  Add
                </LoadingButton>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                >
                  Description
                </Typography>
                <TextField
                  id="outlined-basic"
                  //  label="Write something about company"
                  variant="outlined"
                  color="success"
                  multiline
                  rows={5}
                  sx={{
                    width: "100%",
                    mt: "10px",
                    color: "#696969",
                    fontFamily: "Sofia Pro",
                    fontSize: "15px",
                  }}
                  {...register2("description", {
                    required: "Description is required",
                  })}
                  error={Boolean(errors2.description)}
                  helperText={
                    errors2.description && errors2.description.message
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box sx={{ mt: "40px" }}>
        <Typography>Live</Typography>

        <Stack
          spacing={3}
          className={classes.hidescrollbar}
          sx={{ mt: "20px", height: "80vh", overflowY: "scroll" }}
        >
          {TestimonialData.map((arr, index) => (
            <>
              {" "}
              <Box
                sx={{
                  padding: "24px 20px",
                  borderRadius: "8px",
                  boxShadow: "0px 20px 60px rgba(71, 119, 54, 0.15)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #ECEDF2",
                    padding: "20px 24px",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    sx={{
                      width: "75%",
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <img
                        style={{
                          width: "87px",
                          height: "87px",
                          borderRadius: "50%",
                        }}
                        src={arr.image ? arr.image : ProfileLogo}
                        alt=""
                      />
                    </Box>
                    <Box>
                      <Typography>{arr.name}</Typography>
                      <Typography>{arr.tagline}</Typography>
                      <Typography sx={{ mt: "10px" }}>
                        {arr.description}
                      </Typography>
                      <Typography sx={{ mt: "10px" }}>
                        {arr.occupation}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {/* <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#EAF9EA",
                    }}
                  >
                   <EmployeeDashboardComponentChattingTracedIcon /> 
                  </Box> */}
                    <Box
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#EAF9EA",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        dele(arr.id);
                      }}
                    >
                      <EmployeeDashboardDeleteProfileIcon />
                    </Box>
                    {/* <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#EAF9EA",
                    }}
                  >
                 <EmployeeDashboardComponentTickIcon /> 
                  </Box> */}
                  </Box>
                </Box>
              </Box>
            </>
          ))}
        </Stack>
      </Box>
      <Modal
        open={loadingLive}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          border={5}
          sx={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems="center"
        >
          <LoadingSearchComponents />
        </Stack>
      </Modal>
    </Container>
  );
};

export default SuperAdminDashboardAboutUs;
