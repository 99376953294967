import { Box, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import {
  CitySelector,
  CountrySelector,
  StateSelector
} from "volkeno-react-country-state-city";
import {
  responsiveFlexDirection,
  responsiveWidth
} from "../../utilities/commonStyles/commonStyles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const CandidateProfileUpdatingContactInformation = ({
  register,
  errors,
  setValue,
  getValues,
}) => {
  const theme = useTheme();
  const [country, setCountry] = React.useState([]);
  const [location, setLocation] = React.useState({});

  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCountry(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            color: "#25C027",
            fontFamily: "Sofia Pro",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Address
        </Typography>
      </Box>
      <Stack spacing={3} sx={{ mt: "30px" }}>
        <Box sx={{ ...responsiveFlexDirection, display: "flex", gap: "25px" }}>
          <Box sx={{ ...responsiveWidth }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Country
            </Typography>
            {/* <FormControl sx={{ width: "100%", mt: "10px" }}> */}
            <CountrySelector
              onChange={(e) => {
                console.log(e.name);
                setValue("country", e.name);
                setLocation({ ...location, country: e });
              }}
              name="country"
              placeholder={
                getValues("country") ? getValues("country") : "Select Country"
              }
              value={location.country}
            />
            {/* </FormControl> */}
          </Box>
          <Box sx={{ ...responsiveWidth }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              State*
            </Typography>
            <StateSelector
              country={location.country}
              name="state"
              value={location.state}
              placeholder={
                getValues("state") ? getValues("state") : "Select State"
              }
              countryPlaceholder={
                getValues("state") ? getValues("state") : "Select country first"
              }
              onChange={(e) => {
                console.log(e);
                setValue("state", e.name);
                setLocation({ ...location, state: e });
              }}
            />
          </Box>
          <Box sx={{ ...responsiveWidth }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              City*
            </Typography>
            <CitySelector
              state={location.state}
              name="city"
              value={location.city}
              placeholder={
                getValues("city") ? getValues("city") : "Select City"
              }
              statePlaceholder={
                getValues("city") ? getValues("city") : "Select state first"
              }
              onChange={(e) => {
                console.log(e);
                setValue("city", e.name);
                setLocation({ ...location, city: e });
              }}
            />
          </Box>
        </Box>
        <Stack
          gap={1}
          sx={{ ...responsiveFlexDirection, display: "flex", width: "100%" }}
        >
          <Stack
            sx={{
              ...responsiveWidth,
            }}
          >
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Complete Address
            </Typography>

            <TextField
              id="outlined-basic"
              // label="Complete Address"
              variant="outlined"
              {...register("address")}
              sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
            />
          </Stack>
          <Stack sx={{ ...responsiveWidth }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              Zip
            </Typography>

            <TextField
              id="outlined-basic"
              // label="Complete Address"
              variant="outlined"
              {...register("zip")}
              sx={{ minWidth: "200px", mt: "10px", backgroundColor: "#F0FAF0" }}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* <Stack spacing={3} sx={{ mt: "30px" }}>
        <Box sx={{ display: "flex", gap: "25px" }}>
          <Box sx={{ width: "100%" }}>
            <Typography>Country</Typography>
            <FormControl sx={{ width: "100%", mt: "10px" }}>
              <TextField
                {...register("country", {
                  required: "This field is required",
                })}
                error={Boolean(errors.country)}
                helperText={errors.country && errors.country.message}
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
              />
            </FormControl>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography
              sx={{
                color: "#202124",
                fontFamily: "Sofia Pro",
                fontSize: "15px",
              }}
            >
              City
            </Typography>
            <TextField
              {...register("city", {
                required: "This field is required",
              })}
              error={Boolean(errors.city)}
              helperText={errors.city && errors.city.message}
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "100%", mt: "20px", backgroundColor: "#F0FAF0" }}
            />
          </Box>
        </Box>
        <Stack direction justifyContent={"space-between"}>
        <Box sx={{ width: "48%" }}>
          <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          >
            State
          </Typography>
          <TextField
            {...register("state", {
              required: "This field is required",
            })}
            error={Boolean(errors.state)}
            helperText={errors.state && errors.state.message}
            id="outlined-basic"
            variant="outlined"
            sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
          />
        </Box>
        <Box sx={{ width: "48%" }}>
          <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          >
            ZIP Code
          </Typography>
          <TextField
            {...register("zip", {
              required: "Zip code is required",
              pattern:{
                value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                message: "Zip code is not valid"
              }
            })}
            id="outlined-basic"
            error={Boolean(errors.zip)}
            helperText={errors.zip && errors.zip.message}
            variant="outlined"
            sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
          />
        </Box>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#202124",
              fontFamily: "Sofia Pro",
              fontSize: "15px",
            }}
          >
            Complete Address
          </Typography>
          <TextField
            {...register("address", {
              required: "This field is required",
            })}
            error={Boolean(errors.address)}
            helperText={errors.address && errors.address.message}
            id="outlined-basic"
            variant="outlined"
            sx={{ width: "100%", mt: "10px", backgroundColor: "#F0FAF0" }}
          />
        </Box>
      </Stack> */}
    </Box>
  );
};

export default CandidateProfileUpdatingContactInformation;
