import { Avatar, Box, Button, Container, Typography } from "@mui/material";

import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetType } from "../../../context/authContext";
import { auth } from "../../../firebase/firebaseConfig";
import { featuredJob, getCategories ,getFeaturedJobAuth,getFormField} from "../../../firebase/functions";
import { FeaturedCandidatesRightArrowIcon, RecentJobsLocationIcon } from "../../../utilities/Icons/Icons";
import CategoryCard from "./CategoryCard";
import PopularJobCard from "./PopularJobCard";
const PopularJobCategories = () => {
  const user = auth.currentUser;
  const userType = GetType();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [flag, setflag] = useState(false);
  useEffect(() => {
    getFeaturedJobAuth()
    .then(({ data }) => {
      console.log("FEAAATURED", data);
      setAppliedJobs(data);
      setflag(true);
    }).catch((e) => {
      console.log(e.message);
    });
  }, []);
  const getJobData = () => {
    
  }
  return (
    <Box sx={{ mt: "100px" }}>
      {flag ? (
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#202124",
                fontSize: { xs: "20px", md: "30px" },
                fontWeight: 500,
              }}
            >
              Featured Jobs
            </Typography>

            {!user ? (
              <Box
                onClick={() => navigate("/job-search")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <Typography sx={{ color: "#25C027", fontSize: "14px" }}>
                  Browse All
                </Typography>
                <FeaturedCandidatesRightArrowIcon />
              </Box>
            ) : userType.role == "user" ? (
              <Box
                onClick={() => navigate("/job-search")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <Typography sx={{ color: "#25C027", fontSize: "14px" }}>
                  Browse All
                </Typography>
                <FeaturedCandidatesRightArrowIcon />
              </Box>
            ) : (
              <></>
            )}
            {/* <Box
          onClick={() => navigate("/candidate-list")}
          sx={{ display: "flex", alignItems: "center", gap: "10px",cursor:"pointer" }}
        >
          <Typography sx={{ color: "#25C027", fontSize: "14px" }}>
            Browse All
          </Typography>
          <FeaturedCandidatesRightArrowIcon />
        </Box> */}
          </Box>

          <Box sx={{ flexGrow: 1, mt: "30px" }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {appliedJobs
                ?.map((item) => <PopularJobCard data={item} avoidTag={true} />)
                .slice(0, 4)}
            </Grid>
          </Box>
        </Container>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PopularJobCategories;
